import React, { useCallback, useEffect, useState } from 'react';
import { getDetailUser, getSuspicious, updateBlacklist } from '../../services';
import moment from 'moment';
import { Button, Card, List, Popover, Select, Slider, Table } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Layer, Map, MapLayerMouseEvent, MapRef, MapboxStyle, Source } from 'react-map-gl';
import { APP_COLOR, MAPBOX_TOKEN, MAP_STYLE, initialViewState, pointLayer } from '../../constant';
import { decodeGeoHash } from './helper';
import { IResponseUserDetail, PointData } from '../../constant/type';
import { LngLat, LngLatBounds } from 'mapbox-gl';
import './index.css';
import { Link, useHistory, useParams } from 'react-router-dom';

const DetectSuspicious = () => {
    const [suspiciousData, setSuspiciousData] = useState<any>([]);
    const { IUserId, IStartTime, IEndTime }: any = useParams();

    // Get today's date
    const today = moment().unix();

    // Get the date from one week ago
    const oneWeekAgo = moment().subtract(1, 'weeks').unix();
    const [customerDetail, setCustomerDetail] = useState<IResponseUserDetail | null>();
    const getInforUser = async (id: any) => {
        const customerData = await getDetailUser({ id: id });
        console.log('customerData', customerData.data);

        setCustomerDetail(customerData.data);
    };
    useEffect(() => {
        setCustomerDetail(null);
        const fetchData = async () => {
            try {
                const res = await getSuspicious({
                    start_date: oneWeekAgo,
                    end_date: today,
                });
                let finalData = res.data;
                if (IUserId) {
                    finalData = finalData.filter((item: any) => item.user_id == IUserId)
                    getInforUser(IUserId);
                }
                if (IStartTime) {
                    finalData = finalData.filter((item: any) => item.start_time == IStartTime)
                }
                if (IEndTime) {
                    finalData = finalData.filter((item: any) => item.end_time == IEndTime)
                }

                setSuspiciousData(finalData);
            } catch (error) {
                // Handle error if necessary
                console.error(error);
            }
        };

        fetchData();
    }, [IUserId]);
    const [show, setShow] = useState<any>(false);
    const [suspiciousRoute, setSuspiciousRoute] = useState<any>([]);
    const mapRef = React.useRef<MapRef>(null);
    const [hoverInfo, setHoverInfo] = useState<any>();

    const onHover = useCallback((event: MapLayerMouseEvent) => {
        const {
            features,
            point: { x, y }
        } = event;
        const hoveredFeature = features && features[0];
        if (hoveredFeature) {
            setHoverInfo(hoveredFeature && { feature: hoveredFeature, x, y });
        } else setHoverInfo(false)
    }, []);
    const [listCenterRoute, setListCenterRoute] = useState<any>([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [inputValue, setInputValue] = useState(0);
    const [flag, setFlag] = useState(0);
    const [endRoute, setEndRoute] = useState<boolean>(false);
    const [controlPlay, setControlPlay] = useState(true);
    const [timeoutID, setTimeoutID] = useState<NodeJS.Timeout[]>([]);
    const formatter = (value: any) => moment.unix(value.toString()).format('HH:mm:ss DD-MM-YYYY');
    const [prevControlPlay, setPrevControlPlay] = useState(true);
    const [pointData, setPointData] = useState<PointData>({ type: '', coordinates: [0, 0] });
    const [currentStep, setCurrentStep] = useState(1);
    const bounds = new LngLatBounds();
    const [data, setData] = useState<any[]>();
    const [open, setOpen] = useState();

    const hide = () => {
        setOpen(undefined);
    };
    console.log('open', open);

    const handleOpenChange = (newOpen: any) => {
        setOpen(newOpen);
    };
    useEffect(() => {
        if (data && data.length > 0) {

            const updatePointData = (index: number) => {
                if (!controlPlay) {
                    return;
                }
                if (index >= data.length) {
                    setEndRoute(true);
                    // Reached the end of the data
                    return;
                }

                const item = data[index];

                const nextIndex = index + 1;
                const nextItem = data[nextIndex];
                setCurrentIndex(nextIndex); // Update the current index

                setPointData({
                    type: 'Point', coordinates: [item.lon, item.lat], data: {
                        speedCurrent: item.speed_current,
                        maxSpeed: item.speed_max,
                        bearing: item.bearing
                    }
                });
                setInputValue(data[index].timestamp);

                const delay = nextItem ? (nextItem.timestamp - item.timestamp) * 1000 / currentStep : 0;

                const id = setTimeout(() => {
                    updatePointData(nextIndex)
                }, delay);

                setTimeoutID(prevTimeoutIDs => [...prevTimeoutIDs, id]);
            };

            timeoutID.forEach(id => {
                clearTimeout(id);
            });

            updatePointData(currentIndex);
        }
    }, [data, controlPlay, currentStep, flag]);

    console.log('listCenterRoute', listCenterRoute);

    const SliderComponent = (data: any) => {
        if (data) {
            const timestamps = data.map((item: any) => item.timestamp);

            // Find the minimum and maximum timestamps
            const minTimestamp = Math.min(...timestamps);
            const maxTimestamp = Math.max(...timestamps);

            return (

                <div
                    className='flex 
                flex-row 
                justify-between 
                items-center 
                rounded-lg 
                px-0.5
                h-12
                bg-sky
                '
                >
                    <div className="bg-blue w-6 h-6 flex items-center justify-center rounded-full"
                        onClick={() => {
                            if (endRoute) {
                                setCurrentIndex(0); // Update the current index
                                setInputValue(data[0].timestamp);
                                setEndRoute(false);
                                setFlag(flag + Math.random());

                            } else {
                                setControlPlay(!controlPlay);
                                if (!controlPlay) {
                                    timeoutID.forEach(id => clearTimeout(id));
                                }
                            }
                        }}
                    >
                        <FontAwesomeIcon
                            fontSize={18} color='white' icon={endRoute ? 'rotate-left' : controlPlay ? 'pause' : 'play'} />
                    </div>

                    <Slider
                        className="flex-1"
                        // trackStyle={{ backgroundColor: APP_COLOR.base, color: APP_COLOR.base }}
                        // railStyle={{ backgroundColor: APP_COLOR.base, color: APP_COLOR.base }}
                        // handleStyle={{ borderColor: APP_COLOR.base, color: APP_COLOR.base }}
                        step={null}
                        tooltip={{ formatter }}
                        min={minTimestamp} max={maxTimestamp}
                        value={typeof inputValue === 'number' ? inputValue : 0}
                        onAfterChange={(value: any) => {
                            // timeoutID.forEach(id => clearTimeout(id));
                            if (prevControlPlay) {
                                setControlPlay(true);
                            } else
                                setControlPlay(false);
                            setFlag(flag + Math.random());
                        }}

                        onChange={(value: any) => {
                            setPrevControlPlay(controlPlay);
                            setControlPlay(false);
                            setEndRoute(false);
                            const index = data.findIndex((item: any) => item.timestamp == value);
                            setCurrentIndex(index); // Update the current index
                            setInputValue(data[index].timestamp);
                            if (!controlPlay) {
                                setPointData({
                                    type: 'Point', coordinates: [data[index].lon, data[index].lat]
                                });
                            }
                        }}

                        marks={timestamps.reduce((acc: any, timestamp: any) => {
                            acc[timestamp] =
                            {
                                label: moment.unix(timestamp).format('HH'),
                                style: { fontSize: 0, color: APP_COLOR.base }
                            }
                            return acc;
                        }, {})} />
                    <Select
                        style={{ marginLeft: 10 }}
                        onChange={(value) => {
                            setCurrentStep(value);
                        }}
                        suffixIcon={<div className="w-0" />}

                        placeholder="1X"
                        options={
                            [{ value: 1, label: '1X' },
                            { value: 5, label: '5X' },
                            { value: 10, label: '10X' },
                            { value: 20, label: '20X' },
                            { value: 50, label: '50X' }]
                        }></Select>
                </div>
            );
        };
    }
    useEffect(() => {
        if (show && suspiciousRoute) {
            let dataSuspiciousRoute: any = []
            suspiciousRoute.length > 0 && suspiciousRoute.map((item: any) => {
                const dataOneStep = []
                const locationNext = decodeGeoHash(
                    item.next.geohash,

                )
                const locationPrev = decodeGeoHash(
                    item.prev.geohash,

                )
                if (item.prev.time == item.next.time) {
                    dataOneStep.push({ lon: locationPrev.lon, lat: locationPrev.lat, timestamp: item.prev.time });
                    dataOneStep.push({ lon: locationNext.lon, lat: locationNext.lat, timestamp: item.next.time + 1234 });
                } else {
                    dataOneStep.push({ lon: locationPrev.lon, lat: locationPrev.lat, timestamp: item.prev.time });
                    dataOneStep.push({ lon: locationNext.lon, lat: locationNext.lat, timestamp: item.next.time });
                }

                dataSuspiciousRoute.push(dataOneStep)
            })
            setListCenterRoute(dataSuspiciousRoute);
        } else setListCenterRoute([]);
    }, [show, suspiciousRoute]);
    return (
        <div className="flex flex-col w-full">
            {
                show ?
                    <div className="flex flex-row items-stretch h-screen"
                    >
                        <div className="w-[26rem] p-2 overflow-auto">

                            <h1 className="text-lg">
                                <FontAwesomeIcon onClick={() => setShow(false)} icon="angle-left"></FontAwesomeIcon>
                                Chi tiết lộ trình
                                User Id:{show}</h1>
                            {SliderComponent(data)}

                            {listCenterRoute && <List dataSource={listCenterRoute} renderItem={(item: any, index) => (
                                <List.Item
                                    onClick={(e) => {
                                        timeoutID.forEach(id => clearTimeout(id));
                                        setControlPlay(true);
                                        setEndRoute(false);
                                        setCurrentIndex(0); // Update the current index
                                        item.forEach((coord: { lon: any; lat: any; }) => bounds.extend(new LngLat(coord.lon, coord.lat)));
                                        mapRef.current?.fitBounds(bounds, { padding: 20, animate: false });
                                        setData(item);
                                        setFlag(index + Math.random());
                                    }}
                                    className="bg-grey p-2 rounded-2xl mb-6 h-12"

                                >
                                    {/* <p>{index}</p> */}
                                    { }
                                    <p className="font-bold">
                                        Ngày : {moment.unix(item[0].timestamp).format('DD/MM/YYYY')}
                                    </p>
                                    {
                                        (item[item.length - 1].timestamp - item[0].timestamp) == 1234 ?
                                            <p style={{ color: 'red' }} className="font-bold">Thời gian trùng nhau: {moment.unix(item[0].timestamp).format('HH:mm')} - {moment.unix(item[item.length - 1].timestamp - 1234).format('HH:mm')}</p>
                                            : <p className="font-bold">Thời gian: {moment.unix(item[0].timestamp).format('HH:mm')} - {moment.unix(item[item.length - 1].timestamp).format('HH:mm')}</p>

                                    }

                                    {/* <p>{item.length}</p> */}
                                    {/* <p>Start Animation</p> */}
                                </List.Item>
                            )}>

                            </List>}
                        </div>
                        <Map
                            style={{ flex: 4, height: '100vh' }}
                            ref={mapRef as React.Ref<MapRef>}
                            initialViewState={{ ...initialViewState, longitude: 107.51220703125, latitude: 11.3214111328125 }}
                            mapStyle={MAP_STYLE as MapboxStyle}
                            // onClick={onClick}
                            mapboxAccessToken={MAPBOX_TOKEN}
                            interactiveLayerIds={['grid']}
                            onMouseMove={onHover}
                            maxZoom={18}
                        >

                            {pointData && (
                                <Source type="geojson" data={pointData}>
                                    <Layer {...pointLayer} />
                                </Source>
                            )}
                            {hoverInfo && (
                                <div className="tooltip" style={{
                                    left: hoverInfo.x, top: hoverInfo.y, position: 'absolute',
                                    margin: '8px',
                                    padding: '4px',
                                    background: 'rgba(0, 0, 0, 0.8)',
                                    color: '#fff',
                                    maxWidth: '300px',
                                    fontSize: '10px',
                                    zIndex: '9',
                                    pointerEvents: 'none'
                                }}>
                                    <div>time: {hoverInfo.feature.properties.time}</div>
                                    <div>type: {hoverInfo.feature.properties.type}</div>

                                    {/* <div>Expire time: {moment.unix(hoverInfo.feature.properties.expire_time).format('HH:mm:ss DD/MM/YYYY')}</div> */}
                                </div>
                            )}
                        </Map>
                    </div>
                    :
                    <div style={{ padding: 24 }}>
                        <h1 className="font-bold text-lg" >{IUserId ? "Detail Suspicios" : "Detect Suspicious"}</h1>
                        {
                            customerDetail &&
                            <div className="shadow-boxShadow rounded-lg p-4 bg-white" style={{ maxWidth: 560, marginBottom: 12, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>

                                <h1>ID: {customerDetail.id}</h1> |
                                <h1>Phone: {customerDetail.phone_number}</h1> |
                                <h1 style={{ color: !customerDetail.blacklist ? APP_COLOR.base : APP_COLOR.warning, fontWeight: 'bold' }}> {!customerDetail.blacklist ? "Đang hoạt động" : "Đã gắn cờ BlackList"} </h1>
                                {!customerDetail.blacklist ?
                                    <Button onClick={() => {
                                        updateBlacklist({
                                            id: customerDetail.id,
                                            blacklist: true
                                        }).then(() => {
setCustomerDetail({...customerDetail, blacklist: true})
                                        })
                                    }} type='primary' >Gắn cờ Black List</Button> : <Button onClick={() => {
                                        updateBlacklist({
                                            id: customerDetail.id,
                                            blacklist: false
                                        }).then(() => {
                                            setCustomerDetail({...customerDetail, blacklist: false})
                                        })

                                    }} danger >Bỏ gắn cờ</Button>}


                            </div>
                        }
                        <Table

                            tableLayout='auto'
                            style={{ height: 'auto', cursor: "pointer" }}
                            dataSource={suspiciousData}
                            columns={[
                                {
                                    title: "STT",
                                    render: (text, record, index) =>
                                        <h4 style={{ fontSize: 14 }}>{index + 1}</h4>,
                                    key: 'id',
                                },
                                {
                                    title: "Record Id",
                                    render: (text, record, index) =>
                                        <h4 style={{ fontSize: 14 }}>{record.id}</h4>,
                                    key: 'id',
                                },
                                {
                                    title: "User Id",
                                    dataIndex: 'user_id',
                                    key: 'user_id',
                                    filterSearch: true,
                                    filters: Array.from(
                                        new Set(
                                            suspiciousData.filter((item: any) => item.user_id !== null && item.user_id !== undefined)
                                                .map((item: any) => item.user_id)
                                        )
                                    ).map((user_id: any) => ({ text: user_id, value: user_id })),
                                    onFilter: (value: any, record) => record.user_id && record.user_id.startsWith(value),
                                    render: (text, record, index) =>
                                        <Link
                                            target='_blank'
                                            to={{ pathname: `/Playback/null/${record.end_time}/${record.user_id}` }}>
                                            {record.user_id}
                                        </Link>
                                },
                                {
                                    title: "Mã lỗi",
                                    dataIndex: 'message',
                                    key: 'message',
                                    filters: Array.from(
                                        new Set(
                                            suspiciousData.map((item: any) => item.message)
                                        )
                                    ).map((message: any) => ({ text: message, value: message })),
                                    onFilter: (value: any, record) => record.message.startsWith(value),
                                    render: (text, record, index) =>

                                        <p>{record.message}</p>
                                },

                                {
                                    title: "Ngày bắt đầu",
                                    render: (text, record, index) => moment.unix(record.start_time.toString()).format('HH:mm:ss DD-MM-YYYY'),
                                    key: 'start_time',
                                    sorter: (a, b) => a.start_time - b.start_time,

                                },
                                {
                                    title: "Ngày kết thúc",
                                    render: (text, record, index) => moment.unix(record.end_time.toString()).format('HH:mm:ss DD-MM-YYYY'),
                                    key: 'end_time',
                                    sorter: (a, b) => a.end_time - b.end_time,

                                },
                                {
                                    title: "Data",
                                    render: (text, record, index) => {
                                        return Array.isArray(record.data) && record.rule_id == 3 ?
                                            <Popover
                                                content={
                                                    <div>{record.data.map((value: any) => <p>{value.toString()}</p>)}
                                                        <a className='text-blue' onClick={hide}>Close</a></div>}
                                                title="IP"
                                                trigger="click"
                                                open={open == record.id}
                                                onOpenChange={() => handleOpenChange(record.id)}
                                            >
                                                <Button>Xem IP</Button>
                                            </Popover>
                                            :
                                            record.rule_id == 1 ? <Button onClick={() => {
                                                setSuspiciousRoute(record.data);
                                                setShow(record.user_id);
                                            }}>Xem lộ trình</Button> :
                                                record.rule_id == 2 ?
                                                    <p> Giờ sử dụng: {Number(record.data.time_using / 3600).toFixed(2)}h</p> :
                                                    record.rule_id == 1 ? <Button onClick={() => {
                                                        setSuspiciousRoute(record.data);
                                                        setShow(record.user_id);
                                                    }}>Xem lộ trình</Button> :
                                                        record.rule_id == 5 ? <p> Số lần gọi: {record.data.number_request} lần</p> :
                                                            record.rule_id == 4 ? <Popover
                                                                content={
                                                                    <div>
                                                                        <a className='text-blue' onClick={hide}>Close</a>
                                                                        {record.data.map((value: any) => <p>{moment.unix(value.time).format('DD/MM/YYYY HH:mm').toString()}</p>)}
                                                                    </div>}
                                                                title="Thời gian gọi request"
                                                                trigger="click"
                                                                open={open == record.id}
                                                                onOpenChange={() => handleOpenChange(record.id)}
                                                            >
                                                                <Button>Xem thời gian</Button>
                                                            </Popover> :
                                                                record.rule_id == 6 ? <Popover
                                                                    content={
                                                                        <div><h3>User Id:</h3>
                                                                            {record.data.user_id.map((value: any) => <p>{value}</p>)}
                                                                            <h3>Ip: {record.data.ip}</h3>
                                                                            <a className='text-blue' onClick={hide}>Close</a></div>}
                                                                    //  title="IP"
                                                                    trigger="click"
                                                                    open={open == record.id}
                                                                    onOpenChange={() => handleOpenChange(record.id)}
                                                                >
                                                                    <Button>Chi tiết</Button>
                                                                </Popover> :
                                                                    <div />
                                    },
                                    key: 'data',
                                },
                                {
                                    title: "",
                                    render: (text, record, index) =>
                                        <Link
                                            target='_blank'
                                            to={{ pathname: `/DetectSuspicious/${record.user_id}` }}>
                                            <Button>Detail</Button>
                                        </Link>
                                    ,
                                },

                            ]}
                        >
                        </Table>
                    </div>
            }
        </div>
    );
};

export default DetectSuspicious;
