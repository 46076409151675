import React, { useEffect, useState } from 'react';
import { List, message } from 'antd';
import { AxiosResponse } from 'axios';
import { FeedbackData } from '../../constant/type';
import { getFeedback } from '../../services';
import { FeebackList } from './component/FeedbackList';
import moment from 'moment';

const FeedbackList: React.FC = () => {
  const [feedbackData, setFeedbackData] = useState<FeedbackData[]>([]);
  useEffect(() => {
    // Get today's date
    const today = moment().format('DD/MM/YYYY');

    // Get the date from 10 days ago
    const oneWeekAgo = moment().subtract(10, 'days').format('DD/MM/YYYY');
    
    const fetchFeedback = async () => {
      try {
        const response: AxiosResponse<FeedbackData[]> = await getFeedback({ sort: 'newest', startDate: oneWeekAgo, endDate: today });
        setFeedbackData(response.data);
      } catch (error) {
        message.error('Lỗi khi lấy dữ liệu góp ý');
        console.error(`Failed to fetch feedback: ${error}`);
      }
    };
    fetchFeedback();
  }, []);
  return (
    <div className='flex flex-col items-stretch text-left'>
      <h1 className='text-xl py-4 font-semibold text-center'>Danh sách góp ý</h1>
      <div className='w-full'>
        <List pagination={{ position: 'bottom', pageSize: 20, align: 'center' }}
          dataSource={feedbackData}
          rowKey={'id'}
          renderItem={(item) => FeebackList({ item })}
        />
      </div>
    </div>
  );
};


export default FeedbackList;