import { Button, Col, Form, FormInstance, Input, Row, Select, Typography, message } from "antd";
import { FC } from "react"
import { DataPointByAIRoadIDPayload, IResponseDataPointV2AIRoadID } from "../../../constant/type";
import { IMARKER_TYPES } from "../../../constant";
import { MapRef } from "react-map-gl";
import { AxiosResponse } from "axios";
import { LngLat, LngLatBounds } from "mapbox-gl";
const { Text } = Typography;

interface PProps {
    handleFormViewFinish: (data: any) => Promise<void>,
    formView: FormInstance<any>,
    handleKeyUp: (event: any) => void,
    setPoints: (value: any) => void,
    listMarker: any,
    setIMarkerType: React.Dispatch<React.SetStateAction<string>>,
    kindOfPoint: any,
    handleClearFilter: () => Promise<void>,
    getDataPointAiRoadID(params: DataPointByAIRoadIDPayload): Promise<AxiosResponse<IResponseDataPointV2AIRoadID[], any>>,
    geohashEncoded: any,
    setGridGeohash: (value: any) => void,
    mapRef: React.RefObject<MapRef>,
    setSelectAIRoadId: React.Dispatch<any>,
    selectAiRoadId: any,
    optionVectorRoadId: any
}

export const ViewPointV2: FC<PProps> = ({
    handleFormViewFinish,
    formView,
    handleKeyUp,
    setPoints,
    listMarker,
    setIMarkerType,
    kindOfPoint,
    handleClearFilter,
    getDataPointAiRoadID,
    geohashEncoded,
    setGridGeohash,
    mapRef,
    setSelectAIRoadId,
    selectAiRoadId,
    optionVectorRoadId
}) => {
    
    return <div className='p-2 overflow-auto w-[18rem]' >
    <p style={{ fontWeight: 'bold' }}>Filter theo toạ độ</p>
    <Form onFinish={handleFormViewFinish} form={formView} onKeyUp={handleKeyUp} layout='vertical'>
        <Row gutter={4}>
            <Col span={20}>
                <Form.Item required name='VLocation' rules={[
                    {
                        required: true,
                        validateTrigger: 'onFinish',
                        message: 'Vui lòng chọn/nhập toạ độ'
                    },
                ]}
                    style={{ width: '100%' }}
                >
                    <Input onKeyUp={handleKeyUp} allowClear placeholder='Nhập toạ độ (vĩ độ - lat, kinh độ - lon)' />
                </Form.Item>
            </Col>
            <Col span={4}>
                <Button className="bg-base text-white" onClick={() => setIMarkerType(IMARKER_TYPES.normal)}>Chọn</Button></Col>
        </Row>
        <Form.Item name='id' label='Loại biển' >
            <Select showSearch onChange={(value) => {
                // setKindOfPoint(value);
                setPoints(listMarker.filter((point: any) => point.traffic_sign_id == value))
                // formView.resetFields(['instruction']);
            }}
                options={kindOfPoint}></Select>
        </Form.Item>
    </Form>
    {geohashEncoded && <Button
        className='bg-base text-white'
        onClick={handleClearFilter}>Clear filter</Button>}
    <p style={{ marginTop: 12, fontWeight: 'bold' }}>Filter theo AI ROAD ID</p>
    <Select style={{ width: "100%" }} showSearch
        onChange={async (value) => {
            setSelectAIRoadId(value);
            formView.resetFields(['VLocation', 'id']);
            const dataAiRoadId = await getDataPointAiRoadID({ ai_road_id: value })
            if (dataAiRoadId.data.length == 0) {
                message.info('Không có dữ liệu')
                return;
            }
            setGridGeohash(undefined);
            const bounds = new LngLatBounds();

            dataAiRoadId.data.forEach(item => bounds.extend(new LngLat(item.lon, item.lat)));
            mapRef.current?.fitBounds(bounds, { padding: 20, animate: false })
            const listMarker = dataAiRoadId.data.flatMap((item: IResponseDataPointV2AIRoadID) => {
                return {
                    lat: item.lat,
                    lon: item.lon,
                    traffic_sign_id: item.traffic_sign_id,
                    osm_id: item.osm_id,
                    direction: item.direction,
                    compass: item.compass,
                };
            });
            setPoints(listMarker)
        }}
        value={selectAiRoadId}

    >
        {optionVectorRoadId.map((item: any) => <Select.Option key={item.value + Math.random()} value={item.value}>{item.label}</Select.Option>)}
    </Select>
</div>
}