export const vietnamBoundariesData = {
    "type": "FeatureCollection",
    "features": [
        {
            "type": "Feature",
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                104.86148709300005,
                                8.413098319000028
                            ],
                            [
                                104.85241131900005,
                                8.428061544000059
                            ],
                            [
                                104.85089869000007,
                                8.44003170700006
                            ],
                            [
                                104.83930186800006,
                                8.44003170700006
                            ],
                            [
                                104.82921767600004,
                                8.429557834000036
                            ],
                            [
                                104.82417557900004,
                                8.420580004000044
                            ],
                            [
                                104.81459559600006,
                                8.414095885000052
                            ],
                            [
                                104.81963769200007,
                                8.411103179000065
                            ],
                            [
                                104.82871346600007,
                                8.411601965000045
                            ],
                            [
                                104.84736922300004,
                                8.40412010700004
                            ],
                            [
                                104.85644499600005,
                                8.403122515000064
                            ],
                            [
                                104.86148709300005,
                                8.413098319000028
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                104.57291783200009,
                                8.877401943000052
                            ],
                            [
                                104.56969089000006,
                                8.882184340000038
                            ],
                            [
                                104.56065545300004,
                                8.884097282000027
                            ],
                            [
                                104.55710581700004,
                                8.88059021500004
                            ],
                            [
                                104.56614125400006,
                                8.87580779700005
                            ],
                            [
                                104.57291783200009,
                                8.877401943000052
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                104.53387183700005,
                                8.942755960000056
                            ],
                            [
                                104.52999950700007,
                                8.953912793000029
                            ],
                            [
                                104.52451370600005,
                                8.957419156000071
                            ],
                            [
                                104.52031868200004,
                                8.955825359000073
                            ],
                            [
                                104.52064137600007,
                                8.947218735000035
                            ],
                            [
                                104.52419101200007,
                                8.938930682000034
                            ],
                            [
                                104.53387183700005,
                                8.942755960000056
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                103.47382149200007,
                                9.25106721000003
                            ],
                            [
                                103.47526716200008,
                                9.259424472000035
                            ],
                            [
                                103.48249551100008,
                                9.272061905000044
                            ],
                            [
                                103.48869123900005,
                                9.291017202000035
                            ],
                            [
                                103.49344129700006,
                                9.299373513000035
                            ],
                            [
                                103.50583275300005,
                                9.30650679100006
                            ],
                            [
                                103.52380036500006,
                                9.310786688000064
                            ],
                            [
                                103.53082219000004,
                                9.315270333000058
                            ],
                            [
                                103.53701791800006,
                                9.321791896000036
                            ],
                            [
                                103.53743096600004,
                                9.330147471000032
                            ],
                            [
                                103.52999609300008,
                                9.330351263000068
                            ],
                            [
                                103.52235469500005,
                                9.318327331000035
                            ],
                            [
                                103.46927795800008,
                                9.31363992300004
                            ],
                            [
                                103.45977784200005,
                                9.311398097000051
                            ],
                            [
                                103.44924510400006,
                                9.299577323000051
                            ],
                            [
                                103.45172339500004,
                                9.29060957200005
                            ],
                            [
                                103.47010405500004,
                                9.284698884000022
                            ],
                            [
                                103.47444106500006,
                                9.277768984000033
                            ],
                            [
                                103.47299539500005,
                                9.267577707000044
                            ],
                            [
                                103.45874522000008,
                                9.247398105000059
                            ],
                            [
                                103.46432137500005,
                                9.24209822000006
                            ],
                            [
                                103.47382149200007,
                                9.25106721000003
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                103.63549127000005,
                                9.376978285000064
                            ],
                            [
                                103.62742391600005,
                                9.374112833000027
                            ],
                            [
                                103.62710122200008,
                                9.367426687000034
                            ],
                            [
                                103.63613665900004,
                                9.367108296000026
                            ],
                            [
                                103.63549127000005,
                                9.376978285000064
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                104.40608494600008,
                                9.641133996000065
                            ],
                            [
                                104.39511334500008,
                                9.661175997000043
                            ],
                            [
                                104.40447147500004,
                                9.665629613000021
                            ],
                            [
                                104.40802111100004,
                                9.671037496000054
                            ],
                            [
                                104.40447147500004,
                                9.678035803000057
                            ],
                            [
                                104.39769489800005,
                                9.684079679000035
                            ],
                            [
                                104.39285448500004,
                                9.691713893000042
                            ],
                            [
                                104.39027293200007,
                                9.701892574000055
                            ],
                            [
                                104.36478009300004,
                                9.716205822000063
                            ],
                            [
                                104.36026237400006,
                                9.723203187000024
                            ],
                            [
                                104.35380849100005,
                                9.728610141000047
                            ],
                            [
                                104.34670921900005,
                                9.72701987000005
                            ],
                            [
                                104.34961346700004,
                                9.715251625000064
                            ],
                            [
                                104.34412766600008,
                                9.703482965000035
                            ],
                            [
                                104.34541844300009,
                                9.690759626000045
                            ],
                            [
                                104.35187232600003,
                                9.679626308000024
                            ],
                            [
                                104.35090424300006,
                                9.666265839000062
                            ],
                            [
                                104.35509926800006,
                                9.655131710000035
                            ],
                            [
                                104.37833324800005,
                                9.647178535000023
                            ],
                            [
                                104.38898215500006,
                                9.636361916000055
                            ],
                            [
                                104.40156722800003,
                                9.629362742000069
                            ],
                            [
                                104.40608494600008,
                                9.641133996000065
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                104.65720554900008,
                                9.806689508000034
                            ],
                            [
                                104.65390116000003,
                                9.817475179000041
                            ],
                            [
                                104.63882488900003,
                                9.820934659000045
                            ],
                            [
                                104.63180306400005,
                                9.820731162000072
                            ],
                            [
                                104.62478123900007,
                                9.816457678000063
                            ],
                            [
                                104.61445502500004,
                                9.806486001000053
                            ],
                            [
                                104.60846582100004,
                                9.797938611000063
                            ],
                            [
                                104.61486807400007,
                                9.794885919000023
                            ],
                            [
                                104.62168337500003,
                                9.797531587000037
                            ],
                            [
                                104.62994434500007,
                                9.798549146000028
                            ],
                            [
                                104.63944446200009,
                                9.793664834000026
                            ],
                            [
                                104.64770543200007,
                                9.796717538000053
                            ],
                            [
                                104.65720554900008,
                                9.806689508000034
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                104.04466747700008,
                                9.815427455000076
                            ],
                            [
                                104.04853980700005,
                                9.817971210000053
                            ],
                            [
                                104.04918519500006,
                                9.823694587000034
                            ],
                            [
                                104.03853628800005,
                                9.824966435000022
                            ],
                            [
                                104.03401856900007,
                                9.819879013000048
                            ],
                            [
                                104.03530934600008,
                                9.814155570000025
                            ],
                            [
                                104.04466747700008,
                                9.815427455000076
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                104.09049004900004,
                                9.843725626000037
                            ],
                            [
                                104.09081274300007,
                                9.850084433000063
                            ],
                            [
                                104.08435886000007,
                                9.84722298500003
                            ],
                            [
                                104.08403616600003,
                                9.84181796100006
                            ],
                            [
                                104.08694041300004,
                                9.838320545000045
                            ],
                            [
                                104.09049004900004,
                                9.843725626000037
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                104.49785784900007,
                                9.964878336000027
                            ],
                            [
                                104.49691793400007,
                                9.966961234000053
                            ],
                            [
                                104.49080848700004,
                                9.965109769000037
                            ],
                            [
                                104.49386321000009,
                                9.962332554000056
                            ],
                            [
                                104.49785784900007,
                                9.964878336000027
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                104.84430362800003,
                                9.953397806000055
                            ],
                            [
                                104.84591709900008,
                                9.959436657000026
                            ],
                            [
                                104.85785678300005,
                                9.97214966200005
                            ],
                            [
                                104.85688870100006,
                                9.978823791000025
                            ],
                            [
                                104.84591709900008,
                                9.978823791000025
                            ],
                            [
                                104.83204125000009,
                                9.969924922000075
                            ],
                            [
                                104.83010508500007,
                                9.959754488000044
                            ],
                            [
                                104.83526819100007,
                                9.954033480000021
                            ],
                            [
                                104.84010860400008,
                                9.952126455000041
                            ],
                            [
                                104.84430362800003,
                                9.953397806000055
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                104.46002627400009,
                                10.014864207000073
                            ],
                            [
                                104.46026125300006,
                                10.018335162000028
                            ],
                            [
                                104.45791146600004,
                                10.019260744000064
                            ],
                            [
                                104.45767648700007,
                                10.014401410000062
                            ],
                            [
                                104.46002627400009,
                                10.014864207000073
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                104.55839659400004,
                                10.024903324000036
                            ],
                            [
                                104.55484695800004,
                                10.032211891000031
                            ],
                            [
                                104.54807038100006,
                                10.033165170000075
                            ],
                            [
                                104.54355266200008,
                                10.024903324000036
                            ],
                            [
                                104.54516613300007,
                                10.015687941000067
                            ],
                            [
                                104.55420157000003,
                                10.015370164000046
                            ],
                            [
                                104.55839659400004,
                                10.024903324000036
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                104.51547827000007,
                                10.100205412000037
                            ],
                            [
                                104.51838251700008,
                                10.113230571000031
                            ],
                            [
                                104.52870873100005,
                                10.116725036000048
                            ],
                            [
                                104.53451722600005,
                                10.121807826000065
                            ],
                            [
                                104.54322996800005,
                                10.124349191000022
                            ],
                            [
                                104.54742499200006,
                                10.13451444900005
                            ],
                            [
                                104.54839307500004,
                                10.142773484000031
                            ],
                            [
                                104.53548530800003,
                                10.145314683000038
                            ],
                            [
                                104.52903142500008,
                                10.148173507000024
                            ],
                            [
                                104.52322293000009,
                                10.155796915000053
                            ],
                            [
                                104.52709526000007,
                                10.168819814000074
                            ],
                            [
                                104.53258106100009,
                                10.180889345000026
                            ],
                            [
                                104.52548178900008,
                                10.187876760000051
                            ],
                            [
                                104.51386479900003,
                                10.181524571000068
                            ],
                            [
                                104.50708822100006,
                                10.151349949000064
                            ],
                            [
                                104.49966625500008,
                                10.144997034000028
                            ],
                            [
                                104.49224428900004,
                                10.135785084000076
                            ],
                            [
                                104.49837547900006,
                                10.123713851000048
                            ],
                            [
                                104.49676200800008,
                                10.09893463700007
                            ],
                            [
                                104.50515205600004,
                                10.09258068400004
                            ],
                            [
                                104.51547827000007,
                                10.100205412000037
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                104.22598287700004,
                                10.20857131200006
                            ],
                            [
                                104.22164586700006,
                                10.20979083900005
                            ],
                            [
                                104.22123281900008,
                                10.204302933000065
                            ],
                            [
                                104.22433068300006,
                                10.202676868000026
                            ],
                            [
                                104.22598287700004,
                                10.20857131200006
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                104.51635599800005,
                                10.248000298000022
                            ],
                            [
                                104.51160594000004,
                                10.250235815000053
                            ],
                            [
                                104.51305161000005,
                                10.244545376000076
                            ],
                            [
                                104.51635599800005,
                                10.248000298000022
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                104.30443628300009,
                                10.256154779000042
                            ],
                            [
                                104.31089016600004,
                                10.27742911200005
                            ],
                            [
                                104.32412062700007,
                                10.28092177600007
                            ],
                            [
                                104.34477305400009,
                                10.293304546000059
                            ],
                            [
                                104.34348227800007,
                                10.304416875000072
                            ],
                            [
                                104.33186528700008,
                                10.311084084000072
                            ],
                            [
                                104.32637948700005,
                                10.319656003000034
                            ],
                            [
                                104.31605327300008,
                                10.31425889600007
                            ],
                            [
                                104.31476249600007,
                                10.297749525000029
                            ],
                            [
                                104.31121286100006,
                                10.286636961000056
                            ],
                            [
                                104.30379089500008,
                                10.281239289000041
                            ],
                            [
                                104.29830509400006,
                                10.259012608000035
                            ],
                            [
                                104.29443276400008,
                                10.250121499000045
                            ],
                            [
                                104.30443628300009,
                                10.256154779000042
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                104.31605327300008,
                                10.358068028000048
                            ],
                            [
                                104.31637596700006,
                                10.363464382000075
                            ],
                            [
                                104.30863130700004,
                                10.363464382000075
                            ],
                            [
                                104.30540436600006,
                                10.359655200000077
                            ],
                            [
                                104.30959939000007,
                                10.356163410000022
                            ],
                            [
                                104.31605327300008,
                                10.358068028000048
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                104.05878534700008,
                                10.374435359000074
                            ],
                            [
                                104.05172641200005,
                                10.396753068000066
                            ],
                            [
                                104.04012959000005,
                                10.406175622000035
                            ],
                            [
                                104.00634754400005,
                                10.421548651000023
                            ],
                            [
                                103.99071704600004,
                                10.444854701000054
                            ],
                            [
                                103.98365811100007,
                                10.447829815000034
                            ],
                            [
                                103.97256549900004,
                                10.44882151400003
                            ],
                            [
                                103.95441395200004,
                                10.424523989000022
                            ],
                            [
                                103.94231292000006,
                                10.401712342000053
                            ],
                            [
                                103.92718663100004,
                                10.36848370100006
                            ],
                            [
                                103.91526711500006,
                                10.359337767000056
                            ],
                            [
                                103.89461468800005,
                                10.362829522000027
                            ],
                            [
                                103.86783107200006,
                                10.37362197300007
                            ],
                            [
                                103.85169636400008,
                                10.375209067000071
                            ],
                            [
                                103.83362549000009,
                                10.374256811000066
                            ],
                            [
                                103.82781699500003,
                                10.36854321900006
                            ],
                            [
                                103.81200498100009,
                                10.370130338000024
                            ],
                            [
                                103.81168228700005,
                                10.357750592000059
                            ],
                            [
                                103.82717160700008,
                                10.339973692000058
                            ],
                            [
                                103.84137015000005,
                                10.32854515400004
                            ],
                            [
                                103.85008289300004,
                                10.307591754000043
                            ],
                            [
                                103.87073532000005,
                                10.296797034000065
                            ],
                            [
                                103.90526359600005,
                                10.271396237000033
                            ],
                            [
                                103.91881675100007,
                                10.259647678000022
                            ],
                            [
                                103.93462876500007,
                                10.240277480000032
                            ],
                            [
                                103.94301881400008,
                                10.213284248000036
                            ],
                            [
                                103.94527767300008,
                                10.192005614000038
                            ],
                            [
                                103.95366772100004,
                                10.178348429000039
                            ],
                            [
                                103.95786274500006,
                                10.159926184000028
                            ],
                            [
                                103.95883082800003,
                                10.141820530000075
                            ],
                            [
                                103.97206128900007,
                                10.106559214000072
                            ],
                            [
                                103.97270667700008,
                                10.078601547000062
                            ],
                            [
                                103.97722439600005,
                                10.066528168000048
                            ],
                            [
                                103.97883786600005,
                                10.05159468000005
                            ],
                            [
                                103.98593713800005,
                                10.041744555000037
                            ],
                            [
                                103.98851869200007,
                                10.027445453000041
                            ],
                            [
                                104.01794436600005,
                                10.00720717300004
                            ],
                            [
                                104.01693594700004,
                                9.994793472000026
                            ],
                            [
                                104.01138964100005,
                                9.989331294000067
                            ],
                            [
                                104.00685175400008,
                                9.975923740000042
                            ],
                            [
                                104.00533912500003,
                                9.963508845000035
                            ],
                            [
                                103.99222967400004,
                                9.922784678000028
                            ],
                            [
                                103.98063285300009,
                                9.917321294000033
                            ],
                            [
                                103.97811180500008,
                                9.910864451000066
                            ],
                            [
                                103.99222967400004,
                                9.907884326000044
                            ],
                            [
                                104.00533912500003,
                                9.907884326000044
                            ],
                            [
                                104.01340647900008,
                                9.918811317000063
                            ],
                            [
                                104.01290227000004,
                                9.952086725000072
                            ],
                            [
                                104.01744015600008,
                                9.967481663000058
                            ],
                            [
                                104.03206223600006,
                                9.990820988000053
                            ],
                            [
                                104.04466747700008,
                                10.002241750000053
                            ],
                            [
                                104.04214642900007,
                                10.014158638000026
                            ],
                            [
                                104.03054960700007,
                                10.030047142000058
                            ],
                            [
                                104.02500330100008,
                                10.073736507000035
                            ],
                            [
                                104.01189385000004,
                                10.084657924000055
                            ],
                            [
                                104.01138964100005,
                                10.10550687400007
                            ],
                            [
                                104.01945699500004,
                                10.122879967000074
                            ],
                            [
                                104.02601172000004,
                                10.14223744800006
                            ],
                            [
                                104.03155802600008,
                                10.16506784400002
                            ],
                            [
                                104.03760854200004,
                                10.182933974000036
                            ],
                            [
                                104.05021378300006,
                                10.206753923000065
                            ],
                            [
                                104.06181060500006,
                                10.220648070000038
                            ],
                            [
                                104.06281902400008,
                                10.24049579800004
                            ],
                            [
                                104.06685270100007,
                                10.270761191000076
                            ],
                            [
                                104.06786112000003,
                                10.335747562000051
                            ],
                            [
                                104.06584428200006,
                                10.355092058000025
                            ],
                            [
                                104.05878534700008,
                                10.374435359000074
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                104.33702839400007,
                                10.401553647000071
                            ],
                            [
                                104.33960994700004,
                                10.413931658000024
                            ],
                            [
                                104.33638300600006,
                                10.44059032000007
                            ],
                            [
                                104.32896104000008,
                                10.448206661000029
                            ],
                            [
                                104.32153907400004,
                                10.44979337500007
                            ],
                            [
                                104.31573057900005,
                                10.438686206000057
                            ],
                            [
                                104.32250715700008,
                                10.43106963100007
                            ],
                            [
                                104.32476601600007,
                                10.425039711000068
                            ],
                            [
                                104.32024829700003,
                                10.412662141000055
                            ],
                            [
                                104.31508519100004,
                                10.406631865000065
                            ],
                            [
                                104.31218094300004,
                                10.397744929000055
                            ],
                            [
                                104.32992912200007,
                                10.39330136700005
                            ],
                            [
                                104.33702839400007,
                                10.401553647000071
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                108.96915697100007,
                                10.501531926000041
                            ],
                            [
                                108.96994479900007,
                                10.511601971000061
                            ],
                            [
                                108.96127869500003,
                                10.523220846000072
                            ],
                            [
                                108.95655173000006,
                                10.538711998000053
                            ],
                            [
                                108.94237083400009,
                                10.551878865000049
                            ],
                            [
                                108.92740211000006,
                                10.54955534100003
                            ],
                            [
                                108.92425080000004,
                                10.534839283000053
                            ],
                            [
                                108.93843169600007,
                                10.524769996000032
                            ],
                            [
                                108.94552214400005,
                                10.517024167000045
                            ],
                            [
                                108.95103693700008,
                                10.503081185000042
                            ],
                            [
                                108.96049086800008,
                                10.493010862000062
                            ],
                            [
                                108.96915697100007,
                                10.501531926000041
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                108.82989931100008,
                                11.223436782000022
                            ],
                            [
                                108.83119008700004,
                                11.23293231100007
                            ],
                            [
                                108.82796314600006,
                                11.233565335000037
                            ],
                            [
                                108.82118656800009,
                                11.22976717000006
                            ],
                            [
                                108.82021848600004,
                                11.225019392000036
                            ],
                            [
                                108.82280003900007,
                                11.220588062000047
                            ],
                            [
                                108.82989931100008,
                                11.223436782000022
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                109.32555755400006,
                                12.002272726000058
                            ],
                            [
                                109.32523486000008,
                                12.006376013000022
                            ],
                            [
                                109.32071714200003,
                                12.007007283000064
                            ],
                            [
                                109.32136253000004,
                                12.001010164000036
                            ],
                            [
                                109.32555755400006,
                                12.002272726000058
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                109.32942988500008,
                                12.042987190000076
                            ],
                            [
                                109.32717102500004,
                                12.050561293000044
                            ],
                            [
                                109.32103983600007,
                                12.042040412000063
                            ],
                            [
                                109.32136253000004,
                                12.034466068000029
                            ],
                            [
                                109.32588024900008,
                                12.034150466000028
                            ],
                            [
                                109.32942988500008,
                                12.042987190000076
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                109.31184950600004,
                                12.168411965000075
                            ],
                            [
                                109.30854511800004,
                                12.171238328000072
                            ],
                            [
                                109.29801238000005,
                                12.171642091000024
                            ],
                            [
                                109.29718628300009,
                                12.167402542000048
                            ],
                            [
                                109.30172981700008,
                                12.162557260000028
                            ],
                            [
                                109.31494737000008,
                                12.161547816000052
                            ],
                            [
                                109.31184950600004,
                                12.168411965000075
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                109.25195746800006,
                                12.170834563000028
                            ],
                            [
                                109.24885960400007,
                                12.175477815000022
                            ],
                            [
                                109.24101168200008,
                                12.178707856000074
                            ],
                            [
                                109.23688119700006,
                                12.177900350000073
                            ],
                            [
                                109.23873991500005,
                                12.173660900000073
                            ],
                            [
                                109.24576174000003,
                                12.17063268100003
                            ],
                            [
                                109.25195746800006,
                                12.170834563000028
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                109.34633905900006,
                                12.190012688000024
                            ],
                            [
                                109.34592601000008,
                                12.193444417000023
                            ],
                            [
                                109.34344771900004,
                                12.193444417000023
                            ],
                            [
                                109.34200204900009,
                                12.191223892000039
                            ],
                            [
                                109.34386076800007,
                                12.189205216000062
                            ],
                            [
                                109.34633905900006,
                                12.190012688000024
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                109.30462115700004,
                                12.224731659000042
                            ],
                            [
                                109.27880562300004,
                                12.237043689000075
                            ],
                            [
                                109.26538154600007,
                                12.23361252500007
                            ],
                            [
                                109.25897929400008,
                                12.225942704000033
                            ],
                            [
                                109.25133789600005,
                                12.22170402300003
                            ],
                            [
                                109.23997906100004,
                                12.23240151500005
                            ],
                            [
                                109.23171809000007,
                                12.233208856000033
                            ],
                            [
                                109.23047894500007,
                                12.225539023000067
                            ],
                            [
                                109.23915296400008,
                                12.214235706000068
                            ],
                            [
                                109.24968570100003,
                                12.205556046000027
                            ],
                            [
                                109.25526185700005,
                                12.193242552000072
                            ],
                            [
                                109.26930550700007,
                                12.186782786000038
                            ],
                            [
                                109.27446861400006,
                                12.182139731000063
                            ],
                            [
                                109.27467513800008,
                                12.174872179000033
                            ],
                            [
                                109.28851226400008,
                                12.178304103000073
                            ],
                            [
                                109.29532756500004,
                                12.183350971000038
                            ],
                            [
                                109.29284927400005,
                                12.194655605000037
                            ],
                            [
                                109.30338201100005,
                                12.196270514000048
                            ],
                            [
                                109.30999078800005,
                                12.188801479000062
                            ],
                            [
                                109.31701261300009,
                                12.191425759000026
                            ],
                            [
                                109.31742566100007,
                                12.184158460000049
                            ],
                            [
                                109.32238224400004,
                                12.183350971000038
                            ],
                            [
                                109.33704546700005,
                                12.192636956000058
                            ],
                            [
                                109.33642589400006,
                                12.201115180000045
                            ],
                            [
                                109.33105626300005,
                                12.204344908000053
                            ],
                            [
                                109.32816492300009,
                                12.211813504000077
                            ],
                            [
                                109.32651272900006,
                                12.222915082000043
                            ],
                            [
                                109.32011047700007,
                                12.226144544000022
                            ],
                            [
                                109.31370822500008,
                                12.223924293000039
                            ],
                            [
                                109.30462115700004,
                                12.224731659000042
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                109.36575234000009,
                                12.273169116000076
                            ],
                            [
                                109.36306752500008,
                                12.27619616100003
                            ],
                            [
                                109.35728484500004,
                                12.276397963000022
                            ],
                            [
                                109.35687179700005,
                                12.269334808000053
                            ],
                            [
                                109.36368709800007,
                                12.267518538000047
                            ],
                            [
                                109.36575234000009,
                                12.273169116000076
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                109.37380678700004,
                                12.28305733600007
                            ],
                            [
                                109.37318721400004,
                                12.286286062000045
                            ],
                            [
                                109.37070892300005,
                                12.28870758100004
                            ],
                            [
                                109.36740453400006,
                                12.28487349900007
                            ],
                            [
                                109.36885020400007,
                                12.278617773000065
                            ],
                            [
                                109.37091544700007,
                                12.278415973000051
                            ],
                            [
                                109.37380678700004,
                                12.28305733600007
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                109.37835032100008,
                                12.35811495200005
                            ],
                            [
                                109.37896989300003,
                                12.361947963000034
                            ],
                            [
                                109.36905672800003,
                                12.369613816000026
                            ],
                            [
                                109.37174154400009,
                                12.361342755000067
                            ],
                            [
                                109.37545898100007,
                                12.35811495200005
                            ],
                            [
                                109.37835032100008,
                                12.35811495200005
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                109.35912704200007,
                                12.485098815000072
                            ],
                            [
                                109.34617384000006,
                                12.491422182000065
                            ],
                            [
                                109.34220857400004,
                                12.489615521000076
                            ],
                            [
                                109.34868517500007,
                                12.48303400900005
                            ],
                            [
                                109.35978791900004,
                                12.480840134000061
                            ],
                            [
                                109.35912704200007,
                                12.485098815000072
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                109.34592601000008,
                                12.630319665000059
                            ],
                            [
                                109.33849113700006,
                                12.634753213000067
                            ],
                            [
                                109.32713230200005,
                                12.647247341000025
                            ],
                            [
                                109.31350170000007,
                                12.65107606500004
                            ],
                            [
                                109.30214286600005,
                                12.64865793000007
                            ],
                            [
                                109.29739280700005,
                                12.642612494000048
                            ],
                            [
                                109.29759933200006,
                                12.630319665000059
                            ],
                            [
                                109.30833859400008,
                                12.613189328000033
                            ],
                            [
                                109.32238224400004,
                                12.607143054000062
                            ],
                            [
                                109.33312150600005,
                                12.598678029000041
                            ],
                            [
                                109.35294783600006,
                                12.576506402000064
                            ],
                            [
                                109.37835032100008,
                                12.554131274000042
                            ],
                            [
                                109.39032872800004,
                                12.552115401000037
                            ],
                            [
                                109.40292670800005,
                                12.557155054000077
                            ],
                            [
                                109.40333975700008,
                                12.56924981700007
                            ],
                            [
                                109.39941579600008,
                                12.583964345000027
                            ],
                            [
                                109.38867653400007,
                                12.612181626000051
                            ],
                            [
                                109.37958946600008,
                                12.624676857000054
                            ],
                            [
                                109.36410014600006,
                                12.63112577000004
                            ],
                            [
                                109.34592601000008,
                                12.630319665000059
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                109.26083801200008,
                                12.680091891000075
                            ],
                            [
                                109.25835972100003,
                                12.682106756000053
                            ],
                            [
                                109.25670752700006,
                                12.67908445200004
                            ],
                            [
                                109.25815319600008,
                                12.677069563000032
                            ],
                            [
                                109.26228368200009,
                                12.676062112000068
                            ],
                            [
                                109.26083801200008,
                                12.680091891000075
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                109.28706659400007,
                                12.697419211000067
                            ],
                            [
                                109.29119707900009,
                                12.70406775300006
                            ],
                            [
                                109.29801238000005,
                                12.710716121000075
                            ],
                            [
                                109.30978426400009,
                                12.719177428000023
                            ],
                            [
                                109.32196919500007,
                                12.731264521000071
                            ],
                            [
                                109.31721913700005,
                                12.733077536000053
                            ],
                            [
                                109.30957773900008,
                                12.725422498000057
                            ],
                            [
                                109.29470799200004,
                                12.71857305900005
                            ],
                            [
                                109.28045781700007,
                                12.69621036600006
                            ],
                            [
                                109.28706659400007,
                                12.697419211000067
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                109.39329177300004,
                                12.826518005000025
                            ],
                            [
                                109.39591413600004,
                                12.82965228200004
                            ],
                            [
                                109.39523739700007,
                                12.833611313000063
                            ],
                            [
                                109.39320718100004,
                                12.832704040000067
                            ],
                            [
                                109.39159992600008,
                                12.828580034000026
                            ],
                            [
                                109.39159992600008,
                                12.826518005000025
                            ],
                            [
                                109.39329177300004,
                                12.826518005000025
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                109.33963849400004,
                                13.284518177000052
                            ],
                            [
                                109.33119394600004,
                                13.29237930100004
                            ],
                            [
                                109.32752240300005,
                                13.292736618000049
                            ],
                            [
                                109.32678809500004,
                                13.285590164000041
                            ],
                            [
                                109.33082679200004,
                                13.281302190000076
                            ],
                            [
                                109.33890418500005,
                                13.279872848000025
                            ],
                            [
                                109.33963849400004,
                                13.284518177000052
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                109.36578986300009,
                                13.605834445000028
                            ],
                            [
                                109.36368445400007,
                                13.611096366000027
                            ],
                            [
                                109.35135277100005,
                                13.62381219200006
                            ],
                            [
                                109.34383345100008,
                                13.636819628000069
                            ],
                            [
                                109.33902108700005,
                                13.635212007000064
                            ],
                            [
                                109.33887070100008,
                                13.628196801000058
                            ],
                            [
                                109.34353267900008,
                                13.620450604000041
                            ],
                            [
                                109.35285663400003,
                                13.613288799000031
                            ],
                            [
                                109.35781938500008,
                                13.607734597000047
                            ],
                            [
                                109.36172943100007,
                                13.600280069000064
                            ],
                            [
                                109.36578986300009,
                                13.599695390000022
                            ],
                            [
                                109.36578986300009,
                                13.605834445000028
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                109.25862428600004,
                                13.68126772900007
                            ],
                            [
                                109.25714592800006,
                                13.684619334000047
                            ],
                            [
                                109.25400441700003,
                                13.685517077000043
                            ],
                            [
                                109.25394281800004,
                                13.68186623300005
                            ],
                            [
                                109.25591396300007,
                                13.679412356000057
                            ],
                            [
                                109.25757711600005,
                                13.678873697000029
                            ],
                            [
                                109.25862428600004,
                                13.68126772900007
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                109.29616096800004,
                                14.138732289000075
                            ],
                            [
                                109.29706328600008,
                                14.140336421000029
                            ],
                            [
                                109.29601058200006,
                                14.143690480000032
                            ],
                            [
                                109.29270208100007,
                                14.144857097000056
                            ],
                            [
                                109.29029589900006,
                                14.141648885000052
                            ],
                            [
                                109.29179976300009,
                                14.137711471000046
                            ],
                            [
                                109.29616096800004,
                                14.138732289000075
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                109.21435077600006,
                                14.247787467000023
                            ],
                            [
                                109.21585464000009,
                                14.250848418000032
                            ],
                            [
                                109.21420039000009,
                                14.254638109000041
                            ],
                            [
                                109.21134304800006,
                                14.255804156000067
                            ],
                            [
                                109.20893686600004,
                                14.253909328000077
                            ],
                            [
                                109.20953841200009,
                                14.247350185000073
                            ],
                            [
                                109.21435077600006,
                                14.247787467000023
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                109.18728122700008,
                                14.36072355500005
                            ],
                            [
                                109.18923625000008,
                                14.36218042400003
                            ],
                            [
                                109.18622852200008,
                                14.364948448000064
                            ],
                            [
                                109.18382234000006,
                                14.361743364000063
                            ],
                            [
                                109.18728122700008,
                                14.36072355500005
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                109.13072772300006,
                                15.371478481000054
                            ],
                            [
                                109.14614820200006,
                                15.384222819000058
                            ],
                            [
                                109.14137519600007,
                                15.388824749000037
                            ],
                            [
                                109.13109487700007,
                                15.394488524000053
                            ],
                            [
                                109.12191602100006,
                                15.39342657800006
                            ],
                            [
                                109.10319115400006,
                                15.387762775000056
                            ],
                            [
                                109.09878530300006,
                                15.383160821000047
                            ],
                            [
                                109.10172253700006,
                                15.37572668000007
                            ],
                            [
                                109.11090139300006,
                                15.371124460000033
                            ],
                            [
                                109.12154886700006,
                                15.367230195000047
                            ],
                            [
                                109.13072772300006,
                                15.371478481000054
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                109.08703636700005,
                                15.427760086000035
                            ],
                            [
                                109.08777067500006,
                                15.431299301000024
                            ],
                            [
                                109.07859181900005,
                                15.433068885000068
                            ],
                            [
                                109.07602173900005,
                                15.42634438400006
                            ],
                            [
                                109.08006043600005,
                                15.424574742000061
                            ],
                            [
                                109.08703636700005,
                                15.427760086000035
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                108.69831180000006,
                                15.809158274000026
                            ],
                            [
                                108.70232754900007,
                                15.816885830000047
                            ],
                            [
                                108.69314869300007,
                                15.818541697000057
                            ],
                            [
                                108.68855926500004,
                                15.81357405700004
                            ],
                            [
                                108.69314869300007,
                                15.80805431400006
                            ],
                            [
                                108.69831180000006,
                                15.809158274000026
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                108.53714209600008,
                                15.901288574000034
                            ],
                            [
                                108.54372150100005,
                                15.903774419000058
                            ],
                            [
                                108.54301656400008,
                                15.910327863000077
                            ],
                            [
                                108.54066677700007,
                                15.914169437000055
                            ],
                            [
                                108.53361741600008,
                                15.913039570000024
                            ],
                            [
                                108.53267750100008,
                                15.900610611000047
                            ],
                            [
                                108.53714209600008,
                                15.901288574000034
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                108.48192209600006,
                                15.93518381000007
                            ],
                            [
                                108.48145213900006,
                                15.941058403000056
                            ],
                            [
                                108.48662167100008,
                                15.943769695000071
                            ],
                            [
                                108.48897145800004,
                                15.946706887000062
                            ],
                            [
                                108.48638669200005,
                                15.949418103000028
                            ],
                            [
                                108.48051222400005,
                                15.946706887000062
                            ],
                            [
                                108.47299290500007,
                                15.938121128000034
                            ],
                            [
                                108.47346286200008,
                                15.930212867000023
                            ],
                            [
                                108.47863239400004,
                                15.927953306000063
                            ],
                            [
                                108.48192209600006,
                                15.93518381000007
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                108.42411733100005,
                                15.970202873000062
                            ],
                            [
                                108.42364737300005,
                                15.974495112000056
                            ],
                            [
                                108.41988771400008,
                                15.974495112000056
                            ],
                            [
                                108.41894779900008,
                                15.971332419000078
                            ],
                            [
                                108.42059265000006,
                                15.96884741100007
                            ],
                            [
                                108.42411733100005,
                                15.970202873000062
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                108.45818924500009,
                                15.972010143000034
                            ],
                            [
                                108.45701435200004,
                                15.979013158000043
                            ],
                            [
                                108.44996499000007,
                                15.979690856000047
                            ],
                            [
                                108.44338558600003,
                                15.975624633000052
                            ],
                            [
                                108.44573537300005,
                                15.970880601000033
                            ],
                            [
                                108.44926005400004,
                                15.969073322000042
                            ],
                            [
                                108.45818924500009,
                                15.972010143000034
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                108.53996184100004,
                                15.922756221000043
                            ],
                            [
                                108.54254660700008,
                                15.932698354000024
                            ],
                            [
                                108.53925690500006,
                                15.954388570000049
                            ],
                            [
                                108.53455733000004,
                                15.962747715000035
                            ],
                            [
                                108.52186847900003,
                                15.966362372000049
                            ],
                            [
                                108.51434916000005,
                                15.988726611000061
                            ],
                            [
                                108.50330516000008,
                                15.984660572000053
                            ],
                            [
                                108.48709162800009,
                                15.970428783000045
                            ],
                            [
                                108.49179120300005,
                                15.964103219000037
                            ],
                            [
                                108.50635988400006,
                                15.956195982000054
                            ],
                            [
                                108.52327835200003,
                                15.943543755000064
                            ],
                            [
                                108.52680303300008,
                                15.933150257000023
                            ],
                            [
                                108.53338243700006,
                                15.922756221000043
                            ],
                            [
                                108.53996184100004,
                                15.922756221000043
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                108.21146158600004,
                                16.21290784200005
                            ],
                            [
                                108.21240150100004,
                                16.21809735100004
                            ],
                            [
                                108.21146158600004,
                                16.22193298700006
                            ],
                            [
                                108.20652703300004,
                                16.225091691000046
                            ],
                            [
                                108.20253239500005,
                                16.22193298700006
                            ],
                            [
                                108.20041758600007,
                                16.216743579000024
                            ],
                            [
                                108.20699699100004,
                                16.211328399000024
                            ],
                            [
                                108.21146158600004,
                                16.21290784200005
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                107.34488942800004,
                                17.15415419900006
                            ],
                            [
                                107.34452227400004,
                                17.160819683000057
                            ],
                            [
                                107.33791349800003,
                                17.16397693500005
                            ],
                            [
                                107.33277333800004,
                                17.161170492000053
                            ],
                            [
                                107.33203902900004,
                                17.156960748000074
                            ],
                            [
                                107.33681203500004,
                                17.152400084000078
                            ],
                            [
                                107.34268650300004,
                                17.151347607000048
                            ],
                            [
                                107.34488942800004,
                                17.15415419900006
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                106.53326234500008,
                                17.934402651000028
                            ],
                            [
                                106.53506698200005,
                                17.93540420200003
                            ],
                            [
                                106.53536775500004,
                                17.937693439000043
                            ],
                            [
                                106.52980345800006,
                                17.937407286000052
                            ],
                            [
                                106.52935229900004,
                                17.935118045000024
                            ],
                            [
                                106.53070577700004,
                                17.933401095000022
                            ],
                            [
                                106.53326234500008,
                                17.934402651000028
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                106.46580465300008,
                                18.10394139500005
                            ],
                            [
                                106.46392482400006,
                                18.107068210000023
                            ],
                            [
                                106.45922524900004,
                                18.105951497000035
                            ],
                            [
                                106.45429069600004,
                                18.103718049000065
                            ],
                            [
                                106.45476065300005,
                                18.100814523000054
                            ],
                            [
                                106.46110507900005,
                                18.099697770000034
                            ],
                            [
                                106.46580465300008,
                                18.10394139500005
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                106.49235724900007,
                                18.116671651000047
                            ],
                            [
                                106.49141733400006,
                                18.118904934000057
                            ],
                            [
                                106.48977248300008,
                                18.118681607000042
                            ],
                            [
                                106.48789265300007,
                                18.11577833000007
                            ],
                            [
                                106.49235724900007,
                                18.116671651000047
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                106.16912878900007,
                                18.322210728000073
                            ],
                            [
                                106.16701134900006,
                                18.32412944400005
                            ],
                            [
                                106.16489390900006,
                                18.321936624000045
                            ],
                            [
                                106.16932128400003,
                                18.320109254000045
                            ],
                            [
                                106.16912878900007,
                                18.322210728000073
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                105.85231280400006,
                                18.750613521000048
                            ],
                            [
                                105.84855314500004,
                                18.767701242000044
                            ],
                            [
                                105.84298884900005,
                                18.78122779200004
                            ],
                            [
                                105.83471759800005,
                                18.78535673300007
                            ],
                            [
                                105.82915330100008,
                                18.784075349000034
                            ],
                            [
                                105.82990523300003,
                                18.776102069000046
                            ],
                            [
                                105.83922918900004,
                                18.762575107000032
                            ],
                            [
                                105.84163537100005,
                                18.750755926000068
                            ],
                            [
                                105.85201203200006,
                                18.748050214000045
                            ],
                            [
                                105.85231280400006,
                                18.750613521000048
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                105.78012734100008,
                                18.79945132200004
                            ],
                            [
                                105.78072888600008,
                                18.80543048800007
                            ],
                            [
                                105.77787154500004,
                                18.808562347000077
                            ],
                            [
                                105.77456304500004,
                                18.808989414000052
                            ],
                            [
                                105.77080338500008,
                                18.80742349600007
                            ],
                            [
                                105.76839720300006,
                                18.80229857000006
                            ],
                            [
                                105.77320956700004,
                                18.79845477400005
                            ],
                            [
                                105.78012734100008,
                                18.79945132200004
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                105.96901263700005,
                                18.801586763000046
                            ],
                            [
                                105.97487770600009,
                                18.80671171000006
                            ],
                            [
                                105.96585452300008,
                                18.808277635000024
                            ],
                            [
                                105.96119254500007,
                                18.811267088000022
                            ],
                            [
                                105.95803443100004,
                                18.816534089000072
                            ],
                            [
                                105.95262052200007,
                                18.81895400800005
                            ],
                            [
                                105.95036472600003,
                                18.81567999200007
                            ],
                            [
                                105.95352284000006,
                                18.809558835000075
                            ],
                            [
                                105.96074138600005,
                                18.80272565300004
                            ],
                            [
                                105.96600491000004,
                                18.800447864000034
                            ],
                            [
                                105.96901263700005,
                                18.801586763000046
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                105.76102827000005,
                                18.854110067000022
                            ],
                            [
                                105.76463754300005,
                                18.85667176000004
                            ],
                            [
                                105.76478793000007,
                                18.85980266400003
                            ],
                            [
                                105.75365933700004,
                                18.85852184600003
                            ],
                            [
                                105.75516320100007,
                                18.854110067000022
                            ],
                            [
                                105.76102827000005,
                                18.854110067000022
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                105.93686754800007,
                                19.34990613900004
                            ],
                            [
                                105.93616261200003,
                                19.354340187000048
                            ],
                            [
                                105.93216797400004,
                                19.355448680000052
                            ],
                            [
                                105.92840831400008,
                                19.34924102100007
                            ],
                            [
                                105.93263793100004,
                                19.346580524000046
                            ],
                            [
                                105.93686754800007,
                                19.34990613900004
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                105.91360465400004,
                                19.32972969900004
                            ],
                            [
                                105.91172482500008,
                                19.33726844100005
                            ],
                            [
                                105.90608533500006,
                                19.345028547000027
                            ],
                            [
                                105.89198661200004,
                                19.357665644000065
                            ],
                            [
                                105.88399733500006,
                                19.367641607000053
                            ],
                            [
                                105.87812286700006,
                                19.366089831000068
                            ],
                            [
                                105.87882780300004,
                                19.35966088500004
                            ],
                            [
                                105.88681708000007,
                                19.351679772000068
                            ],
                            [
                                105.89786108000004,
                                19.344806835000043
                            ],
                            [
                                105.90326559100004,
                                19.338820492000025
                            ],
                            [
                                105.91195980300006,
                                19.323742804000062
                            ],
                            [
                                105.91360465400004,
                                19.32972969900004
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                105.93263793100004,
                                19.365424779000023
                            ],
                            [
                                105.93545767600006,
                                19.382493578000037
                            ],
                            [
                                105.92206388800008,
                                19.385596803000055
                            ],
                            [
                                105.91524950600007,
                                19.375400268000078
                            ],
                            [
                                105.91877418600006,
                                19.367198244000065
                            ],
                            [
                                105.92652848400007,
                                19.36254285800004
                            ],
                            [
                                105.93263793100004,
                                19.365424779000023
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                106.02177419600008,
                                19.903895512000076
                            ],
                            [
                                106.02138920700008,
                                19.906338938000033
                            ],
                            [
                                106.01811679900004,
                                19.904800489000024
                            ],
                            [
                                106.02177419600008,
                                19.903895512000076
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                107.73633460900004,
                                20.124671059000036
                            ],
                            [
                                107.74244405500008,
                                20.13526105400007
                            ],
                            [
                                107.74949341700005,
                                20.159968252000056
                            ],
                            [
                                107.74479384300008,
                                20.159085919000063
                            ],
                            [
                                107.73938933200009,
                                20.153130044000022
                            ],
                            [
                                107.72834533200006,
                                20.146070934000022
                            ],
                            [
                                107.70719724700007,
                                20.137025984000047
                            ],
                            [
                                107.70578737500006,
                                20.13018677100007
                            ],
                            [
                                107.71119188500006,
                                20.124450426000067
                            ],
                            [
                                107.72482065100007,
                                20.11474228700007
                            ],
                            [
                                107.73633460900004,
                                20.124671059000036
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                107.21515180100005,
                                20.609303875000023
                            ],
                            [
                                107.21350695000007,
                                20.612602949000063
                            ],
                            [
                                107.20622261000005,
                                20.61436242600007
                            ],
                            [
                                107.20293290700005,
                                20.611503265000067
                            ],
                            [
                                107.20481273700005,
                                20.607764282000062
                            ],
                            [
                                107.20833741800004,
                                20.606444620000048
                            ],
                            [
                                107.21515180100005,
                                20.609303875000023
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                107.14888780100006,
                                20.61040357400003
                            ],
                            [
                                107.16533631200008,
                                20.61370262400004
                            ],
                            [
                                107.16886099300007,
                                20.61876102900004
                            ],
                            [
                                107.15429231200005,
                                20.62931715900004
                            ],
                            [
                                107.13854873700006,
                                20.630636623000044
                            ],
                            [
                                107.13643392900008,
                                20.633495424000046
                            ],
                            [
                                107.13032448200005,
                                20.625798530000054
                            ],
                            [
                                107.13220431200006,
                                20.61216307600006
                            ],
                            [
                                107.14324831200008,
                                20.609523815000045
                            ],
                            [
                                107.14888780100006,
                                20.61040357400003
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                107.47412772600006,
                                20.704233619000036
                            ],
                            [
                                107.48330658200007,
                                20.712476019000064
                            ],
                            [
                                107.48257227400006,
                                20.740290808000054
                            ],
                            [
                                107.47596349700007,
                                20.737200528000074
                            ],
                            [
                                107.46458171500007,
                                20.713162865000072
                            ],
                            [
                                107.46678464100006,
                                20.703890176000073
                            ],
                            [
                                107.47412772600006,
                                20.704233619000036
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                107.40216549200005,
                                20.751621293000028
                            ],
                            [
                                107.39775964100005,
                                20.756084585000053
                            ],
                            [
                                107.38858078500004,
                                20.751964628000053
                            ],
                            [
                                107.39078371000005,
                                20.745441134000032
                            ],
                            [
                                107.39812679500005,
                                20.742007603000047
                            ],
                            [
                                107.40216549200005,
                                20.751621293000028
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                107.50166429500007,
                                20.75127795800006
                            ],
                            [
                                107.50533583800006,
                                20.757801201000063
                            ],
                            [
                                107.50166429500007,
                                20.764324162000037
                            ],
                            [
                                107.49505551900006,
                                20.76329423900006
                            ],
                            [
                                107.49101682200006,
                                20.75471127900005
                            ],
                            [
                                107.49652413600006,
                                20.748874587000046
                            ],
                            [
                                107.50166429500007,
                                20.75127795800006
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                107.42382759300006,
                                20.77119013300006
                            ],
                            [
                                107.42456190200005,
                                20.780115428000045
                            ],
                            [
                                107.41501589100005,
                                20.798994122000067
                            ],
                            [
                                107.40840711400006,
                                20.80276957800004
                            ],
                            [
                                107.39298663600005,
                                20.796934743000065
                            ],
                            [
                                107.39702533300004,
                                20.781831771000043
                            ],
                            [
                                107.41391442800006,
                                20.762264310000035
                            ],
                            [
                                107.42382759300006,
                                20.77119013300006
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                107.38270631700004,
                                20.806544939000048
                            ],
                            [
                                107.38013623700004,
                                20.81821363800003
                            ],
                            [
                                107.37573038600004,
                                20.824733988000048
                            ],
                            [
                                107.35553690200004,
                                20.84257771500006
                            ],
                            [
                                107.34195219400004,
                                20.848753896000062
                            ],
                            [
                                107.33791349800003,
                                20.841205195000043
                            ],
                            [
                                107.33828065200004,
                                20.82610665800007
                            ],
                            [
                                107.34048357700004,
                                20.816154521000044
                            ],
                            [
                                107.34745950800004,
                                20.80414244700006
                            ],
                            [
                                107.35480259300004,
                                20.806544939000048
                            ],
                            [
                                107.38197200800005,
                                20.794188861000066
                            ],
                            [
                                107.38270631700004,
                                20.806544939000048
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                106.98769239700005,
                                20.854778860000067
                            ],
                            [
                                106.97359367400009,
                                20.861366244000067
                            ],
                            [
                                106.95879001400004,
                                20.87607702500003
                            ],
                            [
                                106.95338550400004,
                                20.877174787000058
                            ],
                            [
                                106.94563120600009,
                                20.874320590000025
                            ],
                            [
                                106.94328141900007,
                                20.858072588000027
                            ],
                            [
                                106.93482218400004,
                                20.84928915300003
                            ],
                            [
                                106.92753784400008,
                                20.844458046000057
                            ],
                            [
                                106.91343912100007,
                                20.82513206400006
                            ],
                            [
                                106.91954856700005,
                                20.814150288000064
                            ],
                            [
                                106.95197563100004,
                                20.781200159000036
                            ],
                            [
                                106.96489946100007,
                                20.772412243000076
                            ],
                            [
                                106.98910226900006,
                                20.740771512000038
                            ],
                            [
                                107.01330507800009,
                                20.73395910000005
                            ],
                            [
                                107.02270422700008,
                                20.72187180800006
                            ],
                            [
                                107.03351324800008,
                                20.71747619000007
                            ],
                            [
                                107.04314737600004,
                                20.715717907000055
                            ],
                            [
                                107.05701112000008,
                                20.720992695000064
                            ],
                            [
                                107.06570533300004,
                                20.733519579000074
                            ],
                            [
                                107.06476541800004,
                                20.755054606000044
                            ],
                            [
                                107.05513129100007,
                                20.76494211200003
                            ],
                            [
                                107.05724609900005,
                                20.773510760000022
                            ],
                            [
                                107.07721929100006,
                                20.783397058000048
                            ],
                            [
                                107.07815920500008,
                                20.796358112000064
                            ],
                            [
                                107.05607120600007,
                                20.82030018100005
                            ],
                            [
                                107.04761197200008,
                                20.835893428000077
                            ],
                            [
                                107.03962269500005,
                                20.846873619000064
                            ],
                            [
                                107.02434907800006,
                                20.85565719500005
                            ],
                            [
                                107.01166022700005,
                                20.859390059000077
                            ],
                            [
                                107.00226107800006,
                                20.84928915300003
                            ],
                            [
                                106.98769239700005,
                                20.854778860000067
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                107.49321974700007,
                                20.819586367000056
                            ],
                            [
                                107.51194461400007,
                                20.853557418000037
                            ],
                            [
                                107.56261190100008,
                                20.919761746000063
                            ],
                            [
                                107.56885352400008,
                                20.935879621000026
                            ],
                            [
                                107.56922067800008,
                                20.949252711000042
                            ],
                            [
                                107.56151043900007,
                                20.95233863900006
                            ],
                            [
                                107.55380019900008,
                                20.948224054000036
                            ],
                            [
                                107.53507533200008,
                                20.925591816000065
                            ],
                            [
                                107.52442785900007,
                                20.90707198700005
                            ],
                            [
                                107.48881389600007,
                                20.86487939700004
                            ],
                            [
                                107.48807958800006,
                                20.836058137000066
                            ],
                            [
                                107.48404089100006,
                                20.822331787000053
                            ],
                            [
                                107.48514235400006,
                                20.808604186000025
                            ],
                            [
                                107.49321974700007,
                                20.819586367000056
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                107.33460910900004,
                                20.872083849000035
                            ],
                            [
                                107.35076389600005,
                                20.880317086000048
                            ],
                            [
                                107.35884129000004,
                                20.888549871000066
                            ],
                            [
                                107.36030990700004,
                                20.904328114000066
                            ],
                            [
                                107.39004940200005,
                                20.925934754000025
                            ],
                            [
                                107.40583703500005,
                                20.941023259000076
                            ],
                            [
                                107.41832027900006,
                                20.965024564000032
                            ],
                            [
                                107.40730565200005,
                                20.969824363000043
                            ],
                            [
                                107.39115086400005,
                                20.970852871000034
                            ],
                            [
                                107.38380777900005,
                                20.96845300700005
                            ],
                            [
                                107.37426176900004,
                                20.959538891000022
                            ],
                            [
                                107.35149820500004,
                                20.946166720000065
                            ],
                            [
                                107.33240618400004,
                                20.939994545000047
                            ],
                            [
                                107.33240618400004,
                                20.924562997000066
                            ],
                            [
                                107.33093756700003,
                                20.918389932000025
                            ],
                            [
                                107.31184554500004,
                                20.911873644000025
                            ],
                            [
                                107.30523676900003,
                                20.905700057000047
                            ],
                            [
                                107.30707254000004,
                                20.891294032000076
                            ],
                            [
                                107.31257985400003,
                                20.878944911000076
                            ],
                            [
                                107.32469594400004,
                                20.867623991000073
                            ],
                            [
                                107.33460910900004,
                                20.872083849000035
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                107.40143118400005,
                                20.893009108000058
                            ],
                            [
                                107.41648450800005,
                                20.909129859000075
                            ],
                            [
                                107.44842692800006,
                                20.933822117000034
                            ],
                            [
                                107.46348025300006,
                                20.949252711000042
                            ],
                            [
                                107.48954820500006,
                                20.990735973000028
                            ],
                            [
                                107.49542267300006,
                                21.007874454000046
                            ],
                            [
                                107.48697812500006,
                                21.00890270000008
                            ],
                            [
                                107.45540285900006,
                                20.993478262000053
                            ],
                            [
                                107.43924807200005,
                                20.980451940000023
                            ],
                            [
                                107.42566336400006,
                                20.965710259000048
                            ],
                            [
                                107.42125751300006,
                                20.951995762000024
                            ],
                            [
                                107.41061004000005,
                                20.933136276000027
                            ],
                            [
                                107.39739248700005,
                                20.913245518000053
                            ],
                            [
                                107.38233916200005,
                                20.89438115400003
                            ],
                            [
                                107.38344062500005,
                                20.885462629000074
                            ],
                            [
                                107.40143118400005,
                                20.893009108000058
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                107.84348490600007,
                                20.99244991000006
                            ],
                            [
                                107.85449953400007,
                                21.00376139800005
                            ],
                            [
                                107.86441269900007,
                                21.02089838300003
                            ],
                            [
                                107.85449953400007,
                                21.03769071800008
                            ],
                            [
                                107.83871190100007,
                                21.032550408000077
                            ],
                            [
                                107.82879873600007,
                                21.02055566300004
                            ],
                            [
                                107.81961988000006,
                                21.01849932400006
                            ],
                            [
                                107.80860525200006,
                                21.001362063000045
                            ],
                            [
                                107.79318477300006,
                                20.99107876200003
                            ],
                            [
                                107.78363876300006,
                                20.99210712400003
                            ],
                            [
                                107.78290445400006,
                                21.01747114400007
                            ],
                            [
                                107.77776429500005,
                                21.029466137000043
                            ],
                            [
                                107.76087519900005,
                                21.021926540000038
                            ],
                            [
                                107.75059488000005,
                                21.005817940000043
                            ],
                            [
                                107.74839195400006,
                                20.986965243000043
                            ],
                            [
                                107.75389926800005,
                                20.97805223100005
                            ],
                            [
                                107.75683650200006,
                                20.962967460000073
                            ],
                            [
                                107.75059488000005,
                                20.94753827900007
                            ],
                            [
                                107.75279780500006,
                                20.936908363000043
                            ],
                            [
                                107.76821828400006,
                                20.945480935000035
                            ],
                            [
                                107.77666283200006,
                                20.953367268000022
                            ],
                            [
                                107.81925272500007,
                                20.975995306000073
                            ],
                            [
                                107.84348490600007,
                                20.99244991000006
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                107.47559634300006,
                                20.88820684700005
                            ],
                            [
                                107.49321974700007,
                                20.90295615900004
                            ],
                            [
                                107.52479501300007,
                                20.93759418700006
                            ],
                            [
                                107.53984833800007,
                                20.95851030400007
                            ],
                            [
                                107.54572280600007,
                                20.995192168000074
                            ],
                            [
                                107.54572280600007,
                                21.04351618800007
                            ],
                            [
                                107.53470817800007,
                                21.040774818000045
                            ],
                            [
                                107.51341323100007,
                                21.02055566300004
                            ],
                            [
                                107.51194461400007,
                                21.00376139800005
                            ],
                            [
                                107.49982852400007,
                                20.97530965800007
                            ],
                            [
                                107.48587666200007,
                                20.960567470000058
                            ],
                            [
                                107.47633065200006,
                                20.947195391000037
                            ],
                            [
                                107.43594368400005,
                                20.911530674000062
                            ],
                            [
                                107.43043637000005,
                                20.89438115400003
                            ],
                            [
                                107.44769262000005,
                                20.88717777100004
                            ],
                            [
                                107.46678464100006,
                                20.883404433000067
                            ],
                            [
                                107.47559634300006,
                                20.88820684700005
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                107.57693091700008,
                                20.981480375000046
                            ],
                            [
                                107.58978131600009,
                                21.006160695000062
                            ],
                            [
                                107.62796535900009,
                                21.099703128000044
                            ],
                            [
                                107.62649674200009,
                                21.116829067000026
                            ],
                            [
                                107.60887333700003,
                                21.110663957000043
                            ],
                            [
                                107.59235139600008,
                                21.075380900000027
                            ],
                            [
                                107.57876668900008,
                                21.056879640000034
                            ],
                            [
                                107.56591629000008,
                                21.03049423500005
                            ],
                            [
                                107.55930751300008,
                                20.991764338000053
                            ],
                            [
                                107.56628344400008,
                                20.980794753000055
                            ],
                            [
                                107.57693091700008,
                                20.981480375000046
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                107.82439288500007,
                                21.100730740000074
                            ],
                            [
                                107.82329142200007,
                                21.10723878400006
                            ],
                            [
                                107.80970671500006,
                                21.116486568000028
                            ],
                            [
                                107.79795777900006,
                                21.122308950000047
                            ],
                            [
                                107.80089501300006,
                                21.110663957000043
                            ],
                            [
                                107.80897240600007,
                                21.09901804900005
                            ],
                            [
                                107.82439288500007,
                                21.100730740000074
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                107.50323571500007,
                                21.035387881000077
                            ],
                            [
                                107.51592456600008,
                                21.043063865000022
                            ],
                            [
                                107.57349435400005,
                                21.09788081100004
                            ],
                            [
                                107.58477333200005,
                                21.117609963000064
                            ],
                            [
                                107.58782805600003,
                                21.12572007500006
                            ],
                            [
                                107.58829801300004,
                                21.14215813100003
                            ],
                            [
                                107.58500831100008,
                                21.145664681000028
                            ],
                            [
                                107.57349435400005,
                                21.137555659000043
                            ],
                            [
                                107.55446107700004,
                                21.117609963000064
                            ],
                            [
                                107.54106729000006,
                                21.102922955000054
                            ],
                            [
                                107.53613273700006,
                                21.092180791000033
                            ],
                            [
                                107.52367886400003,
                                21.080560840000032
                            ],
                            [
                                107.50135588600006,
                                21.06740508300004
                            ],
                            [
                                107.48561231100007,
                                21.045037626000067
                            ],
                            [
                                107.49007690700006,
                                21.032975347000047
                            ],
                            [
                                107.50323571500007,
                                21.035387881000077
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                107.86992001300007,
                                21.131213328000058
                            ],
                            [
                                107.87506017200008,
                                21.143541585000037
                            ],
                            [
                                107.86698277800008,
                                21.15689603800007
                            ],
                            [
                                107.85706961400007,
                                21.158265657000072
                            ],
                            [
                                107.85670245900008,
                                21.15107501400007
                            ],
                            [
                                107.85266376300007,
                                21.143541585000037
                            ],
                            [
                                107.85082799100007,
                                21.133268109000028
                            ],
                            [
                                107.86000684800007,
                                21.131213328000058
                            ],
                            [
                                107.86992001300007,
                                21.131213328000058
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                107.66835232700004,
                                21.15312951900006
                            ],
                            [
                                107.67422679500004,
                                21.171276410000075
                            ],
                            [
                                107.68487426800004,
                                21.195582907000073
                            ],
                            [
                                107.69148304500004,
                                21.203455985000062
                            ],
                            [
                                107.69038158200004,
                                21.209617232000028
                            ],
                            [
                                107.67679687500004,
                                21.20071757100004
                            ],
                            [
                                107.66578224700004,
                                21.188394077000055
                            ],
                            [
                                107.65623623600004,
                                21.157580849000055
                            ],
                            [
                                107.64852599700004,
                                21.13943228000005
                            ],
                            [
                                107.64742453400004,
                                21.12984345900003
                            ],
                            [
                                107.65476761900004,
                                21.126076252000075
                            ],
                            [
                                107.66835232700004,
                                21.15312951900006
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                108.02206873600005,
                                21.206577716000027
                            ],
                            [
                                108.02136379900008,
                                21.211397100000056
                            ],
                            [
                                108.01313954400007,
                                21.21577822200004
                            ],
                            [
                                108.00585520400006,
                                21.208549301000062
                            ],
                            [
                                108.01689920400008,
                                21.20088187700003
                            ],
                            [
                                108.02206873600005,
                                21.206577716000027
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                107.98000754400005,
                                21.236586775000035
                            ],
                            [
                                107.97765775700009,
                                21.24819448200003
                            ],
                            [
                                107.96590882100008,
                                21.25126051600006
                            ],
                            [
                                107.95627469300007,
                                21.24731846000003
                            ],
                            [
                                107.95533477800006,
                                21.241843209000024
                            ],
                            [
                                107.96003435300008,
                                21.235491662000072
                            ],
                            [
                                107.96637877800003,
                                21.23198724300005
                            ],
                            [
                                107.97295818300006,
                                21.22935887400007
                            ],
                            [
                                107.98000754400005,
                                21.236586775000035
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                107.58427400200009,
                                21.247263709000038
                            ],
                            [
                                107.58317254000008,
                                21.257529262000048
                            ],
                            [
                                107.57436083800008,
                                21.26197744500007
                            ],
                            [
                                107.56187759300008,
                                21.26368825000003
                            ],
                            [
                                107.54866004000007,
                                21.258897948000026
                            ],
                            [
                                107.54608996000007,
                                21.24657931300004
                            ],
                            [
                                107.56114328400008,
                                21.241104034000045
                            ],
                            [
                                107.58427400200009,
                                21.247263709000038
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                107.77262413500006,
                                21.283190012000034
                            ],
                            [
                                107.78510738000006,
                                21.284900570000048
                            ],
                            [
                                107.79465339000006,
                                21.28934792700005
                            ],
                            [
                                107.78951323100006,
                                21.294821412000033
                            ],
                            [
                                107.77556136900006,
                                21.294821412000033
                            ],
                            [
                                107.75940658200005,
                                21.290374221000036
                            ],
                            [
                                107.75316496000005,
                                21.28558478700006
                            ],
                            [
                                107.75683650200006,
                                21.282505784000023
                            ],
                            [
                                107.77262413500006,
                                21.283190012000034
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                107.64228437500009,
                                21.23939296800006
                            ],
                            [
                                107.66357932100004,
                                21.254107490000024
                            ],
                            [
                                107.68560857700004,
                                21.273952657000052
                            ],
                            [
                                107.70984075800004,
                                21.29276887900005
                            ],
                            [
                                107.73370578400005,
                                21.308162178000032
                            ],
                            [
                                107.75022772600005,
                                21.315345165000053
                            ],
                            [
                                107.76748397500006,
                                21.326289995000025
                            ],
                            [
                                107.76050804500005,
                                21.33039409500003
                            ],
                            [
                                107.69001442800004,
                                21.29379514900006
                            ],
                            [
                                107.66064208700004,
                                21.27497905900003
                            ],
                            [
                                107.62796535900009,
                                21.247605905000057
                            ],
                            [
                                107.62098942800009,
                                21.23939296800006
                            ],
                            [
                                107.62502812500009,
                                21.233575193000036
                            ],
                            [
                                107.64228437500009,
                                21.23939296800006
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                107.76491389600005,
                                21.36698391500005
                            ],
                            [
                                107.76271097000006,
                                21.371428785000035
                            ],
                            [
                                107.74655618300005,
                                21.36869349600005
                            ],
                            [
                                107.74582187400006,
                                21.36117119000005
                            ],
                            [
                                107.75573503900006,
                                21.35809377100003
                            ],
                            [
                                107.76491389600005,
                                21.36698391500005
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                105.40452087400007,
                                23.30280443600003
                            ],
                            [
                                105.38586511700004,
                                23.314843964000033
                            ],
                            [
                                105.37225145700006,
                                23.32688240300007
                            ],
                            [
                                105.35561253900005,
                                23.350493095000047
                            ],
                            [
                                105.34577036700006,
                                23.372544465000033
                            ],
                            [
                                105.33415337700006,
                                23.38261540800005
                            ],
                            [
                                105.32673141100008,
                                23.392093243000033
                            ],
                            [
                                105.31737328000008,
                                23.39357409300004
                            ],
                            [
                                105.31317825600007,
                                23.388242954000077
                            ],
                            [
                                105.30059318300005,
                                23.384096364000072
                            ],
                            [
                                105.28413578000004,
                                23.354177836000076
                            ],
                            [
                                105.26638760100008,
                                23.350622711000028
                            ],
                            [
                                105.25832024700009,
                                23.34410473400004
                            ],
                            [
                                105.25186636400008,
                                23.33225304700005
                            ],
                            [
                                105.25122097500008,
                                23.312991800000077
                            ],
                            [
                                105.23863590300004,
                                23.29965545600004
                            ],
                            [
                                105.23637704300006,
                                23.28335366400006
                            ],
                            [
                                105.22250119400007,
                                23.27357163100004
                            ],
                            [
                                105.20668918000007,
                                23.27475737000003
                            ],
                            [
                                105.17764670500009,
                                23.289127691000033
                            ],
                            [
                                105.16959225800008,
                                23.28836890100007
                            ],
                            [
                                105.16401610300005,
                                23.278124812000044
                            ],
                            [
                                105.13324398700007,
                                23.262757202000046
                            ],
                            [
                                105.12560258900004,
                                23.257065045000047
                            ],
                            [
                                105.10226534700007,
                                23.25858297700006
                            ],
                            [
                                105.08119987100008,
                                23.266362109000056
                            ],
                            [
                                105.05848220200005,
                                23.26048036800006
                            ],
                            [
                                105.04092763900007,
                                23.249854630000073
                            ],
                            [
                                105.02468966900005,
                                23.227616110000042
                            ],
                            [
                                105.00887765400006,
                                23.227616110000042
                            ],
                            [
                                105.00790957200007,
                                23.220795554000063
                            ],
                            [
                                104.99500180500007,
                                23.208636309000042
                            ],
                            [
                                104.97305860100005,
                                23.211008932000027
                            ],
                            [
                                104.96918627100007,
                                23.20982262600006
                            ],
                            [
                                104.95595581100008,
                                23.19677256500006
                            ],
                            [
                                104.94917923300005,
                                23.192620005000038
                            ],
                            [
                                104.94821115000008,
                                23.183127957000067
                            ],
                            [
                                104.94498420900004,
                                23.174821862000044
                            ],
                            [
                                104.93530338400006,
                                23.169481958000063
                            ],
                            [
                                104.91626442800003,
                                23.178974974000027
                            ],
                            [
                                104.90238857800006,
                                23.181644764000055
                            ],
                            [
                                104.88819003500004,
                                23.17126195000003
                            ],
                            [
                                104.87270071500006,
                                23.133877152000025
                            ],
                            [
                                104.85785678300005,
                                23.12556800300007
                            ],
                            [
                                104.83817243900006,
                                23.123193866000065
                            ],
                            [
                                104.81687462400004,
                                23.12289709600003
                            ],
                            [
                                104.80719379900006,
                                23.11933580400006
                            ],
                            [
                                104.79912644400008,
                                23.103902446000063
                            ],
                            [
                                104.80428955100007,
                                23.08163967100006
                            ],
                            [
                                104.81719731800007,
                                23.059373208000068
                            ],
                            [
                                104.81963769200007,
                                23.027451522000035
                            ],
                            [
                                104.82669662700005,
                                23.008888925000065
                            ],
                            [
                                104.84888185200003,
                                22.983825368000055
                            ],
                            [
                                104.85140290000004,
                                22.965256772000032
                            ],
                            [
                                104.84535238400008,
                                22.94947145900005
                            ],
                            [
                                104.83123451400007,
                                22.93693475200007
                            ],
                            [
                                104.81560401500008,
                                22.927647554000032
                            ],
                            [
                                104.78030934100008,
                                22.91139342500003
                            ],
                            [
                                104.76014095500005,
                                22.89745976300003
                            ],
                            [
                                104.74904834300008,
                                22.881202015000042
                            ],
                            [
                                104.74753571400004,
                                22.869123570000056
                            ],
                            [
                                104.73190521500004,
                                22.857973283000035
                            ],
                            [
                                104.72787153800004,
                                22.84403413800004
                            ],
                            [
                                104.69661054000005,
                                22.835669965000022
                            ],
                            [
                                104.67997162200004,
                                22.834275887000047
                            ],
                            [
                                104.64467694800004,
                                22.836134655000023
                            ],
                            [
                                104.62753382000005,
                                22.83288179300007
                            ],
                            [
                                104.61593699800005,
                                22.825446389000035
                            ],
                            [
                                104.60383596700007,
                                22.82730527800004
                            ],
                            [
                                104.58165074300007,
                                22.854720944000064
                            ],
                            [
                                104.53425503700004,
                                22.836134655000023
                            ],
                            [
                                104.51812032800007,
                                22.81568680500004
                            ],
                            [
                                104.48332986300005,
                                22.781290315000035
                            ],
                            [
                                104.43795099600004,
                                22.753394900000046
                            ],
                            [
                                104.41866497700005,
                                22.744908915000053
                            ],
                            [
                                104.39818146000005,
                                22.729650253000045
                            ],
                            [
                                104.37297097800007,
                                22.69840101400007
                            ],
                            [
                                104.35721442700009,
                                22.696220569000047
                            ],
                            [
                                104.31467173900006,
                                22.735463277000065
                            ],
                            [
                                104.26425077500005,
                                22.75290086800004
                            ],
                            [
                                104.25558467200005,
                                22.76234530000005
                            ],
                            [
                                104.24691856800007,
                                22.778326697000068
                            ],
                            [
                                104.24928205100008,
                                22.813188703000037
                            ],
                            [
                                104.26109946500009,
                                22.835699008000063
                            ],
                            [
                                104.25006987900008,
                                22.845863732000055
                            ],
                            [
                                104.19649760500005,
                                22.837877228000025
                            ],
                            [
                                104.16340884700008,
                                22.835699008000063
                            ],
                            [
                                104.10510960700009,
                                22.812462502000074
                            ],
                            [
                                104.04287123000006,
                                22.741276054000025
                            ],
                            [
                                104.02711467900008,
                                22.71293643100006
                            ],
                            [
                                104.02475119600007,
                                22.666417668000065
                            ],
                            [
                                104.01687292100007,
                                22.64824198900004
                            ],
                            [
                                104.00741899000008,
                                22.611883415000023
                            ],
                            [
                                103.99402592100006,
                                22.59588259700007
                            ],
                            [
                                103.99087461100004,
                                22.578425037000045
                            ],
                            [
                                103.98851112800008,
                                22.548596590000045
                            ],
                            [
                                103.97826937000008,
                                22.516578399000025
                            ],
                            [
                                103.96014933600009,
                                22.51512285000007
                            ],
                            [
                                103.90027444100008,
                                22.552234551000026
                            ],
                            [
                                103.82543082300003,
                                22.614065201000074
                            ],
                            [
                                103.80337165100008,
                                22.64969613200003
                            ],
                            [
                                103.78131248000005,
                                22.66569068700005
                            ],
                            [
                                103.76004113600004,
                                22.690405877000046
                            ],
                            [
                                103.73876979100004,
                                22.70348858600005
                            ],
                            [
                                103.72537672300007,
                                22.722383625000077
                            ],
                            [
                                103.70174189600004,
                                22.74345578200007
                            ],
                            [
                                103.66156269000004,
                                22.787043035000067
                            ],
                            [
                                103.63714003600006,
                                22.79285361700005
                            ],
                            [
                                103.62217131200003,
                                22.79140099400007
                            ],
                            [
                                103.61586869200005,
                                22.779779459000054
                            ],
                            [
                                103.60799041600006,
                                22.77251549700003
                            ],
                            [
                                103.59696083000006,
                                22.75653341900005
                            ],
                            [
                                103.55835728000005,
                                22.71002947100004
                            ],
                            [
                                103.55441814200009,
                                22.694040088000065
                            ],
                            [
                                103.56544772800004,
                                22.675868070000035
                            ],
                            [
                                103.56544772800004,
                                22.64824198900004
                            ],
                            [
                                103.55441814200009,
                                22.63297256000004
                            ],
                            [
                                103.52920766000005,
                                22.619155900000067
                            ],
                            [
                                103.52132938400007,
                                22.60679244800008
                            ],
                            [
                                103.49769455800003,
                                22.61624695200004
                            ],
                            [
                                103.47327190300007,
                                22.643152368000074
                            ],
                            [
                                103.44569793900007,
                                22.66932555300008
                            ],
                            [
                                103.44175880100005,
                                22.694766919000074
                            ],
                            [
                                103.42915356000009,
                                22.72819695800007
                            ],
                            [
                                103.42757790500008,
                                22.75435390000007
                            ],
                            [
                                103.36612735500006,
                                22.792127307000044
                            ],
                            [
                                103.34328035600004,
                                22.810283877000074
                            ],
                            [
                                103.31413073600004,
                                22.809557660000053
                            ],
                            [
                                103.30388897800003,
                                22.778326698000058
                            ],
                            [
                                103.28025415100007,
                                22.76016587400005
                            ],
                            [
                                103.27710284000005,
                                22.72165694100005
                            ],
                            [
                                103.27158804800007,
                                22.69258641500005
                            ],
                            [
                                103.25268018600008,
                                22.670052515000066
                            ],
                            [
                                103.18256353300006,
                                22.651150260000065
                            ],
                            [
                                103.14868694800003,
                                22.617701433000036
                            ],
                            [
                                103.16917046500004,
                                22.574060302000078
                            ],
                            [
                                103.16995829200005,
                                22.55805508900005
                            ],
                            [
                                103.14474781000007,
                                22.54568615200003
                            ],
                            [
                                103.10935859600005,
                                22.513259726000058
                            ],
                            [
                                103.08717337200005,
                                22.50813600400005
                            ],
                            [
                                103.07053445400004,
                                22.50627278500008
                            ],
                            [
                                103.06145868000004,
                                22.482514545000072
                            ],
                            [
                                103.06549235800009,
                                22.467139393000025
                            ],
                            [
                                103.05389553600008,
                                22.455490415000043
                            ],
                            [
                                103.03523977900005,
                                22.45502443500004
                            ],
                            [
                                103.00145773300005,
                                22.465741567000066
                            ],
                            [
                                102.96364201000006,
                                22.48484396500004
                            ],
                            [
                                102.94549046300006,
                                22.490900272000033
                            ],
                            [
                                102.91624630400008,
                                22.503943726000045
                            ],
                            [
                                102.89809475700008,
                                22.52443809700003
                            ],
                            [
                                102.87137164600006,
                                22.59195483900004
                            ],
                            [
                                102.85574114800005,
                                22.60964341300007
                            ],
                            [
                                102.84364011600007,
                                22.618952275000026
                            ],
                            [
                                102.81137069900007,
                                22.629191296000045
                            ],
                            [
                                102.78162233100005,
                                22.646409748000053
                            ],
                            [
                                102.76800867000009,
                                22.658507909000036
                            ],
                            [
                                102.72615927000004,
                                22.67851406500006
                            ],
                            [
                                102.71002456200006,
                                22.690144210000028
                            ],
                            [
                                102.69994036900005,
                                22.70316880100006
                            ],
                            [
                                102.66817516200007,
                                22.703633942000067
                            ],
                            [
                                102.65103203400008,
                                22.700843072000055
                            ],
                            [
                                102.60615737600006,
                                22.729679318000024
                            ],
                            [
                                102.58044268500004,
                                22.726888979000023
                            ],
                            [
                                102.58044268500004,
                                22.711541098000055
                            ],
                            [
                                102.55775325100007,
                                22.724098584000046
                            ],
                            [
                                102.55069431600003,
                                22.74130511800007
                            ],
                            [
                                102.52699646300005,
                                22.75757957600007
                            ],
                            [
                                102.52094594700009,
                                22.771527568000067
                            ],
                            [
                                102.49573546500005,
                                22.778036143000065
                            ],
                            [
                                102.47102919300005,
                                22.76920300200004
                            ],
                            [
                                102.45691132300004,
                                22.756184698000027
                            ],
                            [
                                102.45237343600007,
                                22.735724857000037
                            ],
                            [
                                102.43321347000006,
                                22.714796853000053
                            ],
                            [
                                102.42111243900007,
                                22.698517304000063
                            ],
                            [
                                102.39539774700006,
                                22.68270103100008
                            ],
                            [
                                102.38985144100008,
                                22.665487132000067
                            ],
                            [
                                102.40396931100008,
                                22.646409748000053
                            ],
                            [
                                102.39791879500007,
                                22.624071881000077
                            ],
                            [
                                102.38884302200006,
                                22.61057432700005
                            ],
                            [
                                102.37582811000004,
                                22.59588259700007
                            ],
                            [
                                102.36243504200007,
                                22.589336271000036
                            ],
                            [
                                102.35219328400007,
                                22.58788149000003
                            ],
                            [
                                102.34352718000008,
                                22.55951018500008
                            ],
                            [
                                102.32777062900004,
                                22.556599977000076
                            ],
                            [
                                102.31752887100004,
                                22.55005178600004
                            ],
                            [
                                102.31341326000006,
                                22.53818625300005
                            ],
                            [
                                102.30147357500005,
                                22.531032912000057
                            ],
                            [
                                102.29856932800004,
                                22.522686879000048
                            ],
                            [
                                102.28856580900003,
                                22.50808010700007
                            ],
                            [
                                102.28082114900008,
                                22.502117716000043
                            ],
                            [
                                102.26210488700008,
                                22.483036338000034
                            ],
                            [
                                102.25984602800008,
                                22.47170552500006
                            ],
                            [
                                102.24822903700004,
                                22.460075569000026
                            ],
                            [
                                102.26049141600004,
                                22.449339359000078
                            ],
                            [
                                102.26694529900004,
                                22.441286656000045
                            ],
                            [
                                102.27017224100007,
                                22.428759299000035
                            ],
                            [
                                102.26210488700008,
                                22.418915583000057
                            ],
                            [
                                102.24790634300007,
                                22.418318972000066
                            ],
                            [
                                102.22725391600005,
                                22.42786444500007
                            ],
                            [
                                102.21628231500006,
                                22.428759299000035
                            ],
                            [
                                102.19885683000007,
                                22.43502311900005
                            ],
                            [
                                102.18659445100008,
                                22.43204038300007
                            ],
                            [
                                102.16884627200005,
                                22.43174210600006
                            ],
                            [
                                102.16271508300008,
                                22.428759299000035
                            ],
                            [
                                102.15626119900008,
                                22.418617278000056
                            ],
                            [
                                102.14625768000008,
                                22.41145775800004
                            ],
                            [
                                102.14367612700005,
                                22.401911158000075
                            ],
                            [
                                102.15432503400007,
                                22.389976985000033
                            ],
                            [
                                102.16368316500007,
                                22.37386422700007
                            ],
                            [
                                102.17304129600006,
                                22.34879623000006
                            ],
                            [
                                102.18304481500007,
                                22.340439229000026
                            ],
                            [
                                102.17852709700009,
                                22.327902787000028
                            ],
                            [
                                102.20250604700004,
                                22.298804155000028
                            ],
                            [
                                102.22141390800005,
                                22.28495414200006
                            ],
                            [
                                102.23874611500008,
                                22.277664110000046
                            ],
                            [
                                102.24268525200006,
                                22.269644636000066
                            ],
                            [
                                102.24347308000006,
                                22.256520869000042
                            ],
                            [
                                102.25292701100005,
                                22.238291373000038
                            ],
                            [
                                102.27262270000006,
                                22.225164668000048
                            ],
                            [
                                102.28837925100004,
                                22.206201702000044
                            ],
                            [
                                102.31831669800005,
                                22.200366427000063
                            ],
                            [
                                102.33407325000007,
                                22.185047678000046
                            ],
                            [
                                102.34037839100006,
                                22.16696532000003
                            ],
                            [
                                102.36125141000008,
                                22.163826263000033
                            ],
                            [
                                102.37968531400008,
                                22.152722848000053
                            ],
                            [
                                102.38482195000006,
                                22.133855192000055
                            ],
                            [
                                102.39860452000005,
                                22.12537326300003
                            ],
                            [
                                102.41151228700005,
                                22.124177531000043
                            ],
                            [
                                102.42538813600004,
                                22.118796614000075
                            ],
                            [
                                102.43603704400005,
                                22.111920699000052
                            ],
                            [
                                102.43506896100007,
                                22.10026077300006
                            ],
                            [
                                102.43797320900006,
                                22.092187951000028
                            ],
                            [
                                102.44733134000006,
                                22.076938029000075
                            ],
                            [
                                102.44797672800007,
                                22.06617238700005
                            ],
                            [
                                102.46572490700004,
                                22.059592979000058
                            ],
                            [
                                102.49024966400003,
                                22.040152032000037
                            ],
                            [
                                102.50154396000005,
                                22.034169665000036
                            ],
                            [
                                102.52251908100004,
                                22.029981858000042
                            ],
                            [
                                102.51412903300007,
                                22.014725226000053
                            ],
                            [
                                102.49702624200006,
                                22.01203270900004
                            ],
                            [
                                102.49347660600006,
                                22.004254038000056
                            ],
                            [
                                102.49702624200006,
                                21.989293857000064
                            ],
                            [
                                102.51079882900007,
                                21.971195914000077
                            ],
                            [
                                102.54115789600007,
                                21.950509725000074
                            ],
                            [
                                102.59216939100008,
                                21.92618044900007
                            ],
                            [
                                102.61287344900006,
                                21.91057735100003
                            ],
                            [
                                102.61319614300004,
                                21.896804970000062
                            ],
                            [
                                102.61900463800004,
                                21.883031258000074
                            ],
                            [
                                102.62416774400003,
                                21.864764066000077
                            ],
                            [
                                102.63707551100003,
                                21.85697734000007
                            ],
                            [
                                102.65062866600005,
                                21.85577934400004
                            ],
                            [
                                102.65321022000006,
                                21.842600730000072
                            ],
                            [
                                102.65482369100005,
                                21.812045633000025
                            ],
                            [
                                102.66192296200006,
                                21.796166389000064
                            ],
                            [
                                102.66256835100006,
                                21.78178366800006
                            ],
                            [
                                102.65546907900006,
                                21.768298557000037
                            ],
                            [
                                102.65062866600005,
                                21.731133028000045
                            ],
                            [
                                102.65837332600006,
                                21.711347455000066
                            ],
                            [
                                102.67220883900006,
                                21.691071915000066
                            ],
                            [
                                102.67372146800005,
                                21.676078922000045
                            ],
                            [
                                102.68229303200008,
                                21.66436456100007
                            ],
                            [
                                102.68481408000008,
                                21.654523763000043
                            ],
                            [
                                102.69489827300004,
                                21.652649249000035
                            ],
                            [
                                102.70145299800004,
                                21.65920994100003
                            ],
                            [
                                102.71859612600008,
                                21.661552973000028
                            ],
                            [
                                102.72918452800008,
                                21.66202157500004
                            ],
                            [
                                102.74632765600006,
                                21.658741330000055
                            ],
                            [
                                102.75943710600006,
                                21.662490175000073
                            ],
                            [
                                102.77960549200009,
                                21.677484581000044
                            ],
                            [
                                102.79271494300008,
                                21.700441743000056
                            ],
                            [
                                102.81439595700004,
                                21.726205625000034
                            ],
                            [
                                102.82851382700005,
                                21.733231338000053
                            ],
                            [
                                102.83103487500006,
                                21.737446600000055
                            ],
                            [
                                102.82498436000009,
                                21.76367212300005
                            ],
                            [
                                102.82599277900005,
                                21.772100738000063
                            ],
                            [
                                102.82397594000008,
                                21.805810244000043
                            ],
                            [
                                102.81036228000005,
                                21.82125778300008
                            ],
                            [
                                102.82548856900007,
                                21.845595976000027
                            ],
                            [
                                102.84364011600007,
                                21.845127973000046
                            ],
                            [
                                102.85775798600008,
                                21.83623562300005
                            ],
                            [
                                102.85624535700003,
                                21.824066247000076
                            ],
                            [
                                102.86280008300008,
                                21.800192543000037
                            ],
                            [
                                102.85927061500007,
                                21.787083719000066
                            ],
                            [
                                102.86027903400009,
                                21.769759505000025
                            ],
                            [
                                102.85523693800008,
                                21.748218377000057
                            ],
                            [
                                102.85826219600006,
                                21.716837474000044
                            ],
                            [
                                102.86784217900004,
                                21.70840561700004
                            ],
                            [
                                102.87994321000008,
                                21.702784104000045
                            ],
                            [
                                102.89305266100007,
                                21.707937166000022
                            ],
                            [
                                102.90162422500003,
                                21.715900626000064
                            ],
                            [
                                102.91725472400009,
                                21.719647984000062
                            ],
                            [
                                102.93994415700007,
                                21.719647984000062
                            ],
                            [
                                102.95708728500006,
                                21.728547568000067
                            ],
                            [
                                102.96969252600007,
                                21.741193397000075
                            ],
                            [
                                102.97322199400008,
                                21.74025670700007
                            ],
                            [
                                102.98028092800007,
                                21.72667401700005
                            ],
                            [
                                102.98330618600005,
                                21.71449534100003
                            ],
                            [
                                102.98683565400006,
                                21.658741330000055
                            ],
                            [
                                102.98431460600005,
                                21.61468510700007
                            ],
                            [
                                102.98885249200003,
                                21.600621783000065
                            ],
                            [
                                102.99137354100009,
                                21.582337419000055
                            ],
                            [
                                102.98179355700006,
                                21.570615458000077
                            ],
                            [
                                102.96061675300007,
                                21.557485736000046
                            ],
                            [
                                102.94700309200005,
                                21.53778892500003
                            ],
                            [
                                102.91221262700009,
                                21.502140283000074
                            ],
                            [
                                102.90135833300008,
                                21.480885459000035
                            ],
                            [
                                102.88658656600006,
                                21.463702299000033
                            ],
                            [
                                102.87974152600003,
                                21.436467635000042
                            ],
                            [
                                102.87909613800008,
                                21.428056997000056
                            ],
                            [
                                102.89297198700007,
                                21.428958160000036
                            ],
                            [
                                102.90458897700006,
                                21.432863135000048
                            ],
                            [
                                102.92298254500008,
                                21.44848199200004
                            ],
                            [
                                102.93459953500007,
                                21.453587793000054
                            ],
                            [
                                102.93459953500007,
                                21.446379551000064
                            ],
                            [
                                102.92653218100008,
                                21.434064645000035
                            ],
                            [
                                102.92265985100005,
                                21.418744654000022
                            ],
                            [
                                102.91362441400008,
                                21.403723494000076
                            ],
                            [
                                102.89942587100006,
                                21.368567950000056
                            ],
                            [
                                102.89813509400005,
                                21.356246487000078
                            ],
                            [
                                102.89154003200008,
                                21.33832592600004
                            ],
                            [
                                102.89708633800007,
                                21.318599507000044
                            ],
                            [
                                102.89607791900005,
                                21.309674780000023
                            ],
                            [
                                102.88044742000005,
                                21.30215879900004
                            ],
                            [
                                102.86380850200004,
                                21.297930891000078
                            ],
                            [
                                102.84061485800004,
                                21.296991340000034
                            ],
                            [
                                102.82851382700005,
                                21.280548214000078
                            ],
                            [
                                102.82145489200008,
                                21.266452643000036
                            ],
                            [
                                102.82145489200008,
                                21.257054846000074
                            ],
                            [
                                102.84162327800004,
                                21.25235572300005
                            ],
                            [
                                102.85876640500004,
                                21.255175215000065
                            ],
                            [
                                102.87540532400004,
                                21.251415880000025
                            ],
                            [
                                102.90364106300007,
                                21.240607259000058
                            ],
                            [
                                102.91927156200006,
                                21.21428730100007
                            ],
                            [
                                102.91574209500004,
                                21.187022397000078
                            ],
                            [
                                102.90212843400008,
                                21.17573889600004
                            ],
                            [
                                102.92078419100005,
                                21.160222676000046
                            ],
                            [
                                102.93086838400006,
                                21.15975246200003
                            ],
                            [
                                102.93591048000008,
                                21.156460924000044
                            ],
                            [
                                102.94599467300009,
                                21.13765072600006
                            ],
                            [
                                102.95002835000008,
                                21.117897449000054
                            ],
                            [
                                102.96263359100004,
                                21.079794417000073
                            ],
                            [
                                102.97473462200008,
                                21.067561742000066
                            ],
                            [
                                102.98784407300008,
                                21.06473867400007
                            ],
                            [
                                103.00700403900004,
                                21.064268157000072
                            ],
                            [
                                103.03019768300004,
                                21.05673969000003
                            ],
                            [
                                103.04179450400005,
                                21.047328571000037
                            ],
                            [
                                103.05490395500004,
                                21.026622016000033
                            ],
                            [
                                103.06750919600006,
                                20.995086053000023
                            ],
                            [
                                103.07406392100006,
                                20.990849371000024
                            ],
                            [
                                103.09070283900007,
                                20.97107660100005
                            ],
                            [
                                103.09070283900007,
                                20.939999820000025
                            ],
                            [
                                103.09977861300007,
                                20.923046128000067
                            ],
                            [
                                103.11137543500007,
                                20.907503560000066
                            ],
                            [
                                103.12297225600008,
                                20.901380287000052
                            ],
                            [
                                103.15019957700008,
                                20.90609051900003
                            ],
                            [
                                103.18146057500007,
                                20.907032548000075
                            ],
                            [
                                103.20263737900007,
                                20.89949615300003
                            ],
                            [
                                103.21423420100007,
                                20.889132993000032
                            ],
                            [
                                103.22683944200008,
                                20.872644672000035
                            ],
                            [
                                103.23389837700006,
                                20.854269841000075
                            ],
                            [
                                103.25053729500007,
                                20.83118033200003
                            ],
                            [
                                103.27020147100006,
                                20.827881542000057
                            ],
                            [
                                103.29036985700009,
                                20.831651582000063
                            ],
                            [
                                103.31810138700007,
                                20.821283744000027
                            ],
                            [
                                103.33272346600006,
                                20.813271744000076
                            ],
                            [
                                103.36247183500006,
                                20.806201979000036
                            ],
                            [
                                103.39877492900007,
                                20.78876180800006
                            ],
                            [
                                103.41339700900005,
                                20.788290424000024
                            ],
                            [
                                103.42953171700003,
                                20.793946934000076
                            ],
                            [
                                103.44768326400003,
                                20.812329128000044
                            ],
                            [
                                103.45675903800009,
                                20.82505395000004
                            ],
                            [
                                103.47087690700005,
                                20.82929531800005
                            ],
                            [
                                103.48297793900008,
                                20.813271744000076
                            ],
                            [
                                103.50617158200004,
                                20.767076622000047
                            ],
                            [
                                103.52835680600003,
                                20.754818401000023
                            ],
                            [
                                103.55810517500004,
                                20.747745898000062
                            ],
                            [
                                103.58482828600006,
                                20.747274386000072
                            ],
                            [
                                103.62998656200006,
                                20.720484200000044
                            ],
                            [
                                103.65412559800006,
                                20.69929036600007
                            ],
                            [
                                103.66550182800006,
                                20.698700788000053
                            ],
                            [
                                103.67574358700006,
                                20.68101235900008
                            ],
                            [
                                103.67810706900008,
                                20.667007557000034
                            ],
                            [
                                103.70410537900005,
                                20.663321868000025
                            ],
                            [
                                103.72301324000006,
                                20.667007557000034
                            ],
                            [
                                103.74428458400007,
                                20.66921892700003
                            ],
                            [
                                103.75137503200006,
                                20.68911981100007
                            ],
                            [
                                103.74270892900006,
                                20.71196575600004
                            ],
                            [
                                103.75452634300007,
                                20.73407145500005
                            ],
                            [
                                103.77816116900004,
                                20.739965763000043
                            ],
                            [
                                103.79785685900004,
                                20.76869722600003
                            ],
                            [
                                103.80022034100006,
                                20.78563886200004
                            ],
                            [
                                103.79864468600005,
                                20.801105651000057
                            ],
                            [
                                103.80415947900008,
                                20.825407402000053
                            ],
                            [
                                103.81361341000007,
                                20.83939754000005
                            ],
                            [
                                103.81912820300005,
                                20.86295694000006
                            ],
                            [
                                103.82936996100005,
                                20.87105463200004
                            ],
                            [
                                103.84906565000006,
                                20.869582356000024
                            ],
                            [
                                103.87979092500007,
                                20.882096235000063
                            ],
                            [
                                103.89791095900006,
                                20.89608110000006
                            ],
                            [
                                103.91681882000006,
                                20.901233090000062
                            ],
                            [
                                103.96566412900006,
                                20.90491297500006
                            ],
                            [
                                103.99796505900008,
                                20.90491297500006
                            ],
                            [
                                104.04287123000006,
                                20.907120863000046
                            ],
                            [
                                104.06177909200005,
                                20.94391420100004
                            ],
                            [
                                104.09171653900006,
                                20.975549242000056
                            ],
                            [
                                104.12086615900006,
                                20.97334236300003
                            ],
                            [
                                104.14134967500007,
                                20.954214716000024
                            ],
                            [
                                104.17601408800004,
                                20.951271784000028
                            ],
                            [
                                104.19964891500007,
                                20.946121514000026
                            ],
                            [
                                104.21934460400007,
                                20.938763678000043
                            ],
                            [
                                104.23116201700009,
                                20.931405480000024
                            ],
                            [
                                104.23825246500007,
                                20.914480253000022
                            ],
                            [
                                104.24613074100006,
                                20.90638490400005
                            ],
                            [
                                104.26031163700009,
                                20.910064662000025
                            ],
                            [
                                104.27370470600005,
                                20.91889571400003
                            ],
                            [
                                104.29103691200004,
                                20.91300840400004
                            ],
                            [
                                104.32097435900005,
                                20.887248705000047
                            ],
                            [
                                104.33200394500005,
                                20.85338637900003
                            ],
                            [
                                104.36824401300004,
                                20.826880109000058
                            ],
                            [
                                104.40763539100004,
                                20.805524443000024
                            ],
                            [
                                104.42339194200008,
                                20.787848500000052
                            ],
                            [
                                104.45805635500005,
                                20.779009752000036
                            ],
                            [
                                104.47066159600007,
                                20.772380352000027
                            ],
                            [
                                104.48641814700005,
                                20.75543722900005
                            ],
                            [
                                104.48720597500005,
                                20.723755863000065
                            ],
                            [
                                104.50453818100004,
                                20.70459647200005
                            ],
                            [
                                104.52187038700004,
                                20.69943776100007
                            ],
                            [
                                104.53447562800005,
                                20.712702664000062
                            ],
                            [
                                104.55102000700003,
                                20.716387154000074
                            ],
                            [
                                104.57071569600004,
                                20.712702664000062
                            ],
                            [
                                104.58410876500005,
                                20.70017473000007
                            ],
                            [
                                104.59828966100008,
                                20.674378666000052
                            ],
                            [
                                104.62901493600003,
                                20.663321868000025
                            ],
                            [
                                104.62507579800007,
                                20.62129869900002
                            ],
                            [
                                104.60853141900009,
                                20.614662400000043
                            ],
                            [
                                104.57465483400006,
                                20.61318762800005
                            ],
                            [
                                104.56520090300006,
                                20.602863823000064
                            ],
                            [
                                104.54392955900005,
                                20.593276806000063
                            ],
                            [
                                104.54392955900005,
                                20.582214114000067
                            ],
                            [
                                104.53447562800005,
                                20.56820022000005
                            ],
                            [
                                104.51477993900005,
                                20.542381785000032
                            ],
                            [
                                104.50375035300004,
                                20.534266519000028
                            ],
                            [
                                104.48169118200008,
                                20.520986065000045
                            ],
                            [
                                104.41472583900008,
                                20.486303890000045
                            ],
                            [
                                104.37848577100004,
                                20.476709563000043
                            ],
                            [
                                104.37946267700005,
                                20.434812228000055
                            ],
                            [
                                104.39660580500004,
                                20.424417323000057
                            ],
                            [
                                104.40568157900009,
                                20.41591187800003
                            ],
                            [
                                104.40719420800008,
                                20.39181056500007
                            ],
                            [
                                104.41223630400003,
                                20.377158905000044
                            ],
                            [
                                104.44299309200005,
                                20.37148692100004
                            ],
                            [
                                104.46114463900005,
                                20.371014246000072
                            ],
                            [
                                104.47324567000004,
                                20.36014232800005
                            ],
                            [
                                104.48534670200007,
                                20.359669618000055
                            ],
                            [
                                104.49946457200008,
                                20.371014246000072
                            ],
                            [
                                104.52114558600005,
                                20.377631561000044
                            ],
                            [
                                104.52971715000007,
                                20.39181056500007
                            ],
                            [
                                104.53475924600008,
                                20.404570553000042
                            ],
                            [
                                104.55038974500007,
                                20.412604078000072
                            ],
                            [
                                104.59173493600008,
                                20.417802017000042
                            ],
                            [
                                104.60988648300008,
                                20.422999781000044
                            ],
                            [
                                104.62249172300005,
                                20.42961486300004
                            ],
                            [
                                104.63156749700005,
                                20.451348130000042
                            ],
                            [
                                104.64064327100004,
                                20.468354629000032
                            ],
                            [
                                104.65274430200009,
                                20.475440115000026
                            ],
                            [
                                104.66686217200004,
                                20.472605960000067
                            ],
                            [
                                104.67291268700006,
                                20.459379212000044
                            ],
                            [
                                104.68652634800009,
                                20.44709621100003
                            ],
                            [
                                104.69711475000008,
                                20.441426802000024
                            ],
                            [
                                104.71929997400008,
                                20.410713874000066
                            ],
                            [
                                104.71677892600007,
                                20.397954395000056
                            ],
                            [
                                104.68551792800008,
                                20.396536610000055
                            ],
                            [
                                104.66787059100005,
                                20.392283176000035
                            ],
                            [
                                104.64820641500006,
                                20.372904937000044
                            ],
                            [
                                104.63308012600004,
                                20.36297855400005
                            ],
                            [
                                104.62249172300005,
                                20.291584356000044
                            ],
                            [
                                104.62904644900004,
                                20.242393050000032
                            ],
                            [
                                104.68350109000005,
                                20.21447951500005
                            ],
                            [
                                104.70971999100004,
                                20.20596252400003
                            ],
                            [
                                104.77072935700005,
                                20.200757467000074
                            ],
                            [
                                104.78988932400006,
                                20.193186164000053
                            ],
                            [
                                104.81358717700004,
                                20.177569190000042
                            ],
                            [
                                104.83425977200005,
                                20.161477338000054
                            ],
                            [
                                104.86753760800008,
                                20.14349036300007
                            ],
                            [
                                104.91089963700006,
                                20.147750623000036
                            ],
                            [
                                104.92804276500004,
                                20.152484110000046
                            ],
                            [
                                104.93459749000004,
                                20.145383826000057
                            ],
                            [
                                104.93963958700004,
                                20.127394998000057
                            ],
                            [
                                104.95476587600007,
                                20.11792636200005
                            ],
                            [
                                104.97846372900005,
                                20.099934374000043
                            ],
                            [
                                104.99308580800005,
                                20.095199297000022
                            ],
                            [
                                104.99812790500005,
                                20.085728713000037
                            ],
                            [
                                104.97695110000006,
                                20.075310409000053
                            ],
                            [
                                104.97493426100004,
                                20.065838623000047
                            ],
                            [
                                104.94115221500005,
                                20.040735624000035
                            ],
                            [
                                104.93056381300005,
                                20.026050878000035
                            ],
                            [
                                104.91278057500006,
                                19.991235741000025
                            ],
                            [
                                104.89800880900003,
                                19.968097888000045
                            ],
                            [
                                104.87954410000003,
                                19.949585160000026
                            ],
                            [
                                104.85984841100009,
                                19.933384742000044
                            ],
                            [
                                104.84507664400007,
                                19.865093310000077
                            ],
                            [
                                104.83399781900005,
                                19.826883572000042
                            ],
                            [
                                104.82168801400007,
                                19.79561422300003
                            ],
                            [
                                104.78845153800006,
                                19.771289371000023
                            ],
                            [
                                104.77121781100004,
                                19.764338731000066
                            ],
                            [
                                104.71089976300004,
                                19.745802210000022
                            ],
                            [
                                104.68504917100006,
                                19.71683458700005
                            ],
                            [
                                104.65919857900008,
                                19.684384616000045
                            ],
                            [
                                104.65673661800008,
                                19.651928070000054
                            ],
                            [
                                104.62719308400006,
                                19.629900245000044
                            ],
                            [
                                104.56318209500006,
                                19.612507722000032
                            ],
                            [
                                104.52871463900004,
                                19.607869398000048
                            ],
                            [
                                104.47824443600007,
                                19.64729088400003
                            ],
                            [
                                104.46224168900005,
                                19.64960949400006
                            ],
                            [
                                104.44008403900006,
                                19.658883597000056
                            ],
                            [
                                104.41423344700007,
                                19.68670269000006
                            ],
                            [
                                104.39453775800007,
                                19.704087169000047
                            ],
                            [
                                104.35637736000007,
                                19.704087169000047
                            ],
                            [
                                104.33668167100006,
                                19.683225567000022
                            ],
                            [
                                104.32560284600004,
                                19.677430195000056
                            ],
                            [
                                104.26405381800004,
                                19.707563838000056
                            ],
                            [
                                104.22712440100008,
                                19.708722711000064
                            ],
                            [
                                104.19142596500006,
                                19.701769347000038
                            ],
                            [
                                104.16803733400008,
                                19.69481568200007
                            ],
                            [
                                104.14341772300008,
                                19.662361248000025
                            ],
                            [
                                104.11756713100004,
                                19.661202040000035
                            ],
                            [
                                104.08433065600008,
                                19.669316323000032
                            ],
                            [
                                104.06340398600008,
                                19.68090744400007
                            ],
                            [
                                104.05848006400004,
                                19.656565122000075
                            ],
                            [
                                104.04986320000006,
                                19.636856726000076
                            ],
                            [
                                104.08063771400003,
                                19.578876844000035
                            ],
                            [
                                104.10033340300004,
                                19.56031887000006
                            ],
                            [
                                104.11264320800007,
                                19.522036306000075
                            ],
                            [
                                104.11510517000005,
                                19.493028323000033
                            ],
                            [
                                104.06955888900006,
                                19.46053321000005
                            ],
                            [
                                104.07202085000006,
                                19.43964005400005
                            ],
                            [
                                104.06650605700008,
                                19.417722566000066
                            ],
                            [
                                104.02947816200003,
                                19.41549350300005
                            ],
                            [
                                104.00978247300009,
                                19.40806307300005
                            ],
                            [
                                103.99166243900004,
                                19.396916791000024
                            ],
                            [
                                103.96881543900008,
                                19.39171493200007
                            ],
                            [
                                103.94518061200006,
                                19.353810656000064
                            ],
                            [
                                103.88294223500009,
                                19.318127995000054
                            ],
                            [
                                103.87585178700004,
                                19.306232042000033
                            ],
                            [
                                103.89082051100007,
                                19.299540189000027
                            ],
                            [
                                103.90421357900004,
                                19.297309510000048
                            ],
                            [
                                103.92233361300003,
                                19.284668423000028
                            ],
                            [
                                103.93651450900006,
                                19.25417708200007
                            ],
                            [
                                103.95542237100005,
                                19.241532668000048
                            ],
                            [
                                103.98535981800006,
                                19.23186275200004
                            ],
                            [
                                104.02159988600005,
                                19.228143403000047
                            ],
                            [
                                104.07280867700007,
                                19.228143403000047
                            ],
                            [
                                104.13032008900007,
                                19.20210559700007
                            ],
                            [
                                104.14686446800005,
                                19.196897541000055
                            ],
                            [
                                104.16262101900008,
                                19.199129585000037
                            ],
                            [
                                104.19413412200004,
                                19.190201227000045
                            ],
                            [
                                104.21382981100004,
                                19.15373874000005
                            ],
                            [
                                104.21956771700007,
                                19.118934402000036
                            ],
                            [
                                104.23118470700007,
                                19.113446208000028
                            ],
                            [
                                104.24409247300008,
                                19.11039713300005
                            ],
                            [
                                104.28281577400008,
                                19.108262747000026
                            ],
                            [
                                104.31508519100004,
                                19.083562847000053
                            ],
                            [
                                104.33283337000006,
                                19.074718777000044
                            ],
                            [
                                104.35639004400008,
                                19.070449058000065
                            ],
                            [
                                104.39575873300004,
                                19.047268659000054
                            ],
                            [
                                104.40059914500006,
                                19.033541897000077
                            ],
                            [
                                104.43093239700005,
                                18.992049677000068
                            ],
                            [
                                104.44545363500004,
                                18.981980151000073
                            ],
                            [
                                104.45513446000007,
                                18.977097738000055
                            ],
                            [
                                104.47772305200004,
                                18.98136985700006
                            ],
                            [
                                104.48675848900007,
                                18.991439420000063
                            ],
                            [
                                104.49579392500004,
                                18.994795805000024
                            ],
                            [
                                104.52160945900005,
                                18.977402893000033
                            ],
                            [
                                104.53935763800007,
                                18.971910017000027
                            ],
                            [
                                104.54193919200009,
                                18.961228907000077
                            ],
                            [
                                104.55323348700006,
                                18.940169857000058
                            ],
                            [
                                104.56614125400006,
                                18.924602766000078
                            ],
                            [
                                104.57324052600006,
                                18.906591968000043
                            ],
                            [
                                104.60232333800008,
                                18.886976308000044
                            ],
                            [
                                104.61492857900004,
                                18.88649924500004
                            ],
                            [
                                104.64064327100004,
                                18.855964378000067
                            ],
                            [
                                104.66887901000007,
                                18.838308872000027
                            ],
                            [
                                104.67997162200004,
                                18.825901190000025
                            ],
                            [
                                104.69560212100004,
                                18.81540166600007
                            ],
                            [
                                104.71022420100007,
                                18.81540166600007
                            ],
                            [
                                104.72333365100008,
                                18.806333368000026
                            ],
                            [
                                104.74551887500007,
                                18.786763269000062
                            ],
                            [
                                104.76619147100007,
                                18.77626130400006
                            ],
                            [
                                104.83224293300003,
                                18.770055289000027
                            ],
                            [
                                104.84232712600004,
                                18.762416803000065
                            ],
                            [
                                104.85644499600005,
                                18.762416803000065
                            ],
                            [
                                104.89787086000007,
                                18.776795965000076
                            ],
                            [
                                104.90948785000006,
                                18.777407004000054
                            ],
                            [
                                104.92045945200005,
                                18.774657309000077
                            ],
                            [
                                104.94466151500006,
                                18.735240087000022
                            ],
                            [
                                104.95272886900005,
                                18.725155133000044
                            ],
                            [
                                105.00115316300008,
                                18.724696712000025
                            ],
                            [
                                105.02636364500006,
                                18.72278661000007
                            ],
                            [
                                105.06367515800008,
                                18.700818885000047
                            ],
                            [
                                105.10552455800007,
                                18.690789193000057
                            ],
                            [
                                105.12770978200007,
                                18.700341294000054
                            ],
                            [
                                105.14283607100003,
                                18.68696820100007
                            ],
                            [
                                105.16804655300007,
                                18.660218849000046
                            ],
                            [
                                105.19442680100008,
                                18.63650393200004
                            ],
                            [
                                105.20539840300006,
                                18.632223047000025
                            ],
                            [
                                105.20765726200005,
                                18.612957726000047
                            ],
                            [
                                105.18990908300003,
                                18.603171331000055
                            ],
                            [
                                105.16861126800006,
                                18.601642156000025
                            ],
                            [
                                105.15699427800007,
                                18.597054549000063
                            ],
                            [
                                105.13795532200004,
                                18.56585554000003
                            ],
                            [
                                105.13763262800006,
                                18.540769845000057
                            ],
                            [
                                105.11988444800005,
                                18.525777855000058
                            ],
                            [
                                105.12020714300007,
                                18.502522370000065
                            ],
                            [
                                105.11504403600009,
                                18.484466592000047
                            ],
                            [
                                105.11698020100005,
                                18.471612167000046
                            ],
                            [
                                105.10568590500009,
                                18.448655441000028
                            ],
                            [
                                105.10794476400008,
                                18.438247381000053
                            ],
                            [
                                105.12504755500004,
                                18.418960191000053
                            ],
                            [
                                105.14053687500007,
                                18.414980026000023
                            ],
                            [
                                105.14473189900008,
                                18.404569927000068
                            ],
                            [
                                105.15957583100004,
                                18.389566031000072
                            ],
                            [
                                105.16667510300005,
                                18.385891408000077
                            ],
                            [
                                105.17829209300004,
                                18.38742251000002
                            ],
                            [
                                105.19571757800009,
                                18.37486707000005
                            ],
                            [
                                105.19894452000005,
                                18.358022970000036
                            ],
                            [
                                105.19224861600009,
                                18.324061802000074
                            ],
                            [
                                105.21645067900005,
                                18.310180600000024
                            ],
                            [
                                105.24166116000004,
                                18.300128
                            ],
                            [
                                105.24771167600005,
                                18.295819565000045
                            ],
                            [
                                105.25981270800008,
                                18.28385112600006
                            ],
                            [
                                105.26334217500005,
                                18.258954127000038
                            ],
                            [
                                105.27645162600004,
                                18.25560233500005
                            ],
                            [
                                105.28956107600004,
                                18.256081167000048
                            ],
                            [
                                105.30014947900008,
                                18.239800160000073
                            ],
                            [
                                105.30972946200006,
                                18.251771637000047
                            ],
                            [
                                105.32737679900004,
                                18.25943294900003
                            ],
                            [
                                105.33292310500008,
                                18.252250479000054
                            ],
                            [
                                105.32737679900004,
                                18.23501133900004
                            ],
                            [
                                105.33241889600004,
                                18.206275642000037
                            ],
                            [
                                105.33141047600009,
                                18.189510962000043
                            ],
                            [
                                105.34199887900007,
                                18.17993042100005
                            ],
                            [
                                105.36418410300007,
                                18.17226560900008
                            ],
                            [
                                105.37527671500004,
                                18.16316320900006
                            ],
                            [
                                105.39443668100006,
                                18.155497661000027
                            ],
                            [
                                105.40653771300003,
                                18.162205033000077
                            ],
                            [
                                105.42771451700008,
                                18.16412137800006
                            ],
                            [
                                105.44031975800004,
                                18.17657710700007
                            ],
                            [
                                105.45090816100009,
                                18.19190601500003
                            ],
                            [
                                105.46149656300008,
                                18.20388078600007
                            ],
                            [
                                105.47359759500006,
                                18.207712539000056
                            ],
                            [
                                105.48418599700005,
                                18.206275642000037
                            ],
                            [
                                105.49779965700009,
                                18.19382203300006
                            ],
                            [
                                105.50637122100005,
                                18.183283670000037
                            ],
                            [
                                105.50939647900009,
                                18.142561287000035
                            ],
                            [
                                105.51847225200004,
                                18.124352916000078
                            ],
                            [
                                105.53662379900004,
                                18.118123302000072
                            ],
                            [
                                105.55175008900005,
                                18.103267174000052
                            ],
                            [
                                105.55628797500003,
                                18.085054718000038
                            ],
                            [
                                105.57595215100008,
                                18.08601331600005
                            ],
                            [
                                105.58351529600009,
                                18.074989129000073
                            ],
                            [
                                105.57696057100009,
                                18.05245929000006
                            ],
                            [
                                105.58553213400006,
                                18.01650144000007
                            ],
                            [
                                105.59662474700008,
                                18.01266550200006
                            ],
                            [
                                105.60973419700008,
                                18.010747502000072
                            ],
                            [
                                105.62536469600008,
                                18.004993377000062
                            ],
                            [
                                105.63897835600005,
                                17.99588229400007
                            ],
                            [
                                105.64906254900006,
                                17.984852459000024
                            ],
                            [
                                105.63847414700007,
                                17.962790721000033
                            ],
                            [
                                105.63393626000004,
                                17.93305097700005
                            ],
                            [
                                105.61628892200008,
                                17.891310979000025
                            ],
                            [
                                105.62435627700006,
                                17.874996126000042
                            ],
                            [
                                105.65612148400004,
                                17.863478857000075
                            ],
                            [
                                105.66570146700008,
                                17.857240025000067
                            ],
                            [
                                105.67679407900005,
                                17.844761703000074
                            ],
                            [
                                105.68133196600007,
                                17.82028245600003
                            ],
                            [
                                105.68334880400005,
                                17.798680327000056
                            ],
                            [
                                105.69998772300005,
                                17.770833727000024
                            ],
                            [
                                105.72418978500008,
                                17.756428610000057
                            ],
                            [
                                105.72973609100006,
                                17.743463013000053
                            ],
                            [
                                105.75040868600007,
                                17.706001573000037
                            ],
                            [
                                105.76200550800007,
                                17.667571459000044
                            ],
                            [
                                105.77461074900003,
                                17.67285608700007
                            ],
                            [
                                105.78923282900007,
                                17.670934422000073
                            ],
                            [
                                105.80133386000006,
                                17.663247557000034
                            ],
                            [
                                105.81898119700008,
                                17.647392360000026
                            ],
                            [
                                105.86275289700006,
                                17.626460162000058
                            ],
                            [
                                105.87693379300003,
                                17.60994093100004
                            ],
                            [
                                105.91159820600006,
                                17.577648977000024
                            ],
                            [
                                105.93050606700007,
                                17.54535125700005
                            ],
                            [
                                105.97226092800008,
                                17.492761263000034
                            ],
                            [
                                105.99353227200004,
                                17.47096951900005
                            ],
                            [
                                106.01795492600007,
                                17.452933
                            ],
                            [
                                106.04080192600009,
                                17.416854606000072
                            ],
                            [
                                106.07940547600003,
                                17.37776163600006
                            ],
                            [
                                106.10446810600007,
                                17.355833525000037
                            ],
                            [
                                106.13385406700007,
                                17.336272292000046
                            ],
                            [
                                106.13963674600006,
                                17.329372180000064
                            ],
                            [
                                106.14418028000006,
                                17.314782518000072
                            ],
                            [
                                106.18920257000008,
                                17.287571676000027
                            ],
                            [
                                106.19973530800007,
                                17.27554241300004
                            ],
                            [
                                106.21130066700005,
                                17.266273429000023
                            ],
                            [
                                106.23608357900008,
                                17.251678764000076
                            ],
                            [
                                106.24806198700009,
                                17.25069259600002
                            ],
                            [
                                106.25818167600005,
                                17.253256621000048
                            ],
                            [
                                106.26974703500008,
                                17.258779015000073
                            ],
                            [
                                106.27676886000006,
                                17.26745672900006
                            ],
                            [
                                106.27573623900008,
                                17.293290230000025
                            ],
                            [
                                106.28048629700004,
                                17.29703677300006
                            ],
                            [
                                106.28812769500007,
                                17.29703677300006
                            ],
                            [
                                106.30403006400007,
                                17.290529571000036
                            ],
                            [
                                106.31022579200004,
                                17.281655743000044
                            ],
                            [
                                106.30733445200008,
                                17.266470646000073
                            ],
                            [
                                106.33191084000003,
                                17.249311953000074
                            ],
                            [
                                106.33583480100003,
                                17.230968137000048
                            ],
                            [
                                106.32716078200008,
                                17.21992152000007
                            ],
                            [
                                106.32075852900005,
                                17.21715976200005
                            ],
                            [
                                106.31476932600003,
                                17.210649742000044
                            ],
                            [
                                106.32179115100007,
                                17.20453406000007
                            ],
                            [
                                106.33253041300009,
                                17.19802359600004
                            ],
                            [
                                106.33873178800008,
                                17.177486543000043
                            ],
                            [
                                106.35949393100009,
                                17.146522138000023
                            ],
                            [
                                106.36554444600006,
                                17.132067590000077
                            ],
                            [
                                106.38067073600007,
                                17.120021275000056
                            ],
                            [
                                106.39025071900005,
                                17.106046572000025
                            ],
                            [
                                106.40840226600005,
                                17.09158887800004
                            ],
                            [
                                106.41646962000004,
                                17.08098585700003
                            ],
                            [
                                106.41848645800007,
                                17.05592177400007
                            ],
                            [
                                106.42756223200007,
                                17.03519316300003
                            ],
                            [
                                106.43815063400007,
                                17.026033278000057
                            ],
                            [
                                106.43915905400007,
                                17.00240834600004
                            ],
                            [
                                106.45428534300004,
                                16.99999747100003
                            ],
                            [
                                106.46537795500006,
                                16.994693437000024
                            ],
                            [
                                106.48201687300008,
                                16.981191582000065
                            ],
                            [
                                106.51479050000006,
                                16.96913553400003
                            ],
                            [
                                106.52689153100005,
                                16.973958046000064
                            ],
                            [
                                106.53697572400006,
                                16.97540477600006
                            ],
                            [
                                106.54857254500007,
                                16.96768875600003
                            ],
                            [
                                106.55109359400006,
                                16.958525571000052
                            ],
                            [
                                106.54403465900003,
                                16.930550980000078
                            ],
                            [
                                106.51932838600004,
                                16.91029092100007
                            ],
                            [
                                106.53495888500004,
                                16.889546223000025
                            ],
                            [
                                106.54302623900008,
                                16.874106828000038
                            ],
                            [
                                106.55663990000005,
                                16.86831672900007
                            ],
                            [
                                106.55052635800007,
                                16.81526273000003
                            ],
                            [
                                106.54816287500006,
                                16.727007616000037
                            ],
                            [
                                106.55761680600006,
                                16.69078855400005
                            ],
                            [
                                106.56076811600008,
                                16.67116702800007
                            ],
                            [
                                106.56549508100005,
                                16.65758171600004
                            ],
                            [
                                106.56549508100005,
                                16.64324061800005
                            ],
                            [
                                106.57179770200008,
                                16.630408200000034
                            ],
                            [
                                106.58361511500004,
                                16.619084765000025
                            ],
                            [
                                106.59149339100009,
                                16.609270582000022
                            ],
                            [
                                106.62300649300005,
                                16.59115077100006
                            ],
                            [
                                106.62615780400006,
                                16.581335161000027
                            ],
                            [
                                106.63561173400007,
                                16.58435540200003
                            ],
                            [
                                106.65058045800004,
                                16.586620552000056
                            ],
                            [
                                106.67387877500005,
                                16.558850706000044
                            ],
                            [
                                106.66125835800005,
                                16.55556632400004
                            ],
                            [
                                106.65470827100006,
                                16.547219334000033
                            ],
                            [
                                106.64954516400007,
                                16.543655821000073
                            ],
                            [
                                106.64871906700006,
                                16.529401109000048
                            ],
                            [
                                106.64995821300005,
                                16.517917381000075
                            ],
                            [
                                106.65798787600005,
                                16.506702268000026
                            ],
                            [
                                106.66248184500006,
                                16.504674608000073
                            ],
                            [
                                106.66314272200009,
                                16.498718232000044
                            ],
                            [
                                106.66063138700008,
                                16.490353651000078
                            ],
                            [
                                106.66565405700004,
                                16.485030547000065
                            ],
                            [
                                106.66234966900004,
                                16.478820074000055
                            ],
                            [
                                106.66486100400004,
                                16.47235590100007
                            ],
                            [
                                106.67054455200008,
                                16.472229150000032
                            ],
                            [
                                106.67490634500007,
                                16.46741256400003
                            ],
                            [
                                106.67639331900006,
                                16.45355629000005
                            ],
                            [
                                106.69126306700008,
                                16.440285346000053
                            ],
                            [
                                106.71067634800005,
                                16.433748577000074
                            ],
                            [
                                106.72162213400009,
                                16.427805869000053
                            ],
                            [
                                106.73607883300008,
                                16.41512748400004
                            ],
                            [
                                106.74310065800006,
                                16.411759650000022
                            ],
                            [
                                106.74723114300008,
                                16.413344520000066
                            ],
                            [
                                106.76395960900004,
                                16.425032543000043
                            ],
                            [
                                106.77490539500008,
                                16.436719863000064
                            ],
                            [
                                106.77697063800008,
                                16.452169816000037
                            ],
                            [
                                106.78068807500006,
                                16.466034111000056
                            ],
                            [
                                106.78956861800003,
                                16.467024380000055
                            ],
                            [
                                106.81444859100009,
                                16.478618805000053
                            ],
                            [
                                106.82469034900004,
                                16.501281520000077
                            ],
                            [
                                106.82469034900004,
                                16.512611882000044
                            ],
                            [
                                106.84281038300009,
                                16.533760114000074
                            ],
                            [
                                106.85305214100003,
                                16.53451536500006
                            ],
                            [
                                106.86723303700006,
                                16.533004860000062
                            ],
                            [
                                106.88377741600004,
                                16.513367216000063
                            ],
                            [
                                106.88614089900005,
                                16.46275332600004
                            ],
                            [
                                106.87985844700006,
                                16.42672873300006
                            ],
                            [
                                106.90147895600006,
                                16.410632898000074
                            ],
                            [
                                106.90599667400005,
                                16.398869714000057
                            ],
                            [
                                106.92987604300004,
                                16.369768259000068
                            ],
                            [
                                106.95214194100004,
                                16.363575900000058
                            ],
                            [
                                106.96908338500003,
                                16.34068181500004
                            ],
                            [
                                106.96504970700005,
                                16.32326271000005
                            ],
                            [
                                106.96504970700005,
                                16.305358125000055
                            ],
                            [
                                106.98925177000007,
                                16.29906693600003
                            ],
                            [
                                107.00437805900003,
                                16.30584205400004
                            ],
                            [
                                107.02101697700004,
                                16.308745604000023
                            ],
                            [
                                107.03967273400008,
                                16.306325982000033
                            ],
                            [
                                107.09664842300003,
                                16.304390262000027
                            ],
                            [
                                107.10925366400005,
                                16.29277554500004
                            ],
                            [
                                107.14706938700004,
                                16.267607962000056
                            ],
                            [
                                107.15412832200008,
                                16.25211861500003
                            ],
                            [
                                107.14908622600007,
                                16.22742994600003
                            ],
                            [
                                107.15059885500006,
                                16.19305428900003
                            ],
                            [
                                107.16824619200008,
                                16.16496832200005
                            ],
                            [
                                107.17782617500006,
                                16.159156933000077
                            ],
                            [
                                107.19396088400003,
                                16.146080685000072
                            ],
                            [
                                107.22875134900005,
                                16.14365906200004
                            ],
                            [
                                107.23984396100008,
                                16.14656500600006
                            ],
                            [
                                107.24589447600005,
                                16.14656500600006
                            ],
                            [
                                107.25698708900006,
                                16.137362706000033
                            ],
                            [
                                107.27060074900004,
                                16.129613069000072
                            ],
                            [
                                107.27765968400007,
                                16.115081683000028
                            ],
                            [
                                107.28875229600004,
                                16.106362340000032
                            ],
                            [
                                107.29581123100007,
                                16.10297138100003
                            ],
                            [
                                107.31749224500004,
                                16.07971753000004
                            ],
                            [
                                107.32152592200003,
                                16.072450144000072
                            ],
                            [
                                107.33009748600006,
                                16.064213451000057
                            ],
                            [
                                107.34673640400007,
                                16.059852711000076
                            ],
                            [
                                107.37295530600005,
                                16.069543115000045
                            ],
                            [
                                107.39715736800008,
                                16.065667010000027
                            ],
                            [
                                107.40623314200008,
                                16.072450144000072
                            ],
                            [
                                107.40925840000006,
                                16.080202013000076
                            ],
                            [
                                107.41732575400005,
                                16.080202013000076
                            ],
                            [
                                107.44304044500007,
                                16.075357130000043
                            ],
                            [
                                107.45362884800005,
                                16.07971753000004
                            ],
                            [
                                107.45816673500008,
                                16.075357130000043
                            ],
                            [
                                107.45715831500007,
                                16.050646391000043
                            ],
                            [
                                107.46371304100006,
                                16.033686266000075
                            ],
                            [
                                107.47127618500008,
                                16.023994118000076
                            ],
                            [
                                107.44606570300004,
                                15.974556845000052
                            ],
                            [
                                107.43295625300004,
                                15.934804296000038
                            ],
                            [
                                107.43093941400008,
                                15.921228450000058
                            ],
                            [
                                107.41026681900007,
                                15.89164932400007
                            ],
                            [
                                107.40018262600006,
                                15.885829968000053
                            ],
                            [
                                107.36488795100007,
                                15.89213426300006
                            ],
                            [
                                107.35026587200008,
                                15.89213426300006
                            ],
                            [
                                107.33413116300005,
                                15.88776977200007
                            ],
                            [
                                107.31799645500007,
                                15.876615643000036
                            ],
                            [
                                107.30438279500004,
                                15.862065850000022
                            ],
                            [
                                107.27967652200005,
                                15.855760614000076
                            ],
                            [
                                107.26354181400006,
                                15.846544914000049
                            ],
                            [
                                107.24286921900006,
                                15.840724255000055
                            ],
                            [
                                107.22875134900005,
                                15.831992953000054
                            ],
                            [
                                107.22118820400004,
                                15.822776168000075
                            ],
                            [
                                107.23076818700008,
                                15.797549137000033
                            ],
                            [
                                107.22723872000006,
                                15.78396404800003
                            ],
                            [
                                107.21513768800008,
                                15.765040444000022
                            ],
                            [
                                107.21160822100006,
                                15.745144495000034
                            ],
                            [
                                107.22017978500008,
                                15.738835617000063
                            ],
                            [
                                107.24085238000004,
                                15.743203323000046
                            ],
                            [
                                107.26455023300008,
                                15.729614596000033
                            ],
                            [
                                107.27866810300009,
                                15.70583214100003
                            ],
                            [
                                107.28421440900007,
                                15.692726336000078
                            ],
                            [
                                107.27564284500005,
                                15.674279703000025
                            ],
                            [
                                107.26858391000007,
                                15.653403871000023
                            ],
                            [
                                107.28371019900004,
                                15.643693455000061
                            ],
                            [
                                107.28522282800009,
                                15.629612534000046
                            ],
                            [
                                107.31950908400006,
                                15.62281450200004
                            ],
                            [
                                107.33816484000005,
                                15.62232891900004
                            ],
                            [
                                107.35329113000006,
                                15.608246529000041
                            ],
                            [
                                107.36438374200009,
                                15.569393546000072
                            ],
                            [
                                107.37497214400008,
                                15.551907307000022
                            ],
                            [
                                107.38404791800008,
                                15.522274457000037
                            ],
                            [
                                107.38757738500004,
                                15.498467937000044
                            ],
                            [
                                107.41228365700005,
                                15.489236098000049
                            ],
                            [
                                107.44253623600008,
                                15.500411429000053
                            ],
                            [
                                107.46371304100006,
                                15.50089729900003
                            ],
                            [
                                107.47783091100007,
                                15.49409501200006
                            ],
                            [
                                107.50808348900006,
                                15.47028524500007
                            ],
                            [
                                107.52926029400004,
                                15.462024073000066
                            ],
                            [
                                107.53581501900004,
                                15.437724600000024
                            ],
                            [
                                107.53178134200004,
                                15.420713276000072
                            ],
                            [
                                107.55598340500006,
                                15.399811741000065
                            ],
                            [
                                107.57463916100005,
                                15.401270056000044
                            ],
                            [
                                107.61800119000009,
                                15.41536657100005
                            ],
                            [
                                107.62707696400008,
                                15.41536657100005
                            ],
                            [
                                107.62153065800004,
                                15.399811741000065
                            ],
                            [
                                107.60842120700005,
                                15.387658720000047
                            ],
                            [
                                107.61094225500005,
                                15.361891970000045
                            ],
                            [
                                107.63413589900006,
                                15.33028689300005
                            ],
                            [
                                107.64573272000007,
                                15.308889973000078
                            ],
                            [
                                107.62606854400008,
                                15.286518127000022
                            ],
                            [
                                107.63413589900006,
                                15.23057807300006
                            ],
                            [
                                107.61548014200008,
                                15.215496033000022
                            ],
                            [
                                107.60791699700007,
                                15.204791994000061
                            ],
                            [
                                107.61947348200005,
                                15.17419491000004
                            ],
                            [
                                107.62915430700008,
                                15.160802426000032
                            ],
                            [
                                107.62495928300007,
                                15.132145889000071
                            ],
                            [
                                107.61172882200009,
                                15.089153817000067
                            ],
                            [
                                107.62044156500008,
                                15.075755958000059
                            ],
                            [
                                107.62689544800008,
                                15.05924115700003
                            ],
                            [
                                107.62689544800008,
                                15.044594884000048
                            ],
                            [
                                107.59559411400005,
                                15.044594884000048
                            ],
                            [
                                107.58462251200007,
                                15.038673765000055
                            ],
                            [
                                107.56816510900006,
                                15.042725075000021
                            ],
                            [
                                107.55654811900007,
                                15.048646082000062
                            ],
                            [
                                107.54267227000008,
                                15.047087938000061
                            ],
                            [
                                107.53686377500009,
                                15.045218151000029
                            ],
                            [
                                107.52201984300007,
                                15.028389325000035
                            ],
                            [
                                107.50136741600005,
                                15.021532756000056
                            ],
                            [
                                107.48232846100007,
                                15.020909421000056
                            ],
                            [
                                107.47974690700005,
                                15.008754018000047
                            ],
                            [
                                107.48555540200005,
                                14.995039400000053
                            ],
                            [
                                107.48297384900008,
                                14.981635628000049
                            ],
                            [
                                107.49459083900007,
                                14.957319668000025
                            ],
                            [
                                107.50169011100007,
                                14.952954973000033
                            ],
                            [
                                107.51717943100005,
                                14.936430680000058
                            ],
                            [
                                107.53525030400004,
                                14.93206556000007
                            ],
                            [
                                107.54977154200009,
                                14.922399622000057
                            ],
                            [
                                107.55912967300009,
                                14.910238631000027
                            ],
                            [
                                107.58042748800005,
                                14.89495846400007
                            ],
                            [
                                107.58559059500004,
                                14.889345068000068
                            ],
                            [
                                107.58881753600008,
                                14.863147291000075
                            ],
                            [
                                107.58300904100008,
                                14.848487624000029
                            ],
                            [
                                107.55654811900007,
                                14.821660978000068
                            ],
                            [
                                107.53976802300008,
                                14.810742205000054
                            ],
                            [
                                107.51943829000004,
                                14.780790746000037
                            ],
                            [
                                107.54085711500005,
                                14.771313057000043
                            ],
                            [
                                107.55043709900008,
                                14.761561916000062
                            ],
                            [
                                107.54085711500005,
                                14.743521153000074
                            ],
                            [
                                107.54136132500008,
                                14.723040638000043
                            ],
                            [
                                107.55295814700008,
                                14.721090012000047
                            ],
                            [
                                107.56455496800004,
                                14.71523803100007
                            ],
                            [
                                107.57110969400009,
                                14.70548437900004
                            ],
                            [
                                107.56909285500006,
                                14.679635096000027
                            ],
                            [
                                107.56304234000004,
                                14.651831513000047
                            ],
                            [
                                107.54993288900005,
                                14.606948115000023
                            ],
                            [
                                107.53833606700005,
                                14.576695437000069
                            ],
                            [
                                107.52825187400003,
                                14.522523012000022
                            ],
                            [
                                107.51211716600005,
                                14.510320183000033
                            ],
                            [
                                107.50707507000004,
                                14.504462586000045
                            ],
                            [
                                107.51211716600005,
                                14.490794257000061
                            ],
                            [
                                107.51060453700006,
                                14.474684074000038
                            ],
                            [
                                107.50404981200006,
                                14.452225503000022
                            ],
                            [
                                107.46925934700005,
                                14.407301559000075
                            ],
                            [
                                107.45715831500007,
                                14.404371421000064
                            ],
                            [
                                107.42337626900007,
                                14.360903205000056
                            ],
                            [
                                107.41127523800009,
                                14.342829662000042
                            ],
                            [
                                107.40673735100006,
                                14.319869276000077
                            ],
                            [
                                107.41782996300009,
                                14.290554732000032
                            ],
                            [
                                107.40270367400007,
                                14.212852730000066
                            ],
                            [
                                107.38757738500004,
                                14.194278482000072
                            ],
                            [
                                107.38354370800005,
                                14.180102374000057
                            ],
                            [
                                107.37345951500004,
                                14.164458743000068
                            ],
                            [
                                107.35732480700005,
                                14.14734728700006
                            ],
                            [
                                107.35026587200008,
                                14.135124030000043
                            ],
                            [
                                107.34169430800006,
                                14.108230553000055
                            ],
                            [
                                107.34774482400007,
                                14.102851477000058
                            ],
                            [
                                107.36387953200006,
                                14.095516168000074
                            ],
                            [
                                107.38656896600008,
                                14.079866722000077
                            ],
                            [
                                107.36892162800007,
                                14.028998623000064
                            ],
                            [
                                107.37345951500004,
                                14.013833831000056
                            ],
                            [
                                107.39009843300005,
                                13.989861634000022
                            ],
                            [
                                107.43547730100005,
                                13.995243365000022
                            ],
                            [
                                107.45060359000007,
                                13.994264878000024
                            ],
                            [
                                107.45909448000003,
                                13.97557497200006
                            ],
                            [
                                107.45844909200008,
                                13.939874016000033
                            ],
                            [
                                107.46361219900007,
                                13.927972470000043
                            ],
                            [
                                107.47264763500004,
                                13.920455387000061
                            ],
                            [
                                107.47232494100007,
                                13.887878541000077
                            ],
                            [
                                107.45748100900005,
                                13.875974320000068
                            ],
                            [
                                107.47619727100005,
                                13.862503016000062
                            ],
                            [
                                107.47490649500008,
                                13.840571316000023
                            ],
                            [
                                107.46425758700008,
                                13.829604690000053
                            ],
                            [
                                107.45909448000003,
                                13.826157930000022
                            ],
                            [
                                107.45748100900005,
                                13.796701713000061
                            ],
                            [
                                107.46070795100007,
                                13.784166025000047
                            ],
                            [
                                107.49588161600008,
                                13.766301506000048
                            ],
                            [
                                107.51233901800003,
                                13.760973227000022
                            ],
                            [
                                107.52460139700008,
                                13.74937596600006
                            ],
                            [
                                107.54009071700006,
                                13.739345439000033
                            ],
                            [
                                107.57010127500007,
                                13.657204376000038
                            ],
                            [
                                107.57590977000007,
                                13.619886568000027
                            ],
                            [
                                107.60043452600007,
                                13.57848513500005
                            ],
                            [
                                107.60559763300006,
                                13.554958373000034
                            ],
                            [
                                107.61818270600008,
                                13.52860563400003
                            ],
                            [
                                107.62754083700008,
                                13.427873701000067
                            ],
                            [
                                107.63141316700006,
                                13.360067639000022
                            ],
                            [
                                107.61269690500006,
                                13.31862121200004
                            ],
                            [
                                107.60495224500005,
                                13.29538298600005
                            ],
                            [
                                107.58849484200005,
                                13.267745441000045
                            ],
                            [
                                107.57461899300006,
                                13.216230722000034
                            ],
                            [
                                107.55900866200005,
                                13.186600903000055
                            ],
                            [
                                107.53026871300005,
                                13.122282623000046
                            ],
                            [
                                107.51161295600008,
                                13.08938050200004
                            ],
                            [
                                107.49144457100005,
                                13.022580608000055
                            ],
                            [
                                107.50304139200006,
                                12.982295489000023
                            ],
                            [
                                107.49900771500006,
                                12.972960199000056
                            ],
                            [
                                107.49144457100005,
                                12.970012140000051
                            ],
                            [
                                107.48791510300003,
                                12.957728185000065
                            ],
                            [
                                107.49346140900008,
                                12.946426411000061
                            ],
                            [
                                107.49245299000006,
                                12.924312763000046
                            ],
                            [
                                107.50304139200006,
                                12.921855570000048
                            ],
                            [
                                107.50253718300007,
                                12.908094843000072
                            ],
                            [
                                107.49144457100005,
                                12.891384370000026
                            ],
                            [
                                107.51384922700004,
                                12.854318528000022
                            ],
                            [
                                107.52128410100005,
                                12.85149963200007
                            ],
                            [
                                107.54524091600007,
                                12.826933634000056
                            ],
                            [
                                107.55453450800007,
                                12.812635924000062
                            ],
                            [
                                107.55536060500003,
                                12.804781908000052
                            ],
                            [
                                107.55866499300004,
                                12.799142975000052
                            ],
                            [
                                107.56217590600005,
                                12.786857722000036
                            ],
                            [
                                107.56196938100004,
                                12.765709575000074
                            ],
                            [
                                107.56405075900005,
                                12.73897289000007
                            ],
                            [
                                107.55749603400005,
                                12.708971413000029
                            ],
                            [
                                107.56304234000004,
                                12.69569093900003
                            ],
                            [
                                107.57615179000004,
                                12.683393585000033
                            ],
                            [
                                107.57716020900006,
                                12.670111776000056
                            ],
                            [
                                107.57615179000004,
                                12.658305143000064
                            ],
                            [
                                107.57262232300008,
                                12.64305409900004
                            ],
                            [
                                107.57615179000004,
                                12.626326101000075
                            ],
                            [
                                107.58321072500007,
                                12.616485590000025
                            ],
                            [
                                107.58068967700007,
                                12.593850982000049
                            ],
                            [
                                107.58018546700004,
                                12.567769499000065
                            ],
                            [
                                107.58270651500004,
                                12.552513065000028
                            ],
                            [
                                107.57716020900006,
                                12.534302589000049
                            ],
                            [
                                107.56808443600005,
                                12.52888841500004
                            ],
                            [
                                107.56606759700009,
                                12.520520832000045
                            ],
                            [
                                107.57413495200007,
                                12.506738340000027
                            ],
                            [
                                107.57615179000004,
                                12.495416456000044
                            ],
                            [
                                107.57262232300008,
                                12.476709649000043
                            ],
                            [
                                107.56405075900005,
                                12.462432493000051
                            ],
                            [
                                107.55515408100007,
                                12.427705592000052
                            ],
                            [
                                107.53966476000005,
                                12.399266445000023
                            ],
                            [
                                107.54895835300005,
                                12.37828806300007
                            ],
                            [
                                107.54990836400003,
                                12.368540611000071
                            ],
                            [
                                107.54475351800005,
                                12.354596602000072
                            ],
                            [
                                107.53166814100007,
                                12.34607489800004
                            ],
                            [
                                107.52598459300003,
                                12.339102388000072
                            ],
                            [
                                107.51712883200008,
                                12.333291822000035
                            ],
                            [
                                107.49968166200006,
                                12.327093742000045
                            ],
                            [
                                107.48752151300005,
                                12.324382036000031
                            ],
                            [
                                107.47007434300008,
                                12.309273447000066
                            ],
                            [
                                107.46452297100006,
                                12.30087941000005
                            ],
                            [
                                107.45077671500007,
                                12.28873986800005
                            ],
                            [
                                107.44588622100008,
                                12.288869015000046
                            ],
                            [
                                107.43392433500009,
                                12.257818936000035
                            ],
                            [
                                107.42327542800007,
                                12.251196767000067
                            ],
                            [
                                107.41133574300005,
                                12.257818936000035
                            ],
                            [
                                107.40068683600003,
                                12.267278890000057
                            ],
                            [
                                107.38971523400005,
                                12.278630385000042
                            ],
                            [
                                107.36680394800004,
                                12.309844473000055
                            ],
                            [
                                107.35292809900005,
                                12.321509393000042
                            ],
                            [
                                107.32711256500005,
                                12.330336554000041
                            ],
                            [
                                107.30839630300005,
                                12.329390801000045
                            ],
                            [
                                107.28161268700006,
                                12.324977242000045
                            ],
                            [
                                107.25902409500009,
                                12.312681934000068
                            ],
                            [
                                107.24063052800005,
                                12.306376424000064
                            ],
                            [
                                107.22191426600006,
                                12.293764951000071
                            ],
                            [
                                107.20061645100009,
                                12.292503771000042
                            ],
                            [
                                107.15414849100006,
                                12.27894569800003
                            ],
                            [
                                107.13769108800005,
                                12.244889785000055
                            ],
                            [
                                107.11768404900005,
                                12.23196
                            ],
                            [
                                107.10864861300007,
                                12.214929562000066
                            ],
                            [
                                107.07702458400007,
                                12.19253602300006
                            ],
                            [
                                107.06282604100005,
                                12.16540892100005
                            ],
                            [
                                107.04959558000007,
                                12.149005144000057
                            ],
                            [
                                107.04572325000004,
                                12.132600356000069
                            ],
                            [
                                106.99407327500006,
                                12.096807501000058
                            ],
                            [
                                106.96571148300006,
                                12.077548463000028
                            ],
                            [
                                106.93813751800008,
                                12.065992375000064
                            ],
                            [
                                106.86565738200005,
                                12.068303632000038
                            ],
                            [
                                106.83099297000007,
                                12.072155684000052
                            ],
                            [
                                106.80184335000007,
                                12.08063000200002
                            ],
                            [
                                106.77111807500006,
                                12.077548463000028
                            ],
                            [
                                106.76166414400006,
                                12.042878705000021
                            ],
                            [
                                106.75142238600006,
                                12.022074701000065
                            ],
                            [
                                106.73015104200005,
                                11.99818663900004
                            ],
                            [
                                106.71045535300004,
                                11.972755085000074
                            ],
                            [
                                106.68524487100007,
                                11.968901610000046
                            ],
                            [
                                106.66791266400008,
                                11.978149857000062
                            ],
                            [
                                106.65373176800006,
                                11.982773862000045
                            ],
                            [
                                106.64821697500008,
                                11.969672310000021
                            ],
                            [
                                106.63955087200009,
                                11.962735936000058
                            ],
                            [
                                106.60094732200008,
                                11.971213702000057
                            ],
                            [
                                106.56943421900007,
                                11.969672310000021
                            ],
                            [
                                106.52969115500008,
                                11.978297826000073
                            ],
                            [
                                106.51200726200005,
                                11.97836318000003
                            ],
                            [
                                106.48942497300004,
                                11.970044711000071
                            ],
                            [
                                106.46912171200006,
                                11.974845187000028
                            ],
                            [
                                106.46051107100004,
                                11.984807166000053
                            ],
                            [
                                106.44220195900004,
                                11.986198040000033
                            ],
                            [
                                106.43153540400004,
                                11.974822991000053
                            ],
                            [
                                106.41029557300004,
                                11.974041208000074
                            ],
                            [
                                106.44666673500006,
                                11.90588181000004
                            ],
                            [
                                106.46298169900007,
                                11.871172743000045
                            ],
                            [
                                106.44259398200006,
                                11.85944005600004
                            ],
                            [
                                106.44369694000005,
                                11.83593862400005
                            ],
                            [
                                106.44823482700008,
                                11.826562161000027
                            ],
                            [
                                106.43562958600006,
                                11.81225009800005
                            ],
                            [
                                106.42957907100003,
                                11.800405066000053
                            ],
                            [
                                106.42504118400007,
                                11.786091637000027
                            ],
                            [
                                106.42403276500005,
                                11.76486690400003
                            ],
                            [
                                106.42604960300008,
                                11.750551624000025
                            ],
                            [
                                106.43512537700008,
                                11.722906220000027
                            ],
                            [
                                106.45579797200008,
                                11.678470310000023
                            ],
                            [
                                106.45731060100007,
                                11.670076059000053
                            ],
                            [
                                106.44571377900007,
                                11.663162956000065
                            ],
                            [
                                106.42403276500005,
                                11.674520106000045
                            ],
                            [
                                106.41041910400008,
                                11.68390174800004
                            ],
                            [
                                106.38823388000009,
                                11.69377681800006
                            ],
                            [
                                106.36756128500008,
                                11.695751789000042
                            ],
                            [
                                106.34134238400009,
                                11.68340798500003
                            ],
                            [
                                106.32319083700008,
                                11.681926692000047
                            ],
                            [
                                106.30302245100006,
                                11.687358062000044
                            ],
                            [
                                106.27579513100005,
                                11.698220484000046
                            ],
                            [
                                106.26571093800004,
                                11.71747554700005
                            ],
                            [
                                106.25562674500009,
                                11.721918833000075
                            ],
                            [
                                106.20772682900008,
                                11.735248261000038
                            ],
                            [
                                106.18503739600004,
                                11.750551624000025
                            ],
                            [
                                106.17142373500008,
                                11.749070691000043
                            ],
                            [
                                106.15528902700004,
                                11.740678584000023
                            ],
                            [
                                106.11394383600003,
                                11.746108800000059
                            ],
                            [
                                106.09478387000007,
                                11.750551624000025
                            ],
                            [
                                106.08419546800008,
                                11.764373286000023
                            ],
                            [
                                106.06856496900008,
                                11.774245477000022
                            ],
                            [
                                106.05646393800004,
                                11.776713469000072
                            ],
                            [
                                106.04032922900006,
                                11.776713469000072
                            ],
                            [
                                106.02873240700006,
                                11.77473907700005
                            ],
                            [
                                106.01411032800007,
                                11.76486690400003
                            ],
                            [
                                106.01310190900006,
                                11.750057980000065
                            ],
                            [
                                106.01511874700009,
                                11.724880984000038
                            ],
                            [
                                106.00604297400008,
                                11.715007024000045
                            ],
                            [
                                105.98637879800003,
                                11.700195424000071
                            ],
                            [
                                105.98234512100004,
                                11.694764305000035
                            ],
                            [
                                105.98284933000008,
                                11.672544983000023
                            ],
                            [
                                105.97175671800005,
                                11.66464435000006
                            ],
                            [
                                105.96217673500007,
                                11.660200145000033
                            ],
                            [
                                105.94503360700008,
                                11.644891784000038
                            ],
                            [
                                105.90973893200004,
                                11.655262056000026
                            ],
                            [
                                105.88352003100005,
                                11.67550766200003
                            ],
                            [
                                105.87293162900005,
                                11.676495215000045
                            ],
                            [
                                105.85679692000008,
                                11.667113322000034
                            ],
                            [
                                105.84469588900004,
                                11.651311522000071
                            ],
                            [
                                105.83259485800005,
                                11.628101
                            ],
                            [
                                105.82452750300007,
                                11.62118685300004
                            ],
                            [
                                105.81040963400005,
                                11.619211351000047
                            ],
                            [
                                105.80940121400005,
                                11.609333631000027
                            ],
                            [
                                105.81595594000004,
                                11.592046779000043
                            ],
                            [
                                105.81444331100005,
                                11.57525281200003
                            ],
                            [
                                105.83612432500007,
                                11.56240968700007
                            ],
                            [
                                105.84620851800008,
                                11.560433769000042
                            ],
                            [
                                105.86284743600004,
                                11.560927750000076
                            ],
                            [
                                105.87847793500003,
                                11.549071971000046
                            ],
                            [
                                105.88241077000004,
                                11.53282874100006
                            ],
                            [
                                105.87950652300009,
                                11.516386948000047
                            ],
                            [
                                105.88273346400007,
                                11.487927728000045
                            ],
                            [
                                105.87821574600008,
                                11.476859477000062
                            ],
                            [
                                105.87886113400003,
                                11.468320815000027
                            ],
                            [
                                105.88596040600004,
                                11.458200586000032
                            ],
                            [
                                105.89144620700006,
                                11.456619267000065
                            ],
                            [
                                105.90015894900006,
                                11.448396268000067
                            ],
                            [
                                105.90306319700005,
                                11.438907894000067
                            ],
                            [
                                105.89693200800008,
                                11.428470317000063
                            ],
                            [
                                105.88854195900007,
                                11.423093233000031
                            ],
                            [
                                105.87660227500004,
                                11.410757184000033
                            ],
                            [
                                105.87627958100006,
                                11.393991951000032
                            ],
                            [
                                105.87079378000004,
                                11.38323637600007
                            ],
                            [
                                105.87746951600008,
                                11.362771980000048
                            ],
                            [
                                105.87394004800007,
                                11.327672617000076
                            ],
                            [
                                105.86839374200008,
                                11.315807069000073
                            ],
                            [
                                105.86738532300006,
                                11.293063394000058
                            ],
                            [
                                105.87091479000009,
                                11.288118879000024
                            ],
                            [
                                105.89461264300007,
                                11.284657667000033
                            ],
                            [
                                105.90419262600005,
                                11.280207476000044
                            ],
                            [
                                105.91074735200004,
                                11.271801372000027
                            ],
                            [
                                105.91427681900007,
                                11.235207235000075
                            ],
                            [
                                105.91780628700008,
                                11.228778056000067
                            ],
                            [
                                105.93192415700008,
                                11.219875878000039
                            ],
                            [
                                105.94200834900005,
                                11.205038306000063
                            ],
                            [
                                105.95612621900005,
                                11.196630010000035
                            ],
                            [
                                105.97326934700004,
                                11.191189218000034
                            ],
                            [
                                105.99898403900005,
                                11.192673081000066
                            ],
                            [
                                106.00805981200006,
                                11.191189218000034
                            ],
                            [
                                106.02923661700004,
                                11.142217496000058
                            ],
                            [
                                106.04385869700008,
                                11.13479681800004
                            ],
                            [
                                106.07360706500003,
                                11.104617447000066
                            ],
                            [
                                106.080666,
                                11.095216677000053
                            ],
                            [
                                106.08520388700003,
                                11.09670102900003
                            ],
                            [
                                106.09831333800008,
                                11.08433119700004
                            ],
                            [
                                106.10738911100009,
                                11.071466017000034
                            ],
                            [
                                106.12705328700008,
                                11.081362360000071
                            ],
                            [
                                106.13108696400008,
                                11.090268782000066
                            ],
                            [
                                106.14621325300004,
                                11.100659265000047
                            ],
                            [
                                106.15881849400006,
                                11.094227105000073
                            ],
                            [
                                106.17444899300006,
                                11.077403863000029
                            ],
                            [
                                106.18150792800009,
                                11.056126025000026
                            ],
                            [
                                106.19108791100007,
                                11.043754479000029
                            ],
                            [
                                106.19915526500006,
                                10.993768130000035
                            ],
                            [
                                106.20470157100004,
                                10.981393962000027
                            ],
                            [
                                106.20570999100005,
                                10.972979231000068
                            ],
                            [
                                106.16285217100005,
                                10.980404006000072
                            ],
                            [
                                106.15175955900008,
                                10.979909027000076
                            ],
                            [
                                106.14369220500004,
                                10.970009269000059
                            ],
                            [
                                106.15428060800008,
                                10.94872366800007
                            ],
                            [
                                106.15276797900003,
                                10.936347614000056
                            ],
                            [
                                106.14318799500006,
                                10.922485821000066
                            ],
                            [
                                106.14419641500007,
                                10.90862338100004
                            ],
                            [
                                106.15579323600008,
                                10.894760295000026
                            ],
                            [
                                106.16839847700004,
                                10.871488663000036
                            ],
                            [
                                106.17444899300006,
                                10.852671974000032
                            ],
                            [
                                106.18856686300006,
                                10.830387520000045
                            ],
                            [
                                106.19406879900004,
                                10.788591282000027
                            ],
                            [
                                106.18486556100004,
                                10.794208299000047
                            ],
                            [
                                106.17412629900008,
                                10.804554494000058
                            ],
                            [
                                106.16132179400006,
                                10.811451760000068
                            ],
                            [
                                106.13881064900005,
                                10.811857476000057
                            ],
                            [
                                106.11485383400009,
                                10.808814589000065
                            ],
                            [
                                106.08779915500008,
                                10.809220309000068
                            ],
                            [
                                106.06828261100009,
                                10.813886051000054
                            ],
                            [
                                106.04859826700005,
                                10.830050764000077
                            ],
                            [
                                106.01955579200006,
                                10.850017735000051
                            ],
                            [
                                105.94630421600004,
                                10.913712952000026
                            ],
                            [
                                105.94307727400007,
                                10.906425135000063
                            ],
                            [
                                105.95533965200008,
                                10.88456061200003
                            ],
                            [
                                105.94275458000004,
                                10.871567889000062
                            ],
                            [
                                105.93275106000004,
                                10.864279040000042
                            ],
                            [
                                105.93106014300008,
                                10.853985615000056
                            ],
                            [
                                105.93565530800004,
                                10.841460625000025
                            ],
                            [
                                105.92016598800006,
                                10.841460625000025
                            ],
                            [
                                105.89360422400006,
                                10.84722481600005
                            ],
                            [
                                105.84872956600009,
                                10.865051508000022
                            ],
                            [
                                105.85881375900004,
                                10.894760295000026
                            ],
                            [
                                105.86335164600007,
                                10.90218702900006
                            ],
                            [
                                105.82402329400009,
                                10.943773308000061
                            ],
                            [
                                105.81141805300007,
                                10.964069255000027
                            ],
                            [
                                105.77662758800005,
                                11.029402832000073
                            ],
                            [
                                105.76351813700006,
                                11.030887518000043
                            ],
                            [
                                105.72418978500008,
                                11.027918139000064
                            ],
                            [
                                105.70654244800005,
                                11.015050485000074
                            ],
                            [
                                105.69797088400009,
                                11.003667091000068
                            ],
                            [
                                105.64553308200004,
                                10.97891906600006
                            ],
                            [
                                105.60908880900007,
                                10.975493776000064
                            ],
                            [
                                105.59359948900004,
                                10.972325842000032
                            ],
                            [
                                105.57036550800007,
                                10.964722663000032
                            ],
                            [
                                105.55261732900004,
                                10.962188226000023
                            ],
                            [
                                105.54293650400007,
                                10.954901600000028
                            ],
                            [
                                105.53228759700005,
                                10.952050263000046
                            ],
                            [
                                105.48517424800008,
                                10.951099811000063
                            ],
                            [
                                105.47936575300008,
                                10.953000711000072
                            ],
                            [
                                105.46355373900008,
                                10.966306675000055
                            ],
                            [
                                105.45229473700005,
                                10.95899539900006
                            ],
                            [
                                105.43968949600009,
                                10.965183017000072
                            ],
                            [
                                105.43102339300003,
                                10.973690780000027
                            ],
                            [
                                105.41605466900006,
                                10.957448474000046
                            ],
                            [
                                105.40581291100006,
                                10.940431769000043
                            ],
                            [
                                105.38871450700009,
                                10.92563923800003
                            ],
                            [
                                105.34823575000007,
                                10.87676504600006
                            ],
                            [
                                105.33625734300006,
                                10.86520441700003
                            ],
                            [
                                105.33398557600003,
                                10.85891686900004
                            ],
                            [
                                105.30899613900004,
                                10.871694650000052
                            ],
                            [
                                105.28483280000006,
                                10.888730839000061
                            ],
                            [
                                105.27430006200007,
                                10.89339534000004
                            ],
                            [
                                105.25220196600009,
                                10.897451369000066
                            ],
                            [
                                105.22890602800004,
                                10.896615831000076
                            ],
                            [
                                105.21251626200007,
                                10.899081876000025
                            ],
                            [
                                105.20458573000008,
                                10.902456432000065
                            ],
                            [
                                105.19956306000006,
                                10.90855649200006
                            ],
                            [
                                105.19150035300004,
                                10.911801153000056
                            ],
                            [
                                105.17590364000006,
                                10.91400750300005
                            ],
                            [
                                105.15660601200005,
                                10.92348164300006
                            ],
                            [
                                105.14775025200004,
                                10.925558126000055
                            ],
                            [
                                105.14061277300004,
                                10.925817685000027
                            ],
                            [
                                105.11791030400008,
                                10.92071470600007
                            ],
                            [
                                105.10352960600005,
                                10.920548585000063
                            ],
                            [
                                105.10065346600004,
                                10.922043669000061
                            ],
                            [
                                105.10310664400004,
                                10.927774756000076
                            ],
                            [
                                105.11504403600009,
                                10.945713859000023
                            ],
                            [
                                105.11472134200005,
                                10.957119288000058
                            ],
                            [
                                105.10955823500007,
                                10.961554614000022
                            ],
                            [
                                105.10084549200008,
                                10.963772251000023
                            ],
                            [
                                105.08471078400004,
                                10.960287384000026
                            ],
                            [
                                105.07825690100009,
                                10.956802476000064
                            ],
                            [
                                105.07277110000007,
                                10.947931616000062
                            ],
                            [
                                105.05631369700006,
                                10.935575331000052
                            ],
                            [
                                105.04663287200003,
                                10.922267988000044
                            ],
                            [
                                105.02953008100008,
                                10.893433366000068
                            ],
                            [
                                105.02888469300007,
                                10.882025491000036
                            ],
                            [
                                105.03049816400005,
                                10.863328308000064
                            ],
                            [
                                105.03404779900006,
                                10.844629953000037
                            ],
                            [
                                105.03856551800004,
                                10.835438808000049
                            ],
                            [
                                105.04276054200005,
                                10.816738711000028
                            ],
                            [
                                105.05437753200005,
                                10.78472397400003
                            ],
                            [
                                105.08083845400006,
                                10.760314410000035
                            ],
                            [
                                105.08890580800005,
                                10.754925018000051
                            ],
                            [
                                105.09084197300007,
                                10.742560757000035
                            ],
                            [
                                105.09051927900003,
                                10.717196587000046
                            ],
                            [
                                105.05728178000004,
                                10.702294145000053
                            ],
                            [
                                105.04243784800008,
                                10.692147383000076
                            ],
                            [
                                105.01565423200009,
                                10.681366076000074
                            ],
                            [
                                104.98596636800005,
                                10.660753690000035
                            ],
                            [
                                104.97434937800006,
                                10.650605540000072
                            ],
                            [
                                104.95886005800008,
                                10.641408488000025
                            ],
                            [
                                104.94692037400006,
                                10.63125969400005
                            ],
                            [
                                104.93175374800006,
                                10.61381566700004
                            ],
                            [
                                104.90561552000008,
                                10.567822101000047
                            ],
                            [
                                104.89432122400007,
                                10.554498539000065
                            ],
                            [
                                104.88948081200004,
                                10.54498135600005
                            ],
                            [
                                104.87818651600008,
                                10.538319152000042
                            ],
                            [
                                104.86947377300004,
                                10.525946106000049
                            ],
                            [
                                104.78686406600008,
                                10.520869840000046
                            ],
                            [
                                104.74555921200005,
                                10.521821647000024
                            ],
                            [
                                104.72684295000005,
                                10.52562884200006
                            ],
                            [
                                104.69941394600005,
                                10.53514662300006
                            ],
                            [
                                104.68618348500007,
                                10.537050144000034
                            ],
                            [
                                104.64100630100006,
                                10.538953654000068
                            ],
                            [
                                104.64423324300009,
                                10.529435990000025
                            ],
                            [
                                104.62680775800004,
                                10.528484207000076
                            ],
                            [
                                104.61357729700006,
                                10.529435990000025
                            ],
                            [
                                104.60099222400004,
                                10.533877603000064
                            ],
                            [
                                104.59618175800006,
                                10.532977610000046
                            ],
                            [
                                104.58759034800005,
                                10.528299434000076
                            ],
                            [
                                104.58640076900008,
                                10.524530851000065
                            ],
                            [
                                104.58679729500005,
                                10.516343772000027
                            ],
                            [
                                104.58455031100004,
                                10.510885598000073
                            ],
                            [
                                104.56683879000008,
                                10.500618773000042
                            ],
                            [
                                104.56419527900005,
                                10.49607007000003
                            ],
                            [
                                104.55651257700003,
                                10.49312150000003
                            ],
                            [
                                104.55291079300008,
                                10.489344365000022
                            ],
                            [
                                104.54898683200008,
                                10.460709749000046
                            ],
                            [
                                104.54175848300008,
                                10.454007649000062
                            ],
                            [
                                104.53225836600006,
                                10.448930204000021
                            ],
                            [
                                104.52213867700004,
                                10.445680595000056
                            ],
                            [
                                104.50114755100009,
                                10.415221990000077
                            ],
                            [
                                104.49731446000004,
                                10.40612201500005
                            ],
                            [
                                104.48462560900003,
                                10.426791574000049
                            ],
                            [
                                104.47233328500005,
                                10.428741461000072
                            ],
                            [
                                104.45462176400008,
                                10.425751629000047
                            ],
                            [
                                104.43585283800007,
                                10.42471168000003
                            ],
                            [
                                104.43968592800007,
                                10.414442002000044
                            ],
                            [
                                104.43836417300008,
                                10.394681671000058
                            ],
                            [
                                104.43915722600008,
                                10.382850874000042
                            ],
                            [
                                104.43770329500006,
                                10.374660060000053
                            ],
                            [
                                104.44325466800007,
                                10.37374995600004
                            ],
                            [
                                104.45634004500005,
                                10.376220232000037
                            ],
                            [
                                104.46255229500008,
                                10.37518011800006
                            ],
                            [
                                104.47074717800007,
                                10.372969865000073
                            ],
                            [
                                104.47999946600004,
                                10.368159261000073
                            ],
                            [
                                104.48700476900007,
                                10.362048388000062
                            ],
                            [
                                104.50479063900008,
                                10.333345191000035
                            ],
                            [
                                104.51057331800007,
                                10.312620697000057
                            ],
                            [
                                104.51016027000009,
                                10.303070717000026
                            ],
                            [
                                104.51429075500005,
                                10.294739646000039
                            ],
                            [
                                104.51532337600008,
                                10.28254743900004
                            ],
                            [
                                104.51738861900009,
                                10.278889685000024
                            ],
                            [
                                104.53184531800008,
                                10.283563474000061
                            ],
                            [
                                104.54423677400007,
                                10.285798740000075
                            ],
                            [
                                104.56468267700006,
                                10.267306518000055
                            ],
                            [
                                104.56984578300006,
                                10.25145518100004
                            ],
                            [
                                104.57851980300006,
                                10.243325983000034
                            ],
                            [
                                104.58430248200006,
                                10.22462803600007
                            ],
                            [
                                104.58554162800004,
                                10.20857131200006
                            ],
                            [
                                104.58409595800003,
                                10.190074588000073
                            ],
                            [
                                104.57191102600007,
                                10.177065259000074
                            ],
                            [
                                104.56550877400008,
                                10.163038983000035
                            ],
                            [
                                104.58141114200004,
                                10.150638429000026
                            ],
                            [
                                104.59132430700004,
                                10.144742915000052
                            ],
                            [
                                104.60702015200008,
                                10.144742915000052
                            ],
                            [
                                104.61879203500007,
                                10.147589039000025
                            ],
                            [
                                104.63675964600009,
                                10.137424195000051
                            ],
                            [
                                104.64419452000004,
                                10.151045012000054
                            ],
                            [
                                104.65245549100007,
                                10.158769994000068
                            ],
                            [
                                104.66154255800006,
                                10.179504549000058
                            ],
                            [
                                104.67352096600007,
                                10.20044101600007
                            ],
                            [
                                104.68942333500007,
                                10.21121361400003
                            ],
                            [
                                104.71605670400004,
                                10.221563094000032
                            ],
                            [
                                104.72397071400007,
                                10.227237685000034
                            ],
                            [
                                104.73331387200005,
                                10.231530146000068
                            ],
                            [
                                104.74140136200003,
                                10.224384143000066
                            ],
                            [
                                104.74891884600004,
                                10.220010287000036
                            ],
                            [
                                104.77911269400005,
                                10.205855816000053
                            ],
                            [
                                104.81189222600005,
                                10.176861984000027
                            ],
                            [
                                104.85237098200008,
                                10.118923352000024
                            ],
                            [
                                104.86372981700003,
                                10.097981552000022
                            ],
                            [
                                104.87529517600007,
                                10.088425131000065
                            ],
                            [
                                104.88975187500006,
                                10.085985149000066
                            ],
                            [
                                104.90441509800007,
                                10.08700181000006
                            ],
                            [
                                104.95129610700008,
                                10.097778227000049
                            ],
                            [
                                104.97370399000005,
                                10.091627580000022
                            ],
                            [
                                104.98854792200007,
                                10.085273484000027
                            ],
                            [
                                105.00371454800006,
                                10.076377538000031
                            ],
                            [
                                105.01952656200007,
                                10.058902644000057
                            ],
                            [
                                105.02920738700004,
                                10.043968803000041
                            ],
                            [
                                105.06438105100005,
                                10.008379002000027
                            ],
                            [
                                105.09374622100006,
                                9.966428871000062
                            ],
                            [
                                105.09729585700006,
                                9.958165329000053
                            ],
                            [
                                105.10439512800008,
                                9.949583738000058
                            ],
                            [
                                105.10278165700004,
                                9.936552001000052
                            ],
                            [
                                105.09277813800009,
                                9.931466304000026
                            ],
                            [
                                105.07954767700005,
                                9.940366221000033
                            ],
                            [
                                105.06212219200006,
                                9.946723157000065
                            ],
                            [
                                105.05760447400007,
                                9.94290901100004
                            ],
                            [
                                105.03566127000005,
                                9.935598439000046
                            ],
                            [
                                105.02501236300009,
                                9.922884012000054
                            ],
                            [
                                105.01145920800008,
                                9.913665743000024
                            ],
                            [
                                104.94530690300007,
                                9.853899657000056
                            ],
                            [
                                104.92497717000003,
                                9.859940340000037
                            ],
                            [
                                104.90948785000006,
                                9.856125185000053
                            ],
                            [
                                104.89722547200006,
                                9.849448557000073
                            ],
                            [
                                104.88496309300007,
                                9.824648473000025
                            ],
                            [
                                104.88334962200008,
                                9.809067982000045
                            ],
                            [
                                104.87560496200007,
                                9.790624821000051
                            ],
                            [
                                104.86237450100003,
                                9.751827245000072
                            ],
                            [
                                104.85914756000005,
                                9.738787701000035
                            ],
                            [
                                104.85688870100006,
                                9.71811420900002
                            ],
                            [
                                104.84365824000008,
                                9.667856399000073
                            ],
                            [
                                104.83300933200007,
                                9.527540232000035
                            ],
                            [
                                104.83300933200007,
                                9.502079845000026
                            ],
                            [
                                104.82978239000005,
                                9.454654830000038
                            ],
                            [
                                104.82623275500004,
                                9.419320535000054
                            ],
                            [
                                104.82816892000005,
                                9.376978285000064
                            ],
                            [
                                104.82655544900007,
                                9.330491370000061
                            ],
                            [
                                104.81848809400009,
                                9.274125637000054
                            ],
                            [
                                104.80977535200009,
                                9.166782837000028
                            ],
                            [
                                104.80203069200007,
                                9.09987622400007
                            ],
                            [
                                104.80622571600009,
                                9.032001017000027
                            ],
                            [
                                104.80203069200007,
                                9.002042630000062
                            ],
                            [
                                104.80267608000008,
                                8.946581199000036
                            ],
                            [
                                104.79589950300004,
                                8.916934550000065
                            ],
                            [
                                104.79396333800008,
                                8.89238657900006
                            ],
                            [
                                104.78880023100004,
                                8.85859057700003
                            ],
                            [
                                104.78880023100004,
                                8.842329128000074
                            ],
                            [
                                104.77750593500008,
                                8.83435756700004
                            ],
                            [
                                104.77653785200005,
                                8.821602712000072
                            ],
                            [
                                104.77879671200009,
                                8.807890751000059
                            ],
                            [
                                104.79364064300006,
                                8.779827478000072
                            ],
                            [
                                104.80364416300006,
                                8.768346436000058
                            ],
                            [
                                104.81461576400005,
                                8.765157195000029
                            ],
                            [
                                104.85559792400005,
                                8.778551824000033
                            ],
                            [
                                104.87334610300007,
                                8.78205986200004
                            ],
                            [
                                104.89335314200008,
                                8.792264873000022
                            ],
                            [
                                104.89787086000007,
                                8.797048375000031
                            ],
                            [
                                104.89109428300009,
                                8.774724837000065
                            ],
                            [
                                104.87721843300005,
                                8.754632504000028
                            ],
                            [
                                104.86882838500009,
                                8.745702231000053
                            ],
                            [
                                104.85204828800005,
                                8.73453908700003
                            ],
                            [
                                104.83462280300006,
                                8.720185983000022
                            ],
                            [
                                104.79686758500009,
                                8.713168709000058
                            ],
                            [
                                104.79041370200008,
                                8.699452749000045
                            ],
                            [
                                104.78170095900003,
                                8.690840144000049
                            ],
                            [
                                104.78073287700005,
                                8.679675362000069
                            ],
                            [
                                104.79138178400007,
                                8.667872232000036
                            ],
                            [
                                104.79267256100007,
                                8.65096440000002
                            ],
                            [
                                104.78847753700006,
                                8.64330777300006
                            ],
                            [
                                104.77589246400004,
                                8.637246167000058
                            ],
                            [
                                104.76104853200007,
                                8.635650991000034
                            ],
                            [
                                104.72619756200004,
                                8.637884235000058
                            ],
                            [
                                104.71490326600008,
                                8.634374845000025
                            ],
                            [
                                104.71361248900007,
                                8.619698862000064
                            ],
                            [
                                104.72361600900007,
                                8.603426997000042
                            ],
                            [
                                104.73781455200009,
                                8.589068889000032
                            ],
                            [
                                104.79234986700004,
                                8.574391150000054
                            ],
                            [
                                104.81848809400009,
                                8.558436442000072
                            ],
                            [
                                104.84656248700009,
                                8.556202730000052
                            ],
                            [
                                104.87334610300007,
                                8.557160037000074
                            ],
                            [
                                104.94014379600009,
                                8.572177474000057
                            ],
                            [
                                104.96232902000008,
                                8.57566749700004
                            ],
                            [
                                104.98048056700009,
                                8.580653189000031
                            ],
                            [
                                105.00165737200007,
                                8.587134491000029
                            ],
                            [
                                105.02535522500006,
                                8.597604052000065
                            ],
                            [
                                105.04401098200009,
                                8.60408506400006
                            ],
                            [
                                105.07325514100006,
                                8.611563017000037
                            ],
                            [
                                105.09997825200008,
                                8.621034879000035
                            ],
                            [
                                105.11560875100008,
                                8.63050650300005
                            ],
                            [
                                105.13023083000007,
                                8.643965771000069
                            ],
                            [
                                105.14434870000008,
                                8.669885968000074
                            ],
                            [
                                105.20586227600006,
                                8.74414738400003
                            ],
                            [
                                105.23799051400005,
                                8.750167394000073
                            ],
                            [
                                105.24799403400004,
                                8.75527037300003
                            ],
                            [
                                105.26412874200008,
                                8.760054352000054
                            ],
                            [
                                105.31705058600005,
                                8.804382958000076
                            ],
                            [
                                105.32382716300003,
                                8.815225119000047
                            ],
                            [
                                105.34254342500009,
                                8.838502800000072
                            ],
                            [
                                105.35061077900008,
                                8.854126721000057
                            ],
                            [
                                105.36093699300005,
                                8.868155799000021
                            ],
                            [
                                105.37287667700008,
                                8.889836046000028
                            ],
                            [
                                105.38126672500005,
                                8.91565937200005
                            ],
                            [
                                105.39707874000004,
                                8.977500399000064
                            ],
                            [
                                105.40353262300005,
                                8.988337433000027
                            ],
                            [
                                105.41740847200003,
                                9.005867243000068
                            ],
                            [
                                105.44612825300004,
                                9.037737446000051
                            ],
                            [
                                105.47872036400008,
                                9.06227558200004
                            ],
                            [
                                105.51421672300006,
                                9.083625583000071
                            ],
                            [
                                105.56574190600008,
                                9.117408066000053
                            ],
                            [
                                105.67997690200008,
                                9.159410782000066
                            ],
                            [
                                105.72645872900006,
                                9.185854392000067
                            ],
                            [
                                105.77845534800008,
                                9.209185350000041
                            ],
                            [
                                105.81627107100007,
                                9.230181896000033
                            ],
                            [
                                105.87850944800005,
                                9.251177191000068
                            ],
                            [
                                105.93696625300004,
                                9.27649429500002
                            ],
                            [
                                105.96570620200004,
                                9.284953657000074
                            ],
                            [
                                105.99242931300006,
                                9.297393525000075
                            ],
                            [
                                106.05696814700008,
                                9.319286618000035
                            ],
                            [
                                106.11192699800006,
                                9.331725264000056
                            ],
                            [
                                106.13310380300004,
                                9.333715406000067
                            ],
                            [
                                106.15478481700006,
                                9.341178338000077
                            ],
                            [
                                106.17596162200005,
                                9.359088722000024
                            ],
                            [
                                106.18705423400007,
                                9.371028466000041
                            ],
                            [
                                106.19360895900007,
                                9.389932220000048
                            ],
                            [
                                106.18856686300006,
                                9.411819489000038
                            ],
                            [
                                106.19058370200008,
                                9.431218410000042
                            ],
                            [
                                106.21831523200007,
                                9.463547520000077
                            ],
                            [
                                106.26268568000006,
                                9.495376288000045
                            ],
                            [
                                106.28285406600008,
                                9.521234985000035
                            ],
                            [
                                106.29192983900003,
                                9.569466165000051
                            ],
                            [
                                106.29293825800005,
                                9.58736465800007
                            ],
                            [
                                106.31966136900007,
                                9.586867490000031
                            ],
                            [
                                106.32621609400007,
                                9.580901422000068
                            ],
                            [
                                106.33579607800004,
                                9.566482991000044
                            ],
                            [
                                106.35142657600005,
                                9.55703610200004
                            ],
                            [
                                106.36100656000008,
                                9.544108354000059
                            ],
                            [
                                106.38621704200006,
                                9.531677365000064
                            ],
                            [
                                106.39630123400008,
                                9.529688365000027
                            ],
                            [
                                106.42705802200004,
                                9.531677365000064
                            ],
                            [
                                106.45831902000003,
                                9.541622193000023
                            ],
                            [
                                106.49260527500007,
                                9.549080623000066
                            ],
                            [
                                106.51932838600004,
                                9.562008182000056
                            ],
                            [
                                106.54605149700006,
                                9.58438164100005
                            ],
                            [
                                106.56016936700007,
                                9.602279347000035
                            ],
                            [
                                106.57176618900007,
                                9.628627025000071
                            ],
                            [
                                106.57983354300006,
                                9.67087844200006
                            ],
                            [
                                106.58134617200005,
                                9.711633601000074
                            ],
                            [
                                106.57832091400007,
                                9.730021501000067
                            ],
                            [
                                106.58072095200004,
                                9.748010846000057
                            ],
                            [
                                106.59362871900004,
                                9.760095960000058
                            ],
                            [
                                106.60911803900007,
                                9.779494832000069
                            ],
                            [
                                106.63396549000004,
                                9.792532782000023
                            ],
                            [
                                106.66365335300009,
                                9.819879013000048
                            ],
                            [
                                106.67139801400003,
                                9.834505140000033
                            ],
                            [
                                106.68420493800005,
                                9.870630487000028
                            ],
                            [
                                106.68319651900003,
                                9.89149315700007
                            ],
                            [
                                106.66857444000004,
                                9.912851185000022
                            ],
                            [
                                106.65697761800004,
                                9.926757991000045
                            ],
                            [
                                106.65143131200006,
                                9.944140667000056
                            ],
                            [
                                106.65647340800007,
                                9.96499865800007
                            ],
                            [
                                106.66756602000004,
                                9.986351885000033
                            ],
                            [
                                106.69277650200007,
                                10.015151693000064
                            ],
                            [
                                106.71344909700008,
                                10.023592531000077
                            ],
                            [
                                106.73109643500004,
                                10.034515644000066
                            ],
                            [
                                106.75580270700004,
                                10.059339528000066
                            ],
                            [
                                106.79412264000007,
                                10.106499648000067
                            ],
                            [
                                106.79273102100007,
                                10.142773484000031
                            ],
                            [
                                106.78079133700004,
                                10.160243818000026
                            ],
                            [
                                106.75658927400008,
                                10.181842184000061
                            ],
                            [
                                106.75691196800005,
                                10.203121496000051
                            ],
                            [
                                106.76497932300003,
                                10.21074359000005
                            ],
                            [
                                106.77691900700006,
                                10.211061174000065
                            ],
                            [
                                106.78466366700007,
                                10.203756677000058
                            ],
                            [
                                106.79337640900008,
                                10.203121496000051
                            ],
                            [
                                106.79853951600006,
                                10.211061174000065
                            ],
                            [
                                106.79434449200005,
                                10.215189728000041
                            ],
                            [
                                106.78305019600003,
                                10.232020970000065
                            ],
                            [
                                106.77562823000005,
                                10.251391673000057
                            ],
                            [
                                106.76659279300009,
                                10.254884624000056
                            ],
                            [
                                106.75691196800005,
                                10.267903468000043
                            ],
                            [
                                106.77240128800008,
                                10.290129523000076
                            ],
                            [
                                106.77917786600005,
                                10.303464405000057
                            ],
                            [
                                106.77917786600005,
                                10.323148199000059
                            ],
                            [
                                106.77691900700006,
                                10.350132044000077
                            ],
                            [
                                106.77853247800005,
                                10.369495491000066
                            ],
                            [
                                106.80047568100008,
                                10.375209067000071
                            ],
                            [
                                106.81499691900007,
                                10.371082606000073
                            ],
                            [
                                106.83080893300007,
                                10.35997263400003
                            ],
                            [
                                106.85985140800005,
                                10.358068028000048
                            ],
                            [
                                106.89341160200007,
                                10.35997263400003
                            ],
                            [
                                106.91922713500009,
                                10.373939392000068
                            ],
                            [
                                106.92826257200005,
                                10.375526484000034
                            ],
                            [
                                106.94375189200008,
                                10.382509594000055
                            ],
                            [
                                106.97634400300007,
                                10.399966687000074
                            ],
                            [
                                106.98182980400009,
                                10.412344761000043
                            ],
                            [
                                106.98537944000009,
                                10.41710542800007
                            ],
                            [
                                106.97892555700008,
                                10.429165459000046
                            ],
                            [
                                106.97376245000004,
                                10.434560584000053
                            ],
                            [
                                106.97473053200008,
                                10.447571973000038
                            ],
                            [
                                106.97892555700008,
                                10.452014762000033
                            ],
                            [
                                106.99441487700005,
                                10.45487080600003
                            ],
                            [
                                107.00829072600004,
                                10.469467958000052
                            ],
                            [
                                107.03539703600006,
                                10.46851599100006
                            ],
                            [
                                107.05056366200006,
                                10.462486797000054
                            ],
                            [
                                107.05282252100005,
                                10.452332101000025
                            ],
                            [
                                107.08121960800008,
                                10.433608510000056
                            ],
                            [
                                107.10058125800003,
                                10.434560584000053
                            ],
                            [
                                107.11477980200004,
                                10.433291151000049
                            ],
                            [
                                107.11252094300005,
                                10.41710542800007
                            ],
                            [
                                107.10154934100007,
                                10.402188429000034
                            ],
                            [
                                107.09090043300006,
                                10.394570962000046
                            ],
                            [
                                107.07379764200005,
                                10.387270716000046
                            ],
                            [
                                107.06185795800008,
                                10.378383230000054
                            ],
                            [
                                107.06218065200005,
                                10.364099241000076
                            ],
                            [
                                107.07896074900003,
                                10.332672174000038
                            ],
                            [
                                107.08670540900005,
                                10.327275290000046
                            ],
                            [
                                107.10929400100008,
                                10.355211097000051
                            ],
                            [
                                107.13607761700007,
                                10.377113568000027
                            ],
                            [
                                107.15769812600007,
                                10.391714365000041
                            ],
                            [
                                107.175769,
                                10.396475346000045
                            ],
                            [
                                107.21666080500006,
                                10.39652613000004
                            ],
                            [
                                107.22182391200005,
                                10.394088517000057
                            ],
                            [
                                107.23338927100008,
                                10.381493879000061
                            ],
                            [
                                107.24268286300008,
                                10.377837276000037
                            ],
                            [
                                107.25693303700007,
                                10.38007187200003
                            ],
                            [
                                107.27490064900007,
                                10.390838336000058
                            ],
                            [
                                107.30649886200007,
                                10.434103589000074
                            ],
                            [
                                107.31420479800005,
                                10.441542373000061
                            ],
                            [
                                107.32517640000003,
                                10.449158691000036
                            ],
                            [
                                107.35002385100006,
                                10.461217478000037
                            ],
                            [
                                107.38035710300005,
                                10.468833313000061
                            ],
                            [
                                107.39971875300006,
                                10.466612048000059
                            ],
                            [
                                107.43618319400008,
                                10.46851599100006
                            ],
                            [
                                107.44199168900008,
                                10.473275798000032
                            ],
                            [
                                107.46006256300006,
                                10.497391028000038
                            ],
                            [
                                107.46780722300008,
                                10.502784967000025
                            ],
                            [
                                107.47555188300004,
                                10.505323259000022
                            ],
                            [
                                107.50975746500006,
                                10.506592397000077
                            ],
                            [
                                107.52460139700008,
                                10.512620731000027
                            ],
                            [
                                107.53783185800006,
                                10.528484207000076
                            ],
                            [
                                107.55493464900007,
                                10.554815774000076
                            ],
                            [
                                107.56622894400004,
                                10.568139322000036
                            ],
                            [
                                107.58171826500006,
                                10.577972999000053
                            ],
                            [
                                107.59720758500004,
                                10.581145086000049
                            ],
                            [
                                107.62205503600006,
                                10.590978346000043
                            ],
                            [
                                107.67884920900008,
                                10.615718700000059
                            ],
                            [
                                107.69111158800007,
                                10.624599365000051
                            ],
                            [
                                107.73112566500004,
                                10.63443122800004
                            ],
                            [
                                107.75306886800007,
                                10.647434174000068
                            ],
                            [
                                107.75961310600007,
                                10.648512442000026
                            ],
                            [
                                107.78935260100008,
                                10.667184904000067
                            ],
                            [
                                107.82115733800003,
                                10.697220807000065
                            ],
                            [
                                107.83024440600008,
                                10.703105872000037
                            ],
                            [
                                107.83912494900005,
                                10.705743967000046
                            ],
                            [
                                107.85536292000006,
                                10.71529418800003
                            ],
                            [
                                107.87085224000003,
                                10.718781910000075
                            ],
                            [
                                107.88956850200009,
                                10.71941603700003
                            ],
                            [
                                107.93184143800005,
                                10.71656245500003
                            ],
                            [
                                107.96088391300009,
                                10.702611226000045
                            ],
                            [
                                107.97927748100005,
                                10.698489149000068
                            ],
                            [
                                107.99153985900006,
                                10.705147861000057
                            ],
                            [
                                108.00057529600008,
                                10.717830717000027
                            ],
                            [
                                108.01316036900005,
                                10.730195990000027
                            ],
                            [
                                108.02792362700006,
                                10.773926156000073
                            ],
                            [
                                108.02943625600005,
                                10.794233658000053
                            ],
                            [
                                108.04304991600009,
                                10.845244007000076
                            ],
                            [
                                108.05666357600006,
                                10.871488663000036
                            ],
                            [
                                108.07229407500006,
                                10.894760295000026
                            ],
                            [
                                108.09347088000004,
                                10.914069417000064
                            ],
                            [
                                108.11162242700004,
                                10.92397104500003
                            ],
                            [
                                108.14187500500009,
                                10.927931603000047
                            ],
                            [
                                108.16204339100005,
                                10.928426669000032
                            ],
                            [
                                108.17666547100004,
                                10.93387234200003
                            ],
                            [
                                108.20490121000006,
                                10.950703788000055
                            ],
                            [
                                108.25431375500006,
                                10.94872366800007
                            ],
                            [
                                108.27044846300004,
                                10.941793141000062
                            ],
                            [
                                108.27902002700006,
                                10.927931603000047
                            ],
                            [
                                108.29212947800005,
                                10.914564506000033
                            ],
                            [
                                108.30070104200007,
                                10.922980897000059
                            ],
                            [
                                108.30271788000005,
                                10.937337718000038
                            ],
                            [
                                108.31229786300008,
                                10.952683895000064
                            ],
                            [
                                108.32591152400005,
                                10.957634106000057
                            ],
                            [
                                108.33549150700009,
                                10.951198816000044
                            ],
                            [
                                108.35868515000004,
                                11.003667091000068
                            ],
                            [
                                108.35868515000004,
                                11.02148438200004
                            ],
                            [
                                108.36725671400006,
                                11.032867087000056
                            ],
                            [
                                108.38641668000008,
                                11.036826185000052
                            ],
                            [
                                108.40608085600007,
                                11.033856866000065
                            ],
                            [
                                108.41313979100005,
                                11.025443634000055
                            ],
                            [
                                108.42977870900006,
                                11.038805714000034
                            ],
                            [
                                108.44036711200005,
                                11.038310833000025
                            ],
                            [
                                108.45801444900007,
                                11.04325960600005
                            ],
                            [
                                108.47414915800005,
                                11.057115726000063
                            ],
                            [
                                108.48070388300005,
                                11.073445312000047
                            ],
                            [
                                108.51448592900005,
                                11.121439121000037
                            ],
                            [
                                108.53112484700006,
                                11.158542322000073
                            ],
                            [
                                108.55179744200007,
                                11.168930369000066
                            ],
                            [
                                108.57095740800008,
                                11.169425029000024
                            ],
                            [
                                108.59163000400008,
                                11.180801968000026
                            ],
                            [
                                108.62036995300008,
                                11.179812687000037
                            ],
                            [
                                108.64558043500006,
                                11.189705348000075
                            ],
                            [
                                108.65818567600007,
                                11.189705348000075
                            ],
                            [
                                108.67230354600008,
                                11.184759060000033
                            ],
                            [
                                108.70709401100004,
                                11.166457058000049
                            ],
                            [
                                108.73684238000004,
                                11.199597672000039
                            ],
                            [
                                108.73986763800008,
                                11.228283497000064
                            ],
                            [
                                108.74339710500004,
                                11.246581587000037
                            ],
                            [
                                108.75902760400004,
                                11.271801372000027
                            ],
                            [
                                108.78121282800004,
                                11.292074498000034
                            ],
                            [
                                108.79633911700006,
                                11.311851777000072
                            ],
                            [
                                108.81701171200007,
                                11.315312661000064
                            ],
                            [
                                108.84827271000006,
                                11.32915577600005
                            ],
                            [
                                108.85634006400005,
                                11.331133309000052
                            ],
                            [
                                108.88457580400006,
                                11.331133309000052
                            ],
                            [
                                108.91129891500009,
                                11.321245506000025
                            ],
                            [
                                108.92339994600007,
                                11.309874111000056
                            ],
                            [
                                108.94558517000007,
                                11.313829430000055
                            ],
                            [
                                108.95970304000008,
                                11.31926790500006
                            ],
                            [
                                108.98995561900006,
                                11.33558270900005
                            ],
                            [
                                109.00104823100008,
                                11.353873958000065
                            ],
                            [
                                109.01011755000007,
                                11.35643424400007
                            ],
                            [
                                109.01713937500006,
                                11.361091264000038
                            ],
                            [
                                109.01734590000007,
                                11.363925934000065
                            ],
                            [
                                109.00701968600004,
                                11.375669268000024
                            ],
                            [
                                109.00392182200005,
                                11.385387523000077
                            ],
                            [
                                109.00640011300004,
                                11.437010182000051
                            ],
                            [
                                109.00350877400007,
                                11.477896749000024
                            ],
                            [
                                109.00165005500008,
                                11.487611498000035
                            ],
                            [
                                109.00767230300005,
                                11.513127312000051
                            ],
                            [
                                109.01652806400006,
                                11.518696446000035
                            ],
                            [
                                109.02511947300007,
                                11.537734280000052
                            ],
                            [
                                109.02432642000008,
                                11.546151959000042
                            ],
                            [
                                109.01796547200007,
                                11.556414709000023
                            ],
                            [
                                109.02209595800008,
                                11.573005900000055
                            ],
                            [
                                109.02808516200008,
                                11.57846863800006
                            ],
                            [
                                109.04853106400009,
                                11.587370649000036
                            ],
                            [
                                109.05637898600008,
                                11.581705766000027
                            ],
                            [
                                109.06298776300008,
                                11.57948024500007
                            ],
                            [
                                109.07290092800008,
                                11.584942856000055
                            ],
                            [
                                109.08446628700005,
                                11.586763703000031
                            ],
                            [
                                109.09272725800008,
                                11.58534749000006
                            ],
                            [
                                109.10573828600008,
                                11.573815202000048
                            ],
                            [
                                109.11433795700009,
                                11.572180411000033
                            ],
                            [
                                109.12861291400009,
                                11.559878707000053
                            ],
                            [
                                109.13363558500004,
                                11.578654774000029
                            ],
                            [
                                109.13918695700005,
                                11.584999505000042
                            ],
                            [
                                109.14209481900008,
                                11.59147357200004
                            ],
                            [
                                109.14890185900003,
                                11.615288753000073
                            ],
                            [
                                109.15674978100009,
                                11.622166697000068
                            ],
                            [
                                109.16583684900007,
                                11.622368987000073
                            ],
                            [
                                109.17017385800006,
                                11.643608609000069
                            ],
                            [
                                109.17058690700009,
                                11.66100366300003
                            ],
                            [
                                109.17327172200004,
                                11.675363874000027
                            ],
                            [
                                109.17869918000008,
                                11.680614267000067
                            ],
                            [
                                109.18778624800007,
                                11.695248505000052
                            ],
                            [
                                109.20818258500009,
                                11.71626414900004
                            ],
                            [
                                109.21643529400006,
                                11.717631162000032
                            ],
                            [
                                109.22469626500003,
                                11.721877758000062
                            ],
                            [
                                109.23419638100006,
                                11.729359695000028
                            ],
                            [
                                109.23646814800009,
                                11.735628189000067
                            ],
                            [
                                109.23130504200009,
                                11.743918560000054
                            ],
                            [
                                109.22407669200004,
                                11.747558156000025
                            ],
                            [
                                109.21726139100008,
                                11.756252551000046
                            ],
                            [
                                109.21437005200005,
                                11.768383807000077
                            ],
                            [
                                109.21168523600005,
                                11.773033981000026
                            ],
                            [
                                109.20342426500008,
                                11.776875368000049
                            ],
                            [
                                109.18628275100008,
                                11.796687990000066
                            ],
                            [
                                109.18566317800008,
                                11.801135525000063
                            ],
                            [
                                109.18876104200007,
                                11.81124329000005
                            ],
                            [
                                109.18277183900005,
                                11.825393535000046
                            ],
                            [
                                109.18132616900004,
                                11.83792599800006
                            ],
                            [
                                109.18215226600006,
                                11.859755368000037
                            ],
                            [
                                109.19681548900007,
                                11.873094679000076
                            ],
                            [
                                109.20900042100004,
                                11.879157787000054
                            ],
                            [
                                109.22056578000007,
                                11.881178792000071
                            ],
                            [
                                109.23089199300006,
                                11.87754097100003
                            ],
                            [
                                109.23894643900007,
                                11.87269046700004
                            ],
                            [
                                109.24307692500008,
                                11.865818772000068
                            ],
                            [
                                109.24369649800008,
                                11.858138438000026
                            ],
                            [
                                109.23047894400008,
                                11.847021781000024
                            ],
                            [
                                109.22221797400005,
                                11.844192015000033
                            ],
                            [
                                109.22820717800005,
                                11.837319602000036
                            ],
                            [
                                109.23791381800004,
                                11.836713205000024
                            ],
                            [
                                109.24947917700007,
                                11.845404775000077
                            ],
                            [
                                109.25051179900004,
                                11.855308786000023
                            ],
                            [
                                109.25980539100004,
                                11.86137228900003
                            ],
                            [
                                109.27157727400004,
                                11.866222995000044
                            ],
                            [
                                109.27797952600008,
                                11.871275721000075
                            ],
                            [
                                109.27859909900008,
                                11.880168291000075
                            ],
                            [
                                109.27426208900005,
                                11.888858478000031
                            ],
                            [
                                109.25939234200007,
                                11.903813032000073
                            ],
                            [
                                109.25712057500004,
                                11.911087923000025
                            ],
                            [
                                109.25629447800009,
                                11.924020582000026
                            ],
                            [
                                109.26166410900004,
                                11.93270936600004
                            ],
                            [
                                109.27941228800006,
                                11.943873035000024
                            ],
                            [
                                109.28747964300004,
                                11.951134224000043
                            ],
                            [
                                109.27553995800008,
                                11.959342290000052
                            ],
                            [
                                109.24972442500007,
                                11.981124026000032
                            ],
                            [
                                109.23584857500003,
                                11.99501291200005
                            ],
                            [
                                109.22616775000006,
                                12.008585450000055
                            ],
                            [
                                109.21713231400008,
                                12.02373538200004
                            ],
                            [
                                109.21168523600005,
                                12.04139660100003
                            ],
                            [
                                109.20301121700004,
                                12.050687527000036
                            ],
                            [
                                109.19867420700007,
                                12.058564364000063
                            ],
                            [
                                109.19495677000003,
                                12.096127634000027
                            ],
                            [
                                109.19536981900006,
                                12.109455269000023
                            ],
                            [
                                109.20032640100004,
                                12.122580319000065
                            ],
                            [
                                109.20900042100004,
                                12.134291358000041
                            ],
                            [
                                109.21519614900006,
                                12.139742873000046
                            ],
                            [
                                109.21874578500007,
                                12.15089793900006
                            ],
                            [
                                109.20938765400007,
                                12.158784441000023
                            ],
                            [
                                109.19906144000004,
                                12.163831679000054
                            ],
                            [
                                109.19777066400007,
                                12.17234867600007
                            ],
                            [
                                109.21035573600005,
                                12.191905191000046
                            ],
                            [
                                109.22294080900008,
                                12.189697270000067
                            ],
                            [
                                109.23294432800009,
                                12.182757969000022
                            ],
                            [
                                109.23873991500005,
                                12.185167819000071
                            ],
                            [
                                109.22841370200007,
                                12.192636956000058
                            ],
                            [
                                109.20197859500007,
                                12.21948373500004
                            ],
                            [
                                109.19841605200008,
                                12.242366950000076
                            ],
                            [
                                109.19680258100004,
                                12.268540191000056
                            ],
                            [
                                109.20132029900009,
                                12.277999760000057
                            ],
                            [
                                109.21035573600005,
                                12.283990644000028
                            ],
                            [
                                109.23294432800009,
                                12.285882474000061
                            ],
                            [
                                109.24101168200008,
                                12.289666092000061
                            ],
                            [
                                109.24068898800004,
                                12.299755477000076
                            ],
                            [
                                109.23197624500006,
                                12.306691704000059
                            ],
                            [
                                109.21229190100007,
                                12.327184031000058
                            ],
                            [
                                109.20067491100008,
                                12.346729066000023
                            ],
                            [
                                109.19583449900006,
                                12.364066182000045
                            ],
                            [
                                109.19518911000006,
                                12.389912111000058
                            ],
                            [
                                109.18712175600007,
                                12.399367298000072
                            ],
                            [
                                109.16324238700008,
                                12.411028224000063
                            ],
                            [
                                109.16646932900005,
                                12.439075339000055
                            ],
                            [
                                109.17227782400005,
                                12.446953197000028
                            ],
                            [
                                109.18389481400004,
                                12.448843848000024
                            ],
                            [
                                109.19744796900005,
                                12.44317185500006
                            ],
                            [
                                109.21325998400005,
                                12.430882113000052
                            ],
                            [
                                109.22294080900008,
                                12.394954920000032
                            ],
                            [
                                109.23939821100004,
                                12.399367298000072
                            ],
                            [
                                109.25069250700005,
                                12.392118352000068
                            ],
                            [
                                109.25650100200005,
                                12.375098294000054
                            ],
                            [
                                109.26101872100008,
                                12.367533467000044
                            ],
                            [
                                109.27295840500005,
                                12.359653207000065
                            ],
                            [
                                109.28489808900008,
                                12.357131473000038
                            ],
                            [
                                109.29780585600008,
                                12.362174916000072
                            ],
                            [
                                109.30458243300006,
                                12.372891909000032
                            ],
                            [
                                109.33362490900004,
                                12.390542467000046
                            ],
                            [
                                109.34007879200004,
                                12.401573460000066
                            ],
                            [
                                109.32652563700009,
                                12.410082763000048
                            ],
                            [
                                109.31168170500007,
                                12.41544033100007
                            ],
                            [
                                109.29909663300003,
                                12.424894592000044
                            ],
                            [
                                109.29070658400008,
                                12.440966047000074
                            ],
                            [
                                109.29038389000004,
                                12.452625107000074
                            ],
                            [
                                109.29393352600005,
                                12.47279089400007
                            ],
                            [
                                109.30296896300007,
                                12.49327016500007
                            ],
                            [
                                109.30038740900005,
                                12.500831332000075
                            ],
                            [
                                109.28973850200003,
                                12.49390027000004
                            ],
                            [
                                109.27457187600004,
                                12.48822926400004
                            ],
                            [
                                109.24746556600007,
                                12.50807724200007
                            ],
                            [
                                109.22971738600006,
                                12.531073786000036
                            ],
                            [
                                109.22713583300003,
                                12.538003849000063
                            ],
                            [
                                109.23500150300003,
                                12.55940318000006
                            ],
                            [
                                109.24105201900005,
                                12.592866823000065
                            ],
                            [
                                109.22895098800007,
                                12.596311362000051
                            ],
                            [
                                109.21584153700007,
                                12.584009224000056
                            ],
                            [
                                109.20525313500008,
                                12.581056623000052
                            ],
                            [
                                109.19718578000004,
                                12.59581928800003
                            ],
                            [
                                109.19819420000005,
                                12.617469658000061
                            ],
                            [
                                109.19718578000004,
                                12.624850048000042
                            ],
                            [
                                109.19214368400009,
                                12.633214232000057
                            ],
                            [
                                109.21382469800005,
                                12.675031046000072
                            ],
                            [
                                109.22441310100004,
                                12.692247740000028
                            ],
                            [
                                109.23953939000006,
                                12.70257719700004
                            ],
                            [
                                109.25373793300008,
                                12.70784997800007
                            ],
                            [
                                109.26537509200006,
                                12.714912971000047
                            ],
                            [
                                109.29121079400005,
                                12.744894074000058
                            ],
                            [
                                109.29586969100006,
                                12.74802181900003
                            ],
                            [
                                109.30200088000004,
                                12.758093291000023
                            ],
                            [
                                109.31103631700006,
                                12.767534931000057
                            ],
                            [
                                109.33136605000004,
                                12.781696730000021
                            ],
                            [
                                109.34233765100004,
                                12.786731845000077
                            ],
                            [
                                109.35298655900004,
                                12.781382032000067
                            ],
                            [
                                109.35298655900004,
                                12.766276066000046
                            ],
                            [
                                109.35750427700009,
                                12.744244914000035
                            ],
                            [
                                109.37008935000006,
                                12.732913861000043
                            ],
                            [
                                109.37041204400003,
                                12.712768517000029
                            ],
                            [
                                109.33394760300007,
                                12.668065332000026
                            ],
                            [
                                109.34040148600008,
                                12.653267479000021
                            ],
                            [
                                109.34750075800008,
                                12.650118889000055
                            ],
                            [
                                109.35653619500005,
                                12.655156615000067
                            ],
                            [
                                109.37170282100004,
                                12.670584030000043
                            ],
                            [
                                109.38590136400006,
                                12.678454801000044
                            ],
                            [
                                109.39332333000004,
                                12.680658573000073
                            ],
                            [
                                109.40171337800007,
                                12.675936181000054
                            ],
                            [
                                109.40816726200006,
                                12.660194241000056
                            ],
                            [
                                109.40461762600006,
                                12.647914853000032
                            ],
                            [
                                109.39074177700007,
                                12.637839017000033
                            ],
                            [
                                109.38912830600009,
                                12.628077672000074
                            ],
                            [
                                109.39203255300004,
                                12.621464936000052
                            ],
                            [
                                109.40332684900005,
                                12.610443330000066
                            ],
                            [
                                109.41010342700008,
                                12.599421249000045
                            ],
                            [
                                109.42010694600003,
                                12.577375666000023
                            ],
                            [
                                109.43269201900006,
                                12.570761623000067
                            ],
                            [
                                109.44075937300005,
                                12.58304471100007
                            ],
                            [
                                109.43107854800007,
                                12.612332782000067
                            ],
                            [
                                109.43204663000006,
                                12.629337221000071
                            ],
                            [
                                109.43882320800009,
                                12.643821593000041
                            ],
                            [
                                109.45560330500007,
                                12.648544580000078
                            ],
                            [
                                109.46302527100005,
                                12.65610117700004
                            ],
                            [
                                109.44592248000004,
                                12.668380171000024
                            ],
                            [
                                109.42849699400006,
                                12.67310270300004
                            ],
                            [
                                109.41429845100004,
                                12.68223268500003
                            ],
                            [
                                109.41300767400008,
                                12.692306767000048
                            ],
                            [
                                109.41591192200008,
                                12.702065653000034
                            ],
                            [
                                109.40945803800008,
                                12.699862066000037
                            ],
                            [
                                109.40397223800005,
                                12.703954427000042
                            ],
                            [
                                109.40009990800007,
                                12.708676299000047
                            ],
                            [
                                109.39751835400006,
                                12.716545891000067
                            ],
                            [
                                109.38299711700006,
                                12.732599103000041
                            ],
                            [
                                109.37718862200006,
                                12.742356441000027
                            ],
                            [
                                109.36266738400008,
                                12.77603210600006
                            ],
                            [
                                109.36137660700007,
                                12.79365496500003
                            ],
                            [
                                109.36105391300003,
                                12.822289485000056
                            ],
                            [
                                109.37299359700006,
                                12.83518968800007
                            ],
                            [
                                109.39074177700007,
                                12.84651615100006
                            ],
                            [
                                109.41333036800006,
                                12.867909186000077
                            ],
                            [
                                109.42333388800006,
                                12.879863323000052
                            ],
                            [
                                109.43172393600008,
                                12.88300905400007
                            ],
                            [
                                109.43817782000008,
                                12.87734671000004
                            ],
                            [
                                109.43204663000006,
                                12.863504887000033
                            ],
                            [
                                109.42301119400008,
                                12.857842103000053
                            ],
                            [
                                109.42978777100006,
                                12.851549970000065
                            ],
                            [
                                109.44205015000006,
                                12.856898293000029
                            ],
                            [
                                109.45302175100005,
                                12.868852955000023
                            ],
                            [
                                109.45495791600007,
                                12.881121620000044
                            ],
                            [
                                109.44878639000007,
                                12.915958208000063
                            ],
                            [
                                109.43618114900005,
                                12.939055413000062
                            ],
                            [
                                109.40088647500005,
                                12.97885621200004
                            ],
                            [
                                109.38676860500004,
                                13.00145630000003
                            ],
                            [
                                109.36559180000006,
                                13.027492980000034
                            ],
                            [
                                109.34138973700004,
                                13.06924420100006
                            ],
                            [
                                109.31214557800007,
                                13.104604419000054
                            ],
                            [
                                109.30357401400005,
                                13.131121249000046
                            ],
                            [
                                109.29248140200008,
                                13.149779525000042
                            ],
                            [
                                109.29449824100004,
                                13.16450874000003
                            ],
                            [
                                109.31214557800007,
                                13.169418282000038
                            ],
                            [
                                109.31819609400009,
                                13.178255209000042
                            ],
                            [
                                109.30609506200005,
                                13.189055464000035
                            ],
                            [
                                109.30357401400005,
                                13.195437209000033
                            ],
                            [
                                109.29802770800006,
                                13.223416737000036
                            ],
                            [
                                109.30508664300004,
                                13.23028841100006
                            ],
                            [
                                109.31617925500007,
                                13.280347610000035
                            ],
                            [
                                109.29449824100004,
                                13.299975853000035
                            ],
                            [
                                109.28895193500006,
                                13.31567730300003
                            ],
                            [
                                109.29197719300004,
                                13.337265134000063
                            ],
                            [
                                109.29096877300009,
                                13.353945319000047
                            ],
                            [
                                109.27634669400004,
                                13.365228321000075
                            ],
                            [
                                109.25063200200009,
                                13.366209426000069
                            ],
                            [
                                109.23600992300004,
                                13.378472909000038
                            ],
                            [
                                109.22895098800007,
                                13.396621718000063
                            ],
                            [
                                109.22390889100006,
                                13.421144961000039
                            ],
                            [
                                109.22542152000005,
                                13.437819335000029
                            ],
                            [
                                109.23046361700005,
                                13.46086729700005
                            ],
                            [
                                109.23853097100005,
                                13.480971153000041
                            ],
                            [
                                109.25970777600008,
                                13.488816102000044
                            ],
                            [
                                109.27987616100006,
                                13.466751527000042
                            ],
                            [
                                109.27332143600006,
                                13.451550303000033
                            ],
                            [
                                109.26021198500007,
                                13.441252151000072
                            ],
                            [
                                109.25617830800007,
                                13.431443976000025
                            ],
                            [
                                109.27130459700004,
                                13.418202304000033
                            ],
                            [
                                109.30004454700008,
                                13.42163540000007
                            ],
                            [
                                109.31668346500004,
                                13.455963661000055
                            ],
                            [
                                109.32727186700004,
                                13.469203247000053
                            ],
                            [
                                109.31769188400006,
                                13.473616280000044
                            ],
                            [
                                109.29903612700008,
                                13.47802923100005
                            ],
                            [
                                109.29298561200005,
                                13.491267596000057
                            ],
                            [
                                109.29802770800006,
                                13.502053869000065
                            ],
                            [
                                109.28996035400007,
                                13.507446823000066
                            ],
                            [
                                109.29752349900008,
                                13.520193320000033
                            ],
                            [
                                109.28895193500006,
                                13.53244892400005
                            ],
                            [
                                109.27180880700007,
                                13.543233333000046
                            ],
                            [
                                109.28643088600006,
                                13.55940903100003
                            ],
                            [
                                109.28996035400007,
                                13.570192218000045
                            ],
                            [
                                109.27836353200007,
                                13.576563871000076
                            ],
                            [
                                109.25516988900006,
                                13.597638118000077
                            ],
                            [
                                109.22340468200008,
                                13.649580576000062
                            ],
                            [
                                109.23651413200008,
                                13.666238945000032
                            ],
                            [
                                109.25365726000007,
                                13.677507174000027
                            ],
                            [
                                109.24256464800004,
                                13.685345624000036
                            ],
                            [
                                109.23046361700005,
                                13.697592679000024
                            ],
                            [
                                109.22441310100004,
                                13.70738986400005
                            ],
                            [
                                109.22204961800009,
                                13.725482965000026
                            ],
                            [
                                109.22756441100006,
                                13.769867908000037
                            ],
                            [
                                109.23938182500007,
                                13.774458973000037
                            ],
                            [
                                109.24962358300007,
                                13.764511551000055
                            ],
                            [
                                109.26065316900008,
                                13.757624626000052
                            ],
                            [
                                109.27956103000008,
                                13.751502745000039
                            ],
                            [
                                109.29768106400007,
                                13.76221593200006
                            ],
                            [
                                109.29216627100004,
                                13.797412946000065
                            ],
                            [
                                109.29452975400005,
                                13.824189728000022
                            ],
                            [
                                109.30162020200004,
                                13.837194483000076
                            ],
                            [
                                109.30162020200004,
                                13.850963431000025
                            ],
                            [
                                109.29768106400007,
                                13.864731563000078
                            ],
                            [
                                109.30398368500005,
                                13.876204382000026
                            ],
                            [
                                109.30398368500005,
                                13.880793351000023
                            ],
                            [
                                109.28822713300008,
                                13.88997101700005
                            ],
                            [
                                109.28034885800008,
                                13.892265377000058
                            ],
                            [
                                109.25198706600008,
                                13.912913590000073
                            ],
                            [
                                109.24489661700005,
                                13.936618525000029
                            ],
                            [
                                109.24357306700006,
                                13.950717983000061
                            ],
                            [
                                109.25012779200006,
                                13.96735484800007
                            ],
                            [
                                109.25315305000004,
                                13.979587070000036
                            ],
                            [
                                109.25416147000004,
                                14.002092659000027
                            ],
                            [
                                109.24508569600005,
                                14.028020280000021
                            ],
                            [
                                109.23953939000006,
                                14.053455851000024
                            ],
                            [
                                109.22189205300003,
                                14.065194392000024
                            ],
                            [
                                109.21422806600003,
                                14.083583563000047
                            ],
                            [
                                109.20583801800007,
                                14.118323314000065
                            ],
                            [
                                109.21003304200008,
                                14.12771152700003
                            ],
                            [
                                109.22326350300006,
                                14.12927619100003
                            ],
                            [
                                109.22616775000006,
                                14.140228542000045
                            ],
                            [
                                109.19873874600006,
                                14.175585386000023
                            ],
                            [
                                109.19357563900007,
                                14.189038314000072
                            ],
                            [
                                109.19099408600005,
                                14.202803274000075
                            ],
                            [
                                109.18873522700005,
                                14.222823539000046
                            ],
                            [
                                109.19389833300005,
                                14.230017889000067
                            ],
                            [
                                109.20196568800009,
                                14.229705096000032
                            ],
                            [
                                109.20228838200006,
                                14.24002703900004
                            ],
                            [
                                109.19873874600006,
                                14.250974037000049
                            ],
                            [
                                109.18389481400004,
                                14.264109735000034
                            ],
                            [
                                109.18195864900008,
                                14.276931939000065
                            ],
                            [
                                109.18615367300004,
                                14.282248249000077
                            ],
                            [
                                109.17485937800006,
                                14.295694825000055
                            ],
                            [
                                109.15098000900008,
                                14.329776726000034
                            ],
                            [
                                109.13807224200008,
                                14.357601229000068
                            ],
                            [
                                109.13290913600008,
                                14.37135588500007
                            ],
                            [
                                109.12839141700005,
                                14.392611417000069
                            ],
                            [
                                109.11903328600005,
                                14.411677158000032
                            ],
                            [
                                109.11752065700006,
                                14.425369887000045
                            ],
                            [
                                109.12004170600005,
                                14.450272477000055
                            ],
                            [
                                109.10491541600004,
                                14.479077876000076
                            ],
                            [
                                109.09785648100006,
                                14.501533730000062
                            ],
                            [
                                109.08827649800008,
                                14.520570605000046
                            ],
                            [
                                109.08020914400004,
                                14.545950557000026
                            ],
                            [
                                109.06911653200007,
                                14.561079525000025
                            ],
                            [
                                109.06659548400006,
                                14.57474350900003
                            ],
                            [
                                109.07768809600009,
                                14.576695437000069
                            ],
                            [
                                109.07819230500007,
                                14.593774073000077
                            ],
                            [
                                109.07284768300008,
                                14.60470370400003
                            ],
                            [
                                109.07191993800006,
                                14.617233078000027
                            ],
                            [
                                109.06804760800009,
                                14.631283862000032
                            ],
                            [
                                109.06643413700004,
                                14.649080230000038
                            ],
                            [
                                109.06933838400005,
                                14.661880233000034
                            ],
                            [
                                109.07224263200004,
                                14.666562974000044
                            ],
                            [
                                109.07805112700004,
                                14.665938614000027
                            ],
                            [
                                109.08256884500008,
                                14.676864651000074
                            ],
                            [
                                109.07482418500007,
                                14.696218007000027
                            ],
                            [
                                109.07095185500003,
                                14.710887956000022
                            ],
                            [
                                109.04384554500007,
                                14.748338671000056
                            ],
                            [
                                109.02642006000008,
                                14.785158925000076
                            ],
                            [
                                109.01189882200003,
                                14.809494310000048
                            ],
                            [
                                108.98672867700009,
                                14.845056494000062
                            ],
                            [
                                108.96898049800006,
                                14.887785766000036
                            ],
                            [
                                108.94251957600005,
                                14.958878466000044
                            ],
                            [
                                108.93477491600004,
                                14.972907139000029
                            ],
                            [
                                108.92057637200008,
                                15.034310730000072
                            ],
                            [
                                108.90186011000009,
                                15.07856023100004
                            ],
                            [
                                108.89895586300008,
                                15.112208620000047
                            ],
                            [
                                108.90089202800004,
                                15.178243634000069
                            ],
                            [
                                108.90605513500009,
                                15.199108902000035
                            ],
                            [
                                108.92509409100006,
                                15.200977333000026
                            ],
                            [
                                108.93219336200008,
                                15.214990036000074
                            ],
                            [
                                108.94026071700006,
                                15.22401995100006
                            ],
                            [
                                108.94477843500005,
                                15.239587860000029
                            ],
                            [
                                108.93477491600004,
                                15.24737138300003
                            ],
                            [
                                108.92670756100006,
                                15.248928052000053
                            ],
                            [
                                108.91089554700005,
                                15.24737138300003
                            ],
                            [
                                108.90024664000003,
                                15.252041358000042
                            ],
                            [
                                108.88604809600008,
                                15.269475015000069
                            ],
                            [
                                108.87668996500008,
                                15.296556573000032
                            ],
                            [
                                108.87604457700007,
                                15.306516725000051
                            ],
                            [
                                108.88120768400006,
                                15.320833615000026
                            ],
                            [
                                108.87862613000004,
                                15.32581317200004
                            ],
                            [
                                108.87055877600005,
                                15.332659871000033
                            ],
                            [
                                108.86733183400008,
                                15.356621548000078
                            ],
                            [
                                108.85377867900007,
                                15.351020363000032
                            ],
                            [
                                108.84635671300003,
                                15.357555064000053
                            ],
                            [
                                108.83516325900007,
                                15.377935793000063
                            ],
                            [
                                108.82760011500005,
                                15.396895081000025
                            ],
                            [
                                108.82608748600006,
                                15.410991893000073
                            ],
                            [
                                108.81802013200007,
                                15.415852641000072
                            ],
                            [
                                108.80440647100005,
                                15.416824777000045
                            ],
                            [
                                108.79684332700003,
                                15.422171444000071
                            ],
                            [
                                108.79583490800007,
                                15.409047562000069
                            ],
                            [
                                108.79684332700003,
                                15.397381194000047
                            ],
                            [
                                108.78423808600007,
                                15.38960325100004
                            ],
                            [
                                108.77112863500008,
                                15.385228031000054
                            ],
                            [
                                108.74138026600008,
                                15.39640896700007
                            ],
                            [
                                108.72675818700009,
                                15.410505812000054
                            ],
                            [
                                108.70356454400007,
                                15.445500741000046
                            ],
                            [
                                108.70205191500008,
                                15.459594254000024
                            ],
                            [
                                108.69852244700007,
                                15.468341469000052
                            ],
                            [
                                108.68742983500005,
                                15.48437707100004
                            ],
                            [
                                108.67835406200004,
                                15.514015363000055
                            ],
                            [
                                108.65969830500006,
                                15.504784220000033
                            ],
                            [
                                108.64457201600004,
                                15.504298359000074
                            ],
                            [
                                108.60826892200004,
                                15.529561619000049
                            ],
                            [
                                108.59163000400008,
                                15.546078231000024
                            ],
                            [
                                108.57297424700005,
                                15.577650420000055
                            ],
                            [
                                108.55583111900006,
                                15.600962155000047
                            ],
                            [
                                108.54120904000007,
                                15.616016243000047
                            ],
                            [
                                108.51196488100004,
                                15.65631690600003
                            ],
                            [
                                108.49230070500005,
                                15.687386693000064
                            ],
                            [
                                108.45650182000008,
                                15.738835617000063
                            ],
                            [
                                108.42524082300008,
                                15.805796781000026
                            ],
                            [
                                108.40961032400008,
                                15.856245639000065
                            ],
                            [
                                108.40706668700005,
                                15.875936676000038
                            ],
                            [
                                108.40417534700003,
                                15.882889189000025
                            ],
                            [
                                108.37670762000005,
                                15.88884829500006
                            ],
                            [
                                108.32321783400005,
                                15.933933142000058
                            ],
                            [
                                108.30504369900007,
                                15.95418823600005
                            ],
                            [
                                108.29161962100005,
                                15.972654332000047
                            ],
                            [
                                108.26910847600004,
                                16.01335314800002
                            ],
                            [
                                108.25128849700008,
                                16.06033724300005
                            ],
                            [
                                108.25431375500006,
                                16.089891425000076
                            ],
                            [
                                108.26238110900005,
                                16.09764261400005
                            ],
                            [
                                108.28708738200004,
                                16.102486954000028
                            ],
                            [
                                108.31431470200005,
                                16.101033664000056
                            ],
                            [
                                108.32591152400005,
                                16.10781559000003
                            ],
                            [
                                108.34053360300004,
                                16.122831888000064
                            ],
                            [
                                108.33145783000003,
                                16.13687836200006
                            ],
                            [
                                108.32187784700005,
                                16.14414338900002
                            ],
                            [
                                108.30927260600004,
                                16.132519218000027
                            ],
                            [
                                108.28759159100008,
                                16.133972277000055
                            ],
                            [
                                108.27246530200006,
                                16.140753077000056
                            ],
                            [
                                108.26453912600005,
                                16.14826980600003
                            ],
                            [
                                108.24356400500005,
                                16.154468955000027
                            ],
                            [
                                108.23226971000008,
                                16.140520596000044
                            ],
                            [
                                108.22291157900008,
                                16.133701040000062
                            ],
                            [
                                108.22581582600009,
                                16.125021266000033
                            ],
                            [
                                108.23711012200005,
                                16.117581157000075
                            ],
                            [
                                108.24065975800005,
                                16.108280628000045
                            ],
                            [
                                108.23840089900006,
                                16.09897966400007
                            ],
                            [
                                108.22807468500008,
                                16.094018971000025
                            ],
                            [
                                108.22355696700004,
                                16.08595758100006
                            ],
                            [
                                108.20839034100004,
                                16.077895864000027
                            ],
                            [
                                108.19741873900006,
                                16.07665557000007
                            ],
                            [
                                108.16773087600006,
                                16.08595758100006
                            ],
                            [
                                108.15127347300006,
                                16.094018971000025
                            ],
                            [
                                108.13610684700006,
                                16.126881250000054
                            ],
                            [
                                108.13965648300007,
                                16.152299275000075
                            ],
                            [
                                108.15353233200005,
                                16.174614849000022
                            ],
                            [
                                108.16805357000004,
                                16.186391497000045
                            ],
                            [
                                108.19612796300004,
                                16.204674900000043
                            ],
                            [
                                108.19290102100007,
                                16.21273145500004
                            ],
                            [
                                108.16837626400007,
                                16.213970896000035
                            ],
                            [
                                108.14578767200004,
                                16.218308877000027
                            ],
                            [
                                108.11448633800006,
                                16.22047783200003
                            ],
                            [
                                108.09835162900004,
                                16.229153412000073
                            ],
                            [
                                108.08738002800004,
                                16.241546435000032
                            ],
                            [
                                108.08060345000007,
                                16.253319083000065
                            ],
                            [
                                108.05769216400006,
                                16.282437867000056
                            ],
                            [
                                108.04482312100004,
                                16.312283232000027
                            ],
                            [
                                108.03594257700007,
                                16.32635568200004
                            ],
                            [
                                108.02396416900007,
                                16.339832573000024
                            ],
                            [
                                108.02148587800008,
                                16.31922048200005
                            ],
                            [
                                108.01776844100004,
                                16.31129217600005
                            ],
                            [
                                108.00248564600008,
                                16.30970647600003
                            ],
                            [
                                107.97274615100008,
                                16.326553879000073
                            ],
                            [
                                107.95457201500005,
                                16.343201651000072
                            ],
                            [
                                107.91615850100004,
                                16.360244329000068
                            ],
                            [
                                107.89488650200008,
                                16.372728137000024
                            ],
                            [
                                107.87299492900007,
                                16.391947677000076
                            ],
                            [
                                107.85296207500005,
                                16.413740736000022
                            ],
                            [
                                107.84325543500006,
                                16.427211588000034
                            ],
                            [
                                107.79060000700008,
                                16.463990180000053
                            ],
                            [
                                107.69724277700004,
                                16.536887439000054
                            ],
                            [
                                107.64012590900006,
                                16.57369615500005
                            ],
                            [
                                107.58462251200007,
                                16.601530007000065
                            ],
                            [
                                107.54654460000006,
                                16.628123034000055
                            ],
                            [
                                107.49684969800006,
                                16.660277119000057
                            ],
                            [
                                107.46587105800006,
                                16.68778930800005
                            ],
                            [
                                107.39100601100006,
                                16.744346868000036
                            ],
                            [
                                107.33808416700003,
                                16.78853043400005
                            ],
                            [
                                107.24063052800005,
                                16.85864686900004
                            ],
                            [
                                107.15737543200004,
                                16.94787642400007
                            ],
                            [
                                107.12542870900006,
                                16.989234947000057
                            ],
                            [
                                107.11574788400009,
                                17.013613685000053
                            ],
                            [
                                107.11484434100004,
                                17.06638431700003
                            ],
                            [
                                107.11319214700006,
                                17.083559940000043
                            ],
                            [
                                107.10761599100005,
                                17.095009477000076
                            ],
                            [
                                107.04607175900009,
                                17.13547202600006
                            ],
                            [
                                106.97118089700007,
                                17.178009882000026
                            ],
                            [
                                106.87695419900007,
                                17.256301355000062
                            ],
                            [
                                106.73513011200004,
                                17.364167354000074
                            ],
                            [
                                106.69328071200005,
                                17.413727476000076
                            ],
                            [
                                106.63983449000006,
                                17.470488569000054
                            ],
                            [
                                106.61361558900006,
                                17.50944145400007
                            ],
                            [
                                106.55411885100006,
                                17.603663226000037
                            ],
                            [
                                106.48705896900009,
                                17.718969881000078
                            ],
                            [
                                106.46134427800007,
                                17.774194754000064
                            ],
                            [
                                106.44873903700005,
                                17.829402569000024
                            ],
                            [
                                106.44571377900007,
                                17.875955864000048
                            ],
                            [
                                106.45831902000003,
                                17.89706880400007
                            ],
                            [
                                106.47390918200006,
                                17.91029190200004
                            ],
                            [
                                106.48036306500006,
                                17.923494825000034
                            ],
                            [
                                106.49391622100006,
                                17.926872158000037
                            ],
                            [
                                106.50004741000004,
                                17.933319617000052
                            ],
                            [
                                106.50876015200004,
                                17.939459835000036
                            ],
                            [
                                106.51973175400008,
                                17.938845823000065
                            ],
                            [
                                106.52521755500004,
                                17.941915863000077
                            ],
                            [
                                106.51456864700003,
                                17.957572237000022
                            ],
                            [
                                106.50295165700004,
                                17.964939463000064
                            ],
                            [
                                106.47326379400005,
                                18.006067494000035
                            ],
                            [
                                106.45293406100006,
                                18.03859481300003
                            ],
                            [
                                106.43679935300008,
                                18.058537837000074
                            ],
                            [
                                106.42615044500008,
                                18.080319170000053
                            ],
                            [
                                106.42453697400003,
                                18.09565651500003
                            ],
                            [
                                106.43292702300005,
                                18.12387372300003
                            ],
                            [
                                106.42421428000006,
                                18.129700624000066
                            ],
                            [
                                106.41259729000006,
                                18.125407137000025
                            ],
                            [
                                106.41162920700003,
                                18.11129922400005
                            ],
                            [
                                106.40549801800006,
                                18.10332468400003
                            ],
                            [
                                106.40001221700004,
                                18.103631404000055
                            ],
                            [
                                106.38097326100007,
                                18.11129922400005
                            ],
                            [
                                106.34676767900004,
                                18.12234029600006
                            ],
                            [
                                106.32869680600004,
                                18.137673963000054
                            ],
                            [
                                106.29610469500005,
                                18.17017689000005
                            ],
                            [
                                106.26351258400007,
                                18.21861341600004
                            ],
                            [
                                106.15992775500007,
                                18.281744595000077
                            ],
                            [
                                106.13346683400005,
                                18.291242931000056
                            ],
                            [
                                106.08732156700006,
                                18.304417181000076
                            ],
                            [
                                106.06763722300008,
                                18.32004115500007
                            ],
                            [
                                106.04569402000004,
                                18.345159097000078
                            ],
                            [
                                105.97179705500008,
                                18.41436768500006
                            ],
                            [
                                105.95437157000003,
                                18.44345149000003
                            ],
                            [
                                105.94920846300005,
                                18.458144594000032
                            ],
                            [
                                105.94598152100008,
                                18.478957671000046
                            ],
                            [
                                105.92468370600005,
                                18.487527027000056
                            ],
                            [
                                105.91242132800005,
                                18.490281373000073
                            ],
                            [
                                105.90048164400008,
                                18.50007424100005
                            ],
                            [
                                105.87692496900007,
                                18.53281507500003
                            ],
                            [
                                105.85627254200006,
                                18.567996831000073
                            ],
                            [
                                105.84336477600004,
                                18.595219471000064
                            ],
                            [
                                105.81477407200003,
                                18.636809706000065
                            ],
                            [
                                105.79578755400007,
                                18.686490571000036
                            ],
                            [
                                105.79024124800009,
                                18.719921416000034
                            ],
                            [
                                105.77713179700004,
                                18.750480977000052
                            ],
                            [
                                105.76200550800007,
                                18.774829167000064
                            ],
                            [
                                105.74486238000009,
                                18.795832622000034
                            ],
                            [
                                105.72872767200005,
                                18.82065151000006
                            ],
                            [
                                105.70654244800005,
                                18.870278290000044
                            ],
                            [
                                105.69847509400006,
                                18.874572225000065
                            ],
                            [
                                105.67362764300009,
                                18.89376954000005
                            ],
                            [
                                105.64361708500007,
                                18.90140205600005
                            ],
                            [
                                105.62974123600009,
                                18.92918147300003
                            ],
                            [
                                105.62877315300005,
                                18.949326292000023
                            ],
                            [
                                105.61528050300006,
                                18.979500819000066
                            ],
                            [
                                105.61578471300004,
                                19.01430345500006
                            ],
                            [
                                105.62738153500004,
                                19.046715798000037
                            ],
                            [
                                105.64351624300008,
                                19.07769226700003
                            ],
                            [
                                105.65309622600006,
                                19.085316357000067
                            ],
                            [
                                105.67326461200008,
                                19.094845975000055
                            ],
                            [
                                105.68334880400005,
                                19.086269343000026
                            ],
                            [
                                105.69292878800007,
                                19.087222324000038
                            ],
                            [
                                105.71007191500007,
                                19.10056348200004
                            ],
                            [
                                105.72872767200005,
                                19.10437504400005
                            ],
                            [
                                105.73830765500009,
                                19.129624423000053
                            ],
                            [
                                105.73512483200005,
                                19.18573686600007
                            ],
                            [
                                105.73512483200005,
                                19.210289352000075
                            ],
                            [
                                105.73985179700009,
                                19.22293617100007
                            ],
                            [
                                105.75324486600005,
                                19.22888727900005
                            ],
                            [
                                105.76427445200005,
                                19.243020296000054
                            ],
                            [
                                105.77057707200004,
                                19.263101964000043
                            ],
                            [
                                105.78081883000004,
                                19.27574471500003
                            ],
                            [
                                105.79736320900008,
                                19.277232034000065
                            ],
                            [
                                105.83045196700004,
                                19.320358389000035
                            ],
                            [
                                105.82099803600005,
                                19.345634068000038
                            ],
                            [
                                105.80366583000006,
                                19.345634068000038
                            ],
                            [
                                105.79736320900008,
                                19.355297264000058
                            ],
                            [
                                105.79106058900004,
                                19.390228656000033
                            ],
                            [
                                105.79578755400007,
                                19.41177833000006
                            ],
                            [
                                105.81390758800006,
                                19.428124454000056
                            ],
                            [
                                105.81705889800008,
                                19.56031887000006
                            ],
                            [
                                105.83202762200006,
                                19.57739228500003
                            ],
                            [
                                105.83675458700009,
                                19.621923108000033
                            ],
                            [
                                105.83675458700009,
                                19.64121596800004
                            ],
                            [
                                105.84384503500007,
                                19.65902270500004
                            ],
                            [
                                105.85566244900008,
                                19.675343810000072
                            ],
                            [
                                105.86117724200005,
                                19.68869620600003
                            ],
                            [
                                105.87063117200006,
                                19.70353089500003
                            ],
                            [
                                105.88953903400005,
                                19.71539765600005
                            ],
                            [
                                105.91317386100008,
                                19.714656009000066
                            ],
                            [
                                105.93050606700007,
                                19.737645459000078
                            ],
                            [
                                105.94232348000008,
                                19.756182927000054
                            ],
                            [
                                105.95098958400007,
                                19.78287309700005
                            ],
                            [
                                105.95177741100008,
                                19.81474716300005
                            ],
                            [
                                105.95650437700004,
                                19.826605650000033
                            ],
                            [
                                105.96753396200006,
                                19.845873804000064
                            ],
                            [
                                105.97935137600007,
                                19.90292345900008
                            ],
                            [
                                105.99116878900008,
                                19.924404366000033
                            ],
                            [
                                106.01480361600005,
                                19.928848327000026
                            ],
                            [
                                106.03292365000004,
                                19.945882354000048
                            ],
                            [
                                106.05183151100005,
                                19.944401207000055
                            ],
                            [
                                106.06758806300007,
                                19.92958897500006
                            ],
                            [
                                106.08255678600005,
                                19.919219586000054
                            ],
                            [
                                106.12667513000008,
                                19.93551403500004
                            ],
                            [
                                106.15267343900007,
                                19.94292004600004
                            ],
                            [
                                106.19442830000008,
                                19.969578812000066
                            ],
                            [
                                106.22200226500007,
                                19.989569929000027
                            ],
                            [
                                106.30302245100006,
                                20.085728713000037
                            ],
                            [
                                106.33075398100004,
                                20.12408104000008
                            ],
                            [
                                106.38420020300003,
                                20.173783021000077
                            ],
                            [
                                106.41747803900006,
                                20.190820057000053
                            ],
                            [
                                106.46941163200006,
                                20.209747911000022
                            ],
                            [
                                106.50722735500005,
                                20.210694244000024
                            ],
                            [
                                106.52689153100005,
                                20.21447951500005
                            ],
                            [
                                106.54252203000004,
                                20.210694244000024
                            ],
                            [
                                106.55462306100009,
                                20.196498655000028
                            ],
                            [
                                106.55512727100006,
                                20.190346831000056
                            ],
                            [
                                106.57075777000006,
                                20.19271294600003
                            ],
                            [
                                106.58537984900005,
                                20.206435703000068
                            ],
                            [
                                106.59848930000004,
                                20.224888538000073
                            ],
                            [
                                106.61613663700007,
                                20.27645023100007
                            ],
                            [
                                106.61411979900004,
                                20.301042434000067
                            ],
                            [
                                106.60151455800008,
                                20.339341741000055
                            ],
                            [
                                106.58689247800004,
                                20.350215124000044
                            ],
                            [
                                106.57832091400007,
                                20.370068892000063
                            ],
                            [
                                106.58588405900008,
                                20.390865338000026
                            ],
                            [
                                106.59040177700007,
                                20.41802883200006
                            ],
                            [
                                106.60460032100008,
                                20.433451481000077
                            ],
                            [
                                106.59685566000007,
                                20.448872583000025
                            ],
                            [
                                106.59201524800005,
                                20.462780484000064
                            ],
                            [
                                106.59298333000004,
                                20.481523924000044
                            ],
                            [
                                106.59717835500004,
                                20.49210388700004
                            ],
                            [
                                106.58943369500008,
                                20.50993702900007
                            ],
                            [
                                106.57975286900006,
                                20.51991059200003
                            ],
                            [
                                106.57491245700004,
                                20.528674703000036
                            ],
                            [
                                106.58362520000009,
                                20.555568322000056
                            ],
                            [
                                106.59233794200009,
                                20.569768660000022
                            ],
                            [
                                106.63832186100007,
                                20.60764235700003
                            ],
                            [
                                106.66403655300007,
                                20.620856288000027
                            ],
                            [
                                106.68672598700005,
                                20.655773298000042
                            ],
                            [
                                106.69479334100004,
                                20.67558749400007
                            ],
                            [
                                106.70437332400007,
                                20.688323826000044
                            ],
                            [
                                106.71849119400008,
                                20.698700788000053
                            ],
                            [
                                106.74924798200004,
                                20.70719050100007
                            ],
                            [
                                106.76689531900007,
                                20.70388900200004
                            ],
                            [
                                106.78050897900005,
                                20.69398407500006
                            ],
                            [
                                106.79109738200003,
                                20.670870063000052
                            ],
                            [
                                106.80269420400003,
                                20.668983049000076
                            ],
                            [
                                106.80975313900007,
                                20.678417882000076
                            ],
                            [
                                106.79664368800007,
                                20.693512396000074
                            ],
                            [
                                106.79210580100005,
                                20.706247223000048
                            ],
                            [
                                106.78908054300007,
                                20.724640085000033
                            ],
                            [
                                106.77647530200005,
                                20.74255918600005
                            ],
                            [
                                106.75580270700004,
                                20.780748080000023
                            ],
                            [
                                106.76992057700005,
                                20.796775110000056
                            ],
                            [
                                106.78706370500004,
                                20.796775110000056
                            ],
                            [
                                106.81681207300005,
                                20.791590081000038
                            ],
                            [
                                106.83597204000006,
                                20.782633701000066
                            ],
                            [
                                106.84908149000006,
                                20.78876180800006
                            ],
                            [
                                106.88704807300007,
                                20.797385235000036
                            ],
                            [
                                106.89551556800006,
                                20.80201878400004
                            ],
                            [
                                106.89654818900004,
                                20.80781052100008
                            ],
                            [
                                106.88147191800005,
                                20.820744597000044
                            ],
                            [
                                106.85896077200005,
                                20.848153445000037
                            ],
                            [
                                106.86164558800004,
                                20.863013902000034
                            ],
                            [
                                106.87279789800004,
                                20.876908066000055
                            ],
                            [
                                106.88211730600005,
                                20.895708972000023
                            ],
                            [
                                106.90309242700005,
                                20.906561534000048
                            ],
                            [
                                106.93181220800005,
                                20.907767326000055
                            ],
                            [
                                106.94149303300009,
                                20.911384643000076
                            ],
                            [
                                106.96085468300004,
                                20.924948805000042
                            ],
                            [
                                106.98279788700006,
                                20.924044566000077
                            ],
                            [
                                106.99280140600007,
                                20.937908968000045
                            ],
                            [
                                106.99602834800004,
                                20.946347541000023
                            ],
                            [
                                107.02555486400007,
                                20.949417708000055
                            ],
                            [
                                107.07648003800006,
                                20.947534177000023
                            ],
                            [
                                107.09412737500008,
                                20.939999820000025
                            ],
                            [
                                107.11530418000007,
                                20.928697572000033
                            ],
                            [
                                107.14101887200007,
                                20.92540092200005
                            ],
                            [
                                107.15564095100007,
                                20.919278381000026
                            ],
                            [
                                107.20908717300006,
                                20.931994149000047
                            ],
                            [
                                107.22723872000006,
                                20.94800506200005
                            ],
                            [
                                107.23379344500006,
                                20.956951591000063
                            ],
                            [
                                107.25497025000004,
                                20.977667815000075
                            ],
                            [
                                107.30438279500004,
                                20.977197024000077
                            ],
                            [
                                107.32303855100008,
                                20.973901442000056
                            ],
                            [
                                107.36488795100007,
                                20.980492532000028
                            ],
                            [
                                107.38656896600008,
                                21.012502260000076
                            ],
                            [
                                107.40472051300009,
                                21.02944580600007
                            ],
                            [
                                107.49144457100005,
                                21.08308765700008
                            ],
                            [
                                107.50152876400006,
                                21.08779216000005
                            ],
                            [
                                107.52623503600006,
                                21.108019824000053
                            ],
                            [
                                107.56354654900008,
                                21.146115611000027
                            ],
                            [
                                107.57161390300007,
                                21.163043928000036
                            ],
                            [
                                107.59682438500005,
                                21.19266382400008
                            ],
                            [
                                107.61749698100004,
                                21.225567858000034
                            ],
                            [
                                107.60741278800003,
                                21.22368782500007
                            ],
                            [
                                107.59026966000005,
                                21.210056870000074
                            ],
                            [
                                107.57060548400005,
                                21.19971530600003
                            ],
                            [
                                107.54539500200008,
                                21.200655478000044
                            ],
                            [
                                107.52169714900003,
                                21.21663748800006
                            ],
                            [
                                107.51715926200006,
                                21.22932785200004
                            ],
                            [
                                107.51514242400003,
                                21.241547170000047
                            ],
                            [
                                107.52220135900006,
                                21.267392390000055
                            ],
                            [
                                107.53278976100006,
                                21.27772920800004
                            ],
                            [
                                107.58875703100006,
                                21.276319685000033
                            ],
                            [
                                107.61094225500005,
                                21.281487871000024
                            ],
                            [
                                107.62153065800004,
                                21.287125685000035
                            ],
                            [
                                107.63270394300008,
                                21.308152825000036
                            ],
                            [
                                107.63915782700008,
                                21.330698692000055
                            ],
                            [
                                107.66787760800008,
                                21.365863327000056
                            ],
                            [
                                107.68175345700007,
                                21.371873531000062
                            ],
                            [
                                107.70369666100004,
                                21.373676545000023
                            ],
                            [
                                107.72176753400004,
                                21.38178982900007
                            ],
                            [
                                107.74758306800004,
                                21.39621233500003
                            ],
                            [
                                107.75920005800003,
                                21.410933843000066
                            ],
                            [
                                107.77113974200006,
                                21.446079199000053
                            ],
                            [
                                107.77530895100006,
                                21.44641559300004
                            ],
                            [
                                107.79699399900005,
                                21.470441677000053
                            ],
                            [
                                107.80835283300007,
                                21.472171402000072
                            ],
                            [
                                107.82095081400007,
                                21.470441677000053
                            ],
                            [
                                107.83230964900008,
                                21.458525234000035
                            ],
                            [
                                107.83374241100006,
                                21.44667990100004
                            ],
                            [
                                107.83826012900005,
                                21.441874218000066
                            ],
                            [
                                107.86213949800003,
                                21.458993742000075
                            ],
                            [
                                107.88053306500007,
                                21.465600739000024
                            ],
                            [
                                107.88537347800008,
                                21.465600739000024
                            ],
                            [
                                107.89376352600004,
                                21.458993742000075
                            ],
                            [
                                107.89828124400009,
                                21.451185085000077
                            ],
                            [
                                107.90054010400007,
                                21.43736874600006
                            ],
                            [
                                107.89376352600004,
                                21.420246685000052
                            ],
                            [
                                107.86536643900007,
                                21.39921684500007
                            ],
                            [
                                107.85342675500004,
                                21.379385940000077
                            ],
                            [
                                107.84180976500005,
                                21.367966927000055
                            ],
                            [
                                107.83424581400004,
                                21.365550790000043
                            ],
                            [
                                107.83726623100006,
                                21.35565743500007
                            ],
                            [
                                107.84552720200008,
                                21.340076753000062
                            ],
                            [
                                107.87072316200005,
                                21.350656409000067
                            ],
                            [
                                107.88042980300008,
                                21.359311924000053
                            ],
                            [
                                107.88765815200009,
                                21.362773986000036
                            ],
                            [
                                107.89699046800007,
                                21.361054986000056
                            ],
                            [
                                107.92151522500006,
                                21.362557610000067
                            ],
                            [
                                107.93668185100006,
                                21.365562811000075
                            ],
                            [
                                107.95346194700005,
                                21.376681517000065
                            ],
                            [
                                107.98121364600007,
                                21.39170545500008
                            ],
                            [
                                107.99121716500008,
                                21.406126983000036
                            ],
                            [
                                107.99412141300007,
                                21.421147896000036
                            ],
                            [
                                107.99024908300004,
                                21.426555046000033
                            ],
                            [
                                107.97637323300006,
                                21.420547089000024
                            ],
                            [
                                107.96669240800009,
                                21.405526115000043
                            ],
                            [
                                107.95507541800004,
                                21.39921684500007
                            ],
                            [
                                107.92506486100007,
                                21.395010514000035
                            ],
                            [
                                107.89892663300009,
                                21.397414147000063
                            ],
                            [
                                107.90150818600006,
                                21.409431717000075
                            ],
                            [
                                107.91506134100007,
                                21.414238468000065
                            ],
                            [
                                107.93668185100006,
                                21.416040959000043
                            ],
                            [
                                107.95055770000005,
                                21.42234950200003
                            ],
                            [
                                107.99057177700007,
                                21.472207438000055
                            ],
                            [
                                108.01154689800006,
                                21.50643734600004
                            ],
                            [
                                108.03387733400007,
                                21.520979616000034
                            ],
                            [
                                108.04461659600008,
                                21.525206328000024
                            ],
                            [
                                108.04771446000007,
                                21.532890945000076
                            ],
                            [
                                108.03986653800007,
                                21.541919849000067
                            ],
                            [
                                108.03904044100005,
                                21.551908621000052
                            ],
                            [
                                108.04110568400006,
                                21.572460276000072
                            ],
                            [
                                108.02706203400004,
                                21.575917278000077
                            ],
                            [
                                108.00289869400007,
                                21.575917278000077
                            ],
                            [
                                107.97708316100005,
                                21.56535395900005
                            ],
                            [
                                107.96345255900007,
                                21.56304913200006
                            ],
                            [
                                107.94486537500006,
                                21.569771440000068
                            ],
                            [
                                107.93309349100008,
                                21.578606
                            ],
                            [
                                107.92586514200008,
                                21.592432924000036
                            ],
                            [
                                107.90954972500003,
                                21.599153869000077
                            ],
                            [
                                107.89220168600008,
                                21.61125078200007
                            ],
                            [
                                107.88641900700009,
                                21.62334668400007
                            ],
                            [
                                107.88022327900006,
                                21.64254445000006
                            ],
                            [
                                107.87154926000005,
                                21.64695957600003
                            ],
                            [
                                107.86060347300008,
                                21.649071110000023
                            ],
                            [
                                107.85626646400004,
                                21.65271823200004
                            ],
                            [
                                107.84387500800005,
                                21.646383697000033
                            ],
                            [
                                107.83726623100006,
                                21.646767616000034
                            ],
                            [
                                107.83230964900008,
                                21.649838932000023
                            ],
                            [
                                107.81578770700008,
                                21.654061885000033
                            ],
                            [
                                107.77077671100005,
                                21.644213636000075
                            ],
                            [
                                107.75615463100007,
                                21.638120941000068
                            ],
                            [
                                107.73649045500008,
                                21.624059897000052
                            ],
                            [
                                107.70119578100008,
                                21.616091365000045
                            ],
                            [
                                107.67346425100004,
                                21.60530971000003
                            ],
                            [
                                107.62253907700006,
                                21.610935021000046
                            ],
                            [
                                107.56707601700003,
                                21.611872552000023
                            ],
                            [
                                107.54842026000006,
                                21.60530971000003
                            ],
                            [
                                107.52220135900006,
                                21.59124547500005
                            ],
                            [
                                107.50203297300004,
                                21.594527252000034
                            ],
                            [
                                107.49446982900008,
                                21.609059942000044
                            ],
                            [
                                107.48186458800006,
                                21.642338988000063
                            ],
                            [
                                107.46825092700004,
                                21.658272718000035
                            ],
                            [
                                107.43900676800007,
                                21.652649249000035
                            ],
                            [
                                107.42438468900008,
                                21.634840153000027
                            ],
                            [
                                107.39745989400006,
                                21.614178851000077
                            ],
                            [
                                107.37422591400008,
                                21.59407718400007
                            ],
                            [
                                107.35680042900009,
                                21.607578612000054
                            ],
                            [
                                107.35260540500008,
                                21.664270843000054
                            ],
                            [
                                107.34518343900004,
                                21.682863444000077
                            ],
                            [
                                107.33517991900004,
                                21.69815556100008
                            ],
                            [
                                107.31839982300005,
                                21.71074784900003
                            ],
                            [
                                107.30839630300005,
                                21.729334452000046
                            ],
                            [
                                107.29903817200005,
                                21.73682837000007
                            ],
                            [
                                107.28064460500008,
                                21.738027360000046
                            ],
                            [
                                107.26902761500008,
                                21.72633677500005
                            ],
                            [
                                107.25450637700004,
                                21.722139923000043
                            ],
                            [
                                107.24418016400006,
                                21.708949018000055
                            ],
                            [
                                107.23256317400006,
                                21.705651103000037
                            ],
                            [
                                107.21352421800009,
                                21.71344605300004
                            ],
                            [
                                107.19093562600005,
                                21.732631824000066
                            ],
                            [
                                107.16641086900006,
                                21.749417272000073
                            ],
                            [
                                107.15640735000005,
                                21.753913042000022
                            ],
                            [
                                107.12992625900006,
                                21.77397369600004
                            ],
                            [
                                107.10824524500003,
                                21.787551912000026
                            ],
                            [
                                107.09311895600007,
                                21.801128842000026
                            ],
                            [
                                107.01748751000008,
                                21.82359817400004
                            ],
                            [
                                107.00084859200007,
                                21.834363479000046
                            ],
                            [
                                106.99631070500004,
                                21.84231992200006
                            ],
                            [
                                107.00034438200004,
                                21.851211894000073
                            ],
                            [
                                107.03463063800007,
                                21.878820278000035
                            ],
                            [
                                107.05177376500006,
                                21.89519561000003
                            ],
                            [
                                107.05429481400006,
                                21.904084288000035
                            ],
                            [
                                107.05278218500007,
                                21.917650149000053
                            ],
                            [
                                107.04622745900008,
                                21.924666466000076
                            ],
                            [
                                107.03210958900007,
                                21.933085591000065
                            ],
                            [
                                106.99933596300008,
                                21.956937072000073
                            ],
                            [
                                106.98269704500007,
                                21.953195927000024
                            ],
                            [
                                106.97664652900005,
                                21.938230366000028
                            ],
                            [
                                106.96706654600007,
                                21.927005162000057
                            ],
                            [
                                106.94790658000005,
                                21.928876091000063
                            ],
                            [
                                106.93126766200004,
                                21.941504217000045
                            ],
                            [
                                106.91632288800008,
                                21.965653555000074
                            ],
                            [
                                106.91309594600006,
                                21.97493059900006
                            ],
                            [
                                106.88857118900006,
                                21.975229848000026
                            ],
                            [
                                106.87856767000005,
                                21.98211241000007
                            ],
                            [
                                106.85533369000007,
                                21.984805495000046
                            ],
                            [
                                106.84371670000007,
                                21.983010110000066
                            ],
                            [
                                106.82306427300006,
                                21.976426839000055
                            ],
                            [
                                106.80725225900005,
                                21.98181317500007
                            ],
                            [
                                106.79079485600005,
                                21.991687592000062
                            ],
                            [
                                106.77853247800005,
                                22.00724588500003
                            ],
                            [
                                106.75723466300008,
                                22.010536844000057
                            ],
                            [
                                106.73819570700005,
                                22.00066373900006
                            ],
                            [
                                106.72657871600006,
                                21.98450626600004
                            ],
                            [
                                106.71302556100005,
                                21.974332098000048
                            ],
                            [
                                106.69979510000007,
                                21.973733595000056
                            ],
                            [
                                106.69237313500008,
                                21.991388377000078
                            ],
                            [
                                106.68979158100007,
                                22.006348338000066
                            ],
                            [
                                106.70076318300005,
                                22.022204173000034
                            ],
                            [
                                106.70850784300006,
                                22.038058233000072
                            ],
                            [
                                106.70883053700004,
                                22.080227367000077
                            ],
                            [
                                106.71294488800004,
                                22.097158771000068
                            ],
                            [
                                106.70437332400007,
                                22.117245826000044
                            ],
                            [
                                106.68975124400004,
                                22.13312658800004
                            ],
                            [
                                106.70286069500008,
                                22.155076458000053
                            ],
                            [
                                106.69428913100006,
                                22.16534968900004
                            ],
                            [
                                106.67613758400006,
                                22.18122502500006
                            ],
                            [
                                106.67714600300008,
                                22.19429748400006
                            ],
                            [
                                106.69832280800006,
                                22.20923594800007
                            ],
                            [
                                106.70286069500008,
                                22.226973309000073
                            ],
                            [
                                106.69025545400007,
                                22.249375189000034
                            ],
                            [
                                106.68398308600007,
                                22.28700228100007
                            ],
                            [
                                106.67139801400003,
                                22.29088386500007
                            ],
                            [
                                106.66881646000007,
                                22.300736633000042
                            ],
                            [
                                106.66752568300006,
                                22.315066692000073
                            ],
                            [
                                106.66365335300009,
                                22.326111775000072
                            ],
                            [
                                106.65655408200007,
                                22.33566357500007
                            ],
                            [
                                106.64816403300006,
                                22.339842281000074
                            ],
                            [
                                106.61944425200005,
                                22.337454464000075
                            ],
                            [
                                106.60556840300006,
                                22.34014075500005
                            ],
                            [
                                106.59427410700005,
                                22.344617792000065
                            ],
                            [
                                106.57176618900007,
                                22.345475874000044
                            ],
                            [
                                106.56117778600009,
                                22.348740270000064
                            ],
                            [
                                106.56168199600006,
                                22.35899930000005
                            ],
                            [
                                106.57781670400004,
                                22.37671766400007
                            ],
                            [
                                106.58739668800007,
                                22.383711134000066
                            ],
                            [
                                106.58739668800007,
                                22.397697020000066
                            ],
                            [
                                106.57932933300003,
                                22.400494028000026
                            ],
                            [
                                106.56974935000005,
                                22.406087876000072
                            ],
                            [
                                106.56521146300008,
                                22.412147624000056
                            ],
                            [
                                106.56470725400004,
                                22.421469796000054
                            ],
                            [
                                106.56722830200005,
                                22.430325281000023
                            ],
                            [
                                106.56521146300008,
                                22.451762535000057
                            ],
                            [
                                106.58537984900005,
                                22.482980432000033
                            ],
                            [
                                106.58991773600007,
                                22.528629753000075
                            ],
                            [
                                106.59042194500006,
                                22.547723465000047
                            ],
                            [
                                106.59596825100004,
                                22.55983030400006
                            ],
                            [
                                106.61008612100005,
                                22.603126834000022
                            ],
                            [
                                106.62319557200004,
                                22.605454219000023
                            ],
                            [
                                106.63025450700007,
                                22.599402937000036
                            ],
                            [
                                106.63882607100004,
                                22.586368501000038
                            ],
                            [
                                106.64941447300004,
                                22.57798857000006
                            ],
                            [
                                106.66807023000007,
                                22.574729570000045
                            ],
                            [
                                106.69479334100004,
                                22.575660721000077
                            ],
                            [
                                106.70790279100004,
                                22.58171304700005
                            ],
                            [
                                106.71949961300004,
                                22.59335138800003
                            ],
                            [
                                106.72403750000007,
                                22.60266135300003
                            ],
                            [
                                106.72463624900007,
                                22.627882373000034
                            ],
                            [
                                106.72778755900003,
                                22.64824198900004
                            ],
                            [
                                106.73251452400007,
                                22.661328721000075
                            ],
                            [
                                106.75536152400008,
                                22.68313716600005
                            ],
                            [
                                106.76717893700004,
                                22.702035013000057
                            ],
                            [
                                106.77348155800007,
                                22.718750166000063
                            ],
                            [
                                106.77190590200007,
                                22.729650253000045
                            ],
                            [
                                106.77978417800006,
                                22.757259918000045
                            ],
                            [
                                106.78923810900005,
                                22.76525114800006
                            ],
                            [
                                106.80499466000003,
                                22.766704048000065
                            ],
                            [
                                106.82784165900006,
                                22.784137651000037
                            ],
                            [
                                106.83729559000005,
                                22.803747790000045
                            ],
                            [
                                106.82705383200005,
                                22.810283877000074
                            ],
                            [
                                106.82336679900004,
                                22.816616319000047
                            ],
                            [
                                106.80924892900003,
                                22.82219327200005
                            ],
                            [
                                106.78555107600005,
                                22.817081074000043
                            ],
                            [
                                106.77798793100004,
                                22.818940077000036
                            ],
                            [
                                106.74168483700004,
                                22.840781465000077
                            ],
                            [
                                106.73160064400008,
                                22.858437897000044
                            ],
                            [
                                106.71849119400008,
                                22.86958814600007
                            ],
                            [
                                106.71092804900007,
                                22.888169860000062
                            ],
                            [
                                106.69378492200008,
                                22.894672859000025
                            ],
                            [
                                106.67462495500007,
                                22.894672859000025
                            ],
                            [
                                106.66454076200006,
                                22.878879321000056
                            ],
                            [
                                106.64941447300004,
                                22.870517292000045
                            ],
                            [
                                106.63983449000006,
                                22.88538276500003
                            ],
                            [
                                106.60857349200006,
                                22.91139342500003
                            ],
                            [
                                106.61008612100005,
                                22.922074929000075
                            ],
                            [
                                106.58537984900005,
                                22.935541713000077
                            ],
                            [
                                106.56874093100004,
                                22.928576302000067
                            ],
                            [
                                106.54857254500007,
                                22.931826872000045
                            ],
                            [
                                106.53647151400008,
                                22.94157811200006
                            ],
                            [
                                106.52184943500004,
                                22.94807854900006
                            ],
                            [
                                106.50369788800003,
                                22.937863437000033
                            ],
                            [
                                106.50218525900004,
                                22.919752935000076
                            ],
                            [
                                106.49361369500008,
                                22.909535686000027
                            ],
                            [
                                106.47193268000007,
                                22.90442677300007
                            ],
                            [
                                106.44773061800004,
                                22.90164001200003
                            ],
                            [
                                106.43210011900004,
                                22.889098879000073
                            ],
                            [
                                106.37814968700008,
                                22.884453720000067
                            ],
                            [
                                106.36352760800008,
                                22.878414777000046
                            ],
                            [
                                106.35898972100006,
                                22.870517292000045
                            ],
                            [
                                106.34285501300008,
                                22.861690147000047
                            ],
                            [
                                106.31512348200005,
                                22.873769253000034
                            ],
                            [
                                106.29949298400004,
                                22.870981862000065
                            ],
                            [
                                106.27478671100005,
                                22.877485685000067
                            ],
                            [
                                106.25383175900004,
                                22.88527128000004
                            ],
                            [
                                106.24834595800007,
                                22.92837197800003
                            ],
                            [
                                106.23285663800004,
                                22.94768853200003
                            ],
                            [
                                106.22543467200006,
                                22.962545543000033
                            ],
                            [
                                106.20284608000009,
                                22.98393677200005
                            ],
                            [
                                106.18767945400003,
                                22.993145842000047
                            ],
                            [
                                106.15895967300008,
                                22.999086843000043
                            ],
                            [
                                106.10506974700007,
                                22.991957610000043
                            ],
                            [
                                106.07957690700005,
                                22.997601617000043
                            ],
                            [
                                106.05956986900009,
                                22.991363490000026
                            ],
                            [
                                106.02020118000007,
                                22.99581932500007
                            ],
                            [
                                106.00374377800006,
                                22.991660550000063
                            ],
                            [
                                105.99890336500005,
                                22.979183458000023
                            ],
                            [
                                106.00083953000006,
                                22.96462539500004
                            ],
                            [
                                105.99438564700006,
                                22.95333438800003
                            ],
                            [
                                105.97050627800007,
                                22.95571152100007
                            ],
                            [
                                105.95404887600006,
                                22.94828284400006
                            ],
                            [
                                105.93081489500008,
                                22.94263677600003
                            ],
                            [
                                105.91726174000007,
                                22.950065763000055
                            ],
                            [
                                105.90080433800006,
                                22.94471693400004
                            ],
                            [
                                105.88789657100006,
                                22.936396112000068
                            ],
                            [
                                105.86433989700004,
                                22.94174527000007
                            ],
                            [
                                105.84110591600006,
                                22.979183458000023
                            ],
                            [
                                105.82335773700004,
                                22.990472305000026
                            ],
                            [
                                105.78657060200004,
                                23.00918594700005
                            ],
                            [
                                105.77140397600004,
                                23.027896992000024
                            ],
                            [
                                105.73106720500004,
                                23.050465592000023
                            ],
                            [
                                105.72558140400008,
                                23.063232993000042
                            ],
                            [
                                105.70331550600008,
                                23.069170906000068
                            ],
                            [
                                105.68718079800004,
                                23.069764683000074
                            ],
                            [
                                105.65760910400007,
                                23.074027924000063
                            ],
                            [
                                105.64397850200004,
                                23.081817787000034
                            ],
                            [
                                105.63365228900005,
                                23.080677836000064
                            ],
                            [
                                105.62435869700005,
                                23.074027924000063
                            ],
                            [
                                105.61031504700009,
                                23.073457916000052
                            ],
                            [
                                105.59751054200007,
                                23.07953787400004
                            ],
                            [
                                105.58759737700007,
                                23.076117932000045
                            ],
                            [
                                105.57128196000008,
                                23.077447920000054
                            ],
                            [
                                105.56074922200008,
                                23.084667625000066
                            ],
                            [
                                105.55930355200007,
                                23.098346003000074
                            ],
                            [
                                105.56033617400004,
                                23.11544201600003
                            ],
                            [
                                105.56487970800003,
                                23.13310561000003
                            ],
                            [
                                105.57479287300004,
                                23.157412989000022
                            ],
                            [
                                105.56485953900005,
                                23.172151937000024
                            ],
                            [
                                105.55074166900005,
                                23.188374622000026
                            ],
                            [
                                105.53864063800006,
                                23.197180399000047
                            ],
                            [
                                105.51948067200004,
                                23.198570731000075
                            ],
                            [
                                105.48569862600004,
                                23.23286102800006
                            ],
                            [
                                105.47964811000008,
                                23.248613077000073
                            ],
                            [
                                105.47158075600004,
                                23.263900051000064
                            ],
                            [
                                105.45393341900007,
                                23.284279956000034
                            ],
                            [
                                105.44536185500004,
                                23.299099746000024
                            ],
                            [
                                105.42166400200006,
                                23.298636653000074
                            ],
                            [
                                105.40452087400007,
                                23.30280443600003
                            ]
                        ]
                    ]
                ]
            },
            "properties": {
                "shapeName": "Vietnam",
                "shapeISO": "VNM",
                "shapeID": "46766057B26896484955522",
                "shapeGroup": "VNM",
                "shapeType": "ADM0"
            }
        }
    ]
}