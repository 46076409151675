import React, { FC, useEffect, useState } from 'react';
import { Button, Col, DatePicker, FormInstance, Form, Input, Row, Select, Typography, Upload, UploadFile, message, UploadProps, Checkbox } from 'antd';
import moment from 'moment';
import { PlusOutlined } from '@ant-design/icons';
import { URL_UPLOAD_IMAGE } from '../../../helper/AxiosCustom';
import { contentToSpeech, postUpdateTrafficNews } from '../../../services';
import { extractListImgUpdate, extractUpdateUrls, findAudioLink } from '../helper';
import { APP_COLOR, STORAGE_URL, I_TYPES, TrafficType, IMARKER_TYPES } from '../../../constant';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { AudioData, MarkerType } from '../../../constant/type';


const { TextArea } = Input;
interface PProps {
    formUpdateNews: FormInstance<any>,
    speechUpdateUrl: string
    onGetTrafficRealtime({ setLine, setPoints }: {
        setLine: (value: any) => void;
        setPoints: (value: any) => void;
    }): void,
    watch_content: any,
    formNews: FormInstance<any>,
    setSpeechUpdatehUrl: (value: React.SetStateAction<string>) => void,
    notFoundRoad: boolean,
    listImgUpdateTrafic: UploadFile<any>[],
    setListImgUpdateTrafic: (value: React.SetStateAction<UploadFile<any>[]>) => void
    handlePreview: (file: UploadFile) => Promise<void>,
    setPoints: (value: any) => void,
    setLine: (value: any) => void,
    fixedSound: AudioData[],
    setActiveKeyTrafficNews: React.Dispatch<React.SetStateAction<string>>,
    setMarkers: (value: React.SetStateAction<MarkerType | undefined>) => void
    setIMarkerType: (value: React.SetStateAction<string>) => void,
    setMarkersBearing: (value: React.SetStateAction<MarkerType | undefined>) => void
}
export const UpdateNews: FC<PProps> = ({
    formUpdateNews,
    speechUpdateUrl,
    onGetTrafficRealtime,
    watch_content,
    formNews,
    setSpeechUpdatehUrl,
    notFoundRoad,
    listImgUpdateTrafic,
    setListImgUpdateTrafic,
    handlePreview,
    setPoints,
    setLine,
    fixedSound,
    setActiveKeyTrafficNews,
    setMarkers,
    setIMarkerType,
    setMarkersBearing
}) => {
    const [loadings, setLoadings] = useState<boolean>(false);
    const IWatchForever = Form.useWatch('forever', formUpdateNews);
    useEffect(() => {
        if (IWatchForever) {
            formUpdateNews.resetFields(['expire_time'])
        }
    }, [IWatchForever])
    const IWatchTrafficType = Form.useWatch('traffic_type', formUpdateNews);
    console.log('IWatchTrafficType', listImgUpdateTrafic);

    const handleFormUpdateTrafficNews = (data: any) => {
        console.log(listImgUpdateTrafic, data);
        const [lat, lon] = data.UNLocation.split(',').map(parseFloat);

        const finalData  : { 
            audio_link?: string;
            expire_time?: number;
            id: any; category: any;
             UNLocation: any; traffic_type: any; content: any; ai_road_id: any; osm_combination_id: any; name: any; forever: any; type: string; image: any[]; lat: any; lon: any; bearing: any }= {
            id: data.id,
            category: data.category,
            UNLocation: data.UNLocation,
            traffic_type: data.traffic_type,
            content: data.content,
            ai_road_id: data.ai_road_id ? data.ai_road_id : 'ai_road_id_other',
            osm_combination_id: data.osm_combination_id,
            name: data.name ? data.name : 'null',
            forever: data.forever ? data.forever : false,
            type: I_TYPES.trafficNews,
            image:
                (Array.isArray(listImgUpdateTrafic) && listImgUpdateTrafic.length > 0)
                    ? extractListImgUpdate(listImgUpdateTrafic) :
                    (typeof data.image === 'object' && data.image !== null && 'fileList' in data.image) ? extractUpdateUrls(data.image) : [],
            lat, lon,
            bearing: data.bearing

        }
        speechUpdateUrl.length > 0 && (finalData.audio_link = speechUpdateUrl);
        !data.forever && (finalData.expire_time = moment(data.expire_time.$d).unix())
        const { UNLocation, ...updatedFinalData } = finalData;
        const dataTraffic = data.sta_cty? {...updatedFinalData,sta_cty: data.sta_cty} : 
        updatedFinalData;
        if (typeof (data.traffic_type) == 'string') {
            console.log(dataTraffic)
            postUpdateTrafficNews(dataTraffic)
                .then((res) => {
                    if (res.status == 200) {
                        formUpdateNews.resetFields();
                        setActiveKeyTrafficNews('1');

                        onGetTrafficRealtime({
                            setLine,
                            setPoints
                        });
                    }
                })
        } else {
            if (Array.isArray(data.traffic_type)) {
                data.traffic_type.length > 0 && data.traffic_type.forEach((item: any) => {
                    postUpdateTrafficNews({ ...dataTraffic, traffic_type: item })
                        .then((res) => {
                            if (res.status == 200) {
                                formUpdateNews.resetFields();
                                onGetTrafficRealtime({
                                    setLine,
                                    setPoints
                                });
                            }
                        })
                })
            }
        }
        console.log(updatedFinalData)

    }
    const handleChange: UploadProps['onChange'] = (info) => {
        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
        console.log('info fileList', info.fileList);

        // Cập nhật the listImgUpdateTrafic state
        setListImgUpdateTrafic(
            info.fileList
        );
    };
    return (<Form form={formUpdateNews} onFinish={handleFormUpdateTrafficNews} layout='vertical'>
        <Form.Item name='id' label='id' hidden>
        </Form.Item>
        <Form.Item required name='UNLocation' rules={[
            {
                required: true,
                validateTrigger: 'onFinish',
                message: 'Vui lòng chọn/nhập toạ độ'
            },
        ]} >
            <Input allowClear placeholder='Nhập toạ độ (vĩ độ - lat, kinh độ - lon)' />
        </Form.Item>
        <Row gutter={16} style={{alignItems:'center'}}>
                <Col span={12}>
                    <Form.Item label='Góc hướng' name='bearing'>
                        <Input placeholder='Góc' allowClear />
                    </Form.Item>
                </Col>
                <Col span={12} style={{marginTop:4}}>
                    <Button className="text-white bg-base" onClick={() => setIMarkerType(IMARKER_TYPES.normal)}>Start</Button>
                    <Button className="text-white bg-base" onClick={() => setIMarkerType(IMARKER_TYPES.endUpdate)}>End</Button>
                </Col>
            </Row>
        <Form.Item name='traffic_type' label='Loại bản tin' rules={[{
            required: true,
            validateTrigger: 'onFinish',
            message: 'Vui lòng chọn loại bản tin',
            type: 'array',
            transform: (value) => {
                if (typeof (value) === 'string') {
                    return [value];
                }
                return value;
            }
        },
        ]}>
            <Select
                allowClear
                options={Object.values(TrafficType).map((value) => ({
                    label: value.name,
                    value: value.name,
                    content: value.content
                }))}
                onChange={(value: string, option: any) => {
                    if (value !== TrafficType.other.name) {
                        console.log('value', option)
                        formUpdateNews.setFieldsValue({ content: option.content })
                        setSpeechUpdatehUrl('')
                    }
                    else {
                        formUpdateNews.resetFields(['content'])
                    }
                    const audioLink = findAudioLink(value, fixedSound);
                    audioLink && setSpeechUpdatehUrl(audioLink)
                }}
            />
        </Form.Item>
        {IWatchTrafficType !== TrafficType.other.name ? (<Form.Item name='content' label='Nội dung bản tin' rules={[{
            required: true,
            validateTrigger: 'onFinish',
            message: 'Nhập nội dung bản tin'
        },
        ]}>
            <TextArea allowClear placeholder='Nội dung bản tin'
                disabled
            />
        </Form.Item>) :
            <Row className='-mb-4' gutter={2} align={'middle'} >
                <Col span={20} >
                    <Form.Item name='content' label='Nội dung bản tin' rules={[{
                        required: true,
                        validateTrigger: 'onFinish',
                        message: 'Vui lòng nhập nội dung bản tin'
                    },
                    ]}>
                        <TextArea allowClear placeholder='Nội dung bản tin'
                        />
                    </Form.Item>
                </Col>
                <Col className='-mb-2' span={2}>
                    <Button
                        loading={loadings}
                        onClick={() => {
                            setLoadings(true)
                            contentToSpeech({ input: formUpdateNews.getFieldValue('content') }).then(res => {
                                console.log('res', res);
                                setSpeechUpdatehUrl(res.data);
                                setLoadings(false)
                            })
                        }}
                        className='bg-base text-white'>Render</Button>
                </Col>
            </Row>}
        {speechUpdateUrl.length > 0 && <audio src={`${STORAGE_URL}${speechUpdateUrl}`} controls></audio>}
        <Form.Item hidden name='ai_road_id' label='AI ROAD ID' >
            <Input allowClear placeholder='AI ROAD ID' />
        </Form.Item>

        <Form.Item name='osm_combination_id' label='osm combination id' rules={[{
            required: true,
            validateTrigger: 'onFinish',
            message: 'Vui lòng chọn một điểm nằm trên tên đường để hiện osm combination id'
        },
        ]}>
            <Input allowClear placeholder='OSM Combination id' />
        </Form.Item>
        {/* <Row gutter={4}>
            <Col span={12}> */}
                <Form.Item name='name' label='Tên đường' >
                    <Input placeholder='Tên đường' allowClear />
                </Form.Item>
            {/* </Col>
            <Col span={12}> */}
                <Form.Item name='sta_cty' hidden label='Thành phố' 
                // rules={[{
                //     required: true,
                //     validateTrigger: 'onFinish',
                //     message: <>
                //         Hãy chọn 1 điểm nằm<br />
                //         trên đường.
                //     </>
                // },
                // ]} 
                >
                    <Input allowClear placeholder='Tên thành phố' />
                </Form.Item>
            {/* </Col>
        </Row> */}
        <Form.Item name='forever' valuePropName="checked" >
            <Checkbox>
                Tin vĩnh viễn
            </Checkbox>
        </Form.Item>
        {!IWatchForever && (<Form.Item name='expire_time' label='Thời gian hết hạn'
            rules={[{
                required: true,
                validateTrigger: 'onFinish',
                message: 'Vui lòng chọn thời gian hết hạn',
                type: 'object',
            },
            ]} >
            <DatePicker className='flex'
                placeholder='Thời gian hết hạn'
                format={'HH:mm DD/MM/YYYY'}
                disabledDate={(current) => {
                    let customDate = moment().format("YYYY-MM-DD");
                    return current && current < moment(customDate, "YYYY-MM-DD");
                }}

                showTime></DatePicker>
        </Form.Item>)}
        <Form.Item name='image' label='Hình ảnh'>
            {listImgUpdateTrafic.length > 0 ? <Upload
                accept='.png, .jpg, .jpeg'
                listType="picture-card"
                fileList={listImgUpdateTrafic}
                onChange={handleChange}
                onPreview={handlePreview}
                action={URL_UPLOAD_IMAGE}
                // onChange={(info) => {
                // }}
                multiple={true} >
                <p className="ant-upload-drag-icon">
                    <PlusOutlined size={32} onKeyDown={undefined} />
                </p>
            </Upload> : <Upload
                accept='.png, .jpg, .jpeg'
                listType="picture-card"
                onChange={(info) => {
                    if (info.file.status !== 'uploading') {
                        console.log(info.file, info.fileList);
                    }
                    if (info.file.status === 'done') {
                        message.success(`${info.file.name} file uploaded successfully`);
                    } else if (info.file.status === 'error') {
                        message.error(`${info.file.name} file upload failed.`);
                    }
                }}
                onPreview={handlePreview}
                action={URL_UPLOAD_IMAGE}
                multiple={true} >
                <p className="ant-upload-drag-icon">
                    <PlusOutlined size={32} onKeyDown={undefined} />
                </p>
            </Upload>}

        </Form.Item>
        <Form.Item>
            <Button className='bg-base' type='primary' htmlType='submit'  >
                Hoàn thành
            </Button>
        </Form.Item>
    </Form>
    )
}