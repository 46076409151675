import { Button, FormInstance } from 'antd';
import React from 'react';
import { Popup } from 'react-map-gl';
import { IResponseDataPointV2, MarkerType } from '../../../constant/type';
import { IMARKER_TYPES } from '../../../constant';

type PopupComponentProps = {
    // Define your component props here
    setPopupOpen: React.Dispatch<any>,
    popupOpen: any,
    optionPointV2: ({
        value: string;
        label: number;
    } | {
        value: string;
        label: string;
    })[],
    activeKey: string,
    onGetNomination: (osm_id: string) => Promise<any>,
    setRelationLine: React.Dispatch<any>,
    filterDataByRelation(relations: string[], responseData: IResponseDataPointV2[]): {
        relation: string[];
        compass: string;
        lat: number;
        lon: number;
        osm_id: string;
        direction: number;
        traffic_sign_id: number;
        id: number;
        ai_road_id: string;
    }[],
    responseDataPoint: any,
    setActiveKey: React.Dispatch<React.SetStateAction<string>>,
    formUpdate: FormInstance<any>,
    setIMarkerType: React.Dispatch<React.SetStateAction<string>>,
    setMarkersEnd: React.Dispatch<React.SetStateAction<MarkerType | undefined>>,
    getPointB: (pointA: MarkerType, angle: number) => MarkerType,
    handleDeleteMapPoint: (id: any[]) => void
    


};

const PopupComponent: React.FC<PopupComponentProps> = ({
    setPopupOpen,
    popupOpen,
    optionPointV2,
    activeKey,
    onGetNomination,
    setRelationLine,
    filterDataByRelation,
    responseDataPoint,
    setActiveKey,
    formUpdate,
    setIMarkerType,
    setMarkersEnd,
    getPointB,
    handleDeleteMapPoint
}) => {
    return (
        <Popup longitude={Number(popupOpen.lon)} latitude={Number(popupOpen.lat)}
        anchor="top"
        onClose={() => setPopupOpen({ ...popupOpen, display: false })}
        style={{ padding: 0 }}
    >
        <p>OSM ID: {popupOpen.osm_id}</p>
        <p>AI ROAD ID: {popupOpen.ai_road_id}</p>
        <p>Traffic Sign ID: {popupOpen.traffic_sign_id}</p>
        <p>Biển báo: {optionPointV2.find((option) => option.value == popupOpen.traffic_sign_id)?.label}</p>
        <p>Compass: {popupOpen.compass}</p>
        <p>Direction: {popupOpen.direction}</p>
        {activeKey == '1' && popupOpen.relation && <p>Relation: {popupOpen.relation.toString()}</p>}
        <p>Lat: {popupOpen.lat.toString()}</p>
        <p>Lon: {popupOpen.lon.toString()}</p>
        <div style={{ flex: 1, flexDirection: 'row' }}>
            {activeKey == '1' && popupOpen.relation && <Button
                style={{ marginRight: 2 }}
                onClick={async () => {
                    if (popupOpen.relation.length == 0) {
                        const res = await onGetNomination(popupOpen.osm_id)
                        const singleRelationLine =
                        {
                            "type": "FeatureCollection",
                            "features": [
                                {
                                    type: 'Feature',
                                    properties: { color: 'blue' },
                                    geometry: res
                                },
                            ]
                        }
                        setRelationLine(singleRelationLine)
                    } else {
                        const filterData = filterDataByRelation(popupOpen.relation, responseDataPoint)
                        console.log('filterData', filterData);

                        const multipleRelationLine: {
                            type: string;
                            features: {
                                type: string;
                                properties: {
                                    color: string;
                                };
                                geometry: any;
                            }[];
                        } = {
                            type: "FeatureCollection",
                            features: [],
                        };
                        Promise.all(
                            filterData.map(async (item) => {
                                const res = await onGetNomination(item.osm_id);
                                const itemRelationLine = {
                                    type: 'Feature',
                                    properties: { color: 'blue' },
                                    geometry: res
                                };

                                multipleRelationLine.features.push(itemRelationLine);
                            })
                        ).then(() => {
                            console.log('multipleRelationLine', multipleRelationLine);
                            if (multipleRelationLine.features.length > 0) {
                                setRelationLine(multipleRelationLine);
                            }
                        });
                        console.log('multipleRelationLine', multipleRelationLine);
                    }
                }}>
                Relation
            </Button>}
            <Button onClick={() => {
                console.log('popupOpen', popupOpen.direction, popupOpen.id);
                setActiveKey('4');
                formUpdate.setFieldsValue({
                    id: popupOpen.id,
                    ELocation: `${popupOpen.lat},${popupOpen.lon}`,
                    direction: popupOpen.direction,
                    compass: popupOpen.compass,
                    traffic_sign_id: optionPointV2.find((option) => option.value == popupOpen.traffic_sign_id)?.value,
                    ai_road_id: popupOpen.ai_road_id,
                    osm_id: popupOpen.osm_id,
                });
                setPopupOpen({ ...popupOpen, display: false });
                setIMarkerType(IMARKER_TYPES.endUpdate);
                setMarkersEnd(getPointB({ lat: popupOpen.lat, lon: popupOpen.lon }, Number(popupOpen.direction)))
            }} style={{ marginRight: 2 }}
                type="primary">Edit</Button>
            <Button onClick={() => {
                popupOpen.id && handleDeleteMapPoint([popupOpen.id])
            }
            } type="primary">Delete</Button>
        </div>
    </Popup>
    );
};

export default PopupComponent;