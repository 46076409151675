import { MapLayerMouseEvent, Layer, Map, MapboxStyle, Marker, Source } from "react-map-gl";
import { CMARKER_TYPES, MAPBOX_TOKEN, MAP_STYLE, initialViewState, layerCongestionStyle, layerCreateStyle, layerGridStyle } from "../../constant";
import { Button, Form, Input, Tabs, TabsProps, message, Col, Row } from "antd";
import { useCallback, useEffect, useState } from "react";
import { CMarkerType } from "../../constant/type";
import { useForm } from "antd/es/form/Form";
import { vietnamCity } from './city';
import { searchRoute } from "../../services";
import { decode } from "@googlemaps/polyline-codec";
import { createCellGrid, createMultiPolygonFromLineString, deleteFeatureByIndex, generateCellsWithinPolygon, getBoundingBoxCoordinates } from "./helper";

export default function GridSystem(props: any) {
  const [regionGrid, setRegionGrid] = useState<any>();
  const [lineForGrid, setLineForGrid] = useState<any>();
  const [roadGrid, setRoadGrid] = useState<any>();
  const [markerType, setMarkerType] = useState<string>(CMARKER_TYPES.start)
  const [markersStart, setMarkersStart] = useState<CMarkerType>();
  const [markersEnd, setMarkersEnd] = useState<CMarkerType>();
  // const [hoverInfo, setHoverInfo] = useState<any>();
  const [activeKey, setActiveKey] = useState('1');
  const [formRegionGrid] = useForm();
  const [formRoadGrid] = useForm();

  // const onHover = useCallback((event: MapLayerMouseEvent) => {
  //   const {
  //     features,
  //     point: { x, y }
  //   } = event;
  //   const hoveredFeature = features && features[0];
  //   if (hoveredFeature) {
  //     // setHoverInfo(hoveredFeature && { feature: hoveredFeature, x, y });
  //   }
  // }, []);

  useEffect(() => {
    if (activeKey == '1') {
      const [minX, minY, maxX, maxY] = getBoundingBoxCoordinates();
      console.log(`Top Left: (${minX}, ${maxY})`);
      console.log(`Bottom Right: (${maxX}, ${minY})`);
      console.log('vietnamCity', vietnamCity);
      formRegionGrid.setFieldsValue({
        cellHeight: 100,
        cellWidth: 100,
        srcLocation: '8.593750898262343,102.07004784063747',
        dstLocation: '23.69991947166514,108.79198055184702',
      })
      setMarkersStart({ lat: 9.02770389693481, lon: 103.02809794338668 })
      setMarkersEnd({ lat: 23.69991947166514, lon: 108.79198055184702 })
      // setRegionGrid(vietnamBoundariesData)
    }

  }, [activeKey]);

  useEffect(() => {
    if (markersStart && markersEnd && activeKey == '2') {
      const data = {
        srcLat: markersStart.lat,
        srcLon: markersStart.lon,
        dstLat: markersEnd.lat,
        dstLon: markersEnd.lon,
        bearings: -1,
      }
      searchRoute(data).then((data) => {
        message.success('Tìm thành công');
        const result = data.data.routes[0];
        const coordinates: any = [];
        data.data.routes.forEach((route: any) => {
          const { legs } = route;
          legs.forEach((leg: any) => {
            const { steps } = leg;
            const legCoordinates = steps.flatMap((step: any) => {
              const { geometry } = step;
              return decode(geometry);
            });
            console.log('legCoordinates', legCoordinates);
            coordinates.push(...legCoordinates);
          });
        });
        const validCoordinates = coordinates.map((item: any) => [item[1], item[0]])
        const validMultiPolygon = createMultiPolygonFromLineString(validCoordinates,  formRoadGrid.getFieldValue('cellWidth')||1, formRoadGrid.getFieldValue('cellHeight') || 1);
        console.log('validMultiPolygon', validMultiPolygon);
        setRoadGrid(validMultiPolygon);
        setLineForGrid({
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {},
              geometry: {
                type: 'LineString',
                coordinates: validCoordinates,
              }
            },
          ],
        });
      });
    }
  }, [markersStart, markersEnd]);

  const exportData = () => {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      activeKey == '1' ? JSON.stringify(regionGrid) : JSON.stringify(roadGrid)
    )}`;
    const link = document.createElement("a");
    link.href = jsonString;
    link.download = "data.json";
    link.click();
  };
  const [hoverInfo, setHoverInfo] = useState<any>();

  const itemsTab: TabsProps['items'] = [
    {
      key: '1',
      label: `Grid `, children:
        <Form
          form={formRegionGrid}
          onFinish={(data) => {
            const [startLat, startLon] = data.srcLocation.split(',').map(parseFloat);
            const [endLat, endLon] = data.dstLocation.split(',').map(parseFloat);
            setRegionGrid(createCellGrid(
              Number(data.cellWidth),
              Number(data.cellHeight),
              Number(startLat),
              Number(startLon),
              Number(endLat),
              Number(endLon))
            )
          }}
          layout='vertical'
          labelAlign='left'
          labelCol={{ span: 16 }}
        >
          <Row gutter={2}>
            <Col span={17}>
              <Form.Item required name='srcLocation' >
                <Input
                  // onKeyUp={handleKeyUpStart} 
                  allowClear placeholder='Toạ độ start(vĩ độ-lat,kinh độ-lon)' />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Button className="bg-base text-white w-24"
                onClick={() => setMarkerType(CMARKER_TYPES.start)}>Chọn Start</Button>
            </Col>
          </Row>
          <Row gutter={2}>
            <Col span={17}>
              <Form.Item required name='dstLocation'>
                <Input
                  //  onKeyUp={handleKeyUpEnd} 
                  allowClear placeholder='Toạ độ end(vĩ độ-lat,kinh độ-lon)' />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Button className="bg-base text-white w-24" onClick={() => setMarkerType(CMARKER_TYPES.end)}>Chọn End</Button>
            </Col>
          </Row>
          <Form.Item label='Chiều dài ô lưới (km)' name='cellHeight'>
            <Input allowClear />
          </Form.Item>
          <Form.Item label='Chiều rộng ô lưới (km)' name='cellWidth'>
            <Input allowClear />
          </Form.Item>
          <Form.Item label='Chiều dài ô lưới nhỏ (km) ' name='smallCellHeight'>
            <Input allowClear />
          </Form.Item>
          <Form.Item label='Chiều rộng ô lưới nhỏ (km)' name='smallCellWidth'>
            <Input allowClear />
          </Form.Item>
          <Button htmlType="submit" className="text-white bg-base">Render Region Grid</Button>
          {regionGrid && <Button className="text-white bg-base"  onClick={exportData}>Export</Button>}
        </Form>
    }, {
      key: '2',
      label: `Grid Road`, children: <div>
        <Form
          form={formRoadGrid}
          layout='vertical'
          labelAlign='left'
          labelCol={{ span: 16 }}
        >
          <Row gutter={2}>
            <Col span={17}>
              <Form.Item required name='srcLocation' >
                <Input
                  // onKeyUp={handleKeyUpStart} 
                  allowClear placeholder='Toạ độ start(vĩ độ-lat,kinh độ-lon)' />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Button className="bg-base text-white w-24"
                onClick={() => setMarkerType(CMARKER_TYPES.start)}>Chọn Start</Button>
            </Col>
          </Row>
          <Row gutter={2}>
            <Col span={17}>
              <Form.Item required name='dstLocation'>
                <Input
                  //  onKeyUp={handleKeyUpEnd} 
                  allowClear placeholder='Toạ độ end(vĩ độ-lat,kinh độ-lon)' />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Button className="bg-base text-white w-24" onClick={() => setMarkerType(CMARKER_TYPES.end)}>Chọn End</Button>
            </Col>
          </Row>
          <Form.Item label='Chiều dài ô lưới (km)' name='cellHeight'>
            <Input allowClear />
          </Form.Item>
          <Form.Item label='Chiều rộng ô lưới (km)' name='cellWidth'>
            <Input allowClear />
          </Form.Item>
          {roadGrid && <Button className="text-white bg-base"  onClick={exportData}>Export Road Grid</Button>}
        </Form>

      </div>
    }]
    const onHover = useCallback((event: MapLayerMouseEvent) => {
      const {
          features,
          point: { x, y }
      } = event;
      const hoveredFeature = features && features[0];
      if (hoveredFeature) {
          setHoverInfo(hoveredFeature && { feature: hoveredFeature, x, y });
      }
  }, []);
  const onChangeTab = (key: string) => {
    if (key == '1') {
      setLineForGrid(undefined);
      setRoadGrid(undefined);
      setRegionGrid(undefined);
      formRoadGrid.resetFields();

    }
    if (key == '2') {
      // setIMarkerType(CMARKER_TYPES.other);
      // formUpdateGpx.resetFields();
      formRegionGrid.resetFields();
      setLineForGrid(undefined);
      setRoadGrid(undefined);
      setRegionGrid(undefined);
      setMarkersStart(undefined);
      setMarkersEnd(undefined);
      formRoadGrid.setFieldsValue({cellWidth: 1, cellHeight: 1})
    }
    setActiveKey(key);
  };  
  return (
    <div className='h-screen flex flex-row items-stretch' >
      <div className='w-[22rem] p-2 overflow-auto'>
        <Tabs className='overflow-auto' defaultActiveKey="1" activeKey={activeKey} items={itemsTab} onChange={onChangeTab} />
      </div>
      <Map
        style={{ flex: 4 }}
        initialViewState={initialViewState}
        mapStyle={MAP_STYLE as MapboxStyle}
        mapboxAccessToken={MAPBOX_TOKEN}
        interactiveLayerIds={['grid']}
        maxZoom={18}
        onMouseMove={onHover}
        onClick={(event) => {
          const newMarker: CMarkerType = {
            lat: event.lngLat.lat,
            lon: event.lngLat.lng
          };
          const { features } = event;
          const hoveredFeature = features && features[0];
          if (hoveredFeature?.properties) {
            const { index } = hoveredFeature.properties;
            setRegionGrid(deleteFeatureByIndex(regionGrid, index, formRegionGrid.getFieldValue('smallCellWidth'), formRegionGrid.getFieldValue('smallCellHeight')));
          }
          else if (markerType == CMARKER_TYPES.start) {
            setMarkersStart({ ...newMarker, type: CMARKER_TYPES.start });
            if (activeKey == '2') {
              formRoadGrid.setFieldsValue({
                srcLocation: `${event.lngLat.lat},${event.lngLat.lng}`
              });
             }
             else {
              formRegionGrid.setFieldsValue({
                srcLocation: `${event.lngLat.lat},${event.lngLat.lng}`
              });
             }
           
          }
          else if (markerType == CMARKER_TYPES.end) {
            setMarkersEnd({ ...newMarker, type: CMARKER_TYPES.end });
            if (activeKey == '2') {
              formRoadGrid.setFieldsValue({
                dstLocation: `${event.lngLat.lat},${event.lngLat.lng}`
              })
            } else {
              formRegionGrid.setFieldsValue({
                dstLocation: `${event.lngLat.lat},${event.lngLat.lng}`
              });
            }
          
          }
        }}
      >
        {regionGrid && <Source id="grid" type="geojson" data={regionGrid}>
          <Layer {...layerGridStyle} >
          </Layer>
        </Source>}
        {lineForGrid && <Source id="line-create" type="geojson" data={lineForGrid}>
          <Layer {...layerCreateStyle} />
        </Source>}
        {roadGrid && <Source id="grid-by-line" type="geojson" data={roadGrid}>
          <Layer {...layerGridStyle} />
        </Source>}
        {/* <Source id="my-data" type="geojson" data={vietnamCity}>
          <Layer {...layerCongestionStyle} />
        </Source> */}
        {markersStart && (<Marker
          key={`marker-start`}
          latitude={markersStart?.lat}
          longitude={markersStart?.lon}
          style={{ color: 'red' }}
          draggable={true}
          onDragEnd={(event) => {
            setMarkersStart({ ...markersStart, lat: event.lngLat.lat, lon: event.lngLat.lng });
            if (activeKey == '2') {
              formRoadGrid.setFieldsValue({
                srcLocation: `${event.lngLat.lat},${event.lngLat.lng}`
              });
            } else formRegionGrid.setFieldsValue({
              srcLocation: `${event.lngLat.lat},${event.lngLat.lng}`
            });
          }}
        >
          <div >
            <img src='./pin-start.png'>
            </img>
          </div>
        </Marker>)}
        {markersEnd && (<Marker
          key={`marker-end`}
          latitude={markersEnd?.lat}
          longitude={markersEnd?.lon}
          onDragEnd={(event) => {
            setMarkersEnd({ ...markersEnd, lat: event.lngLat.lat, lon: event.lngLat.lng })
            if (activeKey=='2') {
              formRoadGrid.setFieldsValue({
                dstLocation: `${event.lngLat.lat},${event.lngLat.lng}`
              })
            } else 
            formRegionGrid.setFieldsValue({
              dstLocation: `${event.lngLat.lat},${event.lngLat.lng}`
            });
          }}
          draggable={true}
        >
          <div >
            <img src='./pin-end.png'>
            </img>
          </div>
        </Marker>)}
        {hoverInfo && (
                    <div className="tooltip" style={{
                        left: hoverInfo.x, top: hoverInfo.y, position: 'absolute',
                        margin: '8px',
                        padding: '4px',
                        background: 'rgba(0, 0, 0, 0.8)',
                        color: '#fff',
                        maxWidth: '300px',
                        fontSize: '10px',
                        zIndex: '9',
                        pointerEvents: 'none'
                    }}>
                        <div>Id: {hoverInfo.feature.properties.index}</div>
                        {/* <div>Expire time: {moment.unix(hoverInfo.feature.properties.expire_time).format('HH:mm:ss DD/MM/YYYY')}</div> */}
                    </div>
                )}
      </Map>
    </div>
  )
}


// {hoverInfo && 
//   (
//     <div className="tooltip" style={{
//       left: hoverInfo.x, top: hoverInfo.y, position: 'absolute',
//       margin: '8px',
//       padding: '4px',
//       background: 'rgba(0, 0, 0, 0.8)',
//       color: '#fff',
//       maxWidth: '300px',
//       fontSize: '10px',
//       zIndex: '9',
//       pointerEvents: 'none'
//     }}>
//       <div>Id: {hoverInfo.feature.properties.index}</div>
//       {/* <div>Expire time: {moment.unix(hoverInfo.feature.properties.expire_time).format('HH:mm:ss DD/MM/YYYY')}</div> */}
//     </div>
//   )
//   }