import { Button, Col, Form, FormInstance, Input, Row, Select, Typography } from "antd";
import { FC } from "react"
import { MarkerType } from "../../../constant/type";
import { IMARKER_TYPES } from "../../../constant";
import { getDirectionFromAngle } from "../../../helper/LocationHelper";
const { Text } = Typography;

interface PProps {
    handleUpdatePoint: (data: any) => void,
    formUpdate: FormInstance<any>,
    setIMarkerType: (value: React.SetStateAction<string>) => void,
    optionVectorRoadId: any,
    optionPointV2: ({
        value: string;
        label: number;
    } | {
        value: string;
        label: string;
    })[],
    setMarkersEnd: React.Dispatch<React.SetStateAction<MarkerType | undefined>>
}

export const UpdatePointV2: FC<PProps> = ({
    handleUpdatePoint,
    formUpdate,
    setIMarkerType,
    optionVectorRoadId,
    optionPointV2,
    setMarkersEnd

}) => {

    return <div >
        <Form onFinish={handleUpdatePoint} form={formUpdate}>
            <Form.Item name='id' label='id' hidden />
            <Form.Item name='osm_id' label='osm_id'>
                <Input allowClear placeholder='osm_id' disabled />
            </Form.Item>
            <Row gutter={4}>
                {/* <Col span={20}> */}
                <Form.Item
                    name='ELocation'
                    rules={[{ required: true, message: 'Thiếu toạ độ' }]}
                    style={{ width: '100%', paddingLeft: 2, paddingRight: 2 }}
                >
                    <Input
                        allowClear
                        placeholder='Nhập toạ độ (vĩ độ - lat, kinh độ - lon)' />
                </Form.Item>
                {/* </Col> */}
                {/* <Col span={4}>
                <Button
                    className="bg-base text-white"
                    onClick={() => setIMarkerType(IMARKER_TYPES.start)}>Chọn</Button></Col> */}
            </Row>
            <Row
                gutter={4}>
                <Col
                    span={10}>
                    <Form.Item
                        name='direction'
                        rules={[
                            {
                                required: true,
                                validateTrigger: 'onFinish',
                                message: 'Vui lòng nhập góc',
                                type: 'number',
                                transform: (value) => Number(value),
                            },
                        ]}>
                        <Input
                            onChange={(e) => formUpdate.setFieldValue('compass', getDirectionFromAngle(Number(e.target.value)))}
                            placeholder='Góc'
                            allowClear />
                    </Form.Item>
                </Col>
                <Col span={10}>
                    <Form.Item rules={[{
                        required: true,
                        validateTrigger: 'onFinish',
                        message: 'Vui lòng nhập góc để tính hướng',
                    },
                    ]} name='compass' >
                        <Input
                            disabled
                            placeholder='Hướng'
                            allowClear />
                    </Form.Item>
                </Col>
                <Col span={2}>
                    <Button
                        className="bg-base text-white ml-2"
                        onClick={() => setIMarkerType(IMARKER_TYPES.endUpdate)}>End</Button>
                </Col>
            </Row>
            <Form.Item
                name='ai_road_id'
                label='AI ROAD ID' >
                {/* <Input allowClear placeholder='AI ROAD ID' /> */}
                <Select>
                    {optionVectorRoadId.map((item: any) =>
                        <Select.Option key={item.value + Math.random()}
                            value={item.value}>{item.label}
                        </Select.Option>)}
                </Select>
            </Form.Item>
            <Form.Item
                name='traffic_sign_id'
                label='Traffic Sign Id' >
                <Select onChange={() => console.log('change')}>
                    {optionPointV2.map((item: any) => <Select.Option key={item.value + Math.random()} value={item.value}>{item.label}</Select.Option>)}


                </Select>

            </Form.Item>
            <div className="flex flex-row justify-end	">
                <Button htmlType='submit' className="bg-base text-white mr-2">Cập nhật</Button>
                <Button onClick={() => {
                    formUpdate.resetFields();
                    setMarkersEnd(undefined);
                }}
                    className="bg-warning text-white">Huỷ bỏ</Button>
            </div>
        </Form>

    </div>
}