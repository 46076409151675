import { Layer, Map, MapboxStyle, Source, MapRef } from "react-map-gl";
import { MAPBOX_TOKEN, MAP_STYLE, initialViewState, layerStyle } from "../../constant";
import { Select } from "antd";
import { useEffect, useMemo, useRef, useState } from "react";
import { getDataOsrm } from "../../services";
import { decode } from "@googlemaps/polyline-codec";
import { optionStaCty } from "./option";

export default function OsrmData(props: any) {
    const mapRef = useRef<MapRef>(null);
    const [lineCreate1, setLineCreate1] = useState<any>();
    const [lineCreate2, setLineCreate2] = useState<any>();
    const [lineCreate3, setLineCreate3] = useState<any>();
    const onGetOsrmData = (sta_cty: string) => {
        getDataOsrm({ sta_cty }).then((res) => {
            const data = res.data;
            const totalLength = data.length;
            console.log('totalLength', totalLength);
            const partSize = Math.ceil(totalLength / 3);
            const part1 = data.slice(0, partSize);
            const part2 = data.slice(partSize, partSize * 2);
            const part3 = data.slice(partSize * 2);

            const features1 = part1.map((geometry: any) => ({
                type: 'Feature',
                geometry: {
                    type: 'LineString',
                    coordinates: geometry && decode(geometry.geometry).map((item: any) => [item[1], item[0]]),
                },
            }));

            const features2 = part2.map((geometry: any) => ({
                type: 'Feature',
                geometry: {
                    type: 'LineString',
                    coordinates: geometry && decode(geometry.geometry).map((item: any) => [item[1], item[0]]),
                },
            }));

            const features3 = part3.map((geometry: any) => ({
                type: 'Feature',
                geometry: {
                    type: 'LineString',
                    coordinates: geometry && decode(geometry.geometry).map((item: any) => [item[1], item[0]]),
                },
            }));
            mapRef.current?.flyTo({ center: [features3[0].geometry.coordinates[0][0], features3[0].geometry.coordinates[0][1]], animate: false });

            const finalFeature1 = {
                type: 'FeatureCollection',
                features: features1,
            };

            const finalFeature2 = {
                type: 'FeatureCollection',
                features: features2,
            };

            const finalFeature3 = {
                type: 'FeatureCollection',
                features: features3,
            };

            setLineCreate1(finalFeature1);
            setLineCreate2(finalFeature2);
            setLineCreate3(finalFeature3);
        });
    };


    useEffect(() => {
        onGetOsrmData(optionStaCty[1].value);
    }, [])

    const renderMap = useMemo(() => {
        return <Map
            style={{ flex: 4 }}
            initialViewState={initialViewState}
            mapStyle={MAP_STYLE as MapboxStyle}
            ref={mapRef as React.Ref<MapRef>}
            mapboxAccessToken={MAPBOX_TOKEN}
            maxZoom={18}
            minZoom={3}
        >
            {lineCreate1 && <Source tolerance={0} type="geojson" data={lineCreate1}>
                <Layer {...layerStyle} >
                </Layer>
            </Source>}
            {lineCreate2 && <Source tolerance={0} type="geojson" data={lineCreate2}>
                <Layer {...layerStyle} >
                </Layer>
            </Source>}
            {lineCreate3 && <Source tolerance={0} type="geojson" data={lineCreate3}>
                <Layer {...layerStyle} >
                </Layer>
            </Source>}
        </Map>
    }, [lineCreate1, lineCreate2, lineCreate3]);
    return (
        <div className='h-screen flex flex-row items-stretch' >
            <div className="p-2 overflow-auto" style={{ width: 240 }}>
                <h3 className="text-lg font-bold mb-2">OSRM Data</h3>
                <Select style={{ width: 200 }} options={optionStaCty} defaultValue={optionStaCty[1].value}
                    onChange={(item) => onGetOsrmData(item)
                    } />
            </div>
            {renderMap}
        </div>
    )
}