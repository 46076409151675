import moment from "moment";
import { AudioData, CongestionData, LineCongestionPayload, OutputCongestionForm, TrafficData } from "../../constant/type";
import { listRealtimeTraffic } from "../../services";
import { decode, encode } from '@googlemaps/polyline-codec';
import { APP_COLOR, I_TYPES } from "../../constant";
import { RcFile, UploadFile } from "antd/es/upload";

export const extractUrls = (image: { file: Record<string, unknown>; fileList: { response: string[] }[] }) => {
    const urls: string[] = [];
    image.fileList.forEach(item => {
        urls.push(...item.response);
    });
    return urls;
}
export const extractUpdateUrls = (image: { fileList: { response: string[], original: string }[] }) => {
    const urls: string[] = [];
    image.fileList.forEach(item => {
        if (item.response) {
            urls.push(...item.response);
        } else urls.push(item.original);
    });
    return urls;
}

export const extractListImgUpdate = (listImgUpdateTrafic: any) => {
    const output = [];

    for (const item of listImgUpdateTrafic) {
        if (item.original) {
            output.push(item.original);
        } else if (item.response) {
            output.push(...item.response);
        }
    }
    return output;
}

export const onGetTrafficRealtime = ({ setLine, setPoints }: {
    setLine: (value: any) => void,
    setPoints: (value: any) => void
}) => {
    listRealtimeTraffic({
        id: undefined,
        type: undefined,
    }).then((res) => {
        const data = res.data;
        // decode(item.geometry!).map((coord: any) => [coord[1], coord[0]]);
        // return {
        //     type: 'Feature',
        //     properties: {
        //         id: item.id,
        //         content: item.content,
        //         expire_time: item.expire_time,
        //         geometry: item.geometry,
        //         audio_link: item.audio_link,
        //         image: item.image,
        //         forever: item.forever,
        //     },
        //     geometry: {
        //         type: 'LineString',
        //         coordinates,
        //     },
        // };
        let coordinates1: any[][][] = []
        let coordinates2: any[][][] = []
        let coordinates3: any[][][] = []
        data.traffic_congestion
            .map((item: CongestionData) => {


                if (item.congestion_level === 1) {
                    item.geometry.flat().forEach((item: any) => {
                        coordinates1.push(decode(item).map((coord: any) => [coord[1], coord[0]]))
                    })
                }
                if (item.congestion_level === 2) {
                    item.geometry.flat().forEach((item: any) => {
                        coordinates2.push(decode(item).map((coord: any) => [coord[1], coord[0]]))
                    })
                }
                if (item.congestion_level === 3) {
                    item.geometry.flat().forEach((item: any) => {
                        coordinates3.push(decode(item).map((coord: any) => [coord[1], coord[0]]))
                    })
                }

            })
            .filter((item) => item !== undefined);

        const pointsArray = data.traffic_bulletin.
            map((item: TrafficData) => {
                if (item.expire_time < moment().unix() && !item.forever) {
                    return undefined;
                }
                if (item.type === I_TYPES.trafficNews) {
                    return {
                        ...item
                    };
                }
            })
            .filter((item) => item !== undefined);
        const lineString = {
            type: 'FeatureCollection',
            features: [
                {
                    'type': 'Feature',
                    'properties': {
                        'color': '#F19C5C' 
                    },
                    'geometry': {
                        "type": "MultiLineString",
                        "coordinates": coordinates1
                    }
                },
                {
                    'type': 'Feature',
                    'properties': {
                        'color': '#DF4D3E' 
                    },
                    'geometry': {
                        "type": "MultiLineString",
                        "coordinates": coordinates2
                    }
                },
                {
                    'type': 'Feature',
                    'properties': {
                        'color': '#772723' 
                    },
                    'geometry': {
                        "type": "MultiLineString",
                        "coordinates": coordinates3
                    }
                },
            ]
        };
        setLine(lineString);
        setPoints(pointsArray);
    });
}
export const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });

export function findAudioLink(input: string, fixedSound: AudioData[]) {
    const foundItem = fixedSound.find(item => item.type.toLowerCase() === input.toLowerCase().replace(/\s+/g, '-'));
    if (foundItem) {
        return foundItem.audio_link;
    } else {
        return null; // Return null or handle the case when input is not found
    }
}

export function isLink(input: string) {
    const linkPattern = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i;
    return linkPattern.test(input);
}

export function convertInputToOutput(input: OutputCongestionForm): LineCongestionPayload {
    const output: LineCongestionPayload = {
        congestion_geometry: {
            level1: input.level1?.map((item) => item.level) || [],
            level2: input.level2?.map((item) => item.level) || [],
            level3: input.level3?.map((item) => item.level) || [],
        },
        type: I_TYPES.lineTraffic,
        traffic_type: "Traffic Jam",
        updated_by: "ADMIN",
        grid_zoom: 17,
        grid_lat_center: parseFloat(input.center.split(", ")[0]),
        grid_lon_center: parseFloat(input.center.split(", ")[1]),
    };

    return output;
}