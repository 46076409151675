import { FC } from 'react';
import { Button, FormInstance, Form, Input, Row, Col } from 'antd';
import { postTrafficNews } from '../../../services';
import { IMARKER_TYPES } from '../../../constant';
import { LevelType, MarkerType, OutputCongestionForm } from '../../../constant/type';
import { FormLevel } from './FormLevel';
import { convertInputToOutput } from '../helper';

interface PProps {
    formCreateCongestion: FormInstance<any>,
    onGetTrafficRealtime({ setLine, setPoints }: {
        setLine: (value: any) => void;
        setPoints: (value: any) => void;
    }): void,
    setPoints: (value: any) => void,
    setLine: (value: any) => void,
    setLineCongestion: (value: any) => void,
    setMarkersStart: (value: React.SetStateAction<MarkerType | undefined>) => void,
    setMarkersEnd: (value: React.SetStateAction<MarkerType | undefined>) => void,
    setLevelInfo: React.Dispatch<React.SetStateAction<LevelType>>,
    levelInfor: LevelType,
    formLine: FormInstance<any>,
    setIsEditLevel: React.Dispatch<React.SetStateAction<boolean>>,
    setIMarkerType: React.Dispatch<React.SetStateAction<string>>,
    setMarkerCenter: React.Dispatch<React.SetStateAction<MarkerType | undefined>>
}

export const CreateLine: FC<PProps> = ({
    formCreateCongestion,
    onGetTrafficRealtime,
    setPoints,
    setLine,
    setLineCongestion,
    setMarkersStart,
    setMarkersEnd,
    setLevelInfo,
    levelInfor,
    formLine,
    setIsEditLevel,
    setIMarkerType,
    setMarkerCenter
}) => {

    const handleFormLineTraffic = (data: OutputCongestionForm) => {
        console.log('finalData', data)
        postTrafficNews(convertInputToOutput(data)).then((res) => {
            if (res.status == 200) {
                setLineCongestion(undefined);
                setMarkersStart(undefined);
                setMarkersEnd(undefined);
                formCreateCongestion.resetFields();
                onGetTrafficRealtime({
                    setLine,
                    setPoints
                });
                setMarkerCenter(undefined);
            }
        })
    }
    const renderFormLevel = (levelName: string) => (
        <>
            <h3>{levelName == 'level1' ? 'Level 1' : levelName == 'level2' ? 'Level 2' : 'Level3'} </h3>
            <FormLevel
                levelName={levelName}
                setLevelInfo={setLevelInfo}
                formCongestion={formCreateCongestion}
                formLine={formLine}
                setIsEditLevel={setIsEditLevel}
                setMarkersStart={setMarkersStart}
                setMarkersEnd={setMarkersEnd}
                setLineCongestion={setLineCongestion}
                setIMarkerType={setIMarkerType}
                levelInfor={levelInfor}
            />
        </>
    );

    return (<Form form={formCreateCongestion} layout='horizontal' onFinish={handleFormLineTraffic}>
        <Row gutter={2}>
            <Col span={18}>
                <Form.Item required name='center'>
                    <Input allowClear placeholder='Toạ độ center(vĩ độ-lat,kinh độ-lon)' />
                </Form.Item>
            </Col>
            <Col span={2}>
                <Button className='bg-base text-white w-24'
                    onClick={() => setIMarkerType(IMARKER_TYPES.center)}
                >Chọn</Button>
            </Col>
        </Row>
        {renderFormLevel('level1')}
        {renderFormLevel('level2')}
        {renderFormLevel('level3')}
        <Form.Item>
            <Button className='bg-base' type='primary' htmlType='submit'>
                Hoàn thành
            </Button>
        </Form.Item>
    </Form>)
}