import { IResponseDataPointV2 } from "../../constant/type";

export function getIndexById(items: any, indexFormFocus: number) {
    for (var i = 0; i < items.length; i++) {
        if (items[i].id === indexFormFocus) {
            return i;
        }
    }
    return -1; // Return -1 if no matching item is found
}

export function getRelation(osmId : any, data: any[]) {


  for (const item of data) {
    for (const osm of item.osm) {
      if (osm.osm_id == osmId) {
        if (osm.relation.length >= 1) {
          return osm.relation[0];
        }
      }
    }
  }

  return 'external_data'; // Return null if no matching osm_id or relation with at least one element
}

export function filterDataByRelation(relations: string[], responseData: IResponseDataPointV2[]) {
  const filteredData = [];

  for (const item of responseData) {
      for (const osmItem of item.osm) {
          if (osmItem.relation.some((relation) => relations.includes(relation))) {
              for (const dataPoint of osmItem.datapoint) {
                  filteredData.push({
                      ...dataPoint,
                      relation: osmItem.relation,
                  });
              }
          }
      }
  }

  return filteredData;
}

export const popUpDefaultValue = {
  lat: 0,
  lon: 0,
  display: false,
  traffic_sign_id: "",
  compass: "",
  osm_id: 0,
  direction: '',
  ai_road_id: '',
  id: 0,

}