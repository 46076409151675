
const base32 = '0123456789bcdefghjkmnpqrstuvwxyz'; // (geohash-specific) Base32 map

/**
     * Returns SW/NE latitude/longitude bounds of specified geohash.
     *
     * @param   {string} geohash - Cell that bounds are required of.
     * @returns {{sw: {lat: number, lon: number}, ne: {lat: number, lon: number}}}
     * @throws  Invalid geohash.
     */
export const bounds_geo =(geohash: string): { sw: { lat: number; lon: number; }; ne: { lat: number; lon: number; }; } => {
    if (geohash.length == 0) throw new Error('Invalid geohash');

    geohash = geohash.toLowerCase();

    let evenBit = true;
    let latMin =  -90, latMax =  90;
    let lonMin = -180, lonMax = 180;

    for (let i=0; i<geohash.length; i++) {
        const chr = geohash.charAt(i);
        const idx = base32.indexOf(chr);
        if (idx == -1) throw new Error('Invalid geohash');

        for (let n=4; n>=0; n--) {
            const bitN = idx >> n & 1;
            if (evenBit) {
                // longitude
                const lonMid = (lonMin+lonMax) / 2;
                if (bitN == 1) {
                    lonMin = lonMid;
                } else {
                    lonMax = lonMid;
                }
            } else {
                // latitude
                const latMid = (latMin+latMax) / 2;
                if (bitN == 1) {
                    latMin = latMid;
                } else {
                    latMax = latMid;
                }
            }
            evenBit = !evenBit;
        }
    }

    const bounds = {
        sw: { lat: latMin, lon: lonMin },
        ne: { lat: latMax, lon: lonMax },
    };

    return bounds;
}

    /**
     * Decode geohash to latitude/longitude (location is approximate centre of geohash cell,
     *     to reasonable precision).
     *
     * @param   {string} geohash - Geohash string to be converted to latitude/longitude.
     * @returns {{lat:number, lon:number}} (Center of) geohashed location.
     * @throws  Invalid geohash.
     *
     * @example
     *     const latlon = Geohash.decode('u120fxw'); // => { lat: 52.205, lon: 0.1188 }
     */
    export const decodeGeoHash = (geohash: string) => {

        const bounds = bounds_geo(geohash); // <-- the hard work
        // now just determine the centre of the cell...

        const latMin = bounds.sw.lat, lonMin = bounds.sw.lon;
        const latMax = bounds.ne.lat, lonMax = bounds.ne.lon;

        // cell centre
        let lat = (latMin + latMax)/2;
        let lon = (lonMin + lonMax)/2;

        // round to close to centre without excessive precision: ⌊2-log10(Δ°)⌋ decimal places
        lat = Number(lat.toFixed(Math.floor(2-Math.log(latMax-latMin)/Math.LN10)) );
        lon = Number(lon.toFixed(Math.floor(2-Math.log(lonMax-lonMin)/Math.LN10)));

        return { lat: Number(lat), lon: Number(lon) };
    }



// const base32 = '0123456789bcdefghjkmnpqrstuvwxyz'; // (geohash-specific) Base32 map

// /**
//      * Returns SW/NE latitude/longitude bounds of specified geohash.
//      *
//      * @param   {string} geohash - Cell that bounds are required of.
//      * @returns {{sw: {lat: number, lon: number}, ne: {lat: number, lon: number}}}
//      * @throws  Invalid geohash.
//      */
// export const bounds_geo = (geohash: string): { sw: { lat: number; lon: number; }; ne: { lat: number; lon: number; }; } => {
//     if (geohash.length == 0) throw new Error('Invalid geohash');

//     geohash = geohash.toLowerCase();

//     let evenBit = true;
//     let latMin = -90, latMax = 90;
//     let lonMin = -180, lonMax = 180;

//     for (let i = 0; i < geohash.length; i++) {
//         const chr = geohash.charAt(i);
//         const idx = base32.indexOf(chr);
//         if (idx == -1) throw new Error('Invalid geohash');

//         for (let n = 4; n >= 0; n--) {
//             const bitN = idx >> n & 1;
//             if (evenBit) {
//                 // longitude
//                 const lonMid = (lonMin + lonMax) / 2;
//                 if (bitN == 1) {
//                     lonMin = lonMid;
//                 } else {
//                     lonMax = lonMid;
//                 }
//             } else {
//                 // latitude
//                 const latMid = (latMin + latMax) / 2;
//                 if (bitN == 1) {
//                     latMin = latMid;
//                 } else {
//                     latMax = latMid;
//                 }
//             }
//             evenBit = !evenBit;
//         }
//     }

//     const bounds = {
//         sw: { lat: latMin, lon: lonMin },
//         ne: { lat: latMax, lon: lonMax },
//     };

//     return bounds;
// }


// function createGeoJSONPolygon(latMin: number, lonMin: number, latMax: number, lonMax: number): any {
//     const polygon = {
//         type: "Polygon",
//         coordinates: [
//             [
//                 [lonMin, latMin],
//                 [lonMin, latMax],
//                 [lonMax, latMax],
//                 [lonMax, latMin],
//                 [lonMin, latMin]
//             ]
//         ]
//     };
//     //    const finalGeometry = {
//     //     "type": "FeatureCollection",
//     //     "features": [
//     //       {
//     //         "type": "Feature",
//     //         "properties": {},
//     //         "geometry": polygon

//     //         }]};
//     //         console.log('finalGeometry', finalGeometry);

//     return polygon;
// }


// /**
//  * Decode geohash to latitude/longitude (location is approximate centre of geohash cell,
//  *     to reasonable precision).
//  *
//  * @param   {string} geohash - Geohash string to be converted to latitude/longitude.
//  * @returns {{lat:number, lon:number}} (Center of) geohashed location.
//  * @throws  Invalid geohash.
//  *
//  * @example
//  *     const latlon = Geohash.decode('u120fxw'); // => { lat: 52.205, lon: 0.1188 }
//  */
// export const decodeGeoHash = (geohash: string): any => {

//     const bounds = bounds_geo(geohash); // <-- the hard work
//     // now just determine the centre of the cell...

//     const latMin = bounds.sw.lat, lonMin = bounds.sw.lon;
//     const latMax = bounds.ne.lat, lonMax = bounds.ne.lon;



//     return createGeoJSONPolygon(latMin, lonMin, latMax, lonMax);
// }

// export const createPolygonNextPrev = (geoHashNext: string, geoHashPrev: string,  timeNext: string, timePrev: string, color: string,): any => {
//     const polygonNext = decodeGeoHash(geoHashNext);
//     const polygonPrev = decodeGeoHash(geoHashPrev);
//     const finalGeometry = {
//         "type": "FeatureCollection",
//         "features": [
//             {
//                 "type": "Feature",
//                 "properties": { color: color, time: timeNext, type: 'next' },
//                 "geometry": polygonNext
//             },
//             {
//                 "type": "Feature",
//                 "properties": { color: color, time: timePrev, type:'prev' },
//                 "geometry": polygonPrev
//             }
//         ]
//     };
//     console.log('finalGeometry', finalGeometry);
    
//     return finalGeometry;
// }