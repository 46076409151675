import React from 'react';
import { Marker } from 'react-map-gl';
import { APP_COLOR } from '../../../constant';
import { FormInstance } from 'antd';

type MarkerCustomProps = {
    activeKey: string,
    multipleSelect: any,
    index: any,
    point: any,
    setMultipleSelect: React.Dispatch<any>,
    setPopupOpen: React.Dispatch<any>,
    popupOpen: any,
};

const MarkerCustom: React.FC<MarkerCustomProps> = ({ activeKey,
    multipleSelect,
    index,
    point,
    setMultipleSelect,
    setPopupOpen,
    popupOpen
}) => {
    return  <div key={`marker-${index}`}>
    {multipleSelect.find((e: any) => e === point) || (popupOpen && popupOpen.lat == point.lat && popupOpen.lon == point.lon)   ? (
        <Marker
            style={{ borderColor: APP_COLOR.base, borderWidth: '4px' }}
            latitude={point.lat}
            longitude={point.lon}
            onClick={(e) => {
                e.originalEvent.stopPropagation();
                activeKey === '3' &&
                    setMultipleSelect(multipleSelect.filter((pointToRemove: any) => point !== pointToRemove));
                activeKey !== '3' && setPopupOpen({
                    display: true,
                    ...point,
                });
            }}
        >
            {point.traffic_sign_id && <img className='w-9 h-9' src={`/v2/${point.traffic_sign_id}.png`} />}
        </Marker>
    ) : (
        <Marker
            key={`marker-${index}`}
            latitude={point.lat}
            longitude={point.lon}
            onClick={(e) => {
                e.originalEvent.stopPropagation();
                activeKey === '3' && setMultipleSelect([...multipleSelect, point]);
                activeKey !== '3' && setPopupOpen({
                    display: true,
                    ...point,
                });
            }}
        >
            {point.traffic_sign_id && <img className='w-9 h-9' src={`/v2/${point.traffic_sign_id}.png`} />}
        </Marker>
    )}
</div>;
};

export default MarkerCustom;