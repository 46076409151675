import * as React from 'react';
import { useState, useCallback } from 'react';
import Map, { MapRef, MapLayerMouseEvent, Marker, NavigationControl, ScaleControl, Source, Layer, Popup } from 'react-map-gl';
import "mapbox-gl/dist/mapbox-gl.css";
import { Button, Dropdown, Form, Menu, Modal, Space, Tabs, TabsProps, UploadFile, message } from 'antd';
import { APP_COLOR, MAPBOX_TOKEN, initialViewState, IMARKER_TYPES, ITITLE_TYPES, layerCongestionStyle, initPopupTrafficValue, defaultAudioLink, layerCreateCongestionStyle, defaultLineCongestion } from '../../constant';
import { useForm } from 'antd/es/form/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getFixedSound, getRealtimeTrafficCongestion, getRoadName, searchRoute } from '../../services';
import { RcFile } from 'antd/es/upload';
import { decode, encode } from '@googlemaps/polyline-codec';
import { AudioData, CongestionFeature, CongestionLineData, LevelType, MarkerType, PopupTraffic, PopupTrafficCongestion, PreviewState } from '../../constant/type';
import { useParams } from 'react-router-dom';
import { CreateNews } from './component/CreateNews';
import { getBase64, onGetTrafficRealtime } from './helper';
import { UpdateNews } from './component/UpdateNews';
import { CreateLine } from './component/CreateLine';
import { FormLine } from './component/FormLine';
import { UpdateLine } from './component/UpdateLine';
import { PopupView } from './component/PopupView';
import { getDirectionAndAngle } from '../../helper/LocationHelper';


export default function RealtimeTraffic(props: any) {
    const [markers, setMarkers] = useState<MarkerType>();
    const [markersBearing, setMarkersBearing] = useState<MarkerType>();
    const [levelInfor, setLevelInfo] = useState<LevelType>({ level: 'level1', index: 0 });
    const [markersStart, setMarkersStart] = useState<MarkerType>();
    const [markersEnd, setMarkersEnd] = useState<MarkerType>();
    const [markerCenter, setMarkerCenter] = useState<MarkerType>();
    const [line, setLine] = useState<any>();
    const mapRef = React.useRef<MapRef>(null);
    const [activeKeyTrafficNews, setActiveKeyTrafficNews] = useState('1');
    const [activeKeyLine, setActiveKeyLine] = useState('1');
    const [popupTraffic, setPopupTraffic] = useState<PopupTraffic>(initPopupTrafficValue);
    const [popupTrafficCongestion, setPopupTrafficCongestion] = useState<PopupTrafficCongestion>();
    const [centerCongestion, setCenterCongestion] = useState<any>();
    const [ImarkerType, setIMarkerType] = useState<string>(IMARKER_TYPES.normal)
    const [formLine] = useForm();
    const [formUpdateLine] = useForm();
    const [formCreateCongestion] = useForm();
    const [formNews] = useForm();
    const [formUpdateNews] = useForm();
    const [title, setTitle] = useState<string>(ITITLE_TYPES.trafficNews);
    const [lineCongestion, setLineCongestion] = useState<CongestionLineData>(defaultLineCongestion);
    const [points, setPoints] = useState<any>([]);
    const { Icategory, Icontent, Ilon, Ilat }: any = useParams();
    const [speechUrl, setSpeechUrl] = useState<string>('');
    const [speechUpdateUrl, setSpeechUpdatehUrl] = useState<string>('');
    const watch_content = Form.useWatch('content', formNews);
    const [listImgUpdateTrafic, setListImgUpdateTrafic] = useState<UploadFile[]>([]);
    const [notFoundRoad, setNotFoundRoad] = useState<boolean>(false);
    const [previewState, setPreviewState] = useState<PreviewState>({});
    const [hoverInfo, setHoverInfo] = useState<any>();
    const [fixedSound, setFixedSound] = useState<AudioData[]>(defaultAudioLink);
    const [isEditLevel, setIsEditLevel] = useState<boolean>(false);
    const [mapStyle, setMapStyle] = useState('dark')

    React.useEffect(() => {
        getFixedSound().then((res) => {
            setFixedSound(res.data)
        })
        getRealtimeTrafficCongestion().then((res) => {
            setCenterCongestion(res.data);
        })
        onGetTrafficRealtime({
            setLine,
            setPoints
        })
    }, [])


    React.useEffect(() => {
        if (isEditLevel) {
            return;
        }
        if (markersStart && markersEnd) {
            const data = {
                srcLat: markersStart.lat,
                srcLon: markersStart.lon,
                dstLat: markersEnd.lat,
                dstLon: markersEnd.lon,
                // bearings: formLine.getFieldValue('bearings') || -1,
                bearings: -1,
            }
            searchRoute(data).then((data) => {
                message.success('Tìm thành công');
                const coordinates: any = [];
                data.data.routes.forEach((route: any) => {
                    const { legs } = route;
                    legs.forEach((leg: any) => {
                        const { steps } = leg;
                        const legCoordinates = steps.flatMap((step: any) => {
                            const { geometry } = step;
                            return decode(geometry);
                        });
                        coordinates.push(...legCoordinates);
                    });
                });
                const resultGeometry = encode(coordinates);
                const { level, index } = levelInfor;
                const recentLevel = activeKeyLine == '1' ? formCreateCongestion.getFieldValue(level) : formUpdateLine.getFieldValue(level);
                if (recentLevel) {
                    if (recentLevel && typeof (recentLevel[index]) == 'object') {
                        recentLevel[index].level = resultGeometry;
                        const recentLevelIndex = levelInfor.level == 'level1' ? 0 : levelInfor.level == 'level2' ? 1 : 2;
                        const newLine = [...lineCongestion.features[recentLevelIndex].geometry.coordinates];
                        const mappedCoordinates = coordinates.map((item: number[]) => [item[1], item[0]]);
                        if (index < newLine.length) {
                            newLine[index] = mappedCoordinates;
                        } else {
                            console.error('Invalid index:', index);
                            return;
                        }
                        const newFeatures: CongestionFeature[] = lineCongestion.features.map((feature: CongestionFeature, i: number) => {
                            return {
                                ...feature,
                                geometry: {
                                    ...feature.geometry,
                                    coordinates: feature.properties.level == level ? newLine : feature.geometry.coordinates,
                                },
                            };
                        });

                        const newCongestion: CongestionLineData = {
                            type: 'FeatureCollection',
                            features: newFeatures,
                        };

                        setLineCongestion(newCongestion);
                        const formFields: { [key: string]: string } = {};
                        formFields[level] = recentLevel;
                        activeKeyLine == '1' ? formCreateCongestion.setFieldsValue(formFields) : formUpdateLine.setFieldsValue(formFields);
                    }

                    else {
                        const previousIndex = levelInfor.index - 1;
                        if (recentLevel[previousIndex]) {
                            recentLevel[levelInfor.index] = { level: resultGeometry };
                            const formFields: { [key: string]: any } = {};
                            formFields[level] = recentLevel;
                            activeKeyLine == '1' ? formCreateCongestion.setFieldsValue(formFields) : formUpdateLine.setFieldsValue(formFields);

                        } else {
                            const newDefaultLevel = {
                                level: resultGeometry,
                            }
                            const formFields: { [key: string]: any } = {};
                            formFields[level] = [newDefaultLevel];
                            activeKeyLine == '1' ? formCreateCongestion.setFieldsValue(formFields) : formUpdateLine.setFieldsValue(formFields);
                        }
                        const recentLevelIndex = levelInfor.level == 'level1' ? 0 : levelInfor.level == 'level2' ? 1 : 2;
                        const newLine = lineCongestion.features[recentLevelIndex].geometry.coordinates.concat([coordinates.map((item: any) => [item[1], item[0]])]);
                        const newFeatures: CongestionFeature[] = lineCongestion.features.map((feature: CongestionFeature, i: number) => {
                            return {
                                ...feature,
                                geometry: {
                                    ...feature.geometry,
                                    coordinates: feature.properties.level == level ? newLine : feature.geometry.coordinates,
                                },
                            };
                        });

                        const newCongestion: CongestionLineData = {
                            type: 'FeatureCollection',
                            features: newFeatures,
                        };
                        setLineCongestion(newCongestion);
                    }
                }
                else {
                    const recentLevel =
                    {
                        "level": resultGeometry
                    }
                    const formFields: { [key: string]: any } = {};
                    formFields[level] = [recentLevel];
                    activeKeyLine == '1' ? formCreateCongestion.setFieldsValue(formFields) : formUpdateLine.setFieldsValue(formFields);
                    const newFeatures: CongestionFeature[] = lineCongestion.features.map((feature: CongestionFeature, i: number) => {
                        return {
                            ...feature,
                            geometry: {
                                ...feature.geometry,
                                coordinates: feature.properties.level == level ? [coordinates.map((item: any) => [item[1], item[0]])] : feature.geometry.coordinates,
                            },
                        };
                    });
                    const newCongestion: CongestionLineData = {
                        type: 'FeatureCollection',
                        features: newFeatures,
                    };
                    setLineCongestion(newCongestion);
                }
            });
        }
    }, [markersStart, markersEnd])
    React.useEffect(() => {
        if (markers && markersBearing) {
            const { compass, direction} = getDirectionAndAngle(markers, markersBearing);
            formNews.setFieldsValue({
                bearing: direction,
            })
            formUpdateNews.setFieldsValue({
                bearing: direction,
            })
        }
    }, [markers, markersBearing])
    React.useEffect(() => {
        if (Icategory && Icontent && Ilon && Ilat) {
            formNews.setFieldsValue({
                CNLocation: `${Ilat}, ${Ilon}`,
                content: Icontent,
                traffic_type: Icategory,
            })
            const newMarker: MarkerType = {
                lat: Ilat,
                lon: Ilon,
            };
            getRoadName(newMarker).then((res: any) => {
                if (res.name) {
                    formNews.setFieldsValue({
                        name: res.name,
                    })
                    setNotFoundRoad(false)
                } else {
                    setNotFoundRoad(true);
                    formNews.resetFields(['name'])
                }
                formNews.setFieldsValue({
                    sta_cty: res.address.state
                        ? res.address.state : res.address.city,
                    osm_combination_id: res.osm_type + res.osm_id,
                })
            }).catch((err) => {
                setNotFoundRoad(true);
                formNews.resetFields(['name']);
            });
        }
    }, [Icategory, Icontent, Ilon, Ilat])
    const onClick = useCallback((event: MapLayerMouseEvent) => {
        setIsEditLevel(false);
        const newMarker: MarkerType = {
            lat: event.lngLat.lat,
            lon: event.lngLat.lng
        };
        const { features } = event;
        const hoveredFeature = features && features[0];

        // if (hoveredFeature?.properties) {
        //     setActiveKeyLine('2');
        //     setTitle(ITITLE_TYPES.lineTraffic)
        //     const { geometry, expire_time, image, content, id } = hoveredFeature.properties;
        //     const IlistImgUpdateLine = JSON.parse(image.length) > 0 ? JSON.parse(image).map((item: string, index: number) => ({
        //         uid: index.toString(),
        //         status: 'done',
        //         url: `${STORAGE_URL}${item}`,
        //         name: item,
        //         original: item,
        //     })) : [];
        //     formUpdateLine.setFieldsValue({
        //         expire_time: dayjs.unix(expire_time),
        //         image: IlistImgUpdateLine,
        //         geometry: geometry,
        //         content: content,
        //         id: id,
        //     })
        //     setIMarkerType(IMARKER_TYPES.start)
        //     setMarkers(undefined)
        //     IlistImgUpdateLine && setListImgUpdateLine(IlistImgUpdateLine as any);
        //     // setLine(undefined);
        //     const arr_coord = decode(geometry).map((item: any) => [item[1], item[0]]);
        //     const endIndex = arr_coord.length - 1;
        //     formLine.setFieldsValue(
        //         {
        //             srcLocation: `${arr_coord[0][1]}, ${arr_coord[0][0]}`,
        //             dstLocation: `${arr_coord[endIndex][1]}, ${arr_coord[endIndex][0]}`,

        //         })
        //     setMarkersStart({ ...markersStart, lat: arr_coord[0][1], lon: arr_coord[0][0] });
        //     setMarkersEnd({ ...markersEnd, lat: arr_coord[endIndex][1], lon: arr_coord[endIndex][0] });
        //     // setLineCongestion({
        //     //     type: 'FeatureCollection',
        //     //     features: [
        //     //         {
        //     //             type: 'Feature',
        //     //             properties: {},
        //     //             geometry: {
        //     //                 type: 'LineString',
        //     //                 coordinates: arr_coord,
        //     //             }
        //     //         },
        //     //     ],
        //     // });

        // }
        // else 
        if (ImarkerType == IMARKER_TYPES.normal) {
            mapRef.current?.flyTo({ center: [event.lngLat.lng, event.lngLat.lat], animate: false });
            setMarkers(newMarker);
        }
        else if (ImarkerType == IMARKER_TYPES.start) {
            setMarkersStart(newMarker);
            formLine.setFieldsValue({
                srcLocation: `${event.lngLat.lat}, ${event.lngLat.lng}`
            });
        }
        else if (ImarkerType == IMARKER_TYPES.end) {
            setMarkersEnd(newMarker);
            formLine.setFieldsValue({
                dstLocation: `${event.lngLat.lat}, ${event.lngLat.lng}`
            });
        }
        else if (ImarkerType == IMARKER_TYPES.marker) {
            setIMarkerType(IMARKER_TYPES.normal)
        }
        else if (ImarkerType == IMARKER_TYPES.endUpdate) {
            setMarkersBearing(newMarker);
            setIMarkerType(IMARKER_TYPES.normal)
        }
        else if (ImarkerType == IMARKER_TYPES.center) {
            setMarkerCenter(newMarker);
            formCreateCongestion.setFieldValue('center', `${event.lngLat.lat}, ${event.lngLat.lng}`)
        }
    }, [ImarkerType, markersStart, markersEnd, line, activeKeyTrafficNews]);

    const pins = React.useMemo(
        () => {
            return points && points.map((point: any, index: any) => (
                <Marker
                    key={`marker-${index}`}
                    latitude={point.lat}
                    longitude={point.lon}
                    style={{ color: 'red' }}
                    onClick={(e) => {
                        e.originalEvent.stopPropagation();
                        setIMarkerType(IMARKER_TYPES.marker);
                        setPopupTraffic({
                            display: true,
                            ...point
                        })
                    }}
                >
                    <img className='w-8 h-8' src={`/TrafficType/${point.traffic_type}.png`} />
                </Marker>
            ))
        },
        [points]
    );
    // const pinsCongestion = React.useMemo(
    //     () => {
    //         return centerCongestion && centerCongestion.map((point: any, index: any) => (
    //             <Marker
    //                 key={`center-congestion-${index}`}
    //                 latitude={point.grid_lat_center}
    //                 longitude={point.grid_lon_center}
    //                 style={{ color: 'red' }}
    //                 onClick={(e) => {
    //                     console.log('popupTrafficCongestion', popupTrafficCongestion);

    //                     e.originalEvent.stopPropagation();
    //                     setIMarkerType(IMARKER_TYPES.marker);
    //                     setPopupTrafficCongestion({
    //                         display: true,
    //                         ...point
    //                     })
    //                 }}
    //             >
    //                     <FontAwesomeIcon size='2x' icon='location-crosshairs' className='z-[400]' color={APP_COLOR.warning}></FontAwesomeIcon>
    //             </Marker>
    //         ))
    //     },
    //     [centerCongestion]
    // );

    const lineCongestionView = React.useMemo(
        () => {
            return lineCongestion && <Source id="line-create" type="geojson" data={lineCongestion}>
                <Layer {...layerCreateCongestionStyle} />
            </Source>
        },
        [lineCongestion]
    );
    const handleClick = (e: any) => {
        setTitle(e.key)
    }

    React.useEffect(() => {
        if (title == ITITLE_TYPES.trafficNews) {
            formLine.resetFields();
            setIMarkerType(IMARKER_TYPES.normal);
            setMarkersStart(undefined);
            setMarkersEnd(undefined);
            setLineCongestion(defaultLineCongestion);
        }
        else if (title == ITITLE_TYPES.lineTraffic) {
            formNews.resetFields();
            setMarkers(undefined);
            setIMarkerType(IMARKER_TYPES.start);
        }
    }, [title]);

    const lineTraffic = React.useMemo(
        () => {
            return line && <Source id="line-traffic" type="geojson" data={line}>
                <Layer {...layerCongestionStyle} />
            </Source>
        },
        [line]
    );

    const menu = (
        <Menu onClick={handleClick}>
            {title == ITITLE_TYPES.lineTraffic ?
                <Menu.Item key={ITITLE_TYPES.trafficNews}>{ITITLE_TYPES.trafficNews}</Menu.Item>
                : <Menu.Item key={ITITLE_TYPES.lineTraffic}>{ITITLE_TYPES.lineTraffic}</Menu.Item>}
        </Menu>
    );

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as RcFile);
        }
        setPreviewState(
            {
                previewImage: file.url || (file.preview as string),
                previewOpen: true,
                previewTitle: file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1)
            }
        )
    };
    const handleCancel = () => setPreviewState({ ...previewState, previewOpen: false });
    const onHover = useCallback((event: MapLayerMouseEvent) => {
        const {
            features,
            point: { x, y }
        } = event;
        const hoveredFeature = features && features[0];
        setHoverInfo(hoveredFeature && { feature: hoveredFeature, x, y });
    }, []);

    const itemsTabTrafficNews: TabsProps['items'] = [
        {
            key: '1',
            label: `Tạo bản tin`,
            children:
                <CreateNews
                    formNews={formNews}
                    watch_content={watch_content}
                    setSpeechUrl={setSpeechUrl}
                    speechUrl={speechUrl}
                    notFoundRoad={notFoundRoad}
                    handlePreview={handlePreview}
                    onGetTrafficRealtime={onGetTrafficRealtime}
                    setLine={setLine}
                    setPoints={setPoints}
                    fixedSound={fixedSound}
                    setMarkers={setMarkers}
                    setIMarkerType={setIMarkerType}
                    setMarkersBearing={setMarkersBearing}
                    mapRef={mapRef}
                />
        },
        {
            key: '2',
            label: 'Cập nhật bản tin',
            children: <UpdateNews
                formUpdateNews={formUpdateNews}
                speechUpdateUrl={speechUpdateUrl}
                onGetTrafficRealtime={onGetTrafficRealtime}
                watch_content={watch_content}
                formNews={formNews}
                setSpeechUpdatehUrl={setSpeechUpdatehUrl}
                notFoundRoad={notFoundRoad}
                listImgUpdateTrafic={listImgUpdateTrafic}
                setListImgUpdateTrafic={setListImgUpdateTrafic}
                handlePreview={handlePreview}
                setLine={setLine}
                setPoints={setPoints}
                fixedSound={fixedSound}
                setActiveKeyTrafficNews={setActiveKeyTrafficNews}
                setMarkers={setMarkers}
                setIMarkerType={setIMarkerType}
                setMarkersBearing={setMarkersBearing}
            />
        }
    ]
    const onChangeTabsTrafficNews = (key: string) => {
        setNotFoundRoad(false);
        setMarkers(undefined);
        if (key == '2') {
            setMarkers(undefined);
            setMarkersBearing(undefined);
            formNews.resetFields();
        }
        else if (key == '1') {
            formUpdateNews.resetFields();
            setMarkers(undefined);
            setMarkersBearing(undefined);
        }
        setActiveKeyTrafficNews(key);
    };
    const onChangeTabsLine = (key: string) => {
        setNotFoundRoad(false);
        setMarkersStart(undefined);
        setMarkersEnd(undefined);
        formLine.resetFields();
        formCreateCongestion.resetFields();
        formUpdateLine.resetFields();
        setMarkerCenter(undefined);
        setLineCongestion(defaultLineCongestion);
        setActiveKeyLine(key);
    };
    React.useEffect(() => {
        getRoadName(markers).then((res: any) => {
            if (res.name) {
                activeKeyTrafficNews === '1' ? formNews.setFieldsValue({
                    name: res.name,
                }) : formUpdateNews.setFieldsValue({
                    name: res.name,
                })
                setNotFoundRoad(false)
            } else {
                activeKeyTrafficNews === '1' ? formNews.resetFields(['name']) : formUpdateNews.resetFields(['name']);
                setNotFoundRoad(true)
            }

            if (activeKeyTrafficNews === '1') {
                formNews.setFieldsValue({
                    CNLocation: `${markers?.lat}, ${markers?.lon}`,
                    sta_cty: res.address.state
                        ? res.address.state : res.address.city,
                    osm_combination_id: res.osm_type + res.osm_id,

                })

            } else if (activeKeyTrafficNews === '2') {
                formUpdateNews.setFieldsValue({
                    UNLocation: `${markers?.lat}, ${markers?.lon}`,
                    sta_cty: res.address.state
                        ? res.address.state : res.address.city,
                    osm_combination_id: res.osm_type + res.osm_id,
                })
            }

        }).catch((err) => {
            activeKeyTrafficNews === '1' ? formNews.resetFields(['name', 'osm_combination_id', 'sta_cty']) : formUpdateNews.resetFields(['name', 'osm_combination_id', 'sta_cty']);
            setNotFoundRoad(true)
        })
    }, [markers])
    const itemsTabLine: TabsProps['items'] = [
        {
            key: '1',
            label: `Tạo line ùn tắc`,
            children: <CreateLine
                formCreateCongestion={formCreateCongestion}
                onGetTrafficRealtime={onGetTrafficRealtime}
                setLine={setLine}
                setPoints={setPoints}
                setLineCongestion={setLineCongestion}
                setMarkersStart={setMarkersStart}
                setMarkersEnd={setMarkersEnd}
                setLevelInfo={setLevelInfo}
                levelInfor={levelInfor}
                formLine={formLine}
                setIsEditLevel={setIsEditLevel}
                setIMarkerType={setIMarkerType}
                setMarkerCenter={setMarkerCenter}
            />
        },
        {
            key: '2',
            label: `Cập nhật line ùn tắc`,
            children: <UpdateLine
                onGetTrafficRealtime={onGetTrafficRealtime}
                setPoints={setPoints}
                setLine={setLine}
                formUpdateLine={formUpdateLine}
                setLineCongestion={setLineCongestion}
                setMarkersStart={setMarkersStart}
                setMarkersEnd={setMarkersEnd}
                setActiveKeyLine={setActiveKeyLine}
                formLine={formLine}
                formCreateCongestion={formCreateCongestion}
                setIsEditLevel={setIsEditLevel}
                setIMarkerType={setIMarkerType}
                levelInfor={levelInfor}
                setLevelInfo={setLevelInfo}
                setMarkerCenter={setMarkerCenter}
            />
        },
    ]
    const handleShowAllLine = () => {
        if (line) {
            setLine(undefined);
        }
        else {

            onGetTrafficRealtime({
                setLine,
                setPoints
            })
        }
    };
     const handlShowCenter = () => {
        if (centerCongestion) {
            setCenterCongestion(undefined);
        }
        else {
            getRealtimeTrafficCongestion().then((res) => {
                setCenterCongestion(res.data);
            })
        }
     }
    console.log('centerCongestion', centerCongestion);

    return (
        <div className='flex flex-row items-stretch h-screen'>
            <div className='w-[26rem] p-2 overflow-auto'>
                <div className='flex flex-row items-center justify-between'>
                    <Dropdown trigger={['click']} overlay={menu}>
                        <Space className='flex flex-row items-center'>
                            <h3 className='font-bold'>{title}{'    '}</h3>
                            <FontAwesomeIcon icon='caret-down'></FontAwesomeIcon>
                        </Space>
                    </Dropdown>
                    <div>
                        <Button className='ml-2 bg-base text-white' onClick={() => mapStyle == 'light' ? setMapStyle('dark') : setMapStyle('light')} >
                            <FontAwesomeIcon icon={mapStyle == 'light' ? 'sun' : 'moon'}></FontAwesomeIcon>
                        </Button>
                        <Button className='bg-base' type='primary' onClick={handleShowAllLine} >
                            {line ? 'Hide Line' : 'Show Line'}
                        </Button>
                        <Button className='bg-base' type='primary' onClick={handlShowCenter} >
                            {centerCongestion ? 'Hide Center' : 'Show Center'}
                        </Button>
                    </div>
                </div>

                {title == ITITLE_TYPES.trafficNews ?
                    <div key='traffic-news' className='shadow-boxShadow rounded-lg p-4 bg-white'
                    >
                        <Tabs defaultActiveKey="1"
                            activeKey={activeKeyTrafficNews}
                            onChange={onChangeTabsTrafficNews}
                            items={itemsTabTrafficNews}
                        />
                    </div> : <div key='line-traffic' className='shadow-boxShadow rounded-lg p-4 bg-white'
                    >
                        <FormLine formLine={formLine}
                            setIMarkerType={setIMarkerType}
                            mapRef={mapRef}
                            setMarkersStart={setMarkersStart}
                            setMarkersEnd={setMarkersEnd}
                        ></FormLine>
                        <Tabs defaultActiveKey="1"
                            activeKey={activeKeyLine}
                            onChange={onChangeTabsLine}
                            items={itemsTabLine}
                        />
                    </div>}
                <Modal open={previewState.previewOpen} title={previewState.previewTitle} footer={null} onCancel={handleCancel}>
                    <img alt="example" className='w-full' src={previewState.previewImage} />
                </Modal>
                {/* <Tabs defaultActiveKey="1" items={items} onChange={onChange} /> */}
            </div>

            <Map
                style={{ flex: 4 }}
                initialViewState={initialViewState}
                // mapStyle="mapbox://styles/mapbox/dark-v9"
                mapStyle={`mapbox://styles/mapbox/${mapStyle == 'dark' ? 'dark-v9' : 'streets-v12'}`}
                // mapStyle={MAP_STYLE as MapboxStyle}
                onClick={onClick}
                mapboxAccessToken={MAPBOX_TOKEN}
                interactiveLayerIds={['data']}
                onMouseMove={onHover}
                ref={mapRef as React.Ref<MapRef>}
                onLoad={(e) => {
                    Ilon && Ilat && mapRef.current?.flyTo({ center: [Ilon, Ilat], animate: false })
                        && setMarkers({ lat: Ilat, lon: Ilon });
                }}
            >
                <NavigationControl position="top-left" />
                <ScaleControl />
                {popupTraffic.display && <PopupView
                    popupTraffic={popupTraffic}
                    setActiveKeyTrafficNews={setActiveKeyTrafficNews}
                    setTitle={setTitle}
                    setPopupTraffic={setPopupTraffic}
                    formUpdateNews={formUpdateNews}
                    listImgUpdateTrafic={listImgUpdateTrafic}
                    setListImgUpdateTrafic={setListImgUpdateTrafic}
                    points={points}
                    setPoints={setPoints}
                    setSpeechUpdatehUrl={setSpeechUpdatehUrl}
                    handlePreview={handlePreview}
                    setMarkersBearing={setMarkersBearing}
                ></PopupView>}
                {
                    popupTrafficCongestion?.display && <Popup
                        className='rounded-2xl'
                        longitude={Number(popupTrafficCongestion.grid_lon_center)}
                        latitude={Number(popupTrafficCongestion.grid_lat_center)}
                        anchor="top"
                        onClose={() => setPopupTrafficCongestion({ ...popupTrafficCongestion, display: false })}
                    >
                        <Button onClick={() => {
                            console.log(popupTrafficCongestion)
                            const convertedData = {
                                center: `${popupTrafficCongestion.grid_lat_center}, ${popupTrafficCongestion.grid_lon_center}`,
                                level1: popupTrafficCongestion.congestion_geometry.level1[0].map(level => ({ level: level })),
                                level2: popupTrafficCongestion.congestion_geometry.level2[0].map(level => ({ level: level })),
                                level3: popupTrafficCongestion.congestion_geometry.level3[0].map(level => ({ level: level }))
                            };
                            setTitle(ITITLE_TYPES.lineTraffic);
                            onChangeTabsLine('2');
                            const arrayLineLevel1 = popupTrafficCongestion.congestion_geometry.level1[0].map(level => decode(level).map((item) => [item[1], item[0]]));
                            const arrayLineLevel2 = popupTrafficCongestion.congestion_geometry.level2[0].map(level => decode(level).map((item) => [item[1], item[0]]));
                            const arrayLineLevel3 = popupTrafficCongestion.congestion_geometry.level3[0].map(level => decode(level).map((item) => [item[1], item[0]]));
                            const newFeatures: CongestionFeature[] = lineCongestion.features.map((feature: CongestionFeature, i: number) => {
                                return {
                                    ...feature,
                                    geometry: {
                                        ...feature.geometry,
                                        coordinates: feature.properties.level == 'level1' ? arrayLineLevel1 : feature.properties.level == 'level2' ? arrayLineLevel2 : arrayLineLevel3,
                                    },
                                };
                            });
                            const newCongestion: CongestionLineData = {
                                type: 'FeatureCollection',
                                features: newFeatures,
                            };
                            console.log('newCongestion', newCongestion);
                            setLine(undefined)
                            setCenterCongestion(undefined)
                            setMarkerCenter({ lat: Number(popupTrafficCongestion.grid_lat_center), lon: Number(popupTrafficCongestion.grid_lon_center) })
                            setLineCongestion(newCongestion);
                            formUpdateLine.setFieldsValue(convertedData)
                        }}>Edit</Button>
                        {/* <Button>Delete</Button> */}
                    </Popup>}
                {markers && (<Marker
                    key={`marker`}
                    latitude={markers?.lat}
                    longitude={markers?.lon}
                    draggable={true}
                    onDragEnd={(event) => {
                        const newLocation = { lat: event.lngLat.lat, lon: event.lngLat.lng }
                        setMarkers(newLocation);

                    }}
                >
                    <img src={`/pin-start.png`} ></img>
                </Marker>)}
                {markersBearing && (<Marker
                    key={`marker-bearing-end`}
                    latitude={markersBearing?.lat}
                    longitude={markersBearing?.lon}
                    draggable={true}
                    onDragEnd={(event) => {
                        const newLocation = { lat: event.lngLat.lat, lon: event.lngLat.lng }
                        setMarkersBearing(newLocation);
                    }}
                >
                    <img src={`/pin-end.png`} ></img>
                </Marker>)}
                {lineCongestionView}
                {lineTraffic}
                {pins}
                {centerCongestion && centerCongestion.map((point: any, index: any) => (
                    (point.grid_lat_center && point.grid_lon_center) && <Marker
                        key={`center-congestion-${index}`}
                        latitude={point.grid_lat_center}
                        longitude={point.grid_lon_center}
                        style={{ color: 'red' }}
                        onClick={(e) => {
                            console.log('popupTrafficCongestion', popupTrafficCongestion);

                            e.originalEvent.stopPropagation();
                            setIMarkerType(IMARKER_TYPES.marker);
                            setPopupTrafficCongestion({
                                display: true,
                                ...point
                            })
                        }}
                    >
                        <FontAwesomeIcon size='2x' icon='location-crosshairs' className='z-[400]' color={APP_COLOR.warning}></FontAwesomeIcon>
                    </Marker>
                ))
                }
                {markersStart && (<Marker
                    key={`marker-start`}
                    latitude={markersStart?.lat}
                    longitude={markersStart?.lon}
                    style={{ color: 'red' }}
                    draggable={true}
                    onDragEnd={(event) => {
                        setIsEditLevel(false);
                        const newLocation = { lat: event.lngLat.lat, lon: event.lngLat.lng }
                        setMarkersStart(newLocation);
                        formLine.setFieldsValue({
                            srcLocation: `${event.lngLat.lat}, ${event.lngLat.lng}`
                        });
                    }}
                >
                    <div >
                        <img src='./pin-start.png'>
                        </img>
                    </div>
                </Marker>)}
                {markersEnd && (<Marker
                    key={`marker-end`}
                    latitude={markersEnd?.lat}
                    longitude={markersEnd?.lon}
                    draggable={true}
                    onDragEnd={(event) => {
                        setIsEditLevel(false);
                        const newLocation = { lat: event.lngLat.lat, lon: event.lngLat.lng }
                        setMarkersEnd(newLocation);
                        formLine.setFieldsValue({
                            dstLocation: `${event.lngLat.lat}, ${event.lngLat.lng}`
                        });
                    }}
                >
                    <div >
                        <img src='./pin-end.png'>
                        </img>
                    </div>
                </Marker>)}
                {/* {markerCenter && (<Marker
                    key={`marker-center`}
                    latitude={markerCenter?.lat}
                    longitude={markerCenter?.lon}
                    draggable={true}
                    onDragEnd={(event) => {
                        const newLocation = { lat: event.lngLat.lat, lon: event.lngLat.lng }
                        setMarkerCenter(newLocation);
                        formCreateCongestion.setFieldsValue({
                            center: `${event.lngLat.lat}, ${event.lngLat.lng}`
                        });
                    }}
                >
                    <div >
                        <FontAwesomeIcon icon='location-crosshairs' className='w-8 h-8 z-[400]' color={APP_COLOR.base}></FontAwesomeIcon>
                    </div>
                </Marker>)} */}
                {hoverInfo && (
                    <div className="tooltip" style={{
                        left: hoverInfo.x, top: hoverInfo.y, position: 'absolute',
                        margin: '8px',
                        padding: '4px',
                        background: 'rgba(0, 0, 0, 0.8)',
                        color: '#fff',
                        maxWidth: '300px',
                        fontSize: '10px',
                        zIndex: '9',
                        pointerEvents: 'none'
                    }}>
                        <div>Id: {hoverInfo.feature.properties.id}</div>
                        <div>Content: {hoverInfo.feature.properties.content}</div>
                        {/* <div>Expire time: {moment.unix(hoverInfo.feature.properties.expire_time).format('HH:mm:ss DD/MM/YYYY')}</div> */}
                    </div>
                )}

            </Map>
        </div >
    );
}

