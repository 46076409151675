import { Button, Form, FormInstance, Input, message } from "antd";
import { FC } from "react";
import { CongestionLineData, LevelType, MarkerType } from "../../../constant/type";
import { decode } from '@googlemaps/polyline-codec';
import { IMARKER_TYPES } from "../../../constant";
import { PlusOutlined } from "@ant-design/icons";
interface PProps {
    levelName: string,
    setLevelInfo: React.Dispatch<React.SetStateAction<LevelType>>,
    formCongestion: FormInstance<any>,
    formLine: FormInstance<any>,
    setIsEditLevel: React.Dispatch<React.SetStateAction<boolean>>,
    setMarkersStart: (value: React.SetStateAction<MarkerType | undefined>) => void,
    setMarkersEnd: (value: React.SetStateAction<MarkerType | undefined>) => void,
    setLineCongestion: (value: any) => void,
    levelInfor: LevelType,
    setIMarkerType: React.Dispatch<React.SetStateAction<string>>,
}

export const FormLevel : FC<PProps> = ({
    levelName, 
    setLevelInfo,
    formCongestion,
    formLine,
    setIsEditLevel,
    setMarkersStart,
    setMarkersEnd,
    setLineCongestion,
    levelInfor,
    setIMarkerType
}) => {
    return (<Form.List name={levelName}>
        {(fields, { add, remove }) => (
            <div>
                {fields.map((field) => (
                    <div key={field.name} style={{ display: 'flex', marginBottom: 8 }}>
                        <Form.Item
                            {...field}
                            name={[field.name, 'level']}
                            key={field.name + 'level'}
                            rules={[{ required: true, message: 'Thiếu geometry' }]}
                            label={`Geometry ${field.name + 1}`}
                            style={{ width: '66%', marginRight: 8 }}
                        >
                            <Input disabled placeholder="Nhập Geometry" />
                        </Form.Item>
                        <Button
                            onClick={() => {
                                setLevelInfo({ level: levelName, index: field.name });
                                const recentLevel = formCongestion.getFieldValue(levelName)[field.name];
                                if (recentLevel) {
                                    const listPoint = decode(recentLevel.level).map((item) => [item[1], item[0]]);
                                    const endIndex = listPoint.length - 1;
                                    formLine.setFieldsValue({
                                        srcLocation: `${listPoint[0][1]}, ${listPoint[0][0]}`,
                                        dstLocation: `${listPoint[endIndex][1]}, ${listPoint[endIndex][0]}`,
                                    });
                                    setIsEditLevel(true);
                                    setMarkersStart({ lat: listPoint[0][1], lon: listPoint[0][0] });
                                    setMarkersEnd({ lat: listPoint[endIndex][1], lon: listPoint[endIndex][0] });
                                } else {
                                    setMarkersStart(undefined);
                                    setMarkersEnd(undefined);
                                    formLine.resetFields();
                                }
                            }}
                        >
                            Sửa
                        </Button>
                        <Button
                            danger
                            onClick={() => {
                                if (levelInfor.index == field.name) {
                                    setMarkersStart(undefined);
                                    setMarkersEnd(undefined);
                                    formLine.resetFields();
                                }
                                if (formCongestion.getFieldValue(levelName)[field.name]) {
                                    setLineCongestion((previousState: CongestionLineData) => {
                                        const updatedFeatures = previousState.features.map((feature) => ({
                                            ...feature,
                                            geometry: {
                                                ...feature.geometry,
                                                coordinates:
                                                    feature.properties.level === levelName
                                                        ? feature.geometry.coordinates.filter((_, i) => i !== Number(field.name))
                                                        : feature.geometry.coordinates,
                                            },
                                        }));
                                        return {
                                            ...previousState,
                                            features: updatedFeatures,
                                        };
                                    });
                                }
                                remove(field.name);
                            }}
                        >
                            Xoá
                        </Button>
                    </div>
                ))}

                <Form.Item wrapperCol={{ span: 4 }}>
                    <Button
                        className="bg-base"
                        type="primary"
                        onClick={() => {
                            if (fields.length > 0) {
                                const recentLevel = formCongestion.getFieldValue(levelName)[fields.length - 1];
                                if (!recentLevel) {
                                    message.error('Vui lòng nhập geometry');
                                    return;
                                }
                            }
                            add();
                            setLevelInfo({
                                level: levelName,
                                index: fields.length,
                            });
                            setMarkersStart(undefined);
                            setMarkersEnd(undefined);
                            setIMarkerType(IMARKER_TYPES.start);
                            formLine.resetFields();
                        }}
                    >
                        <PlusOutlined /> Thêm geometry
                    </Button>
                </Form.Item>
            </div>
        )}
    </Form.List>
)}