/* global window */
import * as React from 'react';
import { useState, useCallback } from 'react';
import Map, { MapLayerMouseEvent, Marker, Source, Layer, MapRef, MapboxStyle } from 'react-map-gl';
import "mapbox-gl/dist/mapbox-gl.css";
import { Button, Tabs, TabsProps, message } from 'antd';
import { APP_COLOR, CMARKER_TYPES, MAPBOX_TOKEN, initialViewState, kindOfWayDirection, MAP_STYLE, layerStyle, layerCreateStyle } from '../../constant';
import { useForm } from 'antd/es/form/Form';
import { searchRoute } from '../../services';
import { decode, encode } from "@googlemaps/polyline-codec";
import { CreateGpx } from './component/CreateGpx';
import { UpdateReGenGpx } from './component/Update_ReGenGpx';
import { CMarkerType } from '../../constant/type';
import { handleCallListVectorRoad, handleCopyClick } from './helper';
import { getDirectionAndAngle } from '../../helper/LocationHelper';
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
/* eslint import/no-webpack-loader-syntax: off */

export default function GpxGenerator() {
    const [markersStart, setMarkersStart] = useState<CMarkerType>();
    const [markersEnd, setMarkersEnd] = useState<CMarkerType>();
    const [line, setLine] = useState<any>();
    const [linePrev, setPrevLine] = useState<any>();
    const [lineCreate, setLineCreate] = useState<any>();
    const [ImarkerType, setIMarkerType] = useState<string>(CMARKER_TYPES.start)
    const [formSearchRoute] = useForm();
    const [formCreateGpx] = useForm();
    const [formUpdateGpx] = useForm();
    const mapRef = React.useRef<MapRef>(null);
    const [isCallSearch, setIsCallSearch] = useState<boolean>(true);
    const [optionVectorRoadId, setOptionVectorRoadId] = useState<any>([]);
    const [hoverInfo, setHoverInfo] = useState<any>();
    const [activeKey, setActiveKey] = useState('1');

    React.useEffect(() => {
        handleCallListVectorRoad(setOptionVectorRoadId, setPrevLine);
    }, [])

    const onHover = useCallback((event: MapLayerMouseEvent) => {
        const {
            features,
            point: { x, y }
        } = event;
        const hoveredFeature = features && features[0];
        setHoverInfo(hoveredFeature && { feature: hoveredFeature, x, y });
    }, []);

    const onClick = useCallback((event: MapLayerMouseEvent) => {
        const { features } = event;
        const hoveredFeature = features && features[0];

        if (hoveredFeature?.properties) {
            const { vector_road_id, way_direction, vector_geometry } = hoveredFeature.properties;
            formUpdateGpx.setFieldsValue({
                vectorRoadId: vector_road_id,
                wayDirection: way_direction,
                vectorGeometry: vector_geometry,
                newVectorRoadId: (vector_road_id.includes("_chieu_di") || vector_road_id.includes("_chieu_ve")) ? vector_road_id.replace(/_chieu_(di|ve)/, "") : vector_road_id,
            })
            if (activeKey == '2') {
                setLine(undefined);
                const arr_coord = decode(vector_geometry).map((item: any) => [item[1], item[0]]);
                setIsCallSearch(false);
                const endIndex = arr_coord.length - 1;
                formSearchRoute.setFieldsValue(
                    {
                        dstLocation: `${arr_coord[endIndex][1]}, ${arr_coord[endIndex][0]}`,
                        srcLocation: `${arr_coord[0][1]}, ${arr_coord[0][0]}`,
                    })
                setMarkersStart({ ...markersStart, lat: arr_coord[0][1], lon: arr_coord[0][0] });
                setMarkersEnd({ ...markersEnd, lat: arr_coord[endIndex][1], lon: arr_coord[endIndex][0] });
                setLineCreate({
                    type: 'FeatureCollection',
                    features: [
                        {
                            type: 'Feature',
                            properties: {},
                            geometry: {
                                type: 'LineString',
                                coordinates: arr_coord,
                            }
                        },
                    ],
                });
            }
        }
        const newMarker: CMarkerType = {
            lat: event.lngLat.lat,
            lon: event.lngLat.lng
        };
        if (ImarkerType == CMARKER_TYPES.start) {
            setMarkersStart({ ...newMarker, type: CMARKER_TYPES.start });
            formSearchRoute.setFieldsValue({
                srcLocation: `${event.lngLat.lat}, ${event.lngLat.lng}`,
            });
        }
        else if (ImarkerType == CMARKER_TYPES.end) {
            setMarkersEnd({ ...newMarker, type: CMARKER_TYPES.end });
            formSearchRoute.setFieldsValue({
                dstLocation: `${event.lngLat.lat}, ${event.lngLat.lng}`,
            });
        }
        else if (ImarkerType == CMARKER_TYPES.reGenStart) {
            setIMarkerType(CMARKER_TYPES.other)
            setMarkersStart({ ...newMarker, type: CMARKER_TYPES.reGenStart });

        }
        else if (ImarkerType == CMARKER_TYPES.reGenEnd) {
            setMarkersEnd({ ...newMarker, type: CMARKER_TYPES.reGenEnd });
        }
        else if (ImarkerType == CMARKER_TYPES.updateStart) {
            setIMarkerType(CMARKER_TYPES.other)
            setMarkersStart({ ...newMarker, type: CMARKER_TYPES.updateStart });
            setIsCallSearch(true);

            formSearchRoute.setFieldsValue({
                srcLocation: `${event.lngLat.lat}, ${event.lngLat.lng}`,
            })
        }
        else if (ImarkerType == CMARKER_TYPES.updateEnd) {
            setIMarkerType(CMARKER_TYPES.other);
            setMarkersEnd({ ...newMarker, type: CMARKER_TYPES.updateEnd });
            setIsCallSearch(true);

            formSearchRoute.setFieldsValue({
                dstLocation: `${event.lngLat.lat}, ${event.lngLat.lng}`,
            });
        }
    }, [ImarkerType]);

    const swapMarkers = () => {
        if (!markersStart || !markersEnd) {
            return;
        }
        setIsCallSearch(true);
        const tempMarkerStart = markersStart;
        const tempMarkerEnd = markersEnd;
        formSearchRoute.setFieldsValue({
            srcLocation: `${tempMarkerEnd?.lat}, ${tempMarkerEnd?.lon}`,
            dstLocation: `${tempMarkerStart?.lat}, ${tempMarkerStart?.lon}`,
        })
        setMarkersStart({ ...markersStart, lat: tempMarkerEnd.lat, lon: tempMarkerEnd.lon, });
        setMarkersEnd({ ...markersEnd, lat: tempMarkerStart.lat, lon: tempMarkerStart.lon });

    };


    React.useEffect(() => {
        if (markersStart && markersEnd) {
            if (isCallSearch) {
                const data = {
                    srcLat: markersStart.lat,
                    srcLon: markersStart.lon,
                    dstLat: markersEnd.lat,
                    dstLon: markersEnd.lon,
                    bearings: formSearchRoute.getFieldValue('bearings') || -1,
                }
                searchRoute(data).then((data) => {
                    message.success('Tìm thành công');
                    const result = data.data.routes[0];
                    const coordinates: any = [];
                    data.data.routes.forEach((route: any) => {
                        const { legs } = route;
                        legs.forEach((leg: any) => {
                            const { steps } = leg;
                            const legCoordinates = steps.flatMap((step: any) => {
                                const { geometry } = step;
                                return decode(geometry);
                            });
                            console.log('legCoordinates', legCoordinates);
                            coordinates.push(...legCoordinates);
                        });
                    });
                    const resultGeometry = encode(coordinates);
                    formCreateGpx.setFieldValue('vectorGeometry', resultGeometry);
                    formUpdateGpx.setFieldValue('vectorGeometry', resultGeometry);
                  
                    const { compass, direction } = getDirectionAndAngle(markersStart, markersEnd);
                    formSearchRoute.setFieldValue('bearings', direction);
                    setLineCreate({
                        type: 'FeatureCollection',
                        features: [
                            {
                                type: 'Feature',
                                properties: {},
                                geometry: {
                                    type: 'LineString',
                                    coordinates: coordinates.map((item: any) => [item[1], item[0]]),
                                }
                            },
                        ],
                    });
                });
            }
        }
    }, [markersStart, markersEnd, isCallSearch])

    const handleShowAll = () => {
        if (line) {
            setLine(undefined);
        }
        else
            setLine(linePrev);
    };

    const onChangeTab = (key: string) => {
        setIsCallSearch(true);
        setMarkersStart(undefined);
        setMarkersEnd(undefined);
        formSearchRoute.resetFields();
        setLineCreate(undefined);
        if (key == '1') {
            setIMarkerType(CMARKER_TYPES.start);
            formCreateGpx.resetFields();
        }
        if (key == '2') {
            setIMarkerType(CMARKER_TYPES.other);
            formUpdateGpx.resetFields();
        }
        setActiveKey(key);
    };



    const itemsTab: TabsProps['items'] = [
        {
            key: '1',
            label: `Create GPX`,
            children: <CreateGpx setIMarkerType={setIMarkerType}
                formSearchRoute={formSearchRoute}
                formCreateGpx={formCreateGpx}
                kindOfWayDirection={kindOfWayDirection}
                swapMarkers={swapMarkers}
                handleCopyClick={handleCopyClick}
                setMarkersStart={setMarkersStart}
                setMarkersEnd={setMarkersEnd}
                mapRef={mapRef}
                setOptionVectorRoadId={setOptionVectorRoadId}
                setPrevLine={setPrevLine}
            />
        },
        {
            key: '2',
            label: `Cập nhật/Re-generate GPX`,
            children: <UpdateReGenGpx
                setIMarkerType={setIMarkerType}
                formSearchRoute={formSearchRoute}
                formUpdateGpx={formUpdateGpx}
                optionVectorRoadId={optionVectorRoadId}
                setLine={setLine}
                setIsCallSearch={setIsCallSearch}
                mapRef={mapRef}
                setMarkersStart={setMarkersStart}
                setMarkersEnd={setMarkersEnd}
                markersStart={markersStart}
                markersEnd={markersEnd}
                swapMarkers={swapMarkers}
                setLineCreate={setLineCreate}
                handleCopyClick={handleCopyClick}
                setOptionVectorRoadId={setOptionVectorRoadId}
                setPrevLine={setPrevLine}
            />
        }]

    return (
        <div className='flex flex-row items-strecth'>
            <div className='w-[26rem] p-2 overflow-auto'>
                <div className='flex flex-row items-center justify-between'>
                    <h3 className='font-bold'>GPX Generator</h3>
                    <Button className='bg-base' type='primary' onClick={handleShowAll} >
                        {line ? 'Hide All' : 'Show All'}
                    </Button>
                </div>

                <Tabs className='overflow-auto' defaultActiveKey="1" activeKey={activeKey} items={itemsTab} onChange={onChangeTab} />

            </div>
            <Map
                style={{ flex: 4, height: '100vh' }}
                ref={mapRef as React.Ref<MapRef>}
                initialViewState={initialViewState}
                mapStyle={MAP_STYLE as MapboxStyle}
                onClick={onClick}
                mapboxAccessToken={MAPBOX_TOKEN}
                interactiveLayerIds={['data']}
                onMouseMove={onHover}
                maxZoom={18}
            >
                {/* <NavigationControl position="top-left" />
                <ScaleControl /> */}
                {markersStart && (<Marker
                    key={`marker-start`}
                    latitude={markersStart?.lat}
                    longitude={markersStart?.lon}
                    style={{ color: 'red' }}
                    draggable={true}
                    onDragEnd={(event) => {
                        setMarkersStart({ ...markersStart, lat: event.lngLat.lat, lon: event.lngLat.lng });
                        setIsCallSearch(true);
                        formSearchRoute.setFieldsValue({
                            srcLocation: `${event.lngLat.lat}, ${event.lngLat.lng}`,
                        });
                    }}
                >
                    <div >
                        <img src='./pin-start.png'>
                        </img>
                    </div>
                </Marker>)}
                {markersEnd && (<Marker
                    key={`marker-end`}
                    latitude={markersEnd?.lat}
                    longitude={markersEnd?.lon}
                    onDragEnd={(event) => {
                        setMarkersEnd({ ...markersEnd, lat: event.lngLat.lat, lon: event.lngLat.lng })
                        setIsCallSearch(true);
                        formSearchRoute.setFieldsValue({
                            dstLocation: `${event.lngLat.lat}, ${event.lngLat.lng}`,
                        });
                    }}
                    draggable={true}
                >
                    <div >
                        <img src='./pin-end.png'>
                        </img>
                    </div>
                </Marker>)}
                {line && <Source id="my-data" type="geojson" data={line}>
                    <Layer {...layerStyle} />
                </Source>}
                {lineCreate && <Source id="line-create" type="geojson" data={lineCreate}>
                    <Layer {...layerCreateStyle} />
                </Source>}
                {hoverInfo &&
                    (<div className="tooltip" style={{
                        left: hoverInfo.x, top: hoverInfo.y, position: 'absolute',
                        margin: '8px',
                        padding: '4px',
                        background: 'rgba(0, 0, 0, 0.8)',
                        color: '#fff',
                        maxWidth: '300px',
                        fontSize: '10px',
                        zIndex: '9',
                        pointerEvents: 'none'
                    }}>
                        <div>Id: {hoverInfo.feature.properties.id}</div>
                        <div>Vector Road Id: {hoverInfo.feature.properties.vector_road_id}</div>
                        <div>Way Direction: {hoverInfo.feature.properties.way_direction}</div>
                    </div>)
                }
            </Map>
        </div>
    );
}

