import { Button, Col, Descriptions, Form, FormInstance, Input, List, Row, Select, Typography, message } from "antd";
import { FC } from "react"
import { IUpdatePointV2, MarkerType } from "../../../constant/type";
import { IMARKER_TYPES } from "../../../constant";
import { getDirectionFromAngle } from "../../../helper/LocationHelper";
import { AxiosResponse } from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const { Text } = Typography;

interface PProps {
    multipleSelect: any,
    formEditMultiple: FormInstance<any>,
    setMultipleSelect: (value: any) => void,
    optionPointV2: ({
        value: string;
        label: number;
    } | {
        value: string;
        label: string;
    })[],
    getData: (data: any) => Promise<void>,
    handleDeleteMapPoint: (id: any[]) => void,
    setPopupOpen: (value: any) => void,
    updatePointV2(data: IUpdatePointV2): Promise<AxiosResponse<any, any>>,
    geohashEncoded: any,
    popupOpen: any


}

export const DeleteUpdateMultiple: FC<PProps> = ({
    multipleSelect,
    formEditMultiple,
    setMultipleSelect,
    optionPointV2,
    getData,
    handleDeleteMapPoint,
    setPopupOpen,
    updatePointV2,
    geohashEncoded,
    popupOpen


}) => {

    return <div >
        {multipleSelect && multipleSelect.length > 0 &&
            <div>
                <Button style={{ marginBottom: 4 }} danger onClick={() => handleDeleteMapPoint(multipleSelect.map((item: any) => item.id))}>Xoá nhiều điểm</Button>
                <Form form={formEditMultiple} onFinish={async (data) => {
                    const multipleEditData = multipleSelect.map(({ relation, traffic_sign_id, ...rest }: any) => ({
                        ...rest,
                        traffic_sign_id: data.traffic_sign_id
                    }));
                    const updatePromises = multipleEditData.map((item: any) => updatePointV2(item));

                    Promise.all(updatePromises)
                        .then((results) => {
                            const allSuccessful = results.every((res) => res.status === 200);

                            if (allSuccessful) {
                                message.success('Cập nhật thành công');
                                setMultipleSelect([]);
                                formEditMultiple.resetFields();
                                setPopupOpen({ ...popupOpen, display: false });

                            } else {
                                // Handle the case where not all items were successfully updated
                            }
                        })
                        .catch((error) => {
                            // Handle any errors that occurred during the update process
                        });
                    await getData({ currentLocation: geohashEncoded })
                }
                }>

                    <Row gutter={4}>
                        <Col span={16}>
                            <Form.Item
                                name='traffic_sign_id'
                                label='Traffic Sign Id'
                            >
                                <Select onChange={() => console.log('change')}>
                                    {optionPointV2.map((item: any) => <Select.Option key={item.value + Math.random()} value={item.value}>{item.label}</Select.Option>)}
                                </Select>
                            </Form.Item></Col>
                        <Col span={4}>
                            <Button htmlType="submit" className="bg-base text-white mr-2">Sửa nhiều điểm</Button>
                        </Col>
                    </Row>
                </Form>
            </div>}
        {/* <Divider></Divider> */}

        <List
            className="demo-loadmore-list"
            itemLayout="horizontal"
            dataSource={multipleSelect}
            renderItem={(item: any) => (
                <List.Item style={{ backgroundColor: 'white', padding: 12, borderRadius: 8, boxShadow: '0 0 4px 0 rgba(0,0,0,0.1)' }}
                    extra={<FontAwesomeIcon onClick={(e) => {
                        e.preventDefault();
                        setPopupOpen({ ...popupOpen, display: false })
                        setMultipleSelect(multipleSelect.filter((point: any) => point !== item))
                    }}
                        style={{ zIndex: 100 }} icon='trash' ></FontAwesomeIcon>
                    }
                >
                    <div onClick={(e) => {
                        e.preventDefault();
                        setPopupOpen({
                            display: true,
                            ...item
                        })
                        // setMultipleSelect(multipleSelect.filter((point: any) => point !== item));
                    }}>
                        <Descriptions layout='vertical' column={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xxl: 4 }}
                        >
                            <Descriptions.Item span={2} label="OSM ID"> {item.osm_id}</Descriptions.Item>
                            <Descriptions.Item span={2} label="AI ROAD ID">{item.ai_road_id}</Descriptions.Item>
                            <Descriptions.Item span={2} label="Traffic Sign ID">{item.traffic_sign_id}</Descriptions.Item>
                            <Descriptions.Item span={2} label="Biển báo">{optionPointV2.find((option) => option.value == item.traffic_sign_id)?.label}</Descriptions.Item>
                        </Descriptions >
                    </div>

                </List.Item >
            )}
        />
    </div >
}