import { MapLayerMouseEvent, Layer, Map, Source, MapRef, Marker } from "react-map-gl";
import { CMARKER_TYPES, MAPBOX_TOKEN, initialViewState, layerSearchRouteStyle } from "../../constant";
import { Button, Col, Form, Input, Row } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { CMarkerType } from "../../constant/type";
import { useForm } from "antd/es/form/Form";
import axios from "axios";
import { decode } from "@googlemaps/polyline-codec";
import { SwapOutlined } from "@ant-design/icons";

export default function Sample() {
    const [lineCreate, setLineCreate] = useState<any>();
    const [markerType, setMarkerType] = useState<string>(CMARKER_TYPES.start)
    const [markersStart, setMarkersStart] = useState<CMarkerType>();
    const [markersEnd, setMarkersEnd] = useState<CMarkerType>();
    const [hoverInfo, setHoverInfo] = useState<any>();
    const mapRef = useRef<MapRef>(null);
    const [formSample] = useForm();

    useEffect(() => {
        if (markersStart && markersEnd) {
            const data = {
                costing: "auto",
                locations: [{ lon: markersStart?.lon, lat: markersStart?.lat }, { lon: markersEnd?.lon, lat: markersEnd?.lat }],
                directions_options: {
                    format: "osrm",
                    language: "vi"
                },
                shape_format: "polyline5"
            }
            axios.get(`${process.env.REACT_APP_BASE_URL}/tool/gpx-generator/search-routeV2`, {
                params: {
                    dstLat: markersEnd?.lat,
                    dstLon: markersEnd?.lon,
                    srcLat: markersStart?.lat,
                    srcLon: markersStart?.lon,
                    bearings: -1
                }
            }).then(res => {
                console.log(res.data)
                const coordinatesArray: any[] = [];
                res.data.routes.forEach((route: any, index: number) => {
                    console.log('route', index);

                    const { legs } = route;
                    const coordinates: any[] = [];
                    legs.forEach((leg: any) => {
                        const { steps } = leg;
                        const legCoordinates = steps.flatMap((step: any) => {
                            const { geometry } = step;
                            return decode(geometry);
                        });
                        coordinates.push(...legCoordinates);
                    });
                    coordinatesArray.push(coordinates);
                });

                const features = coordinatesArray.map((coordinates, index) => ({
                    type: 'Feature',
                    properties: {
                        color: index == 0 ? "red" : "blue",
                        index: index
                    },
                    geometry: {
                        type: 'LineString',
                        coordinates: coordinates.map((item: any) => [item[1], item[0]]),
                    },
                }));
                console.log('features', features);
                const finalFeature = {
                    type: 'FeatureCollection',
                    features,
                }
                console.log('finalFeature', finalFeature);
                
                setLineCreate(finalFeature);
            })
        }
    }, [markersStart, markersEnd])

    const swapMarkers = () => {
        if (!markersStart || !markersEnd) {
            return;
        }
        const tempMarkerStart = markersStart;
        const tempMarkerEnd = markersEnd;
        formSample.setFieldsValue({
            srcLocation: `${tempMarkerEnd?.lat}, ${tempMarkerEnd?.lon}`,
            dstLocation: `${tempMarkerStart?.lat}, ${tempMarkerStart?.lon}`,
        })
        setMarkersStart({ ...markersStart, lat: tempMarkerEnd.lat, lon: tempMarkerEnd.lon, });
        setMarkersEnd({ ...markersEnd, lat: tempMarkerStart.lat, lon: tempMarkerStart.lon });
    };

    const onHover = useCallback((event: MapLayerMouseEvent) => {
        
        const {
            features,
            point: { x, y }
        } = event;
        const hoveredFeature = features && features[0];
        // if (hoveredFeature) {
        //     setHoverInfo(hoveredFeature && { feature: hoveredFeature, x, y });
        // }
    }, []);

    
    function updateFeatures(index: number, object: { features: any[]; }) {
        if (object.features && Array.isArray(object.features) && index >= 0 && index < object.features.length) {
          const updatedFeatures = object.features.map((feature: { properties: any; }, i: any) => {
            if (i === index) {
              return {
                ...feature,
                properties: {
                  ...feature.properties,
                  color: 'red'
                }
              };
            } else {
              return {
                ...feature,
                properties: {
                  ...feature.properties,
                  color: 'blue'
                }
              };
            }
          });
      
          const redIndex = updatedFeatures.findIndex((feature: { properties: any }) => feature.properties.color === 'red');

          // Move the feature with color "red" to the beginning of the array
          if (redIndex !== -1) {
            const redFeature = updatedFeatures.splice(redIndex, 1)[0];
            updatedFeatures.unshift(redFeature);
          }
          updatedFeatures.forEach((feature: { properties: any }, i: number) => {
            feature.properties.index = i;
          });
          return {
            ...object,
            features: updatedFeatures
          };
        }
      
        return object;
      }    
      const handleKeyUpStart = (event: any) => {

        if (event.keyCode === 13) {
            event.preventDefault();
            const CNLocation = formSample.getFieldValue('srcLocation');
            const regex = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/
            if (regex.test(CNLocation)) {
                formSample.setFields([
                    {
                        name: 'srcLocation',
                        errors: []
                    }
                ])
                const [lat, lon] = CNLocation.split(',').map(parseFloat);
                console.log(lat, lon);
                setMarkersStart({
                    ...markersStart,
                    lat,
                    lon
                })
                mapRef!.current?.flyTo({ center: [lon, lat], animate: false });
            } else formSample.setFields([
                {
                    name: 'srcLocation',
                    errors: ['Vui lòng nhập đúng định dạng']
                }
            ])
        }
    }
    const handleKeyUpEnd = (event: any) => {
        if (event.keyCode === 13) {
            const dstLocation = formSample.getFieldValue('dstLocation');
            const regex = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/
            if (regex.test(dstLocation)) {
                const [lat, lon] = dstLocation.split(',').map(parseFloat);
                console.log(lat, lon);
                formSample.setFields([
                    {
                        name: 'dstLocation',
                        errors: []
                    }
                ])
                setMarkersEnd({
                    lat,
                    lon,
                })
                mapRef!.current?.flyTo({ center: [lon, lat], animate: false });
            }
            else formSample.setFields([
                {
                    name: 'dstLocation',
                    errors: ['Vui lòng nhập đúng định dạng']
                }
            ])

        }
    }
    return (
        <div className='h-screen flex flex-row items-stretch' >
            {/* <h1>Grid System</h1> */}
            <div className='w-[26rem] p-2 overflow-auto'>
                <Form
                    form={formSample}
                    layout='vertical'
                    labelAlign='left'
                    labelCol={{ span: 16 }}
                >
                    <Col span={2}>
                        <Button className="bg-base text-white mb-4" onClick={swapMarkers}><SwapOutlined onKeyDown={undefined} /></Button>
                    </Col>
                    <Row gutter={2}>
                        <Col span={17}>
                            <Form.Item required name='srcLocation' >
                                <Input
                                    onKeyUp={handleKeyUpStart} 
                                    allowClear placeholder='Toạ độ start(vĩ độ-lat,kinh độ-lon)' />
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Button className="bg-base text-white w-24"
                                onClick={() => setMarkerType(CMARKER_TYPES.start)}>Chọn Start</Button>
                        </Col>
                    </Row>
                    <Row gutter={2}>
                        <Col span={17}>
                            <Form.Item required name='dstLocation'>
                                <Input
                                     onKeyUp={handleKeyUpEnd} 
                                    allowClear placeholder='Toạ độ end(vĩ độ-lat,kinh độ-lon)' />
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Button className="bg-base text-white w-24" onClick={() => setMarkerType(CMARKER_TYPES.end)}>Chọn End</Button>
                        </Col>
                    </Row>
                    <Button htmlType="submit" className="text-white bg-base">Render Route</Button>
                </Form>
            </div>
            <Map
                style={{ flex: 4 }}
                initialViewState={initialViewState}
                mapStyle={`mapbox://styles/mapbox/streets-v12`}
                ref={mapRef as React.Ref<MapRef>}
                mapboxAccessToken={MAPBOX_TOKEN}
                interactiveLayerIds={['search-route']}
                maxZoom={18}
                onMouseMove={onHover}
                onClick={(event) => {
                    const newMarker: CMarkerType = {
                        lat: event.lngLat.lat,
                        lon: event.lngLat.lng
                    };
                    const { features } = event;
                    const hoveredFeature = features && features[0];
                    if (hoveredFeature?.properties) {
                        
                        const { index } = hoveredFeature.properties;
                        setLineCreate(updateFeatures(index, lineCreate));
                    }
                    else if (markerType == CMARKER_TYPES.start) {
                        setMarkersStart({ ...newMarker, type: CMARKER_TYPES.start });
                        formSample.setFieldsValue({
                            srcLocation: `${event.lngLat.lat},${event.lngLat.lng}`
                        });
                    }
                    else if (markerType == CMARKER_TYPES.end) {
                        setMarkersEnd({ ...newMarker, type: CMARKER_TYPES.end });
                        formSample.setFieldsValue({
                            dstLocation: `${event.lngLat.lat},${event.lngLat.lng}`
                        })
                    }
                }}
            >
                {lineCreate && <Source id="grid" type="geojson" data={lineCreate}>
                    <Layer {...layerSearchRouteStyle} >
                    </Layer>
                </Source>}
                {markersStart && (<Marker
                    key={`marker-start`}
                    latitude={markersStart?.lat}
                    longitude={markersStart?.lon}
                    style={{ color: 'red' }}
                    draggable={true}
                    onDragEnd={(event) => {
                        setMarkersStart({ ...markersStart, lat: event.lngLat.lat, lon: event.lngLat.lng });
                        formSample.setFieldsValue({
                            srcLocation: `${event.lngLat.lat},${event.lngLat.lng}`
                        });
                    }}
                >
                    <div >
                        <img src='./pin-start.png'>
                        </img>
                    </div>
                </Marker>)}
                {markersEnd && (<Marker
                    key={`marker-end`}
                    latitude={markersEnd?.lat}
                    longitude={markersEnd?.lon}
                    onDragEnd={(event) => {
                        setMarkersEnd({ ...markersEnd, lat: event.lngLat.lat, lon: event.lngLat.lng })
                        formSample.setFieldsValue({
                            dstLocation: `${event.lngLat.lat},${event.lngLat.lng}`
                        })
                    }}
                    draggable={true}
                >
                    <div >
                        <img src='./pin-end.png'>
                        </img>
                    </div>
                </Marker>)}
            </Map>
        </div>
    )
}