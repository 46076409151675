import { useEffect, useMemo, useState } from "react";
import { Button, Image, Layout, Menu } from "antd";
import FeedbackList from "./pages/feedback";
import DataSystem from "./pages/data_system";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import RealtimeTraffic from "./pages/realtime_traffic";
import GpxGenerator from "./pages/gpx_generator";
import Playback from "./pages/playback";
import { Link, Redirect, Route, Switch, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Rank from "./pages/rank";
import GridSystem from "./pages/grid";
import Sample from "./pages/sample";
import OsrmData from "./pages/osrm_data";
import DetectSuspicious from "./pages/detect_suspicious";
import ViewGeometry from "./pages/view_geometry";
import DataSystemV2 from "./pages/data_systemV2";
import AIRoute from "./pages/ai_route";
const { Content } = Layout;
const MainApp = () => {
  const [collapsed, setCollapsed] = useState<boolean>(
    localStorage.getItem("collapsed") === "1"
  );
  const [activeKey, setActiveKey] = useState(
    localStorage.getItem("activeKey") || "1"
  );
  const [selectedMenu, setSelectedMenu] = useState(null);

  useEffect(() => {
    localStorage.setItem("collapsed", collapsed ? "1" : "0");
  }, [collapsed]);
  const history = useHistory();
  useEffect(() => {
    if (activeKey) {
      localStorage.setItem("activeKey", activeKey);
    }
  }, [activeKey]);

  const buttonCollapsed = useMemo<any>(() => {
    return (
      <Button
        onClick={() => setCollapsed && setCollapsed(!collapsed)}
        className={` ${
          !collapsed ? "top-16" : "top-4"
        } absolute right-1 z-[1000] bg-white w-1 flex justify-center items-center rounded-tl-12 border-0 border-end-start-radius-12 `}
        ghost
      >
        <FontAwesomeIcon
          color="#84A187"
          icon={!collapsed ? "angles-left" : "angles-right"}
        />
      </Button>
    );
  }, [collapsed]);

  return (
    <div>
      <div className="min-h-screen flex flex-row">
        <div className="bg-main sticky pt-4">
          {buttonCollapsed}
          {!collapsed && (
            <Image
              className="max-w-200 flex mx-2 justify-center bg-84A187"
              preview={false}
              src={"/logo.png"}
            />
          )}
          <Menu
            inlineCollapsed={collapsed}
            mode="inline"
            onClick={(e) => setActiveKey(e.key.toString())}
            className="bg-main text-lg pb-6 pt-6 content-around	text-white"
            theme="dark"
            activeKey={activeKey}
            defaultOpenKeys={["1"]}
          >
            <Menu.Item
              key="1"
              className="sider"
              icon={<FontAwesomeIcon icon="message" />}
            >
              <Link to={"/feedback"}>Góp ý</Link>
            </Menu.Item>
            <Menu.Item
              key="2"
              className="sider"
              icon={<FontAwesomeIcon icon="database"></FontAwesomeIcon>}
            >
              <Link to={"/DataSystem"}>Data hệ thống</Link>
            </Menu.Item>
            <Menu.Item
              key="12"
              className="sider"
              icon={<FontAwesomeIcon icon="database"></FontAwesomeIcon>}
            >
              <Link to={"/DataSystemV2"}>Data hệ thống V2</Link>
            </Menu.Item>
            {/* <Menu.Item key="3" className="sider" icon={<FontAwesomeIcon icon='road'></FontAwesomeIcon>
            } >
              <Link to={'/RealtimeTraffic'}>REALTIME TTGT</Link>
            </Menu.Item> */}
            <Menu.Item
              key="4"
              className="sider"
              icon={<FontAwesomeIcon icon="cloud-arrow-down"></FontAwesomeIcon>}
            >
              <Link to={"/GpxGenerator"}>Gpx Generator</Link>
            </Menu.Item>
            <Menu.Item
              key="5"
              className="sider"
              icon={<FontAwesomeIcon icon="route"></FontAwesomeIcon>}
            >
              <Link to={"/Rank"}>Rank</Link>
            </Menu.Item>
            {/* <Menu.Item key="5" className="sider" icon={<FontAwesomeIcon icon='map-location'></FontAwesomeIcon>} onClick={() => handleClick('5')}>
          VISUALIZE GPS
        </Menu.Item> */}
            <Menu.Item
              key="6"
              className="sider"
              icon={<FontAwesomeIcon icon="rotate-left"></FontAwesomeIcon>}
            >
              <Link to={"/Playback"}>Play Back</Link>
            </Menu.Item>
            {/* <Menu.Item key="7" className="sider" icon={<FontAwesomeIcon icon='border-none'></FontAwesomeIcon>}>
              <Link to={'/GridSystem'}>Grid System</Link>
            </Menu.Item> */}
            {/* <Menu.Item key="8" className="sider" icon={<FontAwesomeIcon icon='magnifying-glass-location'></FontAwesomeIcon>}>
              <Link to={'/SearchRoute'}>Search Route</Link>
            </Menu.Item> */}
            {/* <Menu.Item key="9" className="sider" icon={<FontAwesomeIcon icon='server'></FontAwesomeIcon>}>
              <Link to={'/OsrmData'}>Osrm Data</Link>
            </Menu.Item> */}
            {process.env.REACT_APP_ENV === "staging" && (
              <Menu.Item
                key="10"
                className="sider"
                icon={<FontAwesomeIcon icon="street-view"></FontAwesomeIcon>}
              >
                <Link to={"/ViewGeometry"}>View Geometry</Link>
              </Menu.Item>
            )}
            {process.env.REACT_APP_ENV === "staging" && (
              <Menu.Item
                key="11"
                className="sider"
                icon={<FontAwesomeIcon icon="ban"></FontAwesomeIcon>}
              >
                <Link to={"/DetectSuspicious"}>Detect Suspicious</Link>
              </Menu.Item>
            )}

            {/* </SubMenu> */}
            <Menu.Item
              key="13"
              className="sider"
              icon={
                <FontAwesomeIcon icon="magnifying-glass-location"></FontAwesomeIcon>
              }
            >
              <Link to={"/AIRoute"}>AI Route</Link>
            </Menu.Item>
          </Menu>
        </div>

        {/* </Layout.Sider> */}
        <Layout>
          <Content>
            <Switch>
              <Route path="/feedback" component={FeedbackList} />
              <Route
                path="/DataSystem/:Icategory?/:Iinstruction?/:Ilat?/:Ilon?/:Idirection?"
                component={DataSystem}
              ></Route>
              <Route
                path="/RealtimeTraffic/:Icategory?/:Icontent?/:Ilat?/:Ilon?/:Idirection?"
                component={RealtimeTraffic}
              />
              <Route path="/GpxGenerator" component={GpxGenerator} />
              <Route
                path="/Playback/:IcustomerPhone?/:Idate?/:IcustomerId?"
                component={Playback}
              />
              <Route path="/Rank" component={Rank} />
              <Route path="/GridSystem" component={GridSystem} />
              <Route path="/SearchRoute" component={Sample} />
              <Route path="/OsrmData" component={OsrmData} />
              <Route
                path="/DetectSuspicious/:IUserId?/:IStartTime?/:IEndTime?"
                component={DetectSuspicious}
              />
              <Route path="/ViewGeometry" component={ViewGeometry} />
              <Route
                path="/DataSystemV2/:Ilat?/:Ilon?/:Idirection?/:Itraffic_sign_id?"
                component={DataSystemV2}
              />
              <Route path="/AIRoute" component={AIRoute} />

              <Route
                path="/"
                exact={true}
                render={(props) => {
                  setActiveKey("1");
                  return <Redirect to={{ pathname: "/feedback" }}></Redirect>;
                }}
              ></Route>
            </Switch>
          </Content>
        </Layout>
      </div>
    </div>
  );
};

export default MainApp;
library.add(fas);
