import { LineLayer } from "react-map-gl";

export const layerFinalLineStyle: LineLayer = {
    id: 'final',
    type: 'line',
    paint: {
        'line-color': ['get', 'color'],
        'line-width': 8,
        'line-opacity': 0.5,
    },
    source: 'final',
    layout: {
        'line-join': 'round',
        'line-cap': 'round'
    },
};
export const layerGGMLineStyle: LineLayer = {
    id: 'raw',
    type: 'line',
    paint: {
        'line-color': ['get', 'color'],
        'line-width': 8,
        'line-opacity': 0.5,
    },
    source: 'raw',
    layout: {
        'line-join': 'round',
        'line-cap': 'round'
    },
};
export const layerTrimmedLineStyle: LineLayer = {
    id: 'trimmed',
    type: 'line',
    paint: {
        'line-color': ['get', 'color'],
        'line-width': 8,
        'line-opacity': 0.5,
    },
    source: 'trimmed',
    layout: {
        'line-join': 'round',
        'line-cap': 'round'
    },
};
export const layerMiniumLineStyle: LineLayer = {
    id: 'minimum',
    type: 'line',
    paint: {
        'line-color': ['get', 'color'],
        'line-width': 8,
        'line-opacity': 0.5,
    },
    source: 'minimum',
    layout: {
        'line-join': 'round',
        'line-cap': 'round'
    },
};