import { decode } from "@googlemaps/polyline-codec";
import { IVectorRoad } from "../../constant/type";
import { listVectorRoad } from "../../services";
import { message } from "antd";

export const sortOptionVectorRoadID = (data: IVectorRoad[]) => {
    const IoptionVectorRoadId = data.map((option: any) => {
        return {
            value: option.vector_road_id,
            label: option.vector_road_id,
            wayDirection: option.way_direction,
            vectorGeometry: option.vector_geometry,
        };
    }).filter((item) => item != null) as { value: string; label: string }[];

    IoptionVectorRoadId.sort((a, b) => {
        const aValue = a.value.toLowerCase();
        const bValue = b.value.toLowerCase();
        const aFirstPart = aValue.split('_')[0];
        const bFirstPart = bValue.split('_')[0];
        const aIsNumber = /^\d+$/.test(aFirstPart);
        const bIsNumber = /^\d+$/.test(bFirstPart);

        if (aIsNumber && !bIsNumber) {
            return 1;
        }
        if (!aIsNumber && bIsNumber) {
            return -1;
        }
        if (aValue < bValue) {
            return -1;
        }
        if (aValue > bValue) {
            return 1;
        }
        return 0;
    });
    return IoptionVectorRoadId;
}

export const handleCallListVectorRoad = (
    setOptionVectorRoadId: (value: any) => void,
    setPrevLine: (value: any) => void
  ) => {
    listVectorRoad().then((data) => {
      const lineStringArray = data.data.map((item: any) => {
        const coordinates = decode(item.vector_geometry).map((coord: any) => [coord[1], coord[0]]);        
        return {
          type: 'Feature',
          properties: {
            id: item.id,
            vector_road_id: item.vector_road_id,
            way_direction: item.way_direction,
            vector_geometry: item.vector_geometry,
          },
          geometry: {
            type: 'LineString',
            coordinates,
          },
        };
      });  
      const IoptionVectorRoadId = sortOptionVectorRoadID(data.data);
  
      setOptionVectorRoadId(IoptionVectorRoadId);
  
      const lineString = {
        type: 'FeatureCollection',
        features: lineStringArray,
      };
      setPrevLine(lineString);
    });
};


 export const handleCopyClick = (text: any) => {
    if (text) {
        navigator.clipboard.writeText(text)
            .then(() => {
                message.success('Copy thành công');
            })
            .catch((error) => {
                message.error('Copy thất bại');
                console.error('Copy thất bại', error);
            });
    }
}
