import React, { useCallback, useEffect, useState } from "react";
import { Layer, Map, MapLayerMouseEvent, MapRef, MapboxStyle, Marker, Source } from "react-map-gl";
import { APP_COLOR, IMARKER_TYPES, MAPBOX_TOKEN, MAP_STYLE, initialViewState, layerGeohashStyle, lineRelation, optionPointV2 } from "../../constant";
import { IAddMultiplePointV2, IResponseDataPointV2, MarkerType } from "../../constant/type";
import geohash from "../../helper/EncodeGeohash";
import { addMultiplePointV2, deletePointV2, getDataPoint, getDataPointAiRoadID, getNominatim, listVectorRoad, searchNominatim, updatePointV2 } from "../../services";
import { Modal, Spin, Tabs, TabsProps, message } from "antd";
import { useForm } from "antd/es/form/Form";
import { sortOptionVectorRoadID } from "../gpx_generator/helper";
import './index.css'
import { getDirectionAndAngle, getDirectionFromAngle, getPointB } from "../../helper/LocationHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CreatePointV2 } from "./component/CreatePointV2";
import { filterDataByRelation, getIndexById, getRelation, popUpDefaultValue } from "./helper";
import { useParams } from 'react-router-dom';
import { ViewPointV2 } from "./component/ViewPointV2";
import PopupComponent from "./component/PopupComponent";
import { UpdatePointV2 } from "./component/UpdatePointV2";
import { DeleteUpdateMultiple } from "./component/DeleteUpdateMultiple";
import MarkerCustom from "./component/MarkerCustom";

export default function DataSystemV2() {
    const mapRef = React.useRef<MapRef>(null);
    const [multipleSelect, setMultipleSelect] = useState<any>([]);
    const [points, setPoints] = useState<any>([]);
    const [ImarkerType, setIMarkerType] = useState<string>(IMARKER_TYPES.normal)
    const [popupOpen, setPopupOpen] = useState<any>(popUpDefaultValue);
    const [listMarker, setListMarker] = useState<any>([]);
    const [responseDataPoint, setResponseDataPoint] = useState<any>([]);
    const [gridGeohash, setGridGeohash] = useState<any>()
    const [relationLine, setRelationLine] = useState<any>()
    const [markersEnd, setMarkersEnd] = useState<MarkerType>();
    const [selectAiRoadId, setSelectAIRoadId] = useState<any>();
    const [optionVectorRoadId, setOptionVectorRoadId] = useState<any>([]);
    const [markerCreate, setMarkerCreate] = useState<any>();
    const [kindOfPoint, setKindOfPoint] = useState<any>(null);
    const [activeKey, setActiveKey] = useState('1');
    const [geohashEncoded, setGeohashEncoded] = useState<any>()
    const [markerCenter, setMarkerCenter] = useState<any>();
    const [indexFormFocus, setIndexFormFocus] = useState(0);
    //Hook for control form
    const [formView] = useForm();
    const [formUpdate] = useForm();
    const [formCreate] = useForm();
    const [formEditMultiple] = useForm();
    //hook for get params from url
    const { Ilat, Ilon, Idirection, Itraffic_sign_id }: any = useParams();
    React.useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                if (Ilat && Ilon && Idirection && Itraffic_sign_id) {
                    setActiveKey('2');
                    setIMarkerType(IMARKER_TYPES.normal);

                    const compass = getDirectionFromAngle(Idirection);

                    const osm_id = (await searchNominatim({ lat: Ilat, lon: Ilon, format: 'json' })).data.osm_id;
                    const geohashEncoded = geohash.encode(Ilat, Ilon, 6);
                    setGeohashEncoded(geohashEncoded);

                    const dataRelation = await getDataPoint({ currentLocation: geohashEncoded });
                    const relationOfSelectedPoint = getRelation(osm_id, dataRelation.data);
                    const PLoctionEnd = getPointB({ lat: Number(Ilat), lon: Number(Ilon) }, Number(Idirection))
                    setMarkerCreate({ lat: Ilat, lon: Ilon });
                    mapRef!.current?.flyTo({ center: [Number(Ilon), Number(Ilat)], animate: false });
                    setMarkersEnd({ lat: PLoctionEnd.lat, lon: PLoctionEnd.lon })
                    formCreate.setFieldsValue({
                        data: [{
                            PLocation: `${Ilat},${Ilon}`,
                            osm_id: osm_id,
                            ai_road_id: relationOfSelectedPoint,
                            direction: Idirection,
                            compass: compass,
                            traffic_sign_id: Itraffic_sign_id,
                            // PLocationEnd: `${PLoctionEnd.lat},${PLoctionEnd.lon}`
                        }],
                    });
                }
            } catch (error) {
                console.error('Error:', error);
            } finally {
                setIsLoading(false); // Stop loading
            }
        };

        fetchData();
    }, [Itraffic_sign_id, Ilon, Ilat, Idirection]);
    React.useEffect(() => {

        //Caculate direction and angle when update point
        if (activeKey == '4') {
            if (markersEnd) {
                const [lat, lon] = formUpdate.getFieldValue('ELocation') && formUpdate.getFieldValue('ELocation').split(',').map(parseFloat);
                const marketStartUpdate = { lat, lon };
                if (markersEnd && marketStartUpdate) {
                    const { compass, direction } = getDirectionAndAngle(marketStartUpdate, markersEnd);
                    formUpdate.setFieldsValue({
                        compass: compass,
                        direction: direction,
                    })
                }
            }
            //Caculate direction and angle when create point
        } else if (activeKey == '2') {
            if (markersEnd) {
                let data = formCreate.getFieldValue('data');
                if (data && Array.isArray(data) && data.length) {
                    // Check If firstName exists at that `index`
                    const PLocation = data[indexFormFocus]?.PLocation;
                    if (PLocation) {
                        const regex = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/
                        if (regex.test(PLocation)) {
                            const [lat, lon] = PLocation.split(',').map(parseFloat);
                            //location of user click
                            const markersStart = { lat, lon };
                            if (markersEnd && markersStart) {
                                const { compass, direction } = getDirectionAndAngle(markersStart, markersEnd);
                                Object.assign(data[indexFormFocus], { compass: compass, direction: direction, PLocationEnd: `${markersEnd.lat},${markersEnd.lon}` })
                                formCreate.setFieldsValue({ data: data });
                            }
                        }
                    }
                }
            }
        }


    }, [markersEnd, activeKey, indexFormFocus, markerCreate])

    const getData = async (data: any) => {
        const res = await getDataPoint(data)
        const gridGeoHashData: { type: string; features: any[] } = {
            type: "FeatureCollection",
            features: [],
        };
        const IlistMarker = res.data.flatMap((item: IResponseDataPointV2) => {
            const bboxEncoded = geohash.decode_bbox(item.geohash)
            const [minLat, minLon, maxLat, maxLon] = bboxEncoded;

            const rectangleGeoJSON = {
                type: 'Feature',
                geometry: {
                    type: 'Polygon',
                    coordinates: [
                        [
                            [minLon, minLat],
                            [minLon, maxLat],
                            [maxLon, maxLat],
                            [maxLon, minLat],
                            [minLon, minLat]
                        ]
                    ]
                }
            };
            gridGeoHashData.features.push(rectangleGeoJSON);
            return item.osm.flatMap((item_osm) =>
                item_osm.datapoint.map((item_datapoint) => ({
                    lat: item_datapoint.lat,
                    lon: item_datapoint.lon,
                    traffic_sign_id: item_datapoint.traffic_sign_id,
                    osm_id: item_datapoint.osm_id,
                    direction: item_datapoint.direction,
                    compass: item_datapoint.compass,
                    relation: item_osm.relation,
                    id: item_datapoint.id,
                    ai_road_id: item_datapoint.ai_road_id,
                }))
            )
        });
        const existPoint = IlistMarker.map((point: any) => {
            return point.traffic_sign_id
        })
        const uniqueExistPoint = Array.from(new Set(existPoint)).sort((a, b) => (a as number) - (b as number));;
        const filteredOptionPoint = optionPointV2.filter((option) => uniqueExistPoint.includes(Number(option.value)));
        setKindOfPoint(filteredOptionPoint)
        setListMarker(IlistMarker)
        setGridGeohash(gridGeoHashData)
        setPoints(IlistMarker)
        setResponseDataPoint(res.data)
    };
    const handleFormViewFinish = async (data: any) => {
        const [lat, lon] = data.VLocation.trim().split(',').map(parseFloat);
        setMarkerCenter({ lat, lon });

        mapRef!.current?.flyTo({ center: [lon, lat], animate: false });
        const geohashEncoded = geohash.encode(lat, lon, 6);
        await getData({ currentLocation: geohashEncoded })
    }
    const [isLoading, setIsLoading] = useState(false);

    const modifyCreateForm = async (lat: number, lon: number) => {
        setIsLoading(true); // Start loading
        try {
            let data = formCreate.getFieldValue('data');
            const osm_id = (await searchNominatim({ lat: lat, lon: lon, format: 'json' })).data.osm_id;
            const geohashEncode = geohashEncoded ? geohashEncoded : geohash.encode(lat, lon, 6);
            const dataRelation = await getDataPoint({ currentLocation: geohashEncode })
            const relationOfSelectedPoint = getRelation(osm_id, dataRelation.data)
            if (data && Array.isArray(data) && data.length) {
                const indexOfSelectItem = getIndexById(data, indexFormFocus);
                // Check If firstName exists at that `index`
                if (indexOfSelectItem != -1 && data[indexOfSelectItem]?.PLocation) {
                    data[indexOfSelectItem].PLocation = `${lat},${lon}`;
                    data[indexOfSelectItem].osm_id = osm_id;
                    data[indexOfSelectItem].ai_road_id = relationOfSelectedPoint;
                    formCreate.setFieldsValue({ data: data });
                } else if (data[indexOfSelectItem] && !data[indexOfSelectItem]?.PLocation) {
                    Object.assign(data[indexOfSelectItem], { PLocation: `${lat},${lon}` })
                    Object.assign(data[indexOfSelectItem], { osm_id: osm_id })
                    Object.assign(data[indexOfSelectItem], { ai_road_id: relationOfSelectedPoint })
                    formCreate.setFieldsValue({ data: data });
                } else if (!data[indexOfSelectItem]) {
                    data[indexOfSelectItem] = { PLocation: `${lat},${lon}`, osm_id: osm_id, ai_road_id: relationOfSelectedPoint };
                    formCreate.setFieldsValue({ data: data });
                }
            } else if (!data) {
                formCreate.setFieldsValue({ data: [{ PLocation: `${lat},${lon}`, osm_id: osm_id, ai_road_id: relationOfSelectedPoint }] });
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsLoading(false); // Stop loading
        }
    }

    const onClickMap = useCallback(async (event: MapLayerMouseEvent) => {
        setIsLoading(true); // Start loading
        try {
            if (ImarkerType == IMARKER_TYPES.normal) {
                setSelectAIRoadId(undefined);
                const newMarker: MarkerType = {
                    lat: event.lngLat.lat,
                    lon: event.lngLat.lng,
                };
                setMarkerCenter(newMarker);
                mapRef!.current?.flyTo({ center: [event.lngLat.lng, event.lngLat.lat], animate: false });
                formView.resetFields(['id']);
                formView.setFieldValue('VLocation', `${newMarker.lat},${newMarker.lon}`);
                const geohashEncoded = geohash.encode(newMarker.lat, newMarker.lon, 6);
                setGeohashEncoded(geohashEncoded)
                await getData({ currentLocation: geohashEncoded })
            } else if (ImarkerType == IMARKER_TYPES.endUpdate) {
                setMarkersEnd({ lat: event.lngLat.lat, lon: event.lngLat.lng });
                setIMarkerType(IMARKER_TYPES.normal);
            } 
            else if (ImarkerType == IMARKER_TYPES.create) {
                setIMarkerType(IMARKER_TYPES.normal);
                //find relation from geohash
                setMarkerCreate({ lat: event.lngLat.lat, lon: event.lngLat.lng });
                modifyCreateForm(event.lngLat.lat, event.lngLat.lng);

            }
        }
        catch (error) {
            console.error('Error:', error);
        } finally {
            setIsLoading(false); // Stop loading
        }

    }, [ImarkerType, indexFormFocus]);
    useEffect(() => {
        let data = formCreate.getFieldValue('data');
        if (data && Array.isArray(data) && data.length) {
            const indexOfSelectItem = getIndexById(data, indexFormFocus);
            // Check If firstName exists at that `index`
            if (indexOfSelectItem != -1 && data[indexOfSelectItem]?.PLocation) {
                const regex = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/
                const PLocation = data[indexOfSelectItem].PLocation;
                if (regex.test(PLocation)) {
                    const [lat, lon] = PLocation.split(',').map(parseFloat);
                    setMarkerCreate({ lat, lon });
                    // formCreate.setFieldsValue({ data: data });
                }
            }
        }
    }, [indexFormFocus]);
    const handleClearFilter = async () => {
        formView.resetFields(['id']);
        setPoints(listMarker)
    }
    const pins = points && points.map((point: any, index: any) => <MarkerCustom
        multipleSelect={multipleSelect}
        index={index}
        point={point}
        setMultipleSelect={setMultipleSelect}
        setPopupOpen={setPopupOpen}
        activeKey={activeKey}
        popupOpen={popupOpen}
    ></MarkerCustom>);

    const onGetNomination = async (osm_id: string) => {
        const geometry = await getNominatim({ osmid: osm_id })
        return geometry.data.geometry
    }

    const handleKeyUp = (event: any) => {
        if (event.keyCode === 13) {
            formView.submit();
        }
    }

    React.useEffect(() => {
        listVectorRoad().then((data) => {
            const IoptionVectorRoadId = sortOptionVectorRoadID(data.data);
            setOptionVectorRoadId(IoptionVectorRoadId);
        });
    }, [])

    const handleUpdatePoint = (data: any) => {
        const [lat, lon] = data.ELocation.split(',').map(parseFloat);
        const finalData = {
            lat: lat,
            lon: lon,
            id: data.id,
            ai_road_id: data.ai_road_id,
            direction: data.direction,
            compass: data.compass,
            traffic_sign_id: Number(data.traffic_sign_id),
            osm_id: data.osm_id,
        }
        
        updatePointV2(finalData).then(async (res) => {
            if (res.status === 200) {
                message.success('Cập nhật thành công');
                setMarkersEnd(undefined);
                setIMarkerType(IMARKER_TYPES.normal);
                setActiveKey('1');
                formUpdate.resetFields();
                setPopupOpen(popUpDefaultValue);
                await getData({ currentLocation: geohashEncoded });
            }
        });
    }

    const handleDeleteMapPoint = useCallback((id: any[]) => {
        Modal.confirm({
            title: 'Cảnh báo',
            content: 'Bạn có muốn xoá điểm này không?',
            onOk: () => {
                deletePointV2({ id }).then(async (res) => {
                    if (res.status === 200) {
                        if (id.length > 1) {
                            setMultipleSelect([]);
                        }
                        setPopupOpen({ ...popupOpen, display: false });
                        message.success('Xóa thành công');
                        await getData({ currentLocation: geohashEncoded });
                    }
                })
            }
        });
    }, [geohashEncoded]);

    const onAddMultiplePoint = async (data: any) => {
        if (!data || !data?.data

            || (data && data.data && data.data.length == 0)) {
            message.info('Không có dữ liệu');
            return;
        }

        const output: IAddMultiplePointV2 = data.data.map((item: {
                id: number;
                PLocation: string;
                osm_id: number;
                ai_road_id: string;
                compass: string;
                direction: number;
                traffic_sign_id: any;
            }) => ({
                "lat": item.PLocation.split(",")[0],
                "lon": item.PLocation.split(",")[1],
                "osm_id": item.osm_id,
                "ai_road_id": item.ai_road_id,
                "compass": item.compass,
                "direction": item.direction,
                "traffic_sign_id": Number(item.traffic_sign_id),

            }));
        const resAddMultiplePoint = await addMultiplePointV2(output);
        if (resAddMultiplePoint.status === 200) {
            formCreate.setFieldsValue({ data: [] });
            setIMarkerType(IMARKER_TYPES.normal);
            setMarkersEnd(undefined);
            setMarkerCreate(undefined);
            setPopupOpen({ ...popupOpen, display: false });
            await getData({ currentLocation: geohashEncoded });
        }
    }

    const itemsTab: TabsProps['items'] = [
        {
            key: '1',
            label: `View Điểm`,
            children: <ViewPointV2
                handleFormViewFinish={handleFormViewFinish}
                formView={formView}
                handleKeyUp={handleKeyUp}
                setPoints={setPoints}
                listMarker={listMarker}
                setIMarkerType={setIMarkerType}
                kindOfPoint={kindOfPoint}
                handleClearFilter={handleClearFilter}
                getDataPointAiRoadID={getDataPointAiRoadID}
                geohashEncoded={geohashEncoded}
                setGridGeohash={setGridGeohash}
                mapRef={mapRef}
                setSelectAIRoadId={setSelectAIRoadId}
                selectAiRoadId={selectAiRoadId}
                optionVectorRoadId={optionVectorRoadId}
            />,
        },
        {
            key: '2',
            label: `Thêm điểm`,
            children: <CreatePointV2 onFinish={onAddMultiplePoint}
                optionPointV2={optionPointV2}
                optionVectorRoadId={optionVectorRoadId}
                indexFormFocus={indexFormFocus}
                setIndexFormFocus={setIndexFormFocus}
                formCreate={formCreate}
                setIMarkerType={setIMarkerType}
                setMarkersEnd={setMarkersEnd}
                setMarkerCreate={setMarkerCreate}
                setIsLoading={setIsLoading}
            ></CreatePointV2>
        },
        {
            key: '4',
            label: `Cập nhật Điểm`,
            children: <UpdatePointV2
                handleUpdatePoint={handleUpdatePoint}
                formUpdate={formUpdate}
                setIMarkerType={setIMarkerType}
                optionVectorRoadId={optionVectorRoadId}
                optionPointV2={optionPointV2}
                setMarkersEnd={setMarkersEnd}
            ></UpdatePointV2>
        },
        {
            key: '3',
            label: `Xoá/sửa nhiều điểm`,
            children: <DeleteUpdateMultiple
                multipleSelect={multipleSelect}
                formEditMultiple={formEditMultiple}
                setMultipleSelect={setMultipleSelect}
                optionPointV2={optionPointV2}
                getData={getData}
                handleDeleteMapPoint={handleDeleteMapPoint}
                setPopupOpen={setPopupOpen}
                updatePointV2={updatePointV2}
                geohashEncoded={geohashEncoded}
                popupOpen={popupOpen}
            ></DeleteUpdateMultiple>,
        },


    ];
    const onChangeTabs = (key: string) => {
        setActiveKey(key);
        if (key == '1') {
            formView.resetFields(['VLocation', 'id']);
            setSelectAIRoadId(undefined);
            setIMarkerType(IMARKER_TYPES.normal);

        } else if (key == '4') {
            formUpdate.resetFields();
            //set marker type when update point to change location in form and not call another logic
            setIMarkerType(IMARKER_TYPES.start);
        } else if (key == '3') {
            setIMarkerType(IMARKER_TYPES.marker);
        } else if (key == '2') {
            setIMarkerType(IMARKER_TYPES.normal);
        }
        setGridGeohash(undefined);
        setRelationLine(undefined);
        setMultipleSelect([]);
        setMarkersEnd(undefined);
        setMarkerCreate(undefined);
        setMarkerCenter(undefined);
        setPopupOpen(popUpDefaultValue);
    };
    return (
        <div className='h-screen flex flex-row items-stretch' >
            <div className='w-[26rem] p-2 overflow-auto'>
                <Tabs className='overflow-auto' defaultActiveKey="1" activeKey={activeKey} items={itemsTab} onChange={onChangeTabs} />
            </div>
            <Spin spinning={isLoading} fullscreen={true} />
            <Map
                style={{ flex: 4 }}
                ref={mapRef as React.Ref<MapRef>}
                initialViewState={{ ...initialViewState }}
                mapStyle={MAP_STYLE as MapboxStyle}
                onClick={onClickMap}
                mapboxAccessToken={MAPBOX_TOKEN}
                interactiveLayerIds={['nonexist']}
                maxZoom={24}
            >
                {gridGeohash && <Source id="grid" type="geojson" data={gridGeohash}>
                    <Layer {...layerGeohashStyle} >
                    </Layer>
                </Source>}
                {relationLine && <Source id="lineRelation" type="geojson" data={relationLine}>
                    <Layer {...lineRelation} >
                    </Layer>
                </Source>}
                {
                    markerCenter && <Marker
                        latitude={markerCenter.lat}
                        longitude={markerCenter.lon}
                    >
                        <FontAwesomeIcon icon='location-crosshairs' className='w-8 h-8'></FontAwesomeIcon>
                    </Marker>
                }
                {pins}
                {markersEnd && (<Marker
                    key={`marker-end`}
                    latitude={markersEnd?.lat}
                    longitude={markersEnd?.lon}
                    onDragEnd={(event) => {
                        setMarkersEnd({ lat: event.lngLat.lat, lon: event.lngLat.lng })
                    }}
                    draggable={true}
                >
                    <FontAwesomeIcon icon='location-dot' className='w-8 h-8'></FontAwesomeIcon>

                </Marker>)}
                {
                    markerCreate && <Marker latitude={markerCreate?.lat}
                        longitude={markerCreate?.lon}
                        onDragEnd={(event) => {
                            modifyCreateForm(event.lngLat.lat, event.lngLat.lng);
                            setMarkerCreate({ lat: event.lngLat.lat, lon: event.lngLat.lng })
                        }}
                        draggable={true}
                    >
                        <FontAwesomeIcon icon='location-dot' color={APP_COLOR.base} className='w-8 h-8'></FontAwesomeIcon>


                    </Marker>
                }
                {popupOpen.display && <PopupComponent
                    setPopupOpen={setPopupOpen}
                    popupOpen={popupOpen}
                    optionPointV2={optionPointV2}
                    activeKey={activeKey}
                    onGetNomination={onGetNomination}
                    setRelationLine={setRelationLine}
                    filterDataByRelation={filterDataByRelation}
                    responseDataPoint={responseDataPoint}
                    setActiveKey={setActiveKey}
                    formUpdate={formUpdate}
                    setIMarkerType={setIMarkerType}
                    setMarkersEnd={setMarkersEnd}
                    getPointB={getPointB}
                    handleDeleteMapPoint={handleDeleteMapPoint}
                ></PopupComponent>}
            </Map>
        </div>
    );
}