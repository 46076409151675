import { Button, Col, Form, FormInstance, Input, Row, Select, message } from "antd";
import { FC } from "react"
import { APP_COLOR, IMARKER_TYPES } from "../../../constant";
import { MapPoint, MarkerType, PopupState, ValueOfSign } from "../../../constant/type";
import { updateSpeedPoint } from "../../../services";

interface PProps {
    formUpdate: FormInstance<any>,
    valueOfSign: ValueOfSign,
    setMarkersEndUpdate: React.Dispatch<React.SetStateAction<MarkerType | undefined>>,
    setIMarkerType: React.Dispatch<React.SetStateAction<string>>,
    points: any,
    popupOpen: PopupState,
    setPoints: (value: any) => void,
    setPopupOpen: (value: React.SetStateAction<PopupState>) => void,
    onChangeTabs: (key: string) => void
}

export const UpdatePoint: FC<PProps> = ({
    formUpdate,
    valueOfSign,
    setMarkersEndUpdate,
    setIMarkerType,
    points,
    popupOpen,
    setPoints,
    setPopupOpen,
    onChangeTabs
}) => {
    const handleUpdatePoint = (data: any) => {
        const [lat, lon] = data.ELocation.split(',').map(parseFloat);

        const finalData = {
            id: data.id,
            category: data.category,
            ELocation: data.ELocation,
            direction: data.direction,
            compass: data.compass,
            name: data.name ? data.name : 'null',
            ai_road_id: data.ai_road_id ? data.ai_road_id : 'ai_road_id_other',
            osm_combination_id: data.osm_combination_id,
            lat: lat,
            lon: lon,
            max_speed: data.max_speed,
        }
        const { ELocation, ...updatedFinalData } = finalData;
        const dataSpeed = data.sta_cty ? { ...updatedFinalData, sta_cty: data.sta_cty } :
            updatedFinalData;
        updateSpeedPoint(dataSpeed).then((res: any) => {
            if (res.status == 200) {
                message.success('Cập nhật thành công');
                const afterUpdatePoint = points.map((point: MapPoint) => {
                    if (point.id == Number(popupOpen.id)) {
                        return res.data.data;
                    }
                    else return point;
                })
                setPoints(afterUpdatePoint);
                setPopupOpen({ ...popupOpen, display: false });
                onChangeTabs('1');
            }
        })

    }

    return <div>
        <Form onFinish={handleUpdatePoint} form={formUpdate} layout='vertical'>
            <Form.Item name='id' label='id' hidden />
            <Form.Item name='category' label='category' hidden />
            <Form.Item name='ELocation' rules={[
                {
                    required: true,
                    validateTrigger: 'onFinish',
                    message: 'Vui lòng chọn/nhập toạ độ'
                },
            ]} >
                <Input allowClear placeholder='Nhập toạ độ (vĩ độ - lat, kinh độ - lon)' />
            </Form.Item>
            <Row gutter={4}>
                <Col span={10}>
                    <Form.Item rules={[{
                        required: true,
                        validateTrigger: 'onFinish',
                        type: 'number'
                    },
                    ]} name='direction'>
                        <Input placeholder='Góc' allowClear />
                    </Form.Item>
                </Col>
                <Col span={10}>
                    <Form.Item rules={[{
                        required: true,
                        validateTrigger: 'onFinish',
                    },
                    ]} name='compass' >
                        <Input placeholder='Hướng' allowClear />
                    </Form.Item>
                </Col>
                <Col span={2}>
                    <Button className="bg-base text-white ml-2" onClick={() => setIMarkerType(IMARKER_TYPES.endUpdate)}>End</Button>
                </Col>
            </Row>
            {/* <Row gutter={4}> */}
                <Col>
                    <Form.Item name='name' label='Tên đường' >
                        <Input placeholder='Tên đường' allowClear />
                    </Form.Item>
                </Col>
                <Col>
                    <Form.Item name='sta_cty' hidden label='Thành phố'
                    // rules={[{
                    //     required: true,
                    //     validateTrigger: 'onFinish',
                    //     message: <>
                    //         Hãy chọn 1 điểm nằm<br />
                    //         trên đường.
                    //     </>
                    // },
                    // ]}
                    >
                        <Input allowClear placeholder='Tên thành phố' />
                    </Form.Item>
                </Col>
            {/* </Row> */}
            <Form.Item name='ai_road_id' label='AI ROAD ID' >
                <Input allowClear placeholder='AI ROAD ID' />
            </Form.Item>
            <Form.Item name='osm_combination_id' label='Osm combination id' rules={[{
                required: true,
                validateTrigger: 'onFinish',
                message: 'Vui lòng chọn một điểm nằm trên tên đường để hiện Osm combination id'
            },
            ]} >
                <Input allowClear placeholder='Osm combination id' />
            </Form.Item>
            <Form.Item name='max_speed' label='Giá trị biển'>
                <Select options={valueOfSign['speed']} ></Select>
            </Form.Item>
            <div className="flex flex-row justify-end	">
                <Button htmlType='submit' className="bg-base text-white mr-2">Cập nhật</Button>
                <Button onClick={() => {
                    formUpdate.resetFields();
                    setMarkersEndUpdate(undefined);
                }}
                    className="bg-warning text-white">Huỷ bỏ</Button>
            </div>
        </Form>

    </div>
}