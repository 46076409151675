import { Layer, Map, Source, MapRef, Marker, LineLayer } from "react-map-gl";
import { CMARKER_TYPES, MAPBOX_TOKEN, initialViewState } from "../../constant";
import { Button, Checkbox, Col, Form, Input, Row, message } from "antd";
import { useEffect, useMemo, useRef, useState } from "react";
import { CMarkerType } from "../../constant/type";
import { useForm } from "antd/es/form/Form";
import axios from "axios";
import { SwapOutlined } from "@ant-design/icons";
import { extractRoute } from "../../helper/LocationHelper";
import { layerFinalLineStyle, layerGGMLineStyle, layerMiniumLineStyle, layerTrimmedLineStyle } from "./constant";
const BASE_URL_CRAWL = "http://127.0.0.1:8000"
export default function AIRoute() {
    const [lineFinal, setFinalLine] = useState<any>();
    const [lineRaw, setLineRaw] = useState<any>();
    const [lineTrimmed, setLineTrimmed] = useState<any>();
    const [lineMinimum, setLineMinimum] = useState<any>();
    const [markerType, setMarkerType] = useState<string>(CMARKER_TYPES.start)
    const [markersStart, setMarkersStart] = useState<CMarkerType>();
    const [markersEnd, setMarkersEnd] = useState<CMarkerType>();
    const mapRef = useRef<MapRef>(null);
    const [formSample] = useForm();
    const [value, setValue] = useState("final");
    const [mode, changeMode] = useState('production');
    const [routeNumber, setRouteNumber] = useState(0);
    const onChange = (checkedValues: any) => {
        setValue(checkedValues.target.value);
    };
    const [isShowMarker, setIsShowMarker] = useState(false);
    const onChangeRouteNumber = (checkedValues: any) => {
        setRouteNumber(checkedValues.target.value);
    };
    useEffect(() => {
        if (markersStart && markersEnd) {
            mode == 'production' ? callFinalCrawl() :
                // axios.get(`http://localhost:8686/crawl`, {
                axios.get(`${BASE_URL_CRAWL}/crawl`, {
                    params: {
                        dstLat: markersEnd?.lat,
                        dstLon: markersEnd?.lon,
                        srcLat: markersStart?.lat,
                        srcLon: markersStart?.lon,
                    }
                }).then(res => {
                    message.success('Tính toán thành công');
                    const { finalCollectionLineString, ggmapRoutesArray, trimmedRouteArray, mappingRouteArray, minimumRouteArray } = res.data
                    setFinalLine(finalCollectionLineString);
                    setLineRaw(ggmapRoutesArray);
                    setLineTrimmed(trimmedRouteArray);
                    setLineMinimum(minimumRouteArray);
                })
        }
    }, [markersStart, markersEnd, mode])

    const swapMarkers = () => {
        if (!markersStart || !markersEnd) {
            return;
        }
        const tempMarkerStart = markersStart;
        const tempMarkerEnd = markersEnd;
        formSample.setFieldsValue({
            srcLocation: `${tempMarkerEnd?.lat}, ${tempMarkerEnd?.lon}`,
            dstLocation: `${tempMarkerStart?.lat}, ${tempMarkerStart?.lon}`,
        })
        setMarkersStart({ ...markersStart, lat: tempMarkerEnd.lat, lon: tempMarkerEnd.lon, });
        setMarkersEnd({ ...markersEnd, lat: tempMarkerStart.lat, lon: tempMarkerStart.lon });
    };

    function updateFeatures(index: number, object: { features: any[]; }) {
        if (object.features && Array.isArray(object.features) && index >= 0 && index < object.features.length) {
            const updatedFeatures = object.features.map((feature: { properties: any; }, i: any) => {
                if (i === index) {
                    return {
                        ...feature,
                        properties: {
                            ...feature.properties,
                            color: 'red'
                        }
                    };
                } else {
                    return {
                        ...feature,
                        properties: {
                            ...feature.properties,
                            color: 'blue'
                        }
                    };
                }
            });

            const redIndex = updatedFeatures.findIndex((feature: { properties: any }) => feature.properties.color === 'red');

            // Move the feature with color "red" to the beginning of the array
            if (redIndex !== -1) {
                const redFeature = updatedFeatures.splice(redIndex, 1)[0];
                updatedFeatures.unshift(redFeature);
            }
            updatedFeatures.forEach((feature: { properties: any }, i: number) => {
                feature.properties.index = i;
            });
            return {
                ...object,
                features: updatedFeatures
            };
        }

        return object;
    }
    const handleKeyUpStart = (event: any) => {

        if (event.keyCode === 13) {
            event.preventDefault();
            const CNLocation = formSample.getFieldValue('srcLocation');
            const regex = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/
            if (regex.test(CNLocation)) {
                formSample.setFields([
                    {
                        name: 'srcLocation',
                        errors: []
                    }
                ])
                const [lat, lon] = CNLocation.split(',').map(parseFloat);
                setMarkersStart({
                    ...markersStart,
                    lat,
                    lon
                })
                mapRef!.current?.flyTo({ center: [lon, lat], animate: false });
            } else formSample.setFields([
                {
                    name: 'srcLocation',
                    errors: ['Vui lòng nhập đúng định dạng']
                }
            ])
        }
    }

    const handleKeyUpEnd = (event: any) => {
        if (event.keyCode === 13) {
            const dstLocation = formSample.getFieldValue('dstLocation');
            const regex = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/
            if (regex.test(dstLocation)) {
                const [lat, lon] = dstLocation.split(',').map(parseFloat);
                formSample.setFields([
                    {
                        name: 'dstLocation',
                        errors: []
                    }
                ])
                setMarkersEnd({
                    lat,
                    lon,
                })
                mapRef!.current?.flyTo({ center: [lon, lat], animate: false });
            }
            else formSample.setFields([
                {
                    name: 'dstLocation',
                    errors: ['Vui lòng nhập đúng định dạng']
                }
            ])

        }
    }

    const ButtonUrl = useMemo(() => {
        const dstLat = markersEnd?.lat
        const dstLon = markersEnd?.lon
        const srcLat = markersStart?.lat
        const srcLon = markersStart?.lon
        console.log(`https://www.google.com/maps/preview?saddr=${srcLat},${srcLon}&daddr=${dstLat},${dstLon}&dirflg=t`)

        return markersStart && markersEnd && <a href={`https://www.google.com/maps/preview?saddr=${srcLat},${srcLon}&daddr=${dstLat},${dstLon}&dirflg=t`} target="_blank" >Google Map</a>
    }, [markersStart, markersEnd])

    const category = [
        { label: "Final Crawl", value: "final" },
        { label: "Line lấy từ googleMap", value: "raw" },
        { label: "Line đã lọc các điểm", value: "trimmed" },
        { label: "Line nạp vào Valhalla", value: "minimum" },
    ];

    const categoryRoute = [
        { label: "Route 1", value: 0 },
        { label: "Route 2", value: 1 },
        { label: "Route 3", value: 2 },
    ];

    const callFinalCrawl = () => {
        axios.get(`${BASE_URL_CRAWL}/crawl-final`, {
            params: {
                dstLat: markersEnd?.lat,
                dstLon: markersEnd?.lon,
                srcLat: markersStart?.lat,
                srcLon: markersStart?.lon,
            }
        }).then(res => {
            message.success('Crawl thành công');
            const arrayData = res.data
            const finalFeature: any = {
                type: 'FeatureCollection',
                features: [],
            }
            arrayData.forEach((data: any, index: number) => {
                const osmRouteLinestring = extractRoute(data);

                const features = {
                    type: 'Feature',
                    properties: {
                        color: index == 0 ? "red" : index == 1 ? "blue" : 'green',
                        index: index
                    },
                    geometry: osmRouteLinestring,
                };
                finalFeature.features.push(features);

            })
            setFinalLine(finalFeature);
        })
    };

    return (
        <div className='h-screen flex flex-row items-stretch' >
            <div className='w-[26rem] p-2 overflow-auto'>
                <Form
                    form={formSample}
                    layout='vertical'
                    labelAlign='left'
                    labelCol={{ span: 16 }}
                >
                    <Col span={2}>
                        <Button className="bg-base text-white mb-4" onClick={swapMarkers}><SwapOutlined onKeyDown={undefined} /></Button>
                    </Col>
                    <Row gutter={2}>
                        <Col span={17}>
                            <Form.Item required name='srcLocation' >
                                <Input
                                    onKeyUp={handleKeyUpStart}
                                    allowClear placeholder='Toạ độ start(vĩ độ-lat,kinh độ-lon)' />
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Button className="bg-base text-white w-24"
                                onClick={() => setMarkerType(CMARKER_TYPES.start)}>Chọn Start</Button>
                        </Col>
                    </Row>
                    <Row gutter={2}>
                        <Col span={17}>
                            <Form.Item required name='dstLocation'>
                                <Input
                                    onKeyUp={handleKeyUpEnd}
                                    allowClear placeholder='Toạ độ end(vĩ độ-lat,kinh độ-lon)' />
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Button className="bg-base text-white w-24" onClick={() => setMarkerType(CMARKER_TYPES.end)}>Chọn End</Button>
                        </Col>
                    </Row>
                    <Button htmlType="submit" className="text-white bg-base">Crawl Route</Button>
                    {ButtonUrl}
                </Form>
                {mode == 'debug' &&

                    <>
                        {category.map((item) => {
                            return (
                                <div >
                                    <Checkbox
                                        key={item.label}
                                        onChange={onChange}
                                        checked={item.value == value}
                                        value={item.value}
                                    >
                                        {item.value}
                                    </Checkbox>
                                </div>
                            );
                        })}
                        <p>Hiển thị các marker nằm trên các route</p>
                        {categoryRoute.map((item) => {
                            return (
                                <div >
                                    <Checkbox
                                        key={item.label}
                                        onChange={onChangeRouteNumber}
                                        checked={Number(item.value) == routeNumber}
                                        value={item.value}
                                    >
                                        {item.label}
                                    </Checkbox>
                                </div>
                            );
                        })}
                        <Button onClick={() => {
                            setIsShowMarker(!isShowMarker);
                        }}>{!isShowMarker ? `Show Marker` : `Hide Marker`}</Button>
                    </>}

                <Button onClick={() => changeMode(mode == 'debug' ? 'production' : 'debug')}>Change Mode</Button>
            </div>
            <Map
                style={{ flex: 4 }}
                initialViewState={initialViewState}
                mapStyle={`mapbox://styles/mapbox/streets-v12`}
                ref={mapRef as React.Ref<MapRef>}
                mapboxAccessToken={MAPBOX_TOKEN}
                interactiveLayerIds={['search-route']}
                maxZoom={18}
                onClick={(event) => {
                    const newMarker: CMarkerType = {
                        lat: event.lngLat.lat,
                        lon: event.lngLat.lng
                    };
                    const { features } = event;
                    const hoveredFeature = features && features[0];
                    if (hoveredFeature?.properties) {
                        const { index } = hoveredFeature.properties;
                        setFinalLine(updateFeatures(index, lineFinal));
                    }
                    else
                        if (markerType == CMARKER_TYPES.start) {
                            setMarkersStart({ ...newMarker, type: CMARKER_TYPES.start });
                            formSample.setFieldsValue({
                                srcLocation: `${event.lngLat.lat},${event.lngLat.lng}`
                            });
                        }
                        else if (markerType == CMARKER_TYPES.end) {
                            setMarkersEnd({ ...newMarker, type: CMARKER_TYPES.end });
                            formSample.setFieldsValue({
                                dstLocation: `${event.lngLat.lat},${event.lngLat.lng}`
                            })
                        }
                }}
            >
                {value == "final" && lineFinal && <Source id="final" type="geojson" data={lineFinal}>
                    <Layer {...layerFinalLineStyle} >
                    </Layer>
                </Source>}
                {isShowMarker && value == "final" && lineFinal && lineFinal.features[routeNumber].geometry.coordinates.map((item: any, index: number) => {
                    return <Marker
                        onClick={(e) => {
                            console.log(item[1], item[0]);
                            e.originalEvent.stopPropagation();
                        }}
                        key={item[1]} latitude={item[1]} longitude={item[0]}></Marker>
                })}
                {value == "raw" && lineRaw && <Source id="raw" type="geojson" data={lineRaw}>
                    <Layer {...layerGGMLineStyle} >
                    </Layer>
                </Source>}
                {isShowMarker && value == "raw" && lineRaw && lineRaw.features[routeNumber].geometry.coordinates.map((item: any, index: number) => {
                    return <Marker
                        onClick={(e) => {
                            console.log(item[1], item[0]);
                            e.originalEvent.stopPropagation();
                        }}
                        key={item[1]} latitude={item[1]} longitude={item[0]}></Marker>
                })}
                {value == "trimmed" && lineTrimmed && <Source id="trimmed" type="geojson" data={lineTrimmed}>
                    <Layer {...layerTrimmedLineStyle} >
                    </Layer>
                </Source>}
                {isShowMarker && value == "trimmed" && lineTrimmed && lineTrimmed.features[routeNumber].geometry.coordinates.map((item: any, index: number) => {
                    return <Marker
                        onClick={(e) => {
                            console.log(item[1], item[0]);
                            e.originalEvent.stopPropagation();
                        }}
                        key={item[1]} latitude={item[1]} longitude={item[0]}></Marker>
                })}
                {value == "minimum" && lineMinimum && <Source id="minimum" type="geojson" data={lineMinimum}>
                    <Layer {...layerMiniumLineStyle} >
                    </Layer>
                </Source>}
                {isShowMarker && value == "minimum" && lineMinimum && lineMinimum.features[routeNumber].geometry.coordinates.map((item: any, index: number) => {
                    return <Marker
                        onClick={(e) => {
                            console.log(item[1], item[0]);
                            e.originalEvent.stopPropagation();
                        }}
                        key={item[1]} latitude={item[1]} longitude={item[0]}></Marker>
                })}
                {markersStart && (<Marker
                    key={`marker-start`}
                    latitude={markersStart?.lat}
                    longitude={markersStart?.lon}
                    style={{ color: 'red' }}
                    draggable={true}
                    onDragEnd={(event) => {
                        setMarkersStart({ ...markersStart, lat: event.lngLat.lat, lon: event.lngLat.lng });
                        formSample.setFieldsValue({
                            srcLocation: `${event.lngLat.lat},${event.lngLat.lng}`
                        });
                    }}
                >
                    <div >
                        <img src='./pin-start.png'>
                        </img>
                    </div>
                </Marker>)}
                {markersEnd && (<Marker
                    key={`marker-end`}
                    latitude={markersEnd?.lat}
                    longitude={markersEnd?.lon}
                    onDragEnd={(event) => {
                        setMarkersEnd({ ...markersEnd, lat: event.lngLat.lat, lon: event.lngLat.lng })
                        formSample.setFieldsValue({
                            dstLocation: `${event.lngLat.lat},${event.lngLat.lng}`
                        })
                    }}
                    draggable={true}
                >
                    <div >
                        <img src='./pin-end.png'>
                        </img>
                    </div>
                </Marker>)}
            </Map>
        </div>
    )
}
