import { Button, Col, Form, FormInstance, Input, Row, Select, Typography, message } from "antd";
import { FC, useCallback } from "react"
import { IDataCreatePoint, IOtherPoint, MarkerType, PopupState, TrafficSignOptions, ValueOfSign } from "../../../constant/type";
import { APP_COLOR, IMARKER_TYPES } from "../../../constant";
import { addOtherPoint, addSpeedPoint, getRoadName } from "../../../services";
import { MapRef } from "react-map-gl";
const { Text } = Typography;

interface PProps {
    formCreate: FormInstance<any>,
    notFoundRoad: boolean,
    setTrafficSignValueView: React.Dispatch<React.SetStateAction<TrafficSignOptions | undefined>>,
    valueOfSign: ValueOfSign,
    setIMarkerType: React.Dispatch<React.SetStateAction<string>>,
    kindOfSign: {
        value: string;
        label: string;
    }[],
    trafficSignValueView: TrafficSignOptions | undefined,
    setMarkersEnd: React.Dispatch<React.SetStateAction<MarkerType | undefined>>,
    setMarkersStart: React.Dispatch<React.SetStateAction<MarkerType | undefined>>,
    mapRef: React.RefObject<MapRef>,
    setNotFoundRoad: (value: React.SetStateAction<boolean>) => void,
    setPoints: (value: any) => void,
    points: any,
}

export const CreatePoint: FC<PProps> = ({
    formCreate,
    notFoundRoad,
    setTrafficSignValueView,
    setIMarkerType,
    valueOfSign,
    kindOfSign,
    trafficSignValueView,
    setMarkersEnd,
    setMarkersStart,
    mapRef,
    setNotFoundRoad,
    setPoints,
    points
}) => {
    const isCategory = Form.useWatch('category', formCreate);
    const handleEnterLocation = (event: any) => {
        const PLocation = formCreate.getFieldValue('PLocation');

        if (PLocation) {

            const regex = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/
            if (regex.test(PLocation)) {
                const [lat, lon] = PLocation.split(',').map(parseFloat);
                formCreate.setFields([
                    {
                        name: 'PLocation',
                        errors: []
                    }
                ])
                const newMarker: MarkerType = {
                    lat: lat,
                    lon: lon
                };
                setMarkersStart(newMarker);
                mapRef!.current?.flyTo({ center: [lon, lat], animate: false });
                getRoadName(newMarker).then((res: any) => {

                    if (res.name) {
                        formCreate.setFieldsValue({
                            name: res.name,
                        })
                        setNotFoundRoad(false)
                    } else {
                        setNotFoundRoad(true);
                        formCreate.resetFields(['name'])
                    }
                    formCreate.setFieldsValue({
                        sta_cty: res.address.state
                            ? res.address.state : res.address.city,
                        osm_combination_id: res.osm_type + res.osm_id,

                    })
                }).catch((err) => {
                    setNotFoundRoad(true);
                    formCreate.resetFields(['name']);
                });
                setIMarkerType(IMARKER_TYPES.normal)
            }
            else formCreate.setFields([
                {
                    name: 'PLocation',
                    errors: ['Vui lòng nhập đúng định dạng']
                }
            ])
        }
    }

    const handleAddPoint = useCallback((data: IDataCreatePoint) => {
        const [lat, lon] = data.PLocation.split(',').map(parseFloat);
        let finalData  = {
            lat: lat,
            lon: lon,
            direction: data.direction,
            compass: data.compass,
            category: data.category,
            name: data.name ? data.name : 'null',
            ai_road_id: data.ai_road_id ? data.ai_road_id : 'ai_road_id_other',
            osm_combination_id: data.osm_combination_id
        };
    
        if (data.category === 'speed') {
            const dataSpeed = data.sta_cty? {...finalData, max_speed: Number(data.instruction), sta_cty: data.sta_cty} : 
            {...finalData, max_speed: Number(data.instruction)};
            addSpeedPoint(dataSpeed)
                .then((res: any) => {
                    if (res.status === 200) {
                        message.success('Thêm điểm thành công');
                        const afterAddPoints = [...points, res.data.data];
                        setPoints(afterAddPoints);
                        formCreate.resetFields();
                    }
                });
        } else {
            const dataOtherPoint = data.sta_cty? {...finalData, instruction: data.instruction, sta_cty: data.sta_cty} : 
            {...finalData,instruction: data.instruction};
            addOtherPoint(dataOtherPoint)
                .then((res: any) => {
                    if (res.status === 200) {
                        message.success('Thêm điểm thành công');
                        const afterAddPoints = [...points, res.data.data];
                        setPoints(afterAddPoints);
                        formCreate.resetFields();
                    }
                });
        }
    }, [points]);
    return <div>
        <Form form={formCreate} onFinish={handleAddPoint}
            layout='vertical'>
            <Form.Item name='PLocation' rules={[
                {
                    required: true,
                    validateTrigger: 'onFinish',
                    message: 'Vui lòng chọn/nhập toạ độ'
                },
            ]} >
                <Input onPressEnter={handleEnterLocation} allowClear placeholder='Nhập toạ độ (vĩ độ - lat, kinh độ - lon)' />
            </Form.Item>
            {/* <Row gutter={4}> */}
                <Col>
                    <Form.Item name='name' label='Tên đường' >
                        <Input placeholder='Tên đường' allowClear />
                    </Form.Item>
                </Col>
                <Col>
                    <Form.Item hidden name='sta_cty' label='Thành phố' 
                    
                    // rules={[{
                    //     required: true,
                    //     validateTrigger: 'onFinish',
                    //     message: <>
                    //         Hãy chọn 1 điểm nằm<br />
                    //         trên đường.
                    //     </>
                    // },
                    // ]} 
                    
                    >
                        <Input allowClear placeholder='Tên thành phố' />
                    </Form.Item>
                </Col>
            {/* </Row> */}
            <Text type="danger">{notFoundRoad && 'Không tìm thấy tên đường'}</Text>

            <Form.Item name='ai_road_id' label='AI ROAD ID' >
                <Input allowClear placeholder='AI ROAD ID' />
            </Form.Item>

            <Form.Item name='osm_combination_id' label='Osm combination id' rules={[{
                required: true,
                validateTrigger: 'onFinish',
                message: 'Vui lòng chọn một điểm nằm trên tên đường để hiện Osm combination id'
            },
            ]} >
                <Input allowClear placeholder='Osm combination id' />
            </Form.Item>
            <Row gutter={16}>
                <Col span={8}>
                    <Form.Item name='direction'>
                        <Input placeholder='Góc' allowClear />
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item name='compass' >
                        <Input placeholder='Hướng' allowClear />
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Button className="text-white bg-base" onClick={() => setIMarkerType(IMARKER_TYPES.start)}>Start</Button>
                    <Button className="text-white bg-base" onClick={() => setIMarkerType(IMARKER_TYPES.end)}>End</Button>
                </Col>
            </Row>
            <Form.Item name='category' label='Loại biển' rules={[{
                required: true,
                validateTrigger: 'onFinish',
                message: 'Vui lòng chọn loại biển'
            },
            ]} >
                <Select onChange={(value) => {
                    setTrafficSignValueView(valueOfSign[value]);
                    formCreate.resetFields(['instruction']);
                    // formCreate.setFieldsValue({ instruction: '' })
                }}
                    options={kindOfSign}></Select>
            </Form.Item>
            {
                isCategory !== 'others' &&
                <Form.Item name='instruction' label='Giá trị biển' rules={[{
                    required: true,
                    validateTrigger: 'onFinish',
                    message: 'Vui lòng nhập chọn giá trị biển'
                }]}>
                    <Select options={trafficSignValueView} ></Select>
                </Form.Item>
            }
            <div className="flex flex-row justify-end ">
                <Button htmlType='submit' className="bg-base text-white mr-2 " >Lưu lại</Button>
                <Button onClick={() => {
                    formCreate.resetFields();
                    setMarkersEnd(undefined);
                    setMarkersStart(undefined)
                }}
                    className="text-white bg-warning">Huỷ bỏ</Button>
            </div>
        </Form>
    </div>
}