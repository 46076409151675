import { FC, useCallback } from 'react';
import { Button, Col, FormInstance, Form, Input, Row, Typography, Divider, UploadFile, message, Modal, Collapse, Image, Upload } from 'antd';
import { APP_COLOR, STORAGE_URL, ITITLE_TYPES } from '../../../constant';
import { MapPoint, MarkerType, PopupTraffic } from '../../../constant/type';
import { Popup } from 'react-map-gl';
import moment from 'moment';
import { deleteRealtimeTraffic } from '../../../services';
import dayjs from 'dayjs';
import { getPointB } from '../../../helper/LocationHelper';

interface PProps {
    popupTraffic: PopupTraffic,
    setActiveKeyTrafficNews: (value: React.SetStateAction<string>) => void,
    setTitle: (value: React.SetStateAction<string>) => void,
    setPopupTraffic: (value: React.SetStateAction<PopupTraffic>) => void,
    formUpdateNews: FormInstance<any>,
    listImgUpdateTrafic: UploadFile<any>[],
    setListImgUpdateTrafic: (value: React.SetStateAction<UploadFile<any>[]>) => void,
    points: any,
    setPoints: (value: any) => void
    setSpeechUpdatehUrl: React.Dispatch<React.SetStateAction<string>>,
    handlePreview: (file: UploadFile) => Promise<void>,
    setMarkersBearing: (value: React.SetStateAction<MarkerType | undefined>) => void
}

export const PopupView: FC<PProps> = ({
    popupTraffic,
    setActiveKeyTrafficNews,
    setTitle,
    setPopupTraffic,
    formUpdateNews,
    listImgUpdateTrafic,
    setListImgUpdateTrafic,
    points,
    setPoints,
    setSpeechUpdatehUrl,
    handlePreview,
    setMarkersBearing
}) => {

    const handleDeleteTrafficNews = useCallback(() => {
        Modal.confirm({
            title: 'Cảnh báo',
            content: 'Bạn có muốn xoá điểm này không?',
            onOk: () => {
                deleteRealtimeTraffic({ id: popupTraffic.id }).then((res: any) => {
                    if (res == 200) {
                        const afterDeletePoint = points.filter((point: MapPoint) => point.id != Number(popupTraffic.id));
                        setPoints(afterDeletePoint);
                        setPopupTraffic({ ...popupTraffic, display: false });
                    }
                })
            }
        });
    }, [popupTraffic]);
    console.log('popupTraffic', popupTraffic)
    // const IlistImgUpdateLine = listImgUpdateTrafic.length > 0 ? listImgUpdateTrafic.map((item: string, index: number) => ({
    //     uid: index.toString(),
    //     status: 'done',
    //     url: `${STORAGE_URL}${item}`,
    //     name: item,
    //     original: item,
    // })) : [];
    const imgLink = popupTraffic.image.length > 0 ? popupTraffic.image.map((item: string, index: number) => ({
        uid: index.toString(),
        status: 'done',
        url: `${STORAGE_URL}${item}`,
        name: item,
        original: item,
    })) : []
    console.log('listImgUpdateTrafic', listImgUpdateTrafic);

    return (<Popup
        className='rounded-2xl'
        longitude={Number(popupTraffic.lon)} latitude={Number(popupTraffic.lat)}
        anchor="top"
        onClose={() => setPopupTraffic({ ...popupTraffic, display: false })}
    >
        <p className='text-sm'>Content: {popupTraffic.content}</p>
        {/* <Image src={}></Image> */}
        <Upload
            accept='.png, .jpg, .jpeg'
            listType="picture-card"
            onPreview={handlePreview}
            fileList={imgLink as any}
            disabled
            multiple={true} >
        </Upload>
        <Collapse
            ghost
            className='mb-2 border-none '
            items={[
                {
                    key: '1',
                    label: "Xem thêm",
                    children: <div>
                        <p>Id: {popupTraffic.id}</p>
                        <p>Expire time:  {popupTraffic.forever ? 'Vĩnh viễn' : moment.unix(popupTraffic.expire_time).format('HH:mm DD/MM/YYYY')}</p>
                        {/* <p>Type: {popupTraffic.type}</p> */}
                        <p>Traffic Type: {popupTraffic.traffic_type}</p>
                        <p>Name: {popupTraffic.name}</p>
                        <p>Sta Cty: {popupTraffic.sta_cty}</p>
                        <p>Osm Id: {popupTraffic.osm_combination_id}</p>
                        <p>Bearing: {popupTraffic.bearing}</p>
                    </div>
                }
            ]} >
        </Collapse>
        <Button onClick={() => {
            setActiveKeyTrafficNews("2");
            setTitle(ITITLE_TYPES.trafficNews);
            const dataUpdate = {
                id: popupTraffic.id,
                content: popupTraffic.content,
                ai_road_id: popupTraffic.ai_road_id,
                type: popupTraffic.type,
                traffic_type: popupTraffic.traffic_type,
                name: popupTraffic.name,
                sta_cty: popupTraffic.sta_cty,
                audio_link: popupTraffic.audio_link,
                osm_combination_id: popupTraffic.osm_combination_id,
                expire_time: dayjs.unix(popupTraffic.expire_time),
                image: listImgUpdateTrafic,
                forever: popupTraffic.forever,
                UNLocation: `${popupTraffic.lat},${popupTraffic.lon}`,
                bearing: popupTraffic.bearing,
            }
            formUpdateNews.setFieldsValue(dataUpdate)
            if (popupTraffic.lat && popupTraffic.lon) {
                setMarkersBearing(getPointB({ lat: popupTraffic.lat, lon: popupTraffic.lon }, Number(popupTraffic.bearing)))
            }

            console.log('IlistImgUpdateTrafic', imgLink, dataUpdate, popupTraffic)
            setPopupTraffic({ ...popupTraffic, display: false });
            setListImgUpdateTrafic(imgLink as any);
            popupTraffic.audio_link ? setSpeechUpdatehUrl(popupTraffic.audio_link) : setSpeechUpdatehUrl('');
        }}>
            Sửa
        </Button>
        <Button className='bg-warning text-white ml-2' onClick={handleDeleteTrafficNews}>Xoá</Button>

    </Popup>)
}