import React, { } from 'react';
import { fas } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import MainApp from './MainApp';
import Login from './pages/login';
import { Redirect } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import './dist/output.css'
import './sider.css'

const App: React.FC = (props) => {
  
  const isValid = localStorage.getItem("login") !== null ;
  return (
    <Switch>
      <Route path="/login" component={Login} />
      <Route render={props => isValid  ?  <MainApp /> : <Redirect to={{
        pathname:
          '/login'
      }}/>}></Route>
    </Switch>
  );
};

export default App;
library.add(fas)
