
import React, { FC, useState } from 'react';
import { Button, Col, DatePicker, FormInstance, Form, Input, Row, Select, Typography, Upload, UploadFile, message, Checkbox } from 'antd';
import { contentToSpeech, postTrafficNews } from '../../../services';
import { APP_COLOR, IMARKER_TYPES, I_TYPES, STORAGE_URL, TrafficType } from '../../../constant';
import moment from 'moment';
import { PlusOutlined } from '@ant-design/icons';
import { URL_UPLOAD_IMAGE } from '../../../helper/AxiosCustom';
import { extractUrls, findAudioLink, isLink } from '../helper';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { AudioData, MarkerType } from '../../../constant/type';
import { MapRef } from 'react-map-gl';


const { TextArea } = Input;
const { Text } = Typography;
interface PProps {
    formNews: FormInstance<any>,
    watch_content: any,
    setSpeechUrl: (value: React.SetStateAction<string>) => void,
    speechUrl: string,
    notFoundRoad: boolean,
    handlePreview: (file: UploadFile) => Promise<void>,
    onGetTrafficRealtime({ setLine, setPoints }: {
        setLine: (value: any) => void;
        setPoints: (value: any) => void;
    }): void,
    setPoints: (value: any) => void,
    setLine: (value: any) => void,
    fixedSound: AudioData[],
    setMarkers: (value: React.SetStateAction<MarkerType | undefined>) => void
    setIMarkerType: (value: React.SetStateAction<string>) => void,
    setMarkersBearing: (value: React.SetStateAction<MarkerType | undefined>) => void,
    mapRef: React.RefObject<MapRef>
}
export const CreateNews: FC<PProps> = ({
    formNews,
    watch_content,
    setSpeechUrl,
    speechUrl,
    notFoundRoad,
    handlePreview,
    onGetTrafficRealtime,
    setPoints,
    setLine,
    fixedSound,
    setMarkers,
    setIMarkerType,
    setMarkersBearing,
    mapRef
}) => {
    const IWatchForever = Form.useWatch('forever', formNews);
    const IWatchTrafficType = Form.useWatch('traffic_type', formNews);
    const [loadings, setLoadings] = useState<boolean>(false);

    const handleFormTrafficNews = (data: any) => {
        console.log('data', data);

        const [lat, lon] = data.CNLocation.split(',').map(parseFloat);
        const finalData = {
            ...data,
            type: I_TYPES.trafficNews,
            image: data.image ? extractUrls(data.image) : [],
            ai_road_id: data.ai_road_id ? data.ai_road_id : 'ai_road_id_other',
            name: data.name ? data.name : 'null',
            forever: data.forever ? data.forever : false,
            lat, lon
        }
        speechUrl.length > 0 && (finalData.audio_link = speechUrl);
        !data.forever && (finalData.expire_time = moment(data.expire_time.$d).unix())
        const { CNLocation, ...updatedFinalData } = finalData;
        const dataTraffic = data.sta_cty ? { ...updatedFinalData, sta_cty: data.sta_cty } :
            updatedFinalData;
        console.log('updatedFinalData', updatedFinalData);

        console.log(dataTraffic)
        postTrafficNews(dataTraffic).then((res) => {
            if (res.status == 200) {
                formNews.resetFields();
                setMarkers(undefined);
                setMarkersBearing(undefined);
                onGetTrafficRealtime({
                    setLine,
                    setPoints
                });
            }
        })

        console.log(finalData)
    }
    const handleKeyUpS = (event: any) => {

        if (event.keyCode === 13) {
            event.preventDefault();

            const CNLocation = formNews.getFieldValue('CNLocation');
            const regex = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/
            if (regex.test(CNLocation)) {
                formNews.setFields([
                    {
                        name: 'CNLocation',
                        errors: []
                    }
                ])
                const [lat, lon] = CNLocation.split(',').map(parseFloat);
                console.log(lat, lon);
                setMarkers({
                    lat,
                    lon
                })

                mapRef!.current?.flyTo({ center: [lon, lat], animate: false });
            } else formNews.setFields([
                {
                    name: 'CNLocation',
                    errors: ['Vui lòng nhập đúng định dạng']
                }
            ])
            console.log(CNLocation);

        }
    }
    return (<Form onFinish={handleFormTrafficNews} onKeyUp={e => e.preventDefault()} form={formNews} layout='vertical'>
        <Form.Item required name='CNLocation' rules={[
            {
                required: true,
                validateTrigger: 'onFinish',
                message: 'Vui lòng chọn/nhập toạ độ'
            },
        ]} >
            <Input onKeyUp={handleKeyUpS} onKeyDown={(e) => e.key === 'Enter' ? e.preventDefault() : ''} allowClear placeholder='Nhập toạ độ (vĩ độ - lat, kinh độ - lon)' />
        </Form.Item>
        <Row gutter={16} style={{ alignItems: 'center' }}>
            <Col span={12}>
                <Form.Item label='Góc hướng' name='bearing'>
                    <Input placeholder='Góc' allowClear />
                </Form.Item>
            </Col>
            <Col span={12} style={{ marginTop: 4 }}>
                <Button className="text-white bg-base" onClick={() => setIMarkerType(IMARKER_TYPES.normal)}>Start</Button>
                <Button className="text-white bg-base" onClick={() => setIMarkerType(IMARKER_TYPES.endUpdate)}>End</Button>
            </Col>
        </Row>
        <Form.Item name='traffic_type' label='Loại bản tin' rules={[{
            required: true,
            validateTrigger: 'onFinish',
            message: 'Vui lòng chọn loại bản tin',
            type: 'array',
            transform: (value) => {
                if (typeof (value) === 'string') {
                    return [value];
                }
                return value;
            }
        },
        ]}    >
            <Select
                allowClear
                options={Object.values(TrafficType).map((value) => ({
                    label: value.name,
                    value: value.name,
                    content: value.content
                }))}
                onChange={(value: string, option: any) => {
                    if (value !== TrafficType.other.name) {
                        console.log('value', option)
                        formNews.setFieldsValue({ content: option.content })
                        setSpeechUrl('')
                    }
                    else {
                        formNews.resetFields(['content'])
                    }
                    const audioLink = findAudioLink(value, fixedSound);
                    audioLink && setSpeechUrl(audioLink)
                }}
            />
        </Form.Item>
        {IWatchTrafficType !== TrafficType.other.name ? (<Form.Item name='content' label='Nội dung bản tin' rules={[{
            required: true,
            validateTrigger: 'onFinish',
            message: 'Nhập nội dung bản tin'
        },
        ]}>
            <TextArea allowClear placeholder='Nội dung bản tin' disabled={IWatchTrafficType !== TrafficType.other.name} />
        </Form.Item>) :
            <Row gutter={2} align={'middle'} >

                <Col span={18}>
                    <Form.Item name='content' label='Nội dung bản tin' rules={[{
                        required: true,
                        validateTrigger: 'onFinish',
                        message: 'Nhập nội dung bản tin'
                    },
                    ]}>
                        <TextArea allowClear placeholder='Nội dung bản tin' disabled={IWatchTrafficType !== TrafficType.other.name} />
                    </Form.Item>
                </Col>
                <Col className='-mb-2' span={4}>
                    <Button
                        disabled={!watch_content}
                        onClick={() => {
                            setLoadings(true)
                            contentToSpeech({ input: formNews.getFieldValue('content') }).then(res => {
                                console.log('res', res);
                                setSpeechUrl(res.data);
                                setLoadings(false)
                            })
                        }}
                        loading={loadings}
                        className='bg-base text-white'>Render</Button>
                </Col>
            </Row>
        }

        {speechUrl.length > 0 && <audio src={`${STORAGE_URL}${speechUrl}`} controls></audio>}
        <Form.Item hidden name='ai_road_id' label='AI ROAD ID' >
            <Input allowClear placeholder='AI ROAD ID' />
        </Form.Item>
        <Form.Item name='osm_combination_id' label='osm combination id' rules={[{
            required: true,
            validateTrigger: 'onFinish',
            message: 'Chọn một điểm nằm trên đường để hiện osm combination id'
        },
        ]}>
            <Input allowClear placeholder='OSM Combination id' />
        </Form.Item>
        {/* <Row gutter={4}>
            <Col span={12}> */}
        <Form.Item name='name' label='Tên đường' >
            <Input placeholder='Tên đường' allowClear />
        </Form.Item>
        {/* </Col>
            <Col span={12}> */}
        <Form.Item name='sta_cty' hidden label='Thành phố'
        // rules={[{
        //     required: true,
        //     validateTrigger: 'onFinish',
        //     message: <>
        //         Hãy chọn 1 điểm nằm<br />
        //         trên đường.
        //     </>
        // },
        // ]}
        >
            <Input allowClear placeholder='Tên thành phố' />
        </Form.Item>
        {/* </Col>
        </Row> */}
        <Text className='sticky' type="danger">{notFoundRoad && 'Không tìm thấy tên đường'}</Text>
        <Form.Item name='forever' valuePropName="checked" >
            <Checkbox>
                Tin vĩnh viễn
            </Checkbox>
        </Form.Item>
        {!IWatchForever && (<Form.Item name='expire_time' label='Thời gian hết hạn'
            rules={[{
                required: true,
                validateTrigger: 'onFinish',
                message: 'Vui lòng chọn thời gian hết hạn',
                type: 'object',
            },
            ]} >
            <DatePicker className='flex'
                placeholder='Thời gian hết hạn'
                format={'HH:mm DD/MM/YYYY'}
                // secondStep={}
                disabledDate={(current) => {
                    let customDate = moment().format("YYYY-MM-DD");
                    return current && current < moment(customDate, "YYYY-MM-DD");
                }}
                showTime></DatePicker>
        </Form.Item>)}

        <Form.Item name='image' label='Hình ảnh'>
            <Upload
                accept='.png, .jpg, .jpeg'
                listType="picture-card"
                onPreview={handlePreview}
                action={URL_UPLOAD_IMAGE}

                onChange={(info) => {
                    if (info.file.status !== 'uploading') {
                        console.log(info.file, info.fileList);
                    }
                    if (info.file.status === 'done') {
                        message.success(`${info.file.name} file uploaded successfully`);
                    } else if (info.file.status === 'error') {
                        message.error(`${info.file.name} file upload failed.`);
                    }
                }}
                multiple={true} >
                <p className="ant-upload-drag-icon">
                    <PlusOutlined size={32} onKeyDown={undefined} />
                </p>
            </Upload>
        </Form.Item>
        <Form.Item>
            <Button className='bg-base' type='primary' htmlType='submit'  >
                Hoàn thành
            </Button>
        </Form.Item>
    </Form>)
}