// @ts-ignore
import * as React from 'react';
import { useState, useCallback } from 'react';
import Map, { MapLayerMouseEvent, Marker, MapRef, Popup, MapboxStyle } from 'react-map-gl';
import "mapbox-gl/dist/mapbox-gl.css";
import { Button, Form, Modal, Tabs, TabsProps, message } from 'antd';
import { DEFAULT_VALUE_TRAFFIC_SIGN, IMARKER_TYPES, MAPBOX_TOKEN, initialViewState, MAP_STYLE } from '../../constant';
import { useForm } from 'antd/es/form/Form';
import { deleteMapPoint, getAiRoadId, getMapPoint, getRoadName } from '../../services';
import { AiRoadIdItem, AiRoadIdOptions, MapPoint, MarkerType, PopupState, TrafficSignOptions, ValueOfSign, } from '../../constant/type';
import mapboxgl, { LngLat, LngLatBounds } from "mapbox-gl";
import { getDirectionAndAngle, getDirectionFromAngle, getPointB } from '../../helper/LocationHelper';
import { ViewPoint } from './component/ViewPoint';
import { CreatePoint } from './component/CreatePoint';
import { UpdatePoint } from './component/UpdatePoint';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './index.css'

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass =
    require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default; /* eslint import/no-webpack-loader-syntax: off */

export default function DataSystem(props: any) {
    const [markers, setMarkers] = useState<MarkerType>();
    const [markersStart, setMarkersStart] = useState<MarkerType>();
    const [markersEnd, setMarkersEnd] = useState<MarkerType>();
    const [markersEndUpdate, setMarkersEndUpdate] = useState<MarkerType>();
    const [isViewTab, setViewTab] = useState<boolean>(true);
    const [activeKey, setActiveKey] = useState('1');
    const mapRef = React.useRef<MapRef>(null);
    const [ImarkerType, setIMarkerType] = useState<string>(IMARKER_TYPES.normal)
    const [formCreate] = useForm();
    const [formView] = useForm();
    const [points, setPoints] = useState<any>([]);
    const [kindOfPoint, setKindOfPoint] = useState<string>();
    const [aiRoadId, setAiRoadId] = useState<AiRoadIdOptions>();
    const [optionAiRoadId, setOptionAiRoadId] = useState<AiRoadIdOptions>();
    const [filterMarker, setFilterMarker] = useState<boolean>(false);
    const [trafficSignValue, setTrafficSignValue] = useState<TrafficSignOptions>();
    const [trafficSignValueView, setTrafficSignValueView] = useState<TrafficSignOptions>();
    const [popupOpen, setPopupOpen] = useState<PopupState>({
        lat: 0,
        lon: 0,
        display: false,
        ai_road_id: "",
        compass: "",
        id: 0,
        category: '',
        osm_combination_id: '',
        sta_cty: '',

    });
    const instruction = Form.useWatch('instruction', formView);
    const category = Form.useWatch('category', formView);
    const ai_road_id = Form.useWatch('ai_road_id', formView);
    const kindOfSign = [{ value: 'speed', label: 'speed' }, { value: 'camera', label: 'camera' }, { value: 'traffic-sign', label: 'traffic-sign' }, { value: 'others', label: 'others' }]
    const kindOfSignAll = [{ value: 'speed', label: 'speed' }, { value: 'camera', label: 'camera' }, { value: 'traffic-sign', label: 'traffic-sign' }, { value: 'others', label: 'others', }, { value: 'all', label: 'all' }]
    const { Icategory, Iinstruction, Ilon, Ilat, Idirection }: any = useParams();
    const [formUpdate] = useForm();

    React.useEffect(() => {
        if (Icategory && Iinstruction && Ilon && Ilat && Idirection) {
            setActiveKey('2')
            const compass = getDirectionFromAngle(Idirection);
            formCreate.setFieldsValue({
                category: Icategory,
                instruction: Iinstruction,
                PLocation: `${Ilat},${Ilon}`,
                direction: Idirection,
                compass: compass,
            })
            const newMarker: MarkerType = {
                lat: Ilat,
                lon: Ilon,
            };
            getMapPoint(newMarker).then((res: MapPoint[]) => {

                setPoints(res);
                setMarkersStart(newMarker);

            });
            getRoadName(newMarker).then((res: any) => {

                if (res.name) {
                    formCreate.setFieldsValue({
                        name: res.name,
                    })
                    setNotFoundRoad(false)
                } else {
                    setNotFoundRoad(true);
                    formCreate.resetFields(['name'])
                }
                formCreate.setFieldsValue({
                    sta_cty: res.address.state
                        ? res.address.state : res.address.city,
                    osm_combination_id: res.osm_type + res.osm_id,

                })
            }).catch((err) => {
                setNotFoundRoad(true);
                formCreate.resetFields(['name']);
            });
        }
    }, [Icategory, Iinstruction, Ilon, Ilat, Idirection])
    React.useEffect(() => {
        getAiRoadId().then((res: AiRoadIdItem[]) => {
            const IoptionAiRoadId = res.map((item) => {
                if (item.ai_road_id) {
                    return {
                        value: item.ai_road_id,
                        label: item.ai_road_id
                    };
                }
                return undefined; // add this line to handle the case where the if condition is not satisfied
            }).filter((item) => item != null) as { value: string; label: string }[];
            //bug sort: missing item
            IoptionAiRoadId.sort((a, b) => {
                if (a.label.split("_")[0] < b.label.split("_")[0]) {
                    return -1;
                }
                if (a.label.split("_")[0] > b.label.split("_")[0]) {
                    return 1;
                }
                return 0;
            });
            setOptionAiRoadId(IoptionAiRoadId!);
            setAiRoadId(IoptionAiRoadId)
        })
    }, [])

    const valueOfSign: ValueOfSign = {}
    DEFAULT_VALUE_TRAFFIC_SIGN.forEach((sign) => {
        if (sign.name !== 'others' && sign.name !== 'all') {
            valueOfSign[sign.name] = sign.value!.map((value) => ({
                value: value.toString(),
                label: value.toString(),
            }));
        }
    });
    const [notFoundRoad, setNotFoundRoad] = useState<boolean>(false);
    const onClickMap = useCallback((event: MapLayerMouseEvent) => {
        const newMarker: MarkerType = {
            lat: event.lngLat.lat,
            lon: event.lngLat.lng,
        };

        if (ImarkerType == IMARKER_TYPES.normal) {
            setMarkers(newMarker);
            setFilterMarker(true);
            mapRef.current?.flyTo({ center: [event.lngLat.lng, event.lngLat.lat], animate: false });
            formView.setFieldsValue({
                VLocation: `${event.lngLat.lat}, ${event.lngLat.lng}`
            })
        }
        else if (ImarkerType == IMARKER_TYPES.start) {
            setMarkersStart(newMarker);
            formCreate.setFieldsValue({
                PLocation: `${event.lngLat.lat},${event.lngLat.lng}`
            })
            getRoadName(newMarker).then((res: any) => {

                if (res.name) {
                    formCreate.setFieldsValue({
                        name: res.name,
                    })
                    setNotFoundRoad(false)
                } else {
                    setNotFoundRoad(true);
                    formCreate.resetFields(['name'])
                }
                formCreate.setFieldsValue({
                    sta_cty: res.address.state
                        ? res.address.state : res.address.city,
                    osm_combination_id: res.osm_type + res.osm_id,

                })
            }).catch((err) => {
                setNotFoundRoad(true);
                formCreate.resetFields(['name']);
            });
            setIMarkerType(IMARKER_TYPES.normal)
        }
        else if (ImarkerType == IMARKER_TYPES.end) {
            setMarkersEnd(newMarker);
            setIMarkerType(IMARKER_TYPES.normal)

        } else if (ImarkerType == IMARKER_TYPES.marker) {
            setIMarkerType(IMARKER_TYPES.normal)
        }
        else if (ImarkerType == IMARKER_TYPES.endUpdate) {
            setMarkersEndUpdate(newMarker);
            setIMarkerType(IMARKER_TYPES.normal)
        }
    }, [ImarkerType]);

    React.useEffect(() => {
        if (markersStart && markersEnd) {
            const { compass, direction} = getDirectionAndAngle(markersStart, markersEnd);
            formCreate.setFieldsValue({
                compass: compass,
                direction: direction,
            })
        }
    }, [markersStart, markersEnd])

    React.useEffect(() => {
        if (markersEndUpdate) {
            const [lat, lon] = formUpdate.getFieldValue('ELocation') && formUpdate.getFieldValue('ELocation').split(',').map(parseFloat);

            const marketStartUpdate = { lat, lon };
            if (markersEndUpdate && marketStartUpdate) {
                const { compass, direction} = getDirectionAndAngle(marketStartUpdate, markersEndUpdate);
                formUpdate.setFieldsValue({
                    compass: compass,
                    direction: direction,
                })
            }
        }

    }, [markersEndUpdate])

    // const points = getPoint();


    React.useEffect(() => {
        if (markers) {
            const reply = { lat: markers?.lat, lon: markers?.lon }
            getRoadName(reply).then((res: any) => {
                if (res.name) {
                    formView.setFieldsValue({
                        name: res.name,
                    })
                    setNotFoundRoad(false)
                } else {
                    formView.resetFields(['name', 'osm_combination_id', 'sta_cty']);
                    setNotFoundRoad(true)
                }

            }).catch((err) => {
                formView.resetFields(['name', 'osm_combination_id', 'sta_cty']);
                setNotFoundRoad(true)
            })
            if (category === 'speed') {
                const params =
                    instruction ?
                        { ...reply, category: category, max_speed: instruction } :
                        { ...reply, category: category };
                getMapPoint(params).then((res: MapPoint[]) => {
                    if (res.length > 0) {
                        const bounds = new LngLatBounds();
                        res.forEach(coord => bounds.extend(new LngLat(coord.lon, coord.lat)));
                    }
                    setPoints(res);
                    const max_speedSet = new Set();
                    const result: TrafficSignOptions = [];
                    if (!instruction) {
                        res.forEach(({ max_speed }) => {
                            if (!max_speedSet.has(max_speed)) {
                                max_speedSet.add(max_speed);
                                result.push({ value: max_speed.toString(), label: max_speed.toString() });
                            }
                        });
                        setTrafficSignValue(result);
                    }
                });
            } else if (category === 'speed') {
                const params =
                    instruction ?
                        { ...reply, category: category, max_speed: instruction } :
                        { ...reply, category: category };
                getMapPoint(params).then((res: MapPoint[]) => {
                    if (res.length > 0) {
                        const bounds = new LngLatBounds();
                        res.forEach(coord => bounds.extend(new LngLat(coord.lon, coord.lat)));
                    }
                    setPoints(res);
                    const max_speedSet = new Set();
                    const result: TrafficSignOptions = [];
                    if (!instruction) {
                        res.forEach(({ max_speed }) => {
                            if (!max_speedSet.has(max_speed)) {
                                max_speedSet.add(max_speed);
                                result.push({ value: max_speed.toString(), label: max_speed.toString() });
                            }
                        });
                        setTrafficSignValue(result);
                    }
                });
            } else if (category == 'all') {
                getMapPoint(reply).then((res: MapPoint[]) => {
                    if (res.length > 0) {
                        const bounds = new LngLatBounds();
                        res.forEach(coord => bounds.extend(new LngLat(coord.lon, coord.lat)));
                    }
                    setPoints(res);
                });
            }
            else getMapPoint(reply).then((res: MapPoint[]) => {
                if (res.length > 0) {
                    const bounds = new LngLatBounds();
                    res.forEach(coord => bounds.extend(new LngLat(coord.lon, coord.lat)));
                }
                setPoints(res);

            })
        }
    }, [markers]);

    const handleDeleteMapPoint = useCallback(() => {
        Modal.confirm({
            title: 'Cảnh báo',
            content: 'Bạn có muốn xoá điểm này không?',
            onOk: () => {
                deleteMapPoint({ id: popupOpen.id }).then((res: any) => {
                    if (res == 200) {
                        message.success('Xoá điểm thành công');
                        const afterDeletePoint = points.filter((point: MapPoint) => point.id != Number(popupOpen.id));
                        setPoints(afterDeletePoint);
                        setPopupOpen({ ...popupOpen, display: false });
                    }
                })
            }
        });
    }, [popupOpen]);

    React.useEffect(() => {
        if (!filterMarker) {
            if (!category) {
                if (ai_road_id) {
                    getMapPoint({ ai_road_id: ai_road_id }).then((res: MapPoint[]) => {
                        if (res.length > 0) {
                            const bounds = new LngLatBounds();
                            res.forEach(coord => bounds.extend(new LngLat(coord.lon, coord.lat)));
                            mapRef.current?.fitBounds(bounds, { padding: 20, animate: false })
                        }
                        setPoints(res);
                    });
                }
            } else {
                if (ai_road_id) {
                    if (category === 'speed') {
                        const params = instruction ?
                            { category: category, max_speed: instruction, ai_road_id: ai_road_id } :
                            { category: category, ai_road_id: ai_road_id };

                        getMapPoint(params).then((res: MapPoint[]) => {
                            const max_speedSet = new Set();
                            const result: TrafficSignOptions = [];
                            if (res.length > 0) {
                                const bounds = new LngLatBounds();
                                res.forEach(coord => bounds.extend(new LngLat(coord.lon, coord.lat)));
                                mapRef.current?.fitBounds(bounds, { padding: 20, animate: false })
                            }
                            res.forEach(({ max_speed }) => {
                                if (!instruction) {
                                    if (!max_speedSet.has(max_speed)) {
                                        max_speedSet.add(max_speed);
                                        result.push({ value: max_speed.toString(), label: max_speed.toString() });
                                    }
                                }
                            });
                            if (!instruction) {
                                setTrafficSignValue(result);
                            }
                            setPoints(res);
                        });
                    } else if (category == 'all') {
                        getMapPoint({ ai_road_id: ai_road_id }).then((res: MapPoint[]) => {
                            if (res.length > 0) {
                                const bounds = new LngLatBounds();
                                res.forEach(coord => bounds.extend(new LngLat(coord.lon, coord.lat)));
                                mapRef.current?.fitBounds(bounds, { padding: 20, animate: false })
                            };
                            setPoints(res);
                        });
                    } else if (category) {
                        const params = instruction ?
                            { category: category, instruction: instruction, ai_road_id: ai_road_id } :
                            { category: category, ai_road_id: ai_road_id }
                        getMapPoint(params).then((res: MapPoint[]) => {
                            if (!instruction) {
                                const instructionSet = new Set();
                                const result: TrafficSignOptions = [];
                                if (res.length > 0) {
                                    const bounds = new LngLatBounds();
                                    res.forEach(coord => bounds.extend(new LngLat(coord.lon, coord.lat)));
                                    mapRef.current?.fitBounds(bounds, { padding: 20, animate: false })
                                }
                                res.forEach(({ instruction }) => {
                                    if (!instructionSet.has(instruction)) {
                                        instructionSet.add(instruction);
                                        result.push({ value: instruction, label: instruction });
                                    }
                                });
                                if (!instruction) {
                                    setTrafficSignValue(result);
                                }
                            }
                            setPoints(res);
                        });
                    }
                }
            }
        }
        else {
            const reply = { lat: markers?.lat, lon: markers?.lon }
            if (category === 'speed') {
                const params =
                    instruction ?
                        { ...reply, category: category, max_speed: instruction } :
                        { ...reply, category: category };
                getMapPoint(params).then((res: MapPoint[]) => {
                    const max_speedSet = new Set();
                    const result: TrafficSignOptions = [];
                    if (!instruction) {
                        res.forEach(({ max_speed }) => {
                            if (!max_speedSet.has(max_speed)) {
                                max_speedSet.add(max_speed);
                                result.push({ value: max_speed.toString(), label: max_speed.toString() });
                            }
                        });
                        if (res.length > 0) {
                            const bounds = new LngLatBounds();
                            res.forEach(coord => bounds.extend(new LngLat(coord.lon, coord.lat)));
                            mapRef.current?.fitBounds(bounds, { padding: 20, animate: false })
                        }
                        setTrafficSignValue(result);
                    }
                    setPoints(res);
                });
            } else if (category == 'all') {

                getMapPoint(reply).then((res: MapPoint[]) => {
                    if (res.length > 0) {
                        const bounds = new LngLatBounds();
                        res.forEach(coord => bounds.extend(new LngLat(coord.lon, coord.lat)));
                        mapRef.current?.fitBounds(bounds, { padding: 20, animate: false })
                    }
                    setPoints(res);
                });
            } else if (category) {
                const params =
                    instruction ?
                        { ...reply, category: category, instruction: instruction } :
                        { ...reply, category: category };
                getMapPoint(params).then((res: MapPoint[]) => {
                    if (!instruction) {
                        const instructionSet = new Set();
                        const result: TrafficSignOptions = [];
                        if (res.length > 0) {
                            const bounds = new LngLatBounds();
                            res.forEach(coord => bounds.extend(new LngLat(coord.lon, coord.lat)));
                            mapRef.current?.fitBounds(bounds, { padding: 20, animate: false })
                        }
                        res.forEach(({ instruction }) => {
                            if (!instructionSet.has(instruction)) {
                                instructionSet.add(instruction);
                                result.push({ value: instruction, label: instruction });
                            }
                        });
                        if (!instruction) {
                            setTrafficSignValue(result);
                        }
                    }
                    setPoints(res);
                });
            }

        }
    }, [instruction, category, ai_road_id]);

    const onChangeTabs = (key: string) => {
        setNotFoundRoad(false);
        setActiveKey(key);
        if (key !== '1') {
            setViewTab(false);
        } else {
            setViewTab(true);

        }
        if (key !== '2') {
            setMarkersEnd(undefined);
            setMarkersStart(undefined);
            formCreate.resetFields();
        }
        if (key !== '3') {
            formUpdate.resetFields();
            setMarkersEndUpdate(undefined);
        }
    };

    const handleClearFilter = () => {
        formView.resetFields(['category', 'instruction', 'name']);
        setAiRoadId(optionAiRoadId)
        const params = { lat: markers?.lat, lon: markers?.lon }
        getMapPoint(params).then((res: MapPoint[]) => {
            setPoints(res);

        });
    }
    const itemsTab: TabsProps['items'] = [
        {
            key: '1',
            label: `View Điểm`,
            children: <ViewPoint
                formView={formView}
                notFoundRoad={notFoundRoad}
                aiRoadId={aiRoadId}
                setFilterMarker={setFilterMarker}
                setKindOfPoint={setKindOfPoint}
                kindOfSign={kindOfSign}
                kindOfSignAll={kindOfSignAll}
                trafficSignValue={trafficSignValue}
                ImarkerType={ImarkerType}
                handleDeleteMapPoint={handleDeleteMapPoint}
                kindOfPoint={kindOfPoint}
                handleClearFilter={handleClearFilter}
                setNotFoundRoad={setNotFoundRoad}
                mapRef={mapRef}
                setMarkers={setMarkers}
                setPoints={setPoints}
            />,
        },
        {
            key: '2',
            label: `Thêm Điểm`,
            children: <CreatePoint
                formCreate={formCreate}
                notFoundRoad={notFoundRoad}
                setTrafficSignValueView={setTrafficSignValueView}
                valueOfSign={valueOfSign}
                setIMarkerType={setIMarkerType}
                kindOfSign={kindOfSign}
                trafficSignValueView={trafficSignValueView}
                setMarkersEnd={setMarkersEnd}
                setMarkersStart={setMarkersStart}
                setNotFoundRoad={setNotFoundRoad}
                mapRef={mapRef}
                points={points}
                setPoints={setPoints}
            ></CreatePoint>
        },
        {
            key: '3',
            label: `Cập nhật Điểm`,
            children: <UpdatePoint
                formUpdate={formUpdate}
                valueOfSign={valueOfSign}
                setMarkersEndUpdate={setMarkersEndUpdate}
                setIMarkerType={setIMarkerType}
                points={points}
                popupOpen={popupOpen}
                setPoints={setPoints}
                setPopupOpen={setPopupOpen}
                onChangeTabs={onChangeTabs}
            ></UpdatePoint>,
        }
    ];

    const pins = React.useMemo(
        () => {
            return points && points.map((point: MapPoint, index: any) => (
                <div className='text-red-500'>
                    <Marker
                        key={`marker-${index}`}
                        latitude={point.lat}
                        longitude={point.lon}
                        onClick={(e) => {
                            e.originalEvent.stopPropagation();
                            setIMarkerType(IMARKER_TYPES.marker);
                            formView.setFieldsValue({
                                VLocation: `${point.lat}, ${point.lon}`
                            });
                            setPopupOpen({
                                display: true,
                                ...point
                            })
                        }}
                    >
                        {
                            point.category == 'speed' ? (
                                <img className='w-9 h-9' src={`/${point.max_speed}.png`}>
                                </img>
                            ) : point.category == 'camera' ? (
                                <img className='w-9 h-9' src={`/${point.category}.png`}>
                                </img>
                            ) : point.category == 'traffic-sign' ? (
                                <img className='w-9 h-9' src={`/${point.instruction}.png`}>
                                </img>
                            ) : point.category == 'others' && (<img src={`/pin-end.png`} ></img>)
                        }
                    </Marker>
                </div>
            ))
        },
        [points]
    );
    const handleCopyClick = (text: string) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                message.success('Copy thành công');
            })
            .catch((error) => {
                message.error('Copy failed', error);
            });
    }

    return (
        <div className='h-screen flex flex-row items-stretch' >
            <div className='p-2 overflow-auto w-[26rem]' >
                <Tabs defaultActiveKey="1" activeKey={activeKey} items={itemsTab} onChange={onChangeTabs} />
            </div>
            <Map
                style={{ flex: 4 }}
                ref={mapRef as React.Ref<MapRef>}
                initialViewState={initialViewState}
                mapStyle={MAP_STYLE as MapboxStyle}
                onClick={onClickMap}
                mapboxAccessToken={MAPBOX_TOKEN}
                interactiveLayerIds={['nonexist']}
                maxZoom={18}
                onLoad={(e) => {
                    Ilon && Ilat &&
                        mapRef.current?.flyTo({ center: [Ilon, Ilat], animate: false })
                }}
            >
                {pins}
                {popupOpen.display && <Popup longitude={Number(popupOpen.lon)} latitude={Number(popupOpen.lat)}
                    anchor="top"
                    onClose={() => setPopupOpen({ ...popupOpen, display: false })}
                >
                    <p>Id: {popupOpen.id}</p>
                    <a onClick={() => handleCopyClick(popupOpen.ai_road_id)}>AI ROAD ID: {popupOpen.ai_road_id}</a>
                    <p>Compass: {popupOpen.compass}</p>
                    <p>Road: {popupOpen.name}</p>
                    <p>Combination id: {popupOpen.osm_combination_id}</p>
                    {popupOpen.category == 'speed' &&
                        <Button onClick={() => {
                            setActiveKey('3');
                            formUpdate.setFieldsValue({
                                id: popupOpen.id,
                                ELocation: `${popupOpen.lat},${popupOpen.lon}`,
                                direction: popupOpen.direction,
                                compass: popupOpen.compass,
                                max_speed: popupOpen.max_speed,
                                category: popupOpen.category,
                                name: popupOpen.name,
                                sta_cty: popupOpen.sta_cty,
                                ai_road_id: popupOpen.ai_road_id,
                                osm_combination_id: popupOpen.osm_combination_id,
                            })
                            setMarkersEndUpdate(getPointB({ lat: popupOpen.lat, lon: popupOpen.lon }, Number(popupOpen.direction)))
                        }}>
                            Edit
                        </Button>
                    }
                    <Button className='bg-warning text-white' onClick={handleDeleteMapPoint}>Delete</Button>
                </Popup>}
                {markers && isViewTab && (<Marker
                    key={`marker`}
                    latitude={markers?.lat}
                    longitude={markers?.lon}>
                </Marker>)}
                {markersEnd && (<Marker
                    key={`marker-end`}
                    style={{ color: '#DF3F3F' }}
                    latitude={markersEnd?.lat}
                    longitude={markersEnd?.lon}
                    onDragEnd={(event) => {
                        setMarkersEnd({ lat: event.lngLat.lat, lon: event.lngLat.lng })
                    }}
                    draggable={true}
                >
                    <div >
                        <FontAwesomeIcon icon='location-dot' className='w-8 h-8 z-[400]'></FontAwesomeIcon>
                    </div>
                </Marker>)}
                {markersEndUpdate && (<Marker
                    key={`marker-end`}
                    latitude={markersEndUpdate?.lat}
                    longitude={markersEndUpdate?.lon}

                >
                    <FontAwesomeIcon icon='location-dot' className='w-8 h-8'></FontAwesomeIcon>

                </Marker>)}
                {markersStart && (<Marker
                    key={`marker-start`}
                    latitude={markersStart?.lat}
                    longitude={markersStart?.lon}
                    style={{ color: '#13B313' }}
                    draggable={true}
                    onDragEnd={(event) => {
                        const newLocation = { lat: event.lngLat.lat, lon: event.lngLat.lng }
                        const newPLocation = `${event.lngLat.lat}, ${event.lngLat.lng}`
                        setMarkersStart(newLocation);
                        formCreate.setFieldsValue({ PLocation: newPLocation })
                        getRoadName(newLocation).then((res: any) => {
                            if (res.name) {
                                formCreate.setFieldsValue({
                                    name: res.name,
                                })
                                setNotFoundRoad(false)
                            } else {
                                setNotFoundRoad(true);
                                formCreate.resetFields(['name'])
                            }
                            formCreate.setFieldsValue({
                                sta_cty: res.address.state
                                    ? res.address.state : res.address.city,
                                osm_combination_id: res.osm_type + res.osm_id,

                            })
                        }).catch((err) => {
                            setNotFoundRoad(true);
                            formCreate.resetFields(['name']);
                        });
                    }}
                >
                    <FontAwesomeIcon icon='location-dot' className='w-8 h-8'></FontAwesomeIcon>
                </Marker>)}

            </Map>
        </div>
    );
}

