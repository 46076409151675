import { Form, Input, Button, Divider, Row, Col, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd/es/form/Form';
import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getDirectionFromAngle } from '../../../helper/LocationHelper';
import { IMARKER_TYPES } from '../../../constant';
import { MarkerType } from '../../../constant/type';

interface CreatePointV2Props {
    onFinish: (values: any) => void;
    optionVectorRoadId: any;
    optionPointV2: any;
    indexFormFocus: number;
    setIndexFormFocus: React.Dispatch<React.SetStateAction<number>>;
    formCreate: FormInstance<any>,
    setIMarkerType: React.Dispatch<React.SetStateAction<string>>,
    setMarkersEnd: (value: React.SetStateAction<MarkerType | undefined>) => void,
    setMarkerCreate: React.Dispatch<any>,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
}

export const CreatePointV2: React.FC<CreatePointV2Props> = (props) => {
    const { onFinish,
        optionVectorRoadId,
        optionPointV2,
        indexFormFocus,
        setIndexFormFocus,
        formCreate,
        setIMarkerType,
        setMarkersEnd,
        setMarkerCreate,
        setIsLoading } = props;

    const cardStyle = {
        border: '1px solid #ccc',
        borderRadius: 12,
        paddingTop: 24,
        paddingLeft: 24,
        paddingRight: 24,
        marginBottom: 16,
        boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
        backgroundColor: '#fff',
    };
    return (
        <>
            <Form
                form={formCreate}
                layout="horizontal"
                onFinish={onFinish}
                autoComplete="off"
                style={{ width: '100%' }}
            >
                <Button htmlType='submit' className="bg-base text-white mr-2">Thêm nhiều biển báo</Button>
                <Form.List name="data" >
                    {(fields, { add, remove, move }) => (
                        <>
                            {fields.map((field) => (
                                <div key={field.key} onFocus={() => setIndexFormFocus(field.key)}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        setIndexFormFocus(field.key)
                                        let data = formCreate.getFieldValue('data');
                                        if (data && data.length > 0 && data[field.key] && data[field.key]?.PLocation) {
                                            const PLocation = data[field.key]?.PLocation;
                                            const regex = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/
                                            if (regex.test(PLocation)) {
                                                const [lat, lon] = PLocation.split(',').map(parseFloat);
                                                setMarkerCreate({ lat: lat, lon: lon });
                                            }
                                            if (data[field.key]?.direction) {
                                                if (regex.test(data[field.key].PLocationEnd)) {
                                                    const [lat, lon] = data[field.key].PLocationEnd.split(',').map(parseFloat);
                                                    setMarkersEnd({ lat: lat, lon: lon })
                                                }
                                            }
                                        }
                                    }
                                    }
                                    style={indexFormFocus === field.key ? { ...cardStyle, border: '2px solid #1890ff' } : cardStyle}
                                >
                                    <Row gutter={4}>
                                        <Col span={20}>
                                            <Form.Item
                                                name={[field.name, 'PLocation']}
                                                key={field.key + 'PLocation'}
                                                rules={[{ required: true, message: 'Thiếu toạ độ' }]}

                                            // style={{ width: '66%', marginRight: 8 }}
                                            >
                                                <Input allowClear placeholder='Nhập toạ độ (vĩ độ - lat, kinh độ - lon)' />
                                            </Form.Item></Col>
                                        <Col span={4}>
                                            <Button className="bg-base text-white" onClick={() => setIMarkerType(IMARKER_TYPES.create)}>Start</Button></Col>
                                    </Row>
                                    <Form.Item
                                        name={[field.name, 'PLocationEnd']}
                                        key={field.key + 'PLocationEnd'}
                                        hidden
                                    // style={{ width: '66%', marginRight: 8 }}
                                    >
                                    </Form.Item>
                                    <Row gutter={4}>
                                        <Col span={10}>
                                            <Form.Item name={[field.name, 'id']} hidden initialValue={field.key}></Form.Item>
                                            <Form.Item

                                                name={[field.name, 'direction']}
                                                key={field.key + 'direction'}
                                                rules={[
                                                    {
                                                        required: true,
                                                        validateTrigger: 'onFinish',
                                                        message: 'Vui lòng nhập góc',
                                                        type: 'number',
                                                        transform: (value) => Number(value),
                                                    },
                                                ]}>
                                                <Input
                                                    onChange={(e) => formCreate.setFieldValue('compass', getDirectionFromAngle(Number(e.target.value)))}
                                                    placeholder='Góc' allowClear />
                                            </Form.Item>
                                        </Col>
                                        <Col span={10}>
                                            <Form.Item rules={[{
                                                required: true,
                                                validateTrigger: 'onFinish',
                                                message: 'Vui lòng nhập góc để tính hướng',
                                            },
                                            ]}
                                                name={[field.name, 'compass']}
                                                key={field.key + 'compass'}
                                            >
                                                <Input disabled placeholder='Hướng' allowClear />
                                            </Form.Item>
                                        </Col>
                                        <Col span={2}>
                                            <Button className="bg-base text-white ml-2"
                                                onClick={() => setIMarkerType(IMARKER_TYPES.endUpdate)}

                                            >End</Button>
                                        </Col>
                                    </Row>
                                    <Form.Item
                                        name={[field.name, 'osm_id']}
                                        key={field.key + 'Osm_id'}
                                        labelCol={{ span: 4 }}
                                        wrapperCol={{ span: 16 }}
                                        label='Osm_id'>
                                        <Input allowClear placeholder='osm_id' disabled />
                                    </Form.Item>
                                    <Form.Item
                                        name={[field.name, 'ai_road_id']}
                                        key={field.key + 'ai_road_id'}
                                        label={'AI ROAD ID ' + field.key}
                                        rules={[
                                            {
                                                required: true,
                                                validateTrigger: 'onFinish',
                                                message: 'Vui lòng nhập AI ROAD ID',
                                                type: 'string',
                                                // transform: (value) => value.toString(),
                                            },
                                        ]}
                                    >
                                        <Select showSearch>
                                            {optionVectorRoadId.map((item: any) => <Select.Option key={item.value + Math.random()} value={item.value}>{item.label}</Select.Option>)}
                                        </Select>
                                    </Form.Item>
                                    <Row gutter={4}>
                                        <Col span={22}>

                                            <Form.Item
                                                name={[field.name, 'traffic_sign_id']}
                                                key={field.key + 'traffic_sign_id'}
                                                rules={[
                                                    {
                                                        required: true,
                                                        validateTrigger: 'onFinish',
                                                        message: 'Vui lòng nhập Traffic Sign Id',
                                                        type: 'string',
                                                        // transform: (value) => value.toString(),
                                                    },
                                                ]}
                                                label='Traffic Sign Id' >
                                                <Select showSearch>
                                                    {optionPointV2.map((item: any) => <Select.Option key={item.value + Math.random()} value={item.value}>{item.label}</Select.Option>)}
                                                </Select>

                                            </Form.Item>
                                        </Col>
                                        <Col span={2}>

                                            <Button
                                                style={{ zIndex: 1000 }}
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    const indexOfSelectItem = fields.findIndex((item) => item.key === field.key);
                                                    setIMarkerType(IMARKER_TYPES.create);

                                                    if (indexOfSelectItem !== -1 && indexOfSelectItem !== 0) {
                                                        const previousIndex = fields[indexOfSelectItem - 1].key;
                                                        setIndexFormFocus(Number(previousIndex));

                                                    } if (indexOfSelectItem === 0 && fields.length > 1 && fields[1] && fields[1].key) {
                                                        setIndexFormFocus(Number(fields[1].key));

                                                    }
                                                    remove(field.name);
                                                }}
                                                icon={<FontAwesomeIcon icon={['fas', 'trash']} className="text-red-500" size="lg"></FontAwesomeIcon>}
                                            >
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            ))}

                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => {
                                        setMarkersEnd(undefined);
                                        setMarkerCreate(undefined)

                                        add()
                                        setIsLoading(true);
                                        const checkLength = () => {
                                            const data = formCreate.getFieldValue('data');

                                            if (data && Array.isArray(data) && data.length > 0 && data[data.length - 1] && data[data.length - 1].id !== undefined) {

                                                setIndexFormFocus(Number(data[data.length - 1].id));
                                                setIMarkerType(IMARKER_TYPES.create);
                                                setIsLoading(false);
                                            }
                                        }
                                        setTimeout(checkLength, 500)
                                    }}
                                    block
                                    icon={<PlusOutlined />}
                                >
                                    Thêm biển
                                </Button>
                            </Form.Item>
                            <Divider style={{ marginTop: -8 }}></Divider>
                        </>
                    )}
                </Form.List>
            </Form>
        </>
    );
};