
import { FC } from 'react';
import { Button, Col, FormInstance, Form, Input, Row, Divider } from 'antd';
import { IMARKER_TYPES } from '../../../constant';
import { MapRef } from 'react-map-gl';
import { MarkerType } from '../../../constant/type';

interface PProps {
    formLine: FormInstance<any>,
    setIMarkerType: (value: React.SetStateAction<string>) => void,
    mapRef: React.RefObject<MapRef>,
    setMarkersEnd: (value: React.SetStateAction<MarkerType | undefined>) => void
    setMarkersStart: (value: React.SetStateAction<MarkerType | undefined>) => void
}
export const FormLine: FC<PProps> = ({
    formLine,
    setIMarkerType,
    mapRef,
    setMarkersStart,
    setMarkersEnd

}) => {
    const handleKeyUpStart = (event: any) => {
        if (event.keyCode === 13) {
            const srcLocation = formLine.getFieldValue('srcLocation');
            const regex = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/
            if (regex.test(srcLocation)) {
                formLine.setFields([
                    {
                        name: 'srcLocation',
                        errors: []
                    }
                ])
                const [lat, lon] = formLine.getFieldValue('srcLocation').split(',').map(parseFloat);
                console.log(lat, lon);
                setMarkersStart({
                    lat,
                    lon
                })
                mapRef!.current?.flyTo({ center: [lon, lat], animate: false });
            } else formLine.setFields([
                {
                    name: 'srcLocation',
                    errors: ['Vui lòng nhập đúng định dạng']
                }
            ])
            console.log(formLine.getFieldValue('srcLocation'));

        }
    }
    const handleKeyUpEnd = (event: any) => {
        if (event.keyCode === 13) {
            const dstLocation = formLine.getFieldValue('dstLocation');
            const regex = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/
            if (regex.test(dstLocation)) {
                const [lat, lon] = dstLocation.split(',').map(parseFloat);
                console.log(lat, lon);
                formLine.setFields([
                    {
                        name: 'dstLocation',
                        errors: []
                    }
                ])
                setMarkersEnd({
                    lat,
                    lon,
                })
                mapRef!.current?.flyTo({ center: [lon, lat], animate: false });
            }
            else formLine.setFields([
                {
                    name: 'dstLocation',
                    errors: ['Vui lòng nhập đúng định dạng']
                }
            ])

        }
    }
    return (<Form form={formLine} layout='vertical'>
        {/* <h3>Điểm bắt đầu</h3> */}
        <Row gutter={2}>
            <Col span={18}>
                <Form.Item required name='srcLocation' >
                    <Input onKeyUp={handleKeyUpStart} allowClear placeholder='Toạ độ start(vĩ độ-lat,kinh độ-lon)' />
                </Form.Item>
            </Col>
            <Col span={2}>
                <Button className='bg-base text-white w-24'
                    onClick={() => setIMarkerType(IMARKER_TYPES.start)}>Chọn</Button>
            </Col>
        </Row>
        <Row gutter={2}>
            <Col span={18}>
                <Form.Item required name='dstLocation'>
                    <Input onKeyUp={handleKeyUpEnd} allowClear placeholder='Toạ độ end(vĩ độ-lat,kinh độ-lon)' />
                </Form.Item>
            </Col>
            <Col span={2}>
                <Button className='bg-base text-white w-24'
                    onClick={() => setIMarkerType(IMARKER_TYPES.end)}
                >Chọn</Button>
            </Col>
        </Row>
        <Row className='-mb-4' gutter={4} >
            {/* <Col span={21}>
<Form.Item required label='Bearings' name='bearings' >
<Input allowClear placeholder='Bearings' defaultValue={-1} />
</Form.Item>
</Col> */}

        </Row>

        <Divider></Divider>
    </Form>)
}