import { Button, Card, Col, Form, Image, Input, Layout, Row, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { APP_COLOR, VALID_IP } from '../../constant';
import { useHistory } from 'react-router-dom';
import { publicIpv4 } from 'public-ip';
import { useEffect } from 'react';


const Login = (props: any) => {
  const [form] = useForm();
  const history = useHistory();

  const onFinish = ((data: any) => {
    if (data.username === 'admin' && data.password === 'admin') {
      const getIpv4 = async () => {
        return await publicIpv4();
      }
      getIpv4().then((res) => {
        if (res == VALID_IP) {
          localStorage.setItem('login', 'valid');
          history.push('/feedback');
          message.success('Đăng nhập thành công');
        }
        else {
          message.error('IP không hợp lệ');
        }
      });
    }
    else {
      message.error('Sai tài khoản hoặc mật khẩu');
    }
  });

  useEffect(() => {
    const getIpv4 = async () => {
      return await publicIpv4();
    }
    getIpv4().then((res) => {
      if (res == VALID_IP) {
        if (localStorage.getItem('login') == 'valid') {
          history.push('/feedback');
        }
      }
      else {
        message.error('IP không hợp lệ');
      }
    }).catch((err) => {
      console.log('ipv4', err);
    });
  }, [])



return <Layout className='h-screen items-center justify-center flex-row' >
<Row align={'middle'} gutter={[48, 16]}>
      <Col xs={20} md={12}>
        <Image
          alt='example'
          preview={false}
          src={'/logo.png'}
          className='block ml-auto mr-auto'
        />
      </Col>
      <Col xs={20} md={12}>
        <Card
          className='justify-center box-shadow-5'
        >
          <Form onFinish={onFinish}
            layout='horizontal'
            size='large'
            form={form}
            className='mt-9 mb-6 '
          >
            <Form.Item
              rules={[
                { required: true, validateTrigger: 'onFinish', },

              ]}
              name='username'
            // label={<InjectMessage id='auth.username' />}
            >
              <Input prefix={<UserOutlined onKeyDown={undefined} />} placeholder='User name' />
            </Form.Item>
            <Form.Item
              rules={[{
                required: true,
                validateTrigger: 'onFinish',
              },

              ]}
              name='password'
            >
              <Input.Password visibilityToggle prefix={<LockOutlined onKeyDown={undefined} />}
                placeholder='password' />
            </Form.Item>
            <Button className='block ml-auto mr-auto w-2/4 bg-base'
              htmlType='submit' type='primary'>
              Login
            </Button>
          </Form>
        </Card>
      </Col>
    </Row>
  </Layout>;
};
export default Login;
