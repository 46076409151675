import React, { useCallback, useEffect, useRef, useState, } from "react";
import RmglInteractiveMap, { Layer, LayerProps, Map, MapRef, MapboxStyle, NavigationControl, ScaleControl, Source, MapLayerMouseEvent, Marker, Popup } from "react-map-gl";
import { APP_COLOR, MAPBOX_TOKEN, MAP_STYLE, initialViewState, pointLayer } from "../../constant";
import { Button, DatePicker, Divider, Form, Input, List, Select, Radio, RadioChangeEvent } from "antd";
import moment from "moment";
import { IPlaybackData, ListRoute, MapPoint, MarkerType, PointData, PopupState } from "../../constant/type";
import { LngLat, LngLatBounds, } from "mapbox-gl";
import Slider from "antd/es/slider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './index.css';
import dayjs from "dayjs";
import { getMapPoint, getPlayback } from "../../services";
import { useParams } from 'react-router-dom';
import { useForm } from 'antd/es/form/Form';
// import MyImage from './marker-icon.svg';
import { checkBearing } from "../../helper/LocationHelper";
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

export default function Playback(props: any) {
    const { Idate, IcustomerPhone, IcustomerId }: any = useParams();

    const [listRoute, setListRoute] = useState<any>();
    const [pointData, setPointData] = useState<PointData>({ type: '', coordinates: [0, 0] });
    const [dataPlayback, setDataPlayback] = useState<IPlaybackData[]>();
    const [data, setData] = useState<ListRoute[]>();
    const mapRef = useRef<MapRef | null>(null);
    const [controlPlay, setControlPlay] = useState(true);
    const [prevControlPlay, setPrevControlPlay] = useState(true);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentStep, setCurrentStep] = useState(1);
    const [timeoutID, setTimeoutID] = useState<NodeJS.Timeout[]>([]);
    const bounds = new LngLatBounds();
    const [endRoute, setEndRoute] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState(0);
    const [flag, setFlag] = useState(0);
    const { RangePicker } = DatePicker;
    const [formPlayback] = useForm();
    const [popupOpen, setPopupOpen] = useState<PopupState>({
        lat: 0,
        lon: 0,
        display: false,
        ai_road_id: "",
        compass: "",
        id: 0,
        category: '',
        osm_id: '',
        sta_cty: '',

    });

    React.useEffect(() => {
        if (Idate && IcustomerPhone && IcustomerPhone != 'null') {
            const data = {
                phoneNumber: IcustomerPhone,
                date: [dayjs.unix(Idate).startOf('day'), dayjs.unix(Idate).endOf('day')]
            }
            formPlayback.setFieldsValue(data)
            getPlayback({
                phoneNumber: IcustomerPhone,
                beginTime: moment.unix(Idate).startOf('day').unix(),
                endTime: moment.unix(Idate).endOf('day').unix(),
            }).then((res) => {
                setDataPlayback(res.data);
            })

        } else if (Idate && IcustomerId) {
            setValue('customer_id');
            const data = {
                customer_id: IcustomerId,
                date: [dayjs.unix(Idate).startOf('day'), dayjs.unix(Idate).endOf('day')]
            }
            formPlayback.setFieldsValue(data)
            getPlayback({
                customer_id: IcustomerId,
                beginTime: moment.unix(Idate).startOf('day').unix(),
                endTime: moment.unix(Idate).endOf('day').unix(),
            }).then((res) => {
                setDataPlayback(res.data);
            })
        }
    }, [])

    React.useEffect(() => {
        if (dataPlayback && dataPlayback.length > 0) {
            const sortedData = [...dataPlayback].sort((a, b) => a.timestamp - b.timestamp);

            const processData = () => {
                const final = [];
                let tempArray = [sortedData[0]];

                for (let i = 1; i < sortedData.length; i++) {
                    const currentTimestamp = sortedData[i].timestamp;
                    const prevTimestamp = sortedData[i - 1].timestamp;
                    const timeDiffMinutes = (currentTimestamp - prevTimestamp) / 60;

                    if (timeDiffMinutes >= 30) {
                        final.push(tempArray);
                        tempArray = [sortedData[i]];
                    } else {
                        tempArray.push(sortedData[i]);
                    }
                }

                final.push(tempArray);
                setListRoute(final);
            };

            processData();
        }

    }, [dataPlayback]);

    useEffect(() => {
        if (data && data.length > 0) {

            const updatePointData = (index: number) => {
                if (!controlPlay) {
                    return;
                }
                if (index >= data.length) {
                    setEndRoute(true);
                    // Reached the end of the data
                    return;
                }

                const item = data[index];

                const nextIndex = index + 1;
                const nextItem = data[nextIndex];
                setCurrentIndex(nextIndex); // Update the current index

                setPointData({
                    type: 'Point', coordinates: [item.lon, item.lat], data: {
                        speedCurrent: item.speed_current,
                        maxSpeed: item.speed_max,
                        bearing: item.bearing
                    }
                });
                setInputValue(data[index].timestamp);

                const delay = nextItem ? (nextItem.timestamp - item.timestamp) * 1000 / currentStep : 0;

                const id = setTimeout(() => {
                    updatePointData(nextIndex)
                }, delay);

                setTimeoutID(prevTimeoutIDs => [...prevTimeoutIDs, id]);
            };

            timeoutID.forEach(id => {
                clearTimeout(id);
            });

            updatePointData(currentIndex);
        }
    }, [data, controlPlay, currentStep, flag]);


    const formatter = (value: any) => moment.unix(value.toString()).format('HH:mm:ss DD-MM-YYYY');
    const [markers, setMarkers] = useState<MarkerType>();
    const [points, setPoints] = useState<any>([]);

    const SliderComponent = (data: any) => {
        if (data) {
            const timestamps = data.map((item: any) => item.timestamp);

            // Find the minimum and maximum timestamps
            const minTimestamp = Math.min(...timestamps);
            const maxTimestamp = Math.max(...timestamps);

            return (

                <div className='flex flex-row justify-between items-center rounded-lg px-0.5 h-12 bg-sky'>
                    <div className="bg-blue w-6 h-6 flex items-center justify-center rounded-full"
                        onClick={() => {
                            if (endRoute) {
                                setCurrentIndex(0); // Update the current index
                                setInputValue(data[0].timestamp);
                                setEndRoute(false);
                                setFlag(flag + Math.random());

                            } else {
                                setControlPlay(!controlPlay);
                                if (!controlPlay) {
                                    timeoutID.forEach(id => clearTimeout(id));
                                }
                            }
                        }}
                    >
                        <FontAwesomeIcon
                            fontSize={18} color='white' icon={endRoute ? 'rotate-left' : controlPlay ? 'pause' : 'play'} />
                    </div>

                    <Slider
                        className="flex-1"
                        step={null}
                        tooltip={{ formatter }}
                        min={minTimestamp} max={maxTimestamp}
                        value={typeof inputValue === 'number' ? inputValue : 0}
                        onAfterChange={(value: any) => {
                            // timeoutID.forEach(id => clearTimeout(id));
                            if (prevControlPlay) {
                                setControlPlay(true);
                            } else
                                setControlPlay(false);
                            setFlag(flag + Math.random());
                        }}

                        onChange={(value: any) => {
                            setPrevControlPlay(controlPlay);
                            setControlPlay(false);
                            setEndRoute(false);
                            const index = data.findIndex((item: any) => item.timestamp == value);
                            setCurrentIndex(index); // Update the current index
                            setInputValue(data[index].timestamp);
                            if (!controlPlay) {
                                setPointData({
                                    type: 'Point', coordinates: [data[index].lon, data[index].lat], data: {
                                        speedCurrent: data[index].speed_current,
                                        maxSpeed: data[index].speed_max,
                                        bearing: data[index].bearing
                                    }
                                });
                            }
                        }}

                        marks={timestamps.reduce((acc: any, timestamp: any) => {
                            acc[timestamp] =
                            {
                                label: moment.unix(timestamp).format('HH'),
                                style: { fontSize: 0, color: APP_COLOR.base }
                            }
                            return acc;
                        }, {})} />
                    <Select

                        onChange={(value) => {
                            setCurrentStep(value);
                        }}
                        suffixIcon={<div className="w-0" />}

                        placeholder="1X"
                        options={
                            [{ value: 1, label: '1X' },
                            { value: 5, label: '5X' },
                            { value: 10, label: '10X' },
                            { value: 20, label: '20X' },
                            { value: 50, label: '50X' }]
                        }></Select>
                </div>
            );
        };
    }
    const pins = React.useMemo(
        () => {
            return points && points.map((point: MapPoint, index: any) => (
                <div className='text-red-500'>
                    <Marker
                        key={`marker-${index}`}
                        latitude={point.lat}
                        longitude={point.lon}
                        onClick={(e) => {
                            e.originalEvent.stopPropagation();

                            setPopupOpen({
                                display: true,
                                ...point
                            })
                        }}
                    >
                        {
                            point.category == 'speed' ? (
                                <img className='w-9 h-9' src={`/${point.max_speed}.png`}>
                                </img>
                            ) : point.category == 'camera' ? (
                                <img className='w-9 h-9' src={`/${point.category}.png`}>
                                </img>
                            ) : point.category == 'traffic-sign' ? (
                                <img className='w-9 h-9' src={`/${point.instruction}.png`}>
                                </img>
                            ) : point.category == 'others' && (<img src={`/pin-end.png`} ></img>)
                        }
                    </Marker>
                </div>
            ))
        },
        [points]
    );
    // Extract the timestamps from the data
    const onClickMap = ((event: MapLayerMouseEvent) => {
        const newMarker: MarkerType = {
            lat: event.lngLat.lat,
            lon: event.lngLat.lng,
        };
        getMapPoint(newMarker).then((res: MapPoint[]) => {

            setPoints(res);

        });

        setMarkers(newMarker);
        mapRef.current?.flyTo({ center: [event.lngLat.lng, event.lngLat.lat], animate: false });


    });
    const [value, setValue] = useState('phone_number');

    const onChange = (e: RadioChangeEvent) => {
        setValue(e.target.value);
    };
    return (
        <div className="flex flex-row items-stretch h-screen">
            <div className="w-[26rem] p-2 overflow-auto">
                <h3 className="text-lg font-bold mb-2">Lịch sử di chuyển</h3>
                <Radio.Group onChange={onChange} value={value}>
                    <Radio value={'phone_number'}>Phone Number</Radio>
                    <Radio value={'customer_id'}>Customer Id</Radio>
                </Radio.Group>
                <Form
                    labelAlign='left'
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 20 }}
                    form={formPlayback}
                    onFinish={(data) => {
                        data.beginTime = moment(data.date[0].$d).startOf('day').unix();
                        data.endTime = moment(data.date[1].$d).endOf('day').unix();
                        const { date, ...updatedFinalData } = data;
                        getPlayback(updatedFinalData).then((res) => {
                            setDataPlayback(res.data);
                        })
                    }
                    } >
                    {value == 'phone_number' && <Form.Item name='phoneNumber' label='Số điện thoại'
                        rules={[
                            {
                                required: true, validateTrigger: 'onFinish',
                                message: 'Vui lòng nhập số điện thoại'
                            },
                            {
                                pattern: new RegExp(/^(?:\+84|0)\s?\d+$/), message: 'Số điện thoại không hợp lệ', validateTrigger: 'onFinish',
                            }
                        ]}
                    >
                        <Input></Input>
                    </Form.Item>}
                    {value == 'customer_id' && <Form.Item name='customer_id' label='Customer id'
                        rules={[
                            {
                                required: true, validateTrigger: 'onFinish',
                                message: 'Vui lòng nhập customer id'
                            },

                        ]}
                    >
                        <Input></Input>
                    </Form.Item>}
                    <Form.Item name='date' label='Chọn ngày' rules={[
                        {
                            required: true, validateTrigger: 'onFinish',
                            message: 'Vui lòng chọn ngày', type: 'array'
                        }]}>
                        <RangePicker className="flex-1 flex"
                            format={'DD/MM/YYYY'}
                            defaultValue={[
                                dayjs().startOf('month'),
                                dayjs()
                            ]}
                        />
                    </Form.Item>
                    <Form.Item >
                        <Button className="bg-base text-white" htmlType='submit'>Tìm kiếm</Button>
                    </Form.Item>
                </Form>
                {SliderComponent(data)}
                <Divider></Divider>
                <List dataSource={listRoute} renderItem={(item: ListRoute[], index) => (
                    <List.Item
                        onClick={(e) => {
                            timeoutID.forEach(id => clearTimeout(id));
                            setControlPlay(true);
                            setEndRoute(false);
                            setCurrentIndex(0); // Update the current index
                            item.forEach(coord => bounds.extend(new LngLat(coord.lon, coord.lat)));
                            mapRef.current?.fitBounds(bounds, { padding: 20, animate: false });
                            setData(item);
                            setFlag(index + Math.random());
                        }}
                        className="bg-grey p-2 rounded-2xl mb-6 h-12"

                    >
                        <p className="font-bold">
                            Ngày : {moment.unix(item[0].timestamp).format('DD/MM/YYYY')}
                        </p>
                        <p className="font-bold">Thời gian: {moment.unix(item[0].timestamp).format('HH:mm')} - {moment.unix(item[item.length - 1].timestamp).format('HH:mm')}</p>
                    </List.Item>
                )}>

                </List>
            </div>
            {pointData && pointData.data &&
                <div className={`${Number(pointData.data.maxSpeed) < Number(pointData.data.speedCurrent) ? 'bg-red-500' : 'bg-black'}  
                absolute flex flex-row rounded-tl-[48px] rounded-bl-[48px] rounded-tr-[16px] rounded-br-[16px] 
                z-[2] 
                top-4 
                right-4`}>
                    <div className="flex items-center justify-center rounded-full w-24 h-24 border-8 border-red-500 bg-white border-solid">
                        <p className="font-bold text-4xl">
                            {pointData.data.maxSpeed}
                        </p>
                    </div>
                    {/* <span style={{whiteSpace: 'pre-line', color:'white', fontWeight: 'bold'}} >
                            {pointData.data.speedCurrent}{"\n"}km/h
                        </span> */}
                    <span className="whitespace-pre-line text-white font-bold flex flex-col items-center justify-center w-20 text-center text-2xl" >
                        <text className="text-white" >
                            {pointData.data.speedCurrent}
                        </text>
                        {"\n"}
                        <text className="text-white">
                            km/h
                        </text>
                        <text style={{ color: 'white', fontSize: 12 }}>{pointData.data.bearing.toFixed(0)} {checkBearing(pointData.data.bearing).map((direction) => (
                            <span>{direction} </span>
                        ))}</text>
                    </span>
                </div>
            }

            <Map
                style={{ flex: 4 }}
                initialViewState={initialViewState}
                onClick={onClickMap}
                // mapStyle="mapbox://styles/mapbox/dark-v9"
                mapStyle={MAP_STYLE as MapboxStyle}
                maxZoom={18}
                // onClick={onClick}
                mapboxAccessToken={MAPBOX_TOKEN}
            // interactiveLayerIds={['nonexist']}
            >
                <NavigationControl position="top-left" />
                <ScaleControl />
                {pins}
                {popupOpen.display && <Popup longitude={Number(popupOpen.lon)} latitude={Number(popupOpen.lat)}
                    anchor="top"
                    onClose={() => setPopupOpen({ ...popupOpen, display: false })}
                >
                    <p>Id: {popupOpen.id}</p>
                    <p>AI ROAD ID: {popupOpen.ai_road_id}</p>
                    <p>Compass: {popupOpen.compass}</p>
                    <p>Road: {popupOpen.name}</p>
                    <p>Osm id: {popupOpen.osm_id}</p>

                </Popup>}
                {pointData && (
                    <Source type="geojson" data={pointData}>
                        <Layer {...pointLayer} />
                    </Source>
                )}
                {markers && (<Marker
                    key={`marker`}
                    latitude={markers?.lat}
                    longitude={markers?.lon}>
                </Marker>)}
                {/* {line && <Source id="my-data" type="geojson" data={line}>
                    <Layer {...layerStyle} />
                </Source>} */}
            </Map>
        </div>
    );
}

