export const optionStaCty = [
    { value: "Thành phố Cần Thơ", label: "Thành phố Cần Thơ" },
    { value: "Thành phố Hà Nội", label: "Thành phố Hà Nội" },
    { value: "Thành phố Hải Phòng", label: "Thành phố Hải Phòng" },
    { value: "Thành phố Hồ Chí Minh", label: "Thành phố Hồ Chí Minh" },
    { value: "Thành phố Đà Nẵng", label: "Thành phố Đà Nẵng" },
    { value: "Tỉnh An Giang", label: "Tỉnh An Giang" },
    { value: "Tỉnh Bà Rịa - Vũng Tàu", label: "Tỉnh Bà Rịa - Vũng Tàu" },
    { value: "Tỉnh Bình Dương", label: "Tỉnh Bình Dương" },
    { value: "Tỉnh Bình Phước", label: "Tỉnh Bình Phước" },
    { value: "Tỉnh Bình Thuận", label: "Tỉnh Bình Thuận" },
    { value: "Tỉnh Bình Định", label: "Tỉnh Bình Định" },
    { value: "Tỉnh Bạc Liêu", label: "Tỉnh Bạc Liêu" },
    { value: "Tỉnh Bắc Giang", label: "Tỉnh Bắc Giang" },
    { value: "Tỉnh Bắc Kạn", label: "Tỉnh Bắc Kạn" },
    { value: "Tỉnh Bắc Ninh", label: "Tỉnh Bắc Ninh" },
    { value: "Tỉnh Bến Tre", label: "Tỉnh Bến Tre" },
    { value: "Tỉnh Cao Bằng", label: "Tỉnh Cao Bằng" },
    { value: "Tỉnh Cà Mau", label: "Tỉnh Cà Mau" },
    { value: "Tỉnh Gia Lai", label: "Tỉnh Gia Lai" },
    { value: "Tỉnh Hà Giang", label: "Tỉnh Hà Giang" },
    { value: "Tỉnh Hà Nam", label: "Tỉnh Hà Nam" },
    { value: "Tỉnh Hà Tĩnh", label: "Tỉnh Hà Tĩnh" },
    { value: "Tỉnh Hòa Bình", label: "Tỉnh Hòa Bình" },
    { value: "Tỉnh Hưng Yên", label: "Tỉnh Hưng Yên" },
    { value: "Tỉnh Hải Dương", label: "Tỉnh Hải Dương" },
    { value: "Tỉnh Hậu Giang", label: "Tỉnh Hậu Giang" },
    { value: "Tỉnh Kiên Giang", label: "Tỉnh Kiên Giang" },
    { value: "Tỉnh Kon Tum", label: "Tỉnh Kon Tum" },
    { value: "Tỉnh Lai Châu", label: "Tỉnh Lai Châu" },
    { value: "Tỉnh Long An", label: "Tỉnh Long An" },
    { value: "Tỉnh Lào Cai", label: "Tỉnh Lào Cai" },
    { value: "Tỉnh Lâm Đồng", label: "Tỉnh Lâm Đồng" },
    { value: "Tỉnh Lạng Sơn", label: "Tỉnh Lạng Sơn" },
    { value: "Tỉnh Nam Định", label: "Tỉnh Nam Định" },
    { value: "Tỉnh Nghệ An", label: "Tỉnh Nghệ An" },
    { value: "Tỉnh Ninh Bình", label: "Tỉnh Ninh Bình" },
    { value: "Tỉnh Ninh Thuận", label: "Tỉnh Ninh Thuận" },
    { value: "Tỉnh Phú Thọ", label: "Tỉnh Phú Thọ" },
    { value: "Tỉnh Phú Yên", label: "Tỉnh Phú Yên" },
    { value: "Tỉnh Quảng Bình", label: "Tỉnh Quảng Bình" },
    { value: "Tỉnh Quảng Nam", label: "Tỉnh Quảng Nam" },
    { value: "Tỉnh Quảng Ngãi", label: "Tỉnh Quảng Ngãi" },
    { value: "Tỉnh Quảng Ninh", label: "Tỉnh Quảng Ninh" },
    { value: "Tỉnh Quảng Trị", label: "Tỉnh Quảng Trị" },
    { value: "Tỉnh Sóc Trăng", label: "Tỉnh Sóc Trăng" },
    { value: "Tỉnh Sơn La", label: "Tỉnh Sơn La" },
    { value: "Tỉnh Thanh Hóa", label: "Tỉnh Thanh Hóa" },
    { value: "Tỉnh Thái Bình", label: "Tỉnh Thái Bình" },
    { value: "Tỉnh Thái Nguyên", label: "Tỉnh Thái Nguyên" },
    { value: "Tỉnh Thừa Thiên Huế", label: "Tỉnh Thừa Thiên Huế" },
    { value: "Tỉnh Tiền Giang", label: "Tỉnh Tiền Giang" },
    { value: "Tỉnh Trà Vinh", label: "Tỉnh Trà Vinh" },
    { value: "Tỉnh Tuyên Quang", label: "Tỉnh Tuyên Quang" },
    { value: "Tỉnh Tây Ninh", label: "Tỉnh Tây Ninh" },
    { value: "Tỉnh Vĩnh Long", label: "Tỉnh Vĩnh Long" },
    { value: "Tỉnh Vĩnh Phúc", label: "Tỉnh Vĩnh Phúc" },
    { value: "Tỉnh Yên Bái", label: "Tỉnh Yên Bái" },
    { value: "Tỉnh Điện Biên", label: "Tỉnh Điện Biên" },
    { value: "Tỉnh Đăk Lăk", label: "Tỉnh Đăk Lăk" },
    { value: "Tỉnh Đăk Nông", label: "Tỉnh Đăk Nông" },
    { value: "Tỉnh Đồng Nai", label: "Tỉnh Đồng Nai" },
    { value: "Tỉnh Đồng Tháp", label: "Tỉnh Đồng Tháp" },
    { value: "ខេត្តស្វាយរៀង", label: "ខេត្តស្វាយរៀង" }
    ]
    