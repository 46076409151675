import axios, { AxiosResponse } from "axios";
import { AudioData, DataPointByAIRoadIDPayload, DataPointPayload, FeedbackData, IAddMultiplePointV2, IContentToSpeech, ICreateGpx, IOtherPoint, IPlaybackDataResponse, IReGenerateData, IReGenerateGPXResponse, IResponseDataPointV2, IResponseDataPointV2AIRoadID, IResponseUpdateBlacklist, IResponseUserDetail, ISearchRoute, ISpeedPoint, IUpdateGpx, IUpdatePointV2, IUpdateSpeedPoint, IVectorCreateResponse, IVectorRoad, IdPoint, LineCongestionPayload, MapPoint, MapViewResponse, PlaybackData, ReplyFeedbackData, TrafficBulletin, TrafficCongestionData, TrafficPayload, TrafficResponse, UpdateTrafficBulletin } from "../constant/type";
import { message } from 'antd';
import { del, get, post } from "../helper/AxiosCustom";
import { NOMINATIM_URL, STAGING_NOMINATIM_URL } from "../constant";

export const getFeedback = async (reply: any): Promise<AxiosResponse<FeedbackData[]>> => {
  try {
    const response: AxiosResponse<FeedbackData[]> = await get('/dashboard/list-feedback', { params: reply });
    return response;
  } catch (error) {
    throw new Error(`Failed to get feedback: ${error}`);
  }
};

export const postFeedback = async (reply: any): Promise<AxiosResponse> => {
  try {
    const response = await post<ReplyFeedbackData>('/dashboard/admin-feedback', reply);
    return response;
  } catch (error) {
    throw new Error(`Failed to post feedback: ${error}`);
  }
};

export const getMapPoint = async (data: any): Promise<MapPoint[]> => {
  try {
    const response: AxiosResponse<MapViewResponse> = await get("/tool/map-view/get-data-point/", { params: data });
    return response.data.results;
  } catch (error) {
    throw new Error(`Failed to get point: ${error}`);
  }

};
export const getRoadName = async (point: any): Promise<string> => {
  try {
    const response: AxiosResponse<string> = await axios.get(`https://nominatim-staging.gofa.vn/reverseV2?format=json&accept-language=vi&lat=${point.lat}&lon=${point.lon}&zoom=17`);
    return response.data;
  } catch (error) {
    throw new Error(`Failed to get point: ${error}`);
  }
};

interface DeleteMapPointesponse {
  status: number;
}

export const deleteMapPoint = async (id: IdPoint): Promise<number> => {
  try {
    const response: AxiosResponse<DeleteMapPointesponse> = await del("/tool/map-view/delete-data-point", { data: id });
    return response.status;
  } catch (error) {
    throw new Error(`Failed to delete point: ${error}`);
  }
};


export const addSpeedPoint = async (data: ISpeedPoint): Promise<any> => {
  try {
    const response: AxiosResponse = await post("/tool/map-view/update-speed-data-point", data);
    return response;
  } catch (error) {
    message.error('Có lỗi xảy ra');
    throw new Error(`Failed to add point: ${error}`);
  }
};

export const addOtherPoint = async (data: IOtherPoint): Promise<any> => {
  //inclue camera, traffic sign, other
  try {
    const response: AxiosResponse = await post("/tool/map-view/update-other-data-point", data);
    return response;
  } catch (error) {
    message.error('Có lỗi xảy ra');
    throw new Error(`Failed to add point: ${error}`);
  }
};

export const getAiRoadId = async (): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await get("/tool/map-view/get-list-ai-road-id");
    return response.data;
  } catch (error) {
    throw new Error(`Failed to get point: ${error}`);
  }
};

export const updateSpeedPoint = async (data: IUpdateSpeedPoint): Promise<any> => {

  try {
    const response: AxiosResponse = await post("/tool/map-view/update-speed-data-point", data);
    return response;
  } catch (error) {
    message.error('Có lỗi xảy ra');
    throw new Error(`Failed to add point: ${error}`);
  }
};

//GPX GENERATOR

export const searchRoute = async (data: ISearchRoute): Promise<any> => {

  try {
    const response: AxiosResponse = await get("/tool/gpx-generator/search-route", { params: data });
    return response;
  } catch (error) {
    message.error('Có lỗi xảy ra');
    throw new Error(`Failed to add point: ${error}`);
  }
};

export const createGpx = async (data: ICreateGpx): Promise<AxiosResponse<IVectorCreateResponse>> => {
  try {
    const response: AxiosResponse<IVectorCreateResponse> = await post("/tool/gpx-generator/create", data);
    return response;
  } catch (error) {
    message.error('Có lỗi xảy ra');
    throw new Error(`Failed to add point: ${error}`);
  }
};

export const listVectorRoad = async (): Promise<AxiosResponse<IVectorRoad[]>> => {
  try {
    const response: AxiosResponse<IVectorRoad[]> = await get("/tool/gpx-generator/list-vector-road");
    return response;
  } catch (error) {
    message.error('Có lỗi xảy ra');
    throw new Error(`Failed to add point: ${error}`);
  }
};

export const reGenerateGpx = async (data: IReGenerateData): Promise<AxiosResponse<IReGenerateGPXResponse>> => {
  try {
    const response: AxiosResponse<IReGenerateGPXResponse> = await post("/tool/gpx-generator/re-gen", data);
    return response;
  } catch (error) {
    message.error('Có lỗi xảy ra');
    throw new Error(`Failed to add point: ${error}`);
  }
};

export const updateGpx = async (data: IUpdateGpx): Promise<AxiosResponse<any>> => {
  try {
    const response: AxiosResponse<any> = await post("/tool/gpx-generator/update-vector-road", data);
    return response;
  } catch (error) {
    message.error('Có lỗi xảy ra');
    throw new Error(`Failed to add point: ${error}`);
  }
};

export const postTrafficNews = async (data: LineCongestionPayload | TrafficBulletin): Promise<AxiosResponse<any>> => {
  try {
    const response: AxiosResponse<any> = await post("/tool/realtime-traffic/create-realtime-traffic", data);
    message.success('Thêm bản tin thành công');
    return response;
  } catch (error) {
    message.error('Có lỗi xảy ra');
    throw new Error(`Failed to add point: ${error}`);
  }
};

export const postUpdateTrafficNews = async (data: LineCongestionPayload | UpdateTrafficBulletin): Promise<AxiosResponse<any>> => {
  try {
    const response: AxiosResponse<any> = await post("/tool/realtime-traffic/update-realtime-traffic", data);
    message.success('Cập nhật bản tin thành công');
    return response;
  } catch (error) {
    message.error('Có lỗi xảy ra');
    throw new Error(`Failed to add point: ${error}`);
  }
};


export const listRealtimeTraffic = async (payload: TrafficPayload): Promise<AxiosResponse<TrafficResponse>> => {
  try {
    const response: AxiosResponse<TrafficResponse> = await get("/tool/realtime-traffic/get-realtime-traffic", { payload });
    return response;
  } catch (error) {
    message.error('Có lỗi xảy ra');
    throw new Error(`Failed to add point: ${error}`);
  }
};

export const deleteRealtimeTraffic = async (id: IdPoint): Promise<number> => {
  try {
    const response: AxiosResponse<DeleteMapPointesponse> = await del("/tool/realtime-traffic/delete-realtime-traffic", { data: id });
    message.success('Xoá bản tin thành công');

    return response.status;
  } catch (error) {
    throw new Error(`Failed to delete point: ${error}`);
  }
};


export const contentToSpeech = async (data: IContentToSpeech): Promise<AxiosResponse<any>> => {
  try {
    const response: AxiosResponse<any> = await post("/tool/realtime-traffic/convert-content-to-speech", data);
    return response;
  } catch (error) {
    message.error('Có lỗi xảy ra');
    throw new Error(`Failed to add point: ${error}`);
  }
};

export const getFixedSound = async (): Promise<AxiosResponse<AudioData[]>> => {
  try {
    const response: AxiosResponse<AudioData[]> = await get("/tool/realtime-traffic/fixedTTSAudio");
    return response;
  } catch (error) {
    message.error('Có lỗi xảy ra');
    throw new Error(`Failed to add point: ${error}`);
  }
};

export const getPlayback = async (params: PlaybackData): Promise<AxiosResponse<IPlaybackDataResponse>> => {
  try {
    const response: AxiosResponse<IPlaybackDataResponse> = await get("/tool/history", { params });
    return response;
  } catch (error) {
    message.error('Có lỗi xảy ra');
    throw new Error(`Failed to add point: ${error}`);
  }
};

export const getRealtimeTrafficCongestion = async (): Promise<AxiosResponse<TrafficCongestionData[]>> => {
  try {
    const response: AxiosResponse<TrafficCongestionData[]> = await get("/tool/realtime-traffic/get-realtime-traffic-congestion");
    return response;
  } catch (error) {
    message.error('Có lỗi xảy ra');
    throw new Error(`Failed to add point: ${error}`);
  }
};

export const getDataOsrm = async (params: any): Promise<AxiosResponse<any>> => {
  try {
    const response: AxiosResponse<any> = await get("/tool/osm-data/list", { params });
    return response;
  } catch (error) {
    throw new Error(`Failed to add point: ${error}`);
  }
}

export const getSuspicious = async (params: any): Promise<AxiosResponse<any>> => {
  try {
    const response: AxiosResponse<any> = await get("/tool/get-suspicious", { params });
    return response;

  }
  catch (error) {
    throw new Error(`Failed to add point: ${error}`);
  }
}

//V2
export const getDataPoint = async (params: DataPointPayload): Promise<AxiosResponse<IResponseDataPointV2[]>> => {
  try {
    const response: AxiosResponse<IResponseDataPointV2[]> = await get(`/v2/tool/map-view/get-data-point`, { params });
    return response;

  }
  catch (error) {
    throw new Error(`Failed to add point: ${error}`);
  }
}

export const getNominatim = async (params: any): Promise<AxiosResponse<any>> => {
  try {
    const response: AxiosResponse<any> =
      await axios.get(NOMINATIM_URL, { params });
    return response;

  }
  catch (error) {
    throw new Error(`Failed to add point: ${error}`);
  }
}

export const searchNominatim = async (params: {
  lat: number,
  format: string,
  lon: number,
}): Promise<AxiosResponse<any>> => {
  try {
    const response: AxiosResponse<any> =
      await axios.get(STAGING_NOMINATIM_URL, {
        params: {
          ...params,
          zoom: 17
        }
      });
    return response;
  }
  catch (error) {
    throw new Error(`Failed to add point: ${error}`);
  }
}

export const getDataPointAiRoadID = async (params: DataPointByAIRoadIDPayload): Promise<AxiosResponse<IResponseDataPointV2AIRoadID[]>> => {
  try {
    const response: AxiosResponse<IResponseDataPointV2AIRoadID[]> = await get(`/v2/tool/map-view/get-data-point`, { params });
    return response;

  }
  catch (error) {
    throw new Error(`Failed to add point: ${error}`);
  }
}

export const getDetailUser = async (params: { id: string }): Promise<AxiosResponse<IResponseUserDetail>> => {
  try {
    const response: AxiosResponse<IResponseUserDetail> = await get(`/tool/get-details`, { params });
    return response;

  }
  catch (error) {
    throw new Error(`Failed to add point: ${error}`);
  }
}

export const updateBlacklist = async (data: {
  id: string,
  blacklist: boolean
}): Promise<AxiosResponse<IResponseUpdateBlacklist>> => {
  try {
    const response: AxiosResponse<IResponseUpdateBlacklist> = await post(`/tool/update-blacklist-status`, data);
    if (response.status === 200) {
      message.success(response.data.blacklist ? 'Gắn cờ thành công' : 'Huỷ gắn cờ thành công');
    }
    return response;

  }
  catch (error) {
    throw new Error(`Failed to add point: ${error}`);
  }
}

export const updatePointV2 = async (data: IUpdatePointV2): Promise<AxiosResponse<any>> => {
  try {
    const response: AxiosResponse<any> = await post(`/v2/tool/map-view/update-data-point`, data);
    return response;

  }
  catch (error) {
    message.error(`Có lỗi xảy ra ${error}`);
    throw new Error(`Failed to update point: ${error}`);
  }
}

export const deletePointV2 = async (data: { id: any[] }): Promise<AxiosResponse<any>> => {
  try {
    const response: AxiosResponse<any> = await del(`/v2/tool/map-view/delete-data-point`, { data });
    return response;

  }
  catch (error) {
    throw new Error(`Failed to delete point: ${error}`);
  }
}

export const addMultiplePointV2 = async (data: IAddMultiplePointV2): Promise<AxiosResponse<any>> => {
  try {
    const response: AxiosResponse<any> = await post(`/v2/tool/map-view/create-data-point`, { data });
    if (response.status === 200) {
      message.success('Thêm điểm thành công');

    } else {
      message.error('Có lỗi xảy ra');
    }
    return response;

  }
  catch (error) {
    throw new Error(`Failed to delete point: ${error}`);
  }
}