import * as turf from '@turf/turf';
import { GeoJSONFeature, GeoJSONFeatureCollection } from '../../constant/type';
import { vietnamCity } from './city';
import { vietnamBoundariesData } from './pro';

function calculateCenter(point1:any, point2:any, point3:any, point4:any) {
  // Tính trung bình của tọa độ x và y của 4 điểm
  var centerX = (point1[0] + point2[0] + point3[0] + point4[0]) / 4;
  var centerY = (point1[1] + point2[1] + point3[1] + point4[1]) / 4;

  // Trả về tọa độ tâm dưới dạng một mảng
  return [centerX, centerY];
}

export function createRectangleJSON(topLeft: [number, number], bottomRight: [number, number]): {} {
    const rectangle = {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [[
          [topLeft[0], topLeft[1]],
          [topLeft[0], bottomRight[1]],
          [bottomRight[0], bottomRight[1]],
          [bottomRight[0], topLeft[1]],
          [topLeft[0], topLeft[1]]
        ]]
      }
    };
    return rectangle;
  }
  
  export function generateCellsWithinPolygon(
    polygon: any,
    cellWidth: number,
    cellHeight: number
  ): any {
    const polygonData: any = polygon[0].geometry.coordinates; // Extracting the coordinates of the polygon
    const cellWidthDegrees = cellWidth / 111.32;
    const cellHeightDegrees = cellHeight / 111.32;
  
    const coordinates = polygonData[0][0]; // Extracting the coordinates of the polygon
  
    const lonValues = coordinates.map((point: number[]) => point[0]);
    const latValues = coordinates.map((point: number[]) => point[1]);
  
    const startLon = Math.min(...lonValues);
    const endLon = Math.max(...lonValues);
    const startLat = Math.min(...latValues);
    const endLat = Math.max(...latValues);
  
    const numCols = Math.ceil((endLon - startLon) / cellWidthDegrees);
    const numRows = Math.ceil((endLat - startLat) / cellHeightDegrees);
  
    const cells: any = [];
  
    for (let col = 0; col < numCols - 1; col++) {
      for (let row = 0; row < numRows - 1; row++) {
        // Your logic for processing each cell here
        // const cell: Cell = {
        //   // Define the properties of each cell based on your requirements
        // };
        const cellMinLon = startLon + col * cellWidthDegrees;
        const cellMinLat = startLat + row * cellHeightDegrees;
        const cellMaxLon = cellMinLon + cellWidthDegrees;
        const cellMaxLat = cellMinLat + cellHeightDegrees;
        const point1 = turf.point([cellMinLon, cellMinLat]);
        const point2 = turf.point([cellMaxLon, cellMinLat]);
        const point3 = turf.point([cellMaxLon, cellMaxLat]);
        const point4 = turf.point([cellMinLon, cellMaxLat]);
        const checkBoundaries = isPolygonWithinBoundaries(point1, point2, point3, point4);
        if (checkBoundaries) {
  
          const multiPolygon: any = {
            type: "MultiPolygon",
            properties: {
            },
            coordinates: [
              [
                [
                  [cellMinLon, cellMinLat],
                  [cellMaxLon, cellMinLat],
                  [cellMaxLon, cellMaxLat],
                  [cellMinLon, cellMaxLat],
                  [cellMinLon, cellMinLat]
                ]
              ]
            ]
          };
          const feature: GeoJSONFeature = {
            type: "Feature",
            geometry: multiPolygon,
            properties: {
            }
          };
          cells.push(feature);
        }
      }
    }
  
    return cells;
  }
  
  export function getBoundingBoxCoordinates(): [number, number, number, number] {
    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;
  
    const coordinates = vietnamCity.features[0].geometry!.coordinates[0][0];
  
    for (const point of coordinates) {
      const [longitude, latitude] = point;
  
      if (longitude < minX) minX = longitude;
      if (longitude > maxX) maxX = longitude;
      if (latitude < minY) minY = latitude;
      if (latitude > maxY) maxY = latitude;
    }
  
  
    return [minX, minY, maxX, maxY];
  }
  
  export function deleteFeatureByIndex(
    featureCollection: GeoJSONFeatureCollection,
    index: number,
    smallCellWidth: number,
    smallCellHeight: number
  ): GeoJSONFeatureCollection {
    const updatedFeatures = featureCollection.features.filter(
      (feature) => feature.properties.index !== index
    );
    const findFeatures = featureCollection.features.filter(
      (feature) => feature.properties.index == index
    );
    generateCellsWithinPolygon(findFeatures, smallCellWidth, smallCellHeight).forEach((item: any) => {
      updatedFeatures.push(item);
    })
    console.log(updatedFeatures);
  
    return {
      ...featureCollection,
      features: updatedFeatures
    };
  }
  
  export function isPolygonWithinBoundaries(point1: any, point2: any, point3: any, point4: any): boolean {
  
    const checkBoundaries = vietnamBoundariesData.features[0].geometry!.coordinates.some((item: any) => {
      const polygonData = turf.polygon(item);
      return (
        turf.booleanPointInPolygon(point1, polygonData) ||
        turf.booleanPointInPolygon(point2, polygonData) ||
        turf.booleanPointInPolygon(point3, polygonData) ||
        turf.booleanPointInPolygon(point4, polygonData)
      );
    });
  
    if (checkBoundaries) {
      return true;
    }
    return false;
  }
  
  export function createCellGrid(
    width: number,
    height: number,
    startLat: number,
    startLon: number,
    endLat: number,
    endLon: number
  ): any {
    const featureCollectionData: GeoJSONFeatureCollection = {
      type: "FeatureCollection",
      features: []
    };
    const cellWidthDegrees = width / 111.32;
    const cellHeightDegrees = height / 111.32;
    const numCols = Math.ceil((endLon - startLon) / cellWidthDegrees);
    const numRows = Math.ceil((endLat - startLat) / cellHeightDegrees);
    let index = 0;
    for (let col = 0; col < numCols; col++) {
      for (let row = 0; row < numRows; row++) {
        const cellMinLon = startLon + col * cellWidthDegrees;
        const cellMinLat = startLat + row * cellHeightDegrees;
        const cellMaxLon = cellMinLon + cellWidthDegrees;
        const cellMaxLat = cellMinLat + cellHeightDegrees;
        const point1 = turf.point([cellMinLon, cellMinLat]);
        const point2 = turf.point([cellMaxLon, cellMinLat]);
        const point3 = turf.point([cellMaxLon, cellMaxLat]);
        const point4 = turf.point([cellMinLon, cellMaxLat]);
        const checkBoundaries = isPolygonWithinBoundaries(point1, point2, point3, point4);        
        if (checkBoundaries) {
          const multiPolygon: any = {
            type: "MultiPolygon",
            properties: {
              center: calculateCenter([cellMinLon, cellMinLat], [cellMaxLon, cellMinLat], [cellMaxLon, cellMaxLat], [cellMinLon, cellMaxLat]),
              index: index
            },
            coordinates: [
              [
                [
                  [cellMinLon, cellMinLat],
                  [cellMaxLon, cellMinLat],
                  [cellMaxLon, cellMaxLat],
                  [cellMinLon, cellMaxLat],
                  [cellMinLon, cellMinLat]
                ]
              ]
            ]
          };
          const feature: GeoJSONFeature = {
            type: "Feature",
            geometry: multiPolygon,
            properties: {
              index: index,
              center: calculateCenter([cellMinLon, cellMinLat], [cellMaxLon, cellMinLat], [cellMaxLon, cellMaxLat], [cellMinLon, cellMaxLat]),

            }
          };
          index++;
          featureCollectionData.features.push(feature);
        }
      }
    }
    console.log('FeatureCollection:', featureCollectionData);
  
  
    return featureCollectionData;
  }
  export function isAnyPointOnLineStringInsidePolygon(lineString: turf.helpers.Feature<turf.helpers.LineString, turf.helpers.Properties>,
    polygon: turf.helpers.Feature<turf.helpers.Polygon, turf.helpers.Properties>) {
  
    const lineSegmentLength = turf.length(lineString);
  
    const stepSize = lineSegmentLength / 1000; // Dividing line segment into 100 steps for better accuracy, adjust as needed
  
    for (let i = 0; i <= 1000; i++) {
      const pointOnLine = turf.along(lineString, i * stepSize, { units: 'kilometers' });
  
      if (turf.booleanPointInPolygon(pointOnLine, polygon)) {
        return true;
      }
    }
    return false;
  }
  
  export function createMultiPolygonFromLineString(coords: any[], width: number, height: number) {
    const lineString = turf.lineString(coords);
    const bbox = turf.bbox(lineString);
    const polygons = [];
  
    for (let lon = bbox[0]; lon <= bbox[2]; lon += width / 111.32) {
      for (let lat = bbox[1]; lat <= bbox[3]; lat += height / 111.32) {
        const polygon = turf.polygon([
          [
            [lon, lat],
            [lon + width / 111.32, lat],
            [lon + width / 111.32, lat + height / 111.32],
            [lon, lat + height / 111.32],
            [lon, lat],
          ],
        ]);
        if (isAnyPointOnLineStringInsidePolygon(lineString, polygon)) {
          polygons.push(polygon);
        }
      }
    }
  
    const featureCollection = turf.featureCollection(polygons);
    return featureCollection;
  }