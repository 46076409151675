export const vietnamCity = {
    "type": "FeatureCollection",
    "features":        [   {
        "type": "Feature",
        "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    [
                        [
                            105.176559,
                            19.896324
                        ],
                        [
                            105.156013,
                            19.922415
                        ],
                        [
                            105.048149,
                            19.930849
                        ],
                        [
                            104.906265,
                            19.998468
                        ],
                        [
                            104.993027,
                            20.094931
                        ],
                        [
                            104.933578,
                            20.126444
                        ],
                        [
                            104.926392,
                            20.159048
                        ],
                        [
                            104.861145,
                            20.141718
                        ],
                        [
                            104.774498,
                            20.198416
                        ],
                        [
                            104.613693,
                            20.24246
                        ],
                        [
                            104.616524,
                            20.361273
                        ],
                        [
                            104.718979,
                            20.406837
                        ],
                        [
                            104.660217,
                            20.475033
                        ],
                        [
                            104.608093,
                            20.421991
                        ],
                        [
                            104.526955,
                            20.413437
                        ],
                        [
                            104.46788,
                            20.368769
                        ],
                        [
                            104.407341,
                            20.387749
                        ],
                        [
                            104.41478,
                            20.426933
                        ],
                        [
                            104.375977,
                            20.44326
                        ],
                        [
                            104.386826,
                            20.490091
                        ],
                        [
                            104.473595,
                            20.51638
                        ],
                        [
                            104.540604,
                            20.598642
                        ],
                        [
                            104.600807,
                            20.605852
                        ],
                        [
                            104.63649,
                            20.65263
                        ],
                        [
                            104.686287,
                            20.594395
                        ],
                        [
                            104.775902,
                            20.573246
                        ],
                        [
                            104.812233,
                            20.632725
                        ],
                        [
                            104.895447,
                            20.670485
                        ],
                        [
                            104.94915,
                            20.643053
                        ],
                        [
                            104.981613,
                            20.574036
                        ],
                        [
                            105.104454,
                            20.573187
                        ],
                        [
                            105.169441,
                            20.515205
                        ],
                        [
                            105.229187,
                            20.504499
                        ],
                        [
                            105.257401,
                            20.434216
                        ],
                        [
                            105.344635,
                            20.390968
                        ],
                        [
                            105.539528,
                            20.368534
                        ],
                        [
                            105.562271,
                            20.317369
                        ],
                        [
                            105.598457,
                            20.3123
                        ],
                        [
                            105.739883,
                            20.185665
                        ],
                        [
                            105.96035,
                            20.065935
                        ],
                        [
                            106.075165,
                            20.063322
                        ],
                        [
                            106.023613,
                            19.9575
                        ],
                        [
                            105.996948,
                            19.971189
                        ],
                        [
                            105.972504,
                            19.963612
                        ],
                        [
                            106.001007,
                            19.968651
                        ],
                        [
                            106.003609,
                            19.945
                        ],
                        [
                            105.953613,
                            19.919722
                        ],
                        [
                            105.935379,
                            19.783928
                        ],
                        [
                            105.837776,
                            19.667231
                        ],
                        [
                            105.814163,
                            19.426664
                        ],
                        [
                            105.782921,
                            19.41069
                        ],
                        [
                            105.805168,
                            19.286894
                        ],
                        [
                            105.742775,
                            19.322493
                        ],
                        [
                            105.664665,
                            19.313471
                        ],
                        [
                            105.627472,
                            19.37973
                        ],
                        [
                            105.588684,
                            19.372272
                        ],
                        [
                            105.524437,
                            19.46121
                        ],
                        [
                            105.390488,
                            19.454006
                        ],
                        [
                            105.352608,
                            19.5375
                        ],
                        [
                            105.293472,
                            19.475744
                        ],
                        [
                            105.268898,
                            19.529591
                        ],
                        [
                            105.282089,
                            19.606342
                        ],
                        [
                            105.248466,
                            19.657396
                        ],
                        [
                            105.167435,
                            19.684244
                        ],
                        [
                            105.135864,
                            19.743799
                        ],
                        [
                            105.13237,
                            19.827085
                        ],
                        [
                            105.182037,
                            19.853151
                        ],
                        [
                            105.176559,
                            19.896324
                        ]
                    ]
                ],
                [
                    [
                        [
                            105.932503,
                            19.38085
                        ],
                        [
                            105.929443,
                            19.3575
                        ],
                        [
                            105.912811,
                            19.374741
                        ],
                        [
                            105.932503,
                            19.38085
                        ]
                    ]
                ],
                [
                    [
                        [
                            105.827522,
                            19.336578
                        ],
                        [
                            105.827492,
                            19.303461
                        ],
                        [
                            105.814171,
                            19.331671
                        ],
                        [
                            105.827522,
                            19.336578
                        ]
                    ]
                ]
            ]
        },
        "properties": {
            "cartodb_id": 46,
            "id_1": 57,
            "name": "Thanh Hóa",
            "slug": "vietnam-thanhhoa"
        }
    },
    {
        'type': 'Feature',
        'properties': {
            'color': '#F19C5C', // red,
            "name" : 'rectangle cover Ha Noi'
        },
        'geometry': {
        "type": "MultiPolygon",

        "coordinates": [
            [[
                [
                    105.28470698200005,
                    21.385984408000077
                ],
                [
                    105.28470698200005,
                    20.565141830000044
                ],
                [
                    106.01836448300006,
                    20.565141830000044
                ],
                [
                    106.01836448300006,
                    21.385984408000077
                ],
                [
                    105.28470698200005,
                    21.385984408000077
                ]
            ]]
        ]
    }
    }
]
}