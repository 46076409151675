import { Button, Col, Divider, Form, FormInstance, Input, Row, Select, message } from "antd";
import { FC } from "react"
import { APP_COLOR, CMARKER_TYPES, kindOfWayDirection } from "../../../constant";
import { CMarkerType, MarkerType } from "../../../constant/type";
import { LngLat, LngLatBounds } from "mapbox-gl";
import { decode } from "@googlemaps/polyline-codec";
import { MapRef } from "react-map-gl";
import { SwapOutlined } from "@ant-design/icons";
import { reGenerateGpx, updateGpx } from "../../../services";
import { handleCallListVectorRoad } from "../helper";

interface PProps {
    setIMarkerType: React.Dispatch<React.SetStateAction<string>>,
    formSearchRoute: FormInstance<any>,
    formUpdateGpx: FormInstance<any>,
    optionVectorRoadId: any,
    setLine: React.Dispatch<any>
    mapRef: React.RefObject<MapRef>
    setIsCallSearch: React.Dispatch<React.SetStateAction<boolean>>,
    setMarkersStart: (value: React.SetStateAction<CMarkerType | undefined>) => void,
    setMarkersEnd: React.Dispatch<React.SetStateAction<CMarkerType | undefined>>
    markersStart: MarkerType | undefined,
    markersEnd: MarkerType | undefined,
    swapMarkers: () => void,
    setLineCreate: React.Dispatch<any>,
    handleCopyClick: (text: any) => void,
    setOptionVectorRoadId: React.Dispatch<any>,
    setPrevLine: React.Dispatch<any>

}

export const UpdateReGenGpx: FC<PProps> = ({
    setIMarkerType,
    formSearchRoute,
    formUpdateGpx,
    optionVectorRoadId,
    setLine,
    setIsCallSearch,
    setMarkersStart,
    setMarkersEnd,
    markersStart,
    markersEnd,
    swapMarkers,
    setLineCreate,
    mapRef,
    handleCopyClick,
    setOptionVectorRoadId,
    setPrevLine

}) => {
    const handleKeyUpStart = (event: any) => {
        if (event.keyCode === 13) {
            const srcLocation = formSearchRoute.getFieldValue('srcLocation');
            const regex = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/
            if (regex.test(srcLocation)) {
                formSearchRoute.setFields([
                    {
                        name: 'srcLocation',
                        errors: []
                    }
                ])
                const [lat, lon] = formSearchRoute.getFieldValue('srcLocation').split(',').map(parseFloat);
                console.log(lat, lon);
                setMarkersStart({
                    lat,
                    lon,
                    type: CMARKER_TYPES.start
                })
                mapRef!.current?.flyTo({ center: [lon, lat], animate: false });
            } else formSearchRoute.setFields([
                {
                    name: 'srcLocation',
                    errors: ['Vui lòng nhập đúng định dạng']
                }
            ])
            console.log(formSearchRoute.getFieldValue('srcLocation'));

        }
    }
    const handleKeyUpEnd = (event: any) => {
        if (event.keyCode === 13) {
            const dstLocation = formSearchRoute.getFieldValue('dstLocation');
            const regex = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/
            if (regex.test(dstLocation)) {
                const [lat, lon] = dstLocation.split(',').map(parseFloat);
                console.log(lat, lon);
                formSearchRoute.setFields([
                    {
                        name: 'dstLocation',
                        errors: []
                    }
                ])
                setMarkersEnd({
                    lat,
                    lon,
                    type: CMARKER_TYPES.end
                })
                mapRef!.current?.flyTo({ center: [lon, lat], animate: false });
            }
            else formSearchRoute.setFields([
                {
                    name: 'dstLocation',
                    errors: ['Vui lòng nhập đúng định dạng']
                }
            ])

        }
    }
    const handleUpdateGpx = (values: any) => {
        const newVectorRoadId = values.newVectorRoadId ? `${values.newVectorRoadId}_${values.wayDirection}` : values.vectorRoadId;
        const data = {
            vectorRoadId: `${values.vectorRoadId}`,
            wayDirection: values.wayDirection,
            vectorGeometry: values.vectorGeometry,
            newVectorRoadId: newVectorRoadId,
        }
        formUpdateGpx.setFieldValue('vectorRoadId', newVectorRoadId)
        updateGpx(data).then((data) => {
            message.success('Cập nhật GPX thành công');
            handleCallListVectorRoad(setOptionVectorRoadId, setPrevLine);
        }).catch((err) => {
            message.error('Cập nhật GPX thất bại');
            console.log('err', err);
        })
    }
    const handleReGenerateGPX = () => {
        const dataRegen = {
            vectorRoadId: formUpdateGpx.getFieldValue('vectorRoadId'),
            vectorGeometry: formUpdateGpx.getFieldValue('vectorGeometry'),
            maxDistance: formUpdateGpx.getFieldValue('maxDistance') || 5,
            gpxNormalStep: formUpdateGpx.getFieldValue('gpxNormalStep') || 100,
        }
        reGenerateGpx(dataRegen).then((data) => {
            message.success('Re-generate GPX thành công');
            handleCallListVectorRoad(setOptionVectorRoadId, setPrevLine);
            window.open(`${data.data.download_link}`);
        })
    }
    return <div key='line-traffic' className="shadow-boxShadow rounded-lg p-4 bg-white">
        <Form form={formSearchRoute} layout='horizontal'>
            <Row gutter={2}>
                <Col span={18}>
                    <Form.Item required name='srcLocation' >
                        <Input onKeyUp={handleKeyUpStart} allowClear placeholder='Toạ độ start(vĩ độ-lat,kinh độ-lon)' />
                    </Form.Item>
                </Col>
                <Col span={2}>
                    <Button className="bg-base text-white w-24" 
                        onClick={() => setIMarkerType(CMARKER_TYPES.start)}>Chọn</Button>
                </Col>
            </Row>
            <Row gutter={2}>
                <Col span={18}>
                    <Form.Item required name='dstLocation'>
                        <Input onKeyUp={handleKeyUpEnd} allowClear placeholder='Toạ độ end(vĩ độ-lat,kinh độ-lon)' />
                    </Form.Item>
                </Col>
                <Col span={2}>
                    <Button className="bg-base text-white w-24" onClick={() => setIMarkerType(CMARKER_TYPES.end)}>Chọn</Button>
                </Col>
            </Row>
            <Row gutter={4} className="-mb-4" >
                <Col span={21}>
                    <Form.Item required label='Bearings' name='bearings' >
                        <Input allowClear placeholder='Bearings' defaultValue={-1} />
                    </Form.Item>
                </Col>
                <Col span={2}>
                    <Button className="mb-0  bg-base text-white"onClick={swapMarkers}><SwapOutlined onKeyDown={undefined} /></Button>
                </Col>
            </Row>
            <Divider></Divider>
        </Form>
        <Form onFinish={handleUpdateGpx} form={formUpdateGpx} layout='vertical'>
            <Row className="mb-0" gutter={4}>
                <Col span={16}>
                    <Form.Item required label='Vector Road Id' name='vectorRoadId' rules={[{ required: true, message: 'Vui lòng chọn Vector Road Id' }]}>
                        <Select showSearch
                            listHeight={400}
                            onChange={(e) => {
                                console.log(e);
                                const selectedOption = optionVectorRoadId.find((option: any) => option.value == e);
                                if (selectedOption) {
                                    const { vectorGeometry, wayDirection } = selectedOption;
                                    formUpdateGpx.setFieldsValue({
                                        wayDirection: wayDirection,
                                        vectorGeometry: vectorGeometry,
                                        newVectorRoadId: (e.includes("_chieu_di") || e.includes("_chieu_ve")) ? e.replace(/_chieu_(di|ve)/, "") : e,
                                    });
                                    setLine(undefined);
                                    setIsCallSearch(false);
                                    const arr_coord = decode(vectorGeometry).map((item: any) => [item[1], item[0]]);
                                    if (arr_coord.length > 0) {
                                        const bounds = new LngLatBounds();
                                        arr_coord.forEach(coord => bounds.extend(new LngLat(coord[0], coord[1])));
                                        mapRef.current?.fitBounds(bounds, { padding: 20, animate: false })
                                    }
                                    setMarkersStart({ ...markersStart, lat: arr_coord[0][1], lon: arr_coord[0][0] });
                                    const endIndex = arr_coord.length - 1;
                                    setMarkersEnd({ ...markersEnd, lat: arr_coord[endIndex][1], lon: arr_coord[endIndex][0] });
                                    formSearchRoute.setFieldsValue({
                                        srcLat: arr_coord[0][1],
                                        srcLon: arr_coord[0][0],
                                        dstLon: arr_coord[endIndex][0],
                                        dstLat: arr_coord[endIndex][1],
                                    });
                                    setLineCreate({
                                        type: 'FeatureCollection',
                                        features: [
                                            {
                                                type: 'Feature',
                                                properties: {},
                                                geometry: {
                                                    type: 'LineString',
                                                    coordinates: arr_coord,
                                                }
                                            },
                                        ],
                                    });
                                }
                            }} >
                            {optionVectorRoadId.map((item: any) => <Select.Option key={item.value + Math.random()} value={item.value}>{item.label}</Select.Option>)}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item required name='wayDirection' label='Way Direction' >
                        <Select options={kindOfWayDirection}></Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={4} align={'middle'}>
                <Col span={20}>
                    <Form.Item required name='vectorGeometry' label='Vector Geometry' >
                        <Input allowClear placeholder='Nhập Vector Geometry' disabled />
                    </Form.Item>
                </Col>
                <Col span={2}>
                    <Button type='primary' onClick={() => handleCopyClick(formUpdateGpx.getFieldValue('vectorGeometry'))}
                        className="bg-base text-white -mb-2" >Copy</Button>
                </Col>
            </Row>
            <Form.Item required name='newVectorRoadId' label='New Vector Road Id'>
                <Input allowClear placeholder='newVectorRoadId' />
            </Form.Item>
            <Row className="-mb-4" gutter={4}>
                <Col span={10}>
                    <Form.Item required name='maxDistance' label='Max Distance' initialValue={5} >
                        <Input allowClear placeholder='maxDistance' />
                    </Form.Item>
                </Col>
                <Col span={10}>
                    <Form.Item required name='gpxNormalStep'
                        label='Gpx Normal Step' initialValue={100} >
                        <Input allowClear placeholder='gpxNormalStep' />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={4}>
                <Col span={8}>
                    <Form.Item>
                        <Button className="bg-base w-32" type='primary' htmlType='submit' >
                            Cập nhật
                        </Button>
                    </Form.Item>
                </Col>
                <Col className="ml-2" span={8}>
                    <Button className="bg-base w-32"  type='primary' onClick={handleReGenerateGPX}>Re-generate</Button>
                </Col>
            </Row>
            <p></p>
        </Form>
    </div>
}