import { Link, } from 'react-router-dom';
import { FC } from 'react';
import { Row, Col, List } from 'antd';
import { FeedbackData } from '../../../constant/type';
import moment from 'moment';
import { optionPointV2, trafficMappingsV3 } from '../../../constant';
import './FeedbackList.css'
import { ImageList } from './ImageList';
interface PProps {
  item: FeedbackData
}
export const FeebackList: FC<PProps> = ({
  item
}) => {
  const trafficSignV2 = (() => {
    const feedbackContent = item.userFeedbackContent;
    const categoryData = feedbackContent?.categoryData;

    if (!categoryData) return '';

    const { trafficInfo, speed } = categoryData;

    if (Array.isArray(trafficInfo) && trafficInfo.length > 0) {
      return trafficInfo.map((value) => {
        const valueTrimmed = value.trim();
        const option = optionPointV2.find((option) => option.value === valueTrimmed);

        const updatedLinkState = {
          lat: item.lat,
          lon: item.lon,
          bearing: item.bearing,
          traffic_sign_id: valueTrimmed,
        };

        let parsedString = '';
        for (const key in updatedLinkState) {
            parsedString += `/${updatedLinkState[key as keyof typeof updatedLinkState]}`;
        }

        const linkElement = 
          <Link target="_blank" to={{ pathname: `/DataSystemV2${parsedString}` }}>
            {option?.label},{' '}
          </Link>
       ;

        return option ? linkElement : null;
      });
    }

    if (Array.isArray(speed) && speed.length > 0) {
      return speed.map((value) => {
        const valueTrimmed = value.trim();
        const option = optionPointV2.find((option) => option.value === valueTrimmed);

        const updatedLinkState = {
          lat: item.lat,
          lon: item.lon,
          bearing: item.bearing,
          traffic_sign_id: valueTrimmed,
        };

        let parsedString = '';
        for (const key in updatedLinkState) {
            parsedString += `/${updatedLinkState[key as keyof typeof updatedLinkState] ?? 'null'}`;
          
        }

        return (
          <Link target="_blank" to={{ pathname: `/DataSystemV2${parsedString}` }}>
            {option?.label}
          </Link>
        );
      });
    }

    return '';
  })();

  const userContent = item.userFeedbackContent && item.userFeedbackContent?.categoryData && Array.isArray(item.userFeedbackContent?.categoryData?.userContent)
    ? item.userFeedbackContent.categoryData?.userContent.join(", ")
    : "";
  const phoneCustomer = item.customerPhone && typeof item.customerPhone === 'string' && item.customerPhone.replace('+84', '0')
  return (
    <List.Item key={item.id ? item.id.toString() + Math.random() : Math.random()}>
      <div className='w-full flex justify-center mx-7' >
        <Row
          gutter={[0, 0]}
          className='p-4 flex rounded-2xl mb-6 w-full bg-grey '
          justify={'start'}
        >
          <Col span={11}>
            <Row gutter={[0, 0]} className='mb-4'>
              <Col span={8} className='font-bold text-textSize'>Người dùng:</Col>
              <Col span={16} className='text-lg text-blue'>
                <Link
                  target='_blank'
                  to={{ pathname: `/Playback/${phoneCustomer}/${item.timeFeedback}` }}>
                  {phoneCustomer}
                </Link>
              </Col>

            </Row>
            <Row gutter={[0, 0]} className='mb-4'>
              <Col span={8} className='font-bold text-textSize'>Ngày đánh giá:</Col>
              <Col span={16} className='text-textSize'>
                {/* {item.timeFeedback} */}
                {moment(item.timeFeedback * 1000).format('HH:mm - DD/MM/YYYY')}
              </Col>
            </Row>
            {userContent && <Row gutter={[0, 0]} className='mb-4'>
              <Col span={8} className='font-bold text-textSize'>Nội dung:</Col>
              <Col span={16} className='text-textSize'>{userContent}</Col>
            </Row>}

            {item.listImage.length > 0 && (
              <Row gutter={[0, 0]} className='mb-4'>
                <Col span={8} className='font-bold text-textSize'>Hình ảnh:</Col>
                <Col span={16}>
                  <ImageList images={item.listImage}></ImageList>
                </Col>
              </Row>
            )}
          </Col>
          <Col span={11} className='pl-32' >
            {/* {trafficSign && <Row gutter={[0, 0]} className='mb-2'>
              <Col span={12} className='font-bold text-textSize'>Tốc độ giới hạn:</Col>
              <Col span={4} className='text-textSize text-blue'>{trafficSign}</Col>
            </Row>}
            {trafficInfo && <Row gutter={[0, 0]} className='mb-2 w-full'>
              <Col span={12} className='font-bold text-textSize'>Thông tin giao thông:</Col>
              <Col span={12} className='text-textSize text-blue'><div>{trafficInfo}</div></Col>
            </Row>} */}


            {trafficSignV2 && <Row gutter={[0, 0]} className='mb-2 w-full'>
              <Col span={12} className='font-bold text-textSize'>Thông tin giao thông:</Col>
              <Col span={12} className='text-textSize text-blue'><div>{trafficSignV2}</div></Col>
            </Row>}
            <Row></Row>
            <Row gutter={[0, 0]} className='mb-4' >
              <Col span={4} className='font-bold text-textSize'>Vị trí:</Col>
              <Col span={12} className='text-textSize'>{item.lon?.toFixed(4)}, {item.lat?.toFixed(4)}</Col>
              <Col span={6} className='font-bold text-textSize'>Góc hướng:</Col>
              <Col span={2} className='text-textSize'>{typeof item.bearing === 'number' && !isNaN(item.bearing)
                ? item.bearing.toFixed(0)
                : item.bearing}</Col>
            </Row>
          </Col>
        </Row>
      </div>
    </List.Item>
  )
}