import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { publicIpv4 } from 'public-ip';
import { VALID_IP } from '../constant';


async function addIpAddressToRequestConfig(config: AxiosRequestConfig): Promise<AxiosRequestConfig> {
  try {

    const ip = await publicIpv4();
    if (ip !== VALID_IP) {
        window.location.href = '/login';
        throw new Error('IP address not allowed');
    }
    if (!config.params) {
      return {...config};

    }
    return {...config, params: config.params.params};
  } catch (error) {
    // Handle the error here
    console.error('Error retrieving IP address:', error);
    // Call publicIpv4() again or handle the retry logic as needed
    return addIpAddressToRequestConfig(config);
  }
  }

async function sendRequest<T>(config: AxiosRequestConfig): Promise<AxiosResponse<T>> {
  const requestConfig = await addIpAddressToRequestConfig(config);
  return axios.request<T>(requestConfig);
}

async function addIpAddressToRequestConfigPost(config: AxiosRequestConfig): Promise<AxiosRequestConfig> {
  try {
    const ip = await publicIpv4();
    if (ip !== VALID_IP) {
      window.location.href = '/login';
      throw new Error('IP address not allowed');
    }
    return config;
  } catch (error) {
    console.error('Error retrieving IP address:', error);
    return addIpAddressToRequestConfigPost(config);
  }
}


async function sendRequestPost<T>(config: AxiosRequestConfig): Promise<AxiosResponse<T>> {
  const requestConfig = await addIpAddressToRequestConfigPost(config);
  return axios.request<T>(requestConfig);
}

export function post<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
  const requestConfig: AxiosRequestConfig = {
    method: 'post',
    url: `${process.env.REACT_APP_BASE_URL}${url}`,
    data,
    ...config,
  };
  return sendRequestPost<T>(requestConfig);
}

export async function get<T>(url: string, params?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    const requestConfig: AxiosRequestConfig = {
      method: 'get',
      url: `${process.env.REACT_APP_BASE_URL}${url}`,
      params,
      ...config,
    };
    try {
      const request = await sendRequest<T>(requestConfig);
      return request;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  export function del<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    const requestConfig: AxiosRequestConfig = {
      method: 'delete',
      url: `${process.env.REACT_APP_BASE_URL}${url}`,
      ...config,
    };
    return sendRequestPost<T>(requestConfig);
  }

//   export async function delete<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
//     try {
//       const requestConfig: AxiosRequestConfig = {
//         method: 'delete',
//         url: `${API_process.env.REACT_APP_BASE_URL}${url}`,
//         ...config,
//       };
//       const updatedConfig = await addIpAddressToRequestConfig(requestConfig);
//       const response = await sendRequest<T>(updatedConfig);
//       return response;
//     } catch (error) {
//       console.log(error);
//       throw error;
//     }
//   }

export const URL_UPLOAD_IMAGE= `${process.env.REACT_APP_BASE_URL}/tool/realtime-traffic/upload-image`