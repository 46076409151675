import React, { FC } from 'react';
import { Button, FormInstance, Form, Input } from 'antd';
import { postTrafficNews, postUpdateTrafficNews } from '../../../services';
import { convertInputToOutput } from '../helper';
import { LevelType, MarkerType } from '../../../constant/type';
import { FormLevel } from './FormLevel';

interface PProps {
    onGetTrafficRealtime({ setLine, setPoints }: {
        setLine: (value: any) => void;
        setPoints: (value: any) => void;
    }): void,
    setPoints: (value: any) => void,
    setLine: (value: any) => void,
    formUpdateLine: FormInstance<any>,
    setLineCongestion: (value: any) => void,
    setMarkersStart: (value: React.SetStateAction<MarkerType | undefined>) => void,
    setMarkersEnd: (value: React.SetStateAction<MarkerType | undefined>) => void,
    setActiveKeyLine: (value: React.SetStateAction<string>) => void,
    setLevelInfo: React.Dispatch<React.SetStateAction<LevelType>>,
    levelInfor: LevelType,
    formLine: FormInstance<any>,
    formCreateCongestion: FormInstance<any>,
    setIsEditLevel: React.Dispatch<React.SetStateAction<boolean>>,
    setIMarkerType: React.Dispatch<React.SetStateAction<string>>,
    setMarkerCenter: React.Dispatch<React.SetStateAction<MarkerType | undefined>>
}
export const UpdateLine: FC<PProps> = ({
    onGetTrafficRealtime,
    setPoints,
    setLine,
    formUpdateLine,
    setLineCongestion,
    setMarkersStart,
    setMarkersEnd,
    setActiveKeyLine,
    formLine,
    formCreateCongestion,
    setIsEditLevel,
    setIMarkerType,
    levelInfor,
    setLevelInfo,
    setMarkerCenter
}) => {

    const handleFormUpdateLineTraffic = (data: any) => {
        postUpdateTrafficNews(convertInputToOutput(data)).then((res) => {
            if (res.status == 200) {
                setLineCongestion(undefined);
                setMarkersStart(undefined);
                setMarkersEnd(undefined);
                onGetTrafficRealtime({
                    setLine,
                    setPoints
                });
                setMarkerCenter(undefined);
                formUpdateLine.resetFields();
                formLine.resetFields();
                formCreateCongestion.resetFields();
                setActiveKeyLine('1');
            }
        })
    }

    const renderFormLevel = (levelName: string) => (
        <>
            <h3>{levelName == 'level1' ? 'Level 1' : levelName == 'level2' ? 'Level 2' : 'Level3'} </h3>
            <FormLevel
                levelName={levelName}
                setLevelInfo={setLevelInfo}
                formCongestion={formUpdateLine}
                formLine={formLine}
                setIsEditLevel={setIsEditLevel}
                setMarkersStart={setMarkersStart}
                setMarkersEnd={setMarkersEnd}
                setLineCongestion={setLineCongestion}
                setIMarkerType={setIMarkerType}
                levelInfor={levelInfor}
            />
        </>
    );
    return (
        <Form form={formUpdateLine} layout='horizontal' onFinish={handleFormUpdateLineTraffic}>

            <Form.Item label='Vị trí tâm lưới' required name='center'>
                <Input disabled allowClear placeholder='Toạ độ center(vĩ độ-lat,kinh độ-lon)' />
            </Form.Item>

            {renderFormLevel('level1')}
            {renderFormLevel('level2')}
            {renderFormLevel('level3')}
            <Form.Item>
                <Button className='bg-base' type='primary' htmlType='submit'>
                    Hoàn thành
                </Button>
            </Form.Item>
        </Form>
    )
}