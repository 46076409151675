import { DirectionAndAngle, MarkerType } from "../constant/type";
import { decode, encode } from "@googlemaps/polyline-codec";

export const getDirectionAndAngle = (pointA: MarkerType, pointB: MarkerType): DirectionAndAngle => {
    const { lat: lat1, lon: lon1 } = pointA;
    const { lat: lat2, lon: lon2 } = pointB;

    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const lat1Rad = lat1 * (Math.PI / 180);
    const lat2Rad = lat2 * (Math.PI / 180);

    const y = Math.sin(dLon) * Math.cos(lat2Rad);
    const x = Math.cos(lat1Rad) * Math.sin(lat2Rad) - Math.sin(lat1Rad) * Math.cos(lat2Rad) * Math.cos(dLon);
    const bearing = Math.atan2(y, x) * (180 / Math.PI);

    const direction = (bearing + 360) % 360;
    const compass = getDirectionFromAngle(direction);

    return { compass, direction };
};

export const getDirectionFromAngle = (degree: number): string => {
    // const directions = ['NE', 'SE', 'SW', 'NW'];
    // const index = Math.floor(angle / 90 + 0.5) % 4;
    // return directions[index];

    const normalizedDegree = degree;
    if (normalizedDegree >= 45 && normalizedDegree < 135) {
        return "NE";
    } else if (normalizedDegree >= 135 && normalizedDegree < 225) {
        return "SE";
    } else if (normalizedDegree >= 225 && normalizedDegree < 315) {
        return "SW";
    } else {
        return "NW";
    }
};

export const getPointB = (pointA: MarkerType, angle: number): MarkerType => {
    const delta_lat = 50 * Math.cos(angle * Math.PI / 180) / 111111;
    const delta_lon = 50 * Math.sin(angle * Math.PI / 180) / (111111 * Math.cos(pointA.lat * Math.PI / 180));
    const latB = pointA.lat + delta_lat;
    const lonB = pointA.lon + delta_lon;
    return { lat: latB, lon: lonB };
};

/**
 * Return direction of the bearing
 * @param {number} bearing
 * @returns {String[]}
 */

export const checkBearing = (bearing: number): String[] => {
    if (bearing == 45)
        return ["NE"]
    if (bearing == 135) return ["SE"]
    if (bearing == 225) { return ["SW"] }
    if (bearing == 315) { return ["NW"] }
    if (bearing > 315 || bearing < 45) {
        return ["NW", "NE"]
    } else if (bearing > 45 && bearing < 135) {
        return ["NE", "SE"]
    } else if (bearing > 135 && bearing < 225) {
        return ["SE", "SW"]
    } else {
        return ["SW", "NW"]
    }
}

export function decodeGeometry(encodedGeometry: string) {
    const decodedCoords = decode(encodedGeometry);
    const decodedCoordsScaled = decodedCoords.map(([lat, lon]) => [lon / 10, lat / 10]);

    const lineString = {
        type: 'LineString',
        coordinates: decodedCoordsScaled,
    };
    return lineString;
}

function decodeGeo(encodedGeometry:any) {
    const decodedCoords = decode(encodedGeometry);
    const decodedCoordsScaled = decodedCoords.map(([lat, lon]) => [lon / 10, lat / 10]);
    return decodedCoordsScaled;
}

export function extractRoute(route : any) {
    const linestring : any = {
        type: 'LineString',
        coordinates: [],
    };
    if (!route.trip || !route.trip.legs) {
        return linestring;

    }
    for (const leg of route.trip.legs) {
        const geometry = leg.shape || [];
        if (geometry.length > 0) {
            decodeGeo(geometry).forEach(item => linestring.coordinates.push(item));
        }
    }

    return linestring;
}