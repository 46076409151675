// const convertHEICtoJPEG = async (url: string) => {
  //   try {
  //     const response = await fetch(url);
  //     const blob = await response.blob();
  //     const conversionResult = await heic2any({ blob, toType: 'image/jpeg', quality: 1 });
  //     const convertedUrl = URL.createObjectURL(conversionResult as Blob);
  //     console.log('convertedUrl', convertedUrl);

import { useEffect, useState } from "react";
import heic2any from "heic2any";
import { checkUrl } from "../../../helper/helper";
import { STORAGE_URL } from "../../../constant";
import { Image } from "antd";
  
  //     setImageHeic(prevState => [...prevState, { imageUrl: convertedUrl, imageHeicUrl: url }]);
  //   } catch (error) {
  //     console.error('Error converting HEIC to JPEG:', error);
  //   }
  // };
  const convertHEICToJPEG = async (heicURL: string) => {
    const convertedBlob = await heic2any({
      blob: await fetch(heicURL).then(response => response.blob()),
      toType: 'image/jpeg'
    });
    return URL.createObjectURL(convertedBlob as Blob );
  };
export const ImageList = ({ images  } : any) => {
    const [convertedImages, setConvertedImages]  = useState<any>([]);

    useEffect(() => {
      const convertImages = async () => {
        const converted = await Promise.all(
          images.map(async (image:any) => {
            let imageUrl = checkUrl(image) ? image : `${STORAGE_URL}${image}`;

            if (imageUrl.endsWith('.heic')) {
              const convertedURL = await convertHEICToJPEG(imageUrl);
              return convertedURL;
            }
            return imageUrl;
          })
        );
        setConvertedImages(converted);
      };

      convertImages();
    }, [images]);
    return (
      <>
        {convertedImages.map((imageUrl :any, index:any) => (
          <Image className='w-78' key={index} src={imageUrl} alt={`Image ${index}`} />
        ))}
      </>
    );
  };