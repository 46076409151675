import { Layer, Map, Source, MapRef, Marker } from "react-map-gl";
import {
  MAPBOX_TOKEN,
  initialViewState,
  layerReverseStyleOriginal,
  layerReverseStyleSnap,
  layerReverseStyleSnapNominatim,
  layerSearchRouteStyle,
} from "../../constant";
import { Button, Form, Input, Row, Upload, Col, Select } from "antd";
import { useRef, useState } from "react";
import { CMarkerType } from "../../constant/type";
import { useForm } from "antd/es/form/Form";
import { UploadOutlined } from "@ant-design/icons";
import { LngLat, LngLatBounds } from "mapbox-gl";
import axios from "axios";
import { decodeGeometry } from "../../helper/LocationHelper";
import { XMLParser } from "fast-xml-parser";

export default function ViewGeometry() {
  const defaultOriginal = "#e32636";
  const defaultSnap = "#76ee00";
  const defaultNominatim = "#0000ff";
  const [lineCreate, setLineCreate] = useState<any>();
  const [fastLine, setfastLine] = useState<any>();
  const [nominatimLine, setNominatimLine] = useState<any>();
  const [markersStart, setMarkersStart] = useState<CMarkerType>();
  const [markersEnd, setMarkersEnd] = useState<CMarkerType>();
  const mapRef = useRef<MapRef>(null);
  const [formSample] = useForm();

  const [isShowMarker, setIsShowMarker] = useState(false);
  const [isShowMarkerNominatim, setIsShowMarkerNominatim] = useState(false);
  const [formView] = useForm();
  const [markersView, setMarkersView] = useState<any>();
  const [markerOriginal, setmarkerOriginal] = useState<any>();
  const [markers, setMarkers] = useState<Array<{ lon: number; lat: number }>>(
    []
  );
  const [markersNominatim, setMarkersNominatim] = useState<
    Array<{ lon: number; lat: number }>
  >([]);
  const [popupInfo, setPopupInfo] = useState<{
    lon: number;
    lat: number;
    data: any;
  } | null>(null);
  const [filterMarker, setFilterMarker] = useState<boolean>(false);

  const [file1, setFile1] = useState<any>(null);
  const [file2, setFile2] = useState<any>(null);

  const handleKeyUpEnd = (event: any) => {
    if (event.keyCode === 13) {
      const viewLocation = formView.getFieldValue("viewLocationField");
      const regex =
        /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;
      if (regex.test(viewLocation)) {
        const [lat, lon] = viewLocation.split(",").map(parseFloat);
        console.log(lat, lon);
        formView.setFields([{ name: "viewLocationField", errors: [] }]);
        setMarkersView({ lat, lon });
        mapRef!.current?.flyTo({ center: [lon, lat], animate: false });
      } else {
        formView.setFields([
          {
            name: "viewLocationField",
            errors: ["Please enter valid coordinates"],
          },
        ]);
      }
    }
  };

  const checkColor = (color: string) => {
    if (color === defaultOriginal) {
      return defaultOriginal;
    } else if (color === defaultSnap) {
      return defaultSnap;
    } else {
      return defaultNominatim;
    }
  };

  const handleUpload = (file: Blob, color: string) => {
    const reader = new FileReader();

    reader.onload = (e: any) => {
      if (typeof e.target.result === "string") {
        const parser = new XMLParser({ ignoreAttributes: false });
        const gpxData = parser.parse(e.target.result);
        const waypoints = gpxData.gpx.wpt;

        const coordinatesArray = waypoints.map(
          (wpt: { [x: string]: string }) => ({
            lon: parseFloat(wpt["@_lon"]),
            lat: parseFloat(wpt["@_lat"]),
          })
        );

        console.log(coordinatesArray);

        const bounds = new LngLatBounds();
        coordinatesArray.forEach((coord: { lon: number; lat: number }) =>
          bounds.extend(new LngLat(coord.lon, coord.lat))
        );

        mapRef.current?.fitBounds(bounds, { padding: 20, animate: false });

        const features = {
          type: "Feature",
          properties: {
            color: checkColor(color),
          },
          geometry: {
            type: "LineString",
            coordinates: coordinatesArray.map((coord: any) => [
              coord.lon,
              coord.lat,
            ]),
          },
        };

        if (color === defaultOriginal) {
          setLineCreate(features);
          setmarkerOriginal(coordinatesArray);
        } else if (color === defaultSnap) {
          setfastLine(features);
          setMarkers(coordinatesArray);
        } else {
          setNominatimLine(features);
          setMarkersNominatim(coordinatesArray);
        }
      } else {
        console.error("File reading result is not a string.");
      }
    };

    reader.readAsText(file);
    return false;
  };

  const handleFormViewFinish = (data: any) => {
    const [lat, lon] = data.VLocation.split(",").map(parseFloat);

    // mapRef!.current?.flyTo({ center: [lon, lat], animate: false });
    // getMapPoint({ lat: lat, lon: lon }).then((res: MapPoint[]) => {
    //   if (res.length > 0) {
    //     const bounds = new LngLatBounds();
    //     res.forEach((coord) => bounds.extend(new LngLat(coord.lon, coord.lat)));
    //     mapRef.current?.fitBounds(bounds, { padding: 20, animate: false });
    //   }
    //   setPoints(res);
    // });
    // getRoadName({ lat: lat, lon: lon })
    //   .then((res: any) => {
    //     if (res.name) {
    //       formView.setFieldsValue({
    //         name: res.name,
    //       });
    //       setNotFoundRoad(false);
    //     } else {
    //       formView.resetFields(["name"]);
    //       setNotFoundRoad(true);
    //     }
    //   })
    //   .catch((err) => {
    //     setNotFoundRoad(true);
    //     formView.resetFields(["name"]);
    //   });
    // setMarkers({ lat: lat, lon: lon });
  };

  const handleKeyUp = (event: any) => {
    if (event.keyCode === 13) {
      formView.submit();
    }
  };

  return (
    <div className="h-screen flex flex-row items-stretch">
      <div className="w-[16rem] p-2 overflow-auto flex flex-col">
        <Form form={formView}>
          <Row>
            <Form.Item
              name="viewLocationField"
              rules={[{ required: true, message: "Please enter coordinates" }]}
            >
              <Input
                allowClear
                placeholder="Enter coordinates (lat, lon)"
                onKeyUp={handleKeyUpEnd}
              />
            </Form.Item>
            <Button
              onClick={() => {
                const viewLocation =
                  formView.getFieldValue("viewLocationField");
                const regex =
                  /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?((1[0-7]\d)|([1-9]?\d)|180)(\.\d+)?$/;
                if (regex.test(viewLocation)) {
                  const [lat, lon] = viewLocation.split(",").map(parseFloat);
                  formView.setFields([
                    { name: "viewLocationField", errors: [] },
                  ]);
                  setMarkersView({ lat, lon });
                  mapRef!.current?.flyTo({
                    center: [lon, lat],
                    animate: false,
                  });
                }
              }}
              className="bg-base text-white mr-2"
            >
              View
            </Button>
          </Row>
        </Form>
        <Button onClick={() => setIsShowMarker(!isShowMarker)}>
          {!isShowMarker ? `Show Marker` : `Hide Marker`}
        </Button>
        <Upload
          accept=".json"
          beforeUpload={(file) => {
            const reader = new FileReader();
            reader.onload = (e: any) => {
              const coordinatesArray = JSON.parse(e.target.result).coordinates;
              const bounds = new LngLatBounds();
              coordinatesArray.forEach((coord: any) =>
                bounds.extend(new LngLat(coord[0], coord[1]))
              );
              mapRef.current?.fitBounds(bounds, {
                padding: 20,
                animate: false,
              });
              const features = {
                type: "Feature",
                properties: { color: "red" },
                geometry: { type: "LineString", coordinates: coordinatesArray },
              };
              setLineCreate(features);
            };
            reader.readAsText(file);
            return false;
          }}
        >
          <Button icon={<UploadOutlined />}>Upload JSON File</Button>
        </Upload>
        <Upload
          accept=".json"
          beforeUpload={(file) => {
            const reader = new FileReader();
            reader.onload = async (e: any) => {
              const coordinatesArray = JSON.parse(e.target.result).coordinates;
              const payload = {
                costing: "auto",
                costing_options: {
                  auto: {
                    exclude_polygons: [],
                    maneuver_penalty: 5,
                    country_crossing_penalty: 0,
                    country_crossing_cost: 600,
                    width: 1.6,
                    height: 1.9,
                    use_highways: 1,
                    use_tolls: 1,
                    use_ferry: 1,
                    ferry_cost: 300,
                    use_living_streets: 0.5,
                    use_tracks: 0,
                    private_access_penalty: 450,
                    ignore_closures: false,
                    closure_factor: 9,
                    service_penalty: 15,
                    service_factor: 1,
                    exclude_unpaved: 1,
                    shortest: false,
                    exclude_cash_only_tolls: false,
                    top_speed: 140,
                    fixed_speed: 0,
                    toll_booth_penalty: 0,
                    toll_booth_cost: 15,
                    gate_penalty: 300,
                    gate_cost: 30,
                    include_hov2: false,
                    include_hov3: false,
                    include_hot: false,
                    disable_hierarchy_pruning: false,
                    use_trails: 0,
                  },
                },
                exclude_polygons: [[]],
                directions_options: { units: "kilometers" },
                locations: coordinatesArray.map((item: any) => ({
                  lat: item[1],
                  lon: item[0],
                  type: "via",
                })),
                id: "valhalla_directions",
              };
              const valhalla_url = "https://valhalla1.openstreetmap.de/route";
              try {
                const response = await axios.post(valhalla_url, payload);
                if (response.status === 200) {
                  const data = response.data;
                  let linestring = null;
                  for (const leg of data.trip.legs) {
                    const geometry = leg.shape || [];
                    if (geometry.length > 0) {
                      linestring = decodeGeometry(geometry);
                      break;
                    }
                  }
                  const features = {
                    type: "Feature",
                    properties: { color: "red" },
                    geometry: linestring,
                  };
                  setLineCreate(features);
                } else {
                  console.error(`Error get_valhalla_route: ${response.status}`);
                  console.error(response.data);
                  return null;
                }
              } catch (error) {
                console.error("Error get_valhalla_route:", error);
                return null;
              }
            };
            reader.readAsText(file);
            return false;
          }}
        >
          <Button icon={<UploadOutlined />}>Upload JSON to Valhalla</Button>
        </Upload>
        <Upload
          style={{ marginTop: 10 }}
          accept=".gpx"
          beforeUpload={(file) => {
            setFile1(file);
            handleUpload(file, defaultOriginal);
            return false;
          }}
        >
          <Button icon={<UploadOutlined />}>Upload GPX User File</Button>
        </Upload>
        <Upload
          style={{ marginTop: 10 }}
          accept=".gpx"
          beforeUpload={(file) => {
            setFile2(file);
            handleUpload(file, defaultSnap);
            return false;
          }}
        >
          <Button icon={<UploadOutlined />}>
            Upload GPX Snap Fast server File
          </Button>
        </Upload>
        {/* <Upload
          style={{ marginTop: 10 }}
          accept=".gpx"
          beforeUpload={(file) => {
            setFile2(file);
            handleUpload(file, defaultSnap);
            return false;
          }}
        >
          <Button icon={<UploadOutlined />}>
            Upload GPX Snap Nominatim File
          </Button>
        </Upload> */}
      </div>
      <Map
        style={{ flex: 4 }}
        initialViewState={initialViewState}
        mapStyle={`mapbox://styles/mapbox/streets-v12`}
        ref={mapRef as React.Ref<MapRef>}
        mapboxAccessToken={MAPBOX_TOKEN}
      >
        {markersView && (
          <Marker latitude={markersView.lat} longitude={markersView.lon}>
            <div>
              <img src="./pin-start.png" alt="marker" />
            </div>
          </Marker>
        )}
        {markers.map((marker, index) => (
          <Marker key={index} latitude={marker.lat} longitude={marker.lon}>
            <div>
              <img src="./pin-start.png" alt="marker" />
            </div>
          </Marker>
        ))}
        {/* {markerOriginal.map((marker: any, index: any) => (
          <Marker key={index} latitude={marker.lat} longitude={marker.lon}>
            <div>
              <img src="./pin-start.png" alt="marker" />
            </div>
          </Marker>
        ))}
        {markersNominatim.map((marker, index) => (
          <Marker key={index} latitude={marker.lat} longitude={marker.lon}>
            <div>
              <img src="./pin-start.png" alt="marker" />
            </div>
          </Marker>
        ))} */}
        {lineCreate && (
          <Source id="line-create" type="geojson" data={lineCreate}>
            <Layer {...layerReverseStyleOriginal} />
          </Source>
        )}
        {fastLine && (
          <Source id="multi-line-fast" type="geojson" data={fastLine}>
            <Layer {...layerReverseStyleSnap} />
          </Source>
        )}
        {/* {nominatimLine && (
          <Source id="multi-line-nominatim" type="geojson" data={fastLine}>
            <Layer {...layerReverseStyleSnapNominatim} />
          </Source>
        )} */}
        {isShowMarker &&
          lineCreate &&
          lineCreate.geometry.coordinates.map((item: any, index: number) => (
            <Marker
              key={`marker-${index}`}
              latitude={item[1]}
              longitude={item[0]}
            />
          ))}
      </Map>
    </div>
  );
}
